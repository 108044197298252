import { Box, Grid } from "@mui/material";
import React from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import RequestAPrint from "../userClaims/RequestAPrint";
import Text from "../text/Text";
import { useSelector, RootStateOrAny } from "react-redux";
import { NetworkStatusEnums } from "../../helpers/enums";
import useIsMobile from "../../hooks/useIsMobile";
import { useSnackBar } from "../../context/snackAlert-context";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";
import { FanfireSDK } from "fanfire-sdk";

const styles = {
  actionBar: {
    borderRadius: "8px",
  },
};

interface Props {
  nftDetail: any;
  appConfig: any;
  sdkService: FanfireSDK;
  isMarketPlaceNFT: boolean;
}

export default function DetailActions({
  nftDetail,
  appConfig,
  sdkService,
  isMarketPlaceNFT,
}: Props) {
  const { mobileView } = useIsMobile();
  const { showSnackBar } = useSnackBar();
  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);

  const downloadImage = async (image: any, name: string) => {
    // TODO donwload url with image path
    // future - route to fanfire to claim image
    console.log("Downloading image");
    try {
      const file = await axios
        .get(image, { responseType: "blob" })
        .then((response) => {
          return response.data;
        });
      const url = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.jpg`); //or any other extension
      document.body.appendChild(link);
      link.click();
      showSnackBar(
        'Low Resolution Image downloaded. You can request a Full Resolution Image from the "Request a print Option"! ',
        "success"
      );
    } catch (error) {
      console.log("There was an error downloading the image");
      console.error(error);
      showSnackBar(
        "Downloading Item Failed. Please try again.",
        "error",
        error
      );
    }
  };
  return (
    <>
      {isAuthenticated &&
        !mobileView &&
        nftDetail.owner &&
        sdkService.wallet.walletAddress?.toLowerCase() ===
          nftDetail.owner.toLowerCase() &&
        isMarketPlaceNFT && (
          <Box display="flex" mt={1} component="div">
            <PrimaryButton
              onClick={() =>
                downloadImage(nftDetail.metadata.image, nftDetail.name)
              }
            >
              <Text
                variantText="body1"
                style={{
                  fontWeight: "bold",
                  ...appConfig.general.styleOverrides.buttonPrimaryTextColor,
                }}
              >
                Download Image
              </Text>
            </PrimaryButton>
            <RequestAPrint
              appConfig={appConfig}
              nft={nftDetail}
              sdkService={sdkService}
            />
          </Box>
        )}
      {nftDetail && nftDetail.name && nftDetail.name.includes("Vault") && (
        <Grid
          container
          pt={1}
          alignItems="center"
          justifyContent="center"
          style={styles.actionBar}
        >
          <RouterLink
            style={{ textDecoration: "none" }}
            to={{
              pathname: `/nftCollection/${nftDetail.contractAddress}/${nftDetail.tokenId}`,
            }}
            state={{ nft: nftDetail }}
          >
            <PrimaryButton>
              <Text
                variantText="body1"
                style={{
                  fontWeight: "bold",
                  ...appConfig.general.styleOverrides.buttonPrimaryTextColor,
                }}
              >
                View Vault Allocation
              </Text>
            </PrimaryButton>
          </RouterLink>
        </Grid>
      )}
    </>
  );
}
