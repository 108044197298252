import { FanfireSDK } from "fanfire-sdk";
import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { NetworkStatusEnums } from "../../helpers/enums";

interface Props {
  nft: any;
  sdkService: FanfireSDK;
}

/**
 * This hook is used handle whether a user liked a item or not
 *
 * @param {any} nft The nft we are looking at
 * @param {FanfireSDK} sdkService sdk used to get user wallet
 *
 * @returns { getLikedLoading, getLikedError, likedAmount, liked } Returns whether the  get like is loading, whether there is an error and if the item is liked
 */
const useGetLikedNFT = ({ nft, sdkService }: Props) => {
  const [getLikedLoading, setGetLikedLoading] = useState<boolean>(false);
  const [getLikedError, setGetLikedError] = useState<boolean>(false);
  const [likedAmount, setLikedAmount] = useState<number>(0);
  const [liked, setLiked] = useState<boolean>(false);

  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);

  useEffect(() => {
    if (isAuthenticated && Object.keys(nft).length !== 0) {
      getIsLiked(nft);
    }
  }, [isAuthenticated, nft]);

  useEffect(() => {
    if (Object.keys(nft).length !== 0 && nft.likesUserAddresses) {
      setLikedAmount(nft.likesUserAddresses.length);
    }
  }, [nft]);

  const getIsLiked = (nft: any) => {
    setGetLikedLoading(true);
    try {
      const userWallet = sdkService.wallet.walletAddress;
      // console.log("Getting if item is liked and liked amount", {
      //   nft: nft,
      //   user: userWallet,
      // });

      if (nft.likesUserAddresses !== undefined) {
        if (nft.likesUserAddresses.includes(userWallet?.toLowerCase())) {
          setLiked(true);
        } else {
          setLiked(false);
        }
      }
    } catch (error) {
      console.log(error);
      setGetLikedError(true);
    }
    setGetLikedLoading(true);
  };

  return {
    getLikedLoading,
    getLikedError,
    setLikedAmount,
    setLiked,
    likedAmount,
    liked,
  };
};

export default useGetLikedNFT;
