import React from "react";
import Text from "../../components/text/Text";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ScrollToTop from "../../components/helpers/ScrollToTop";
import Markdown from "markdown-to-jsx";

interface Props {
  faqItems: any;
}

export default function FAQ({ faqItems }: Props) {
  return (
    <>
      <ScrollToTop />
      <Grid container pt={15} alignItems="center" justifyContent="center">
        <Grid item xs={8} pb={5}>
          <Text variantText="h1" style={{ textAlign: "left" }}>
            Frequently Asked<br></br>Questions
          </Text>
        </Grid>
        <Grid item xs={8} pb={10}>
          {faqItems.map((item: any, index: number) => (
            <div key={index}>
              <Box pt={2}>
                <Text variantText="h3" style={{ textTransform: "uppercase" }}>
                  {item.heading}
                </Text>
              </Box>
              {item.questions.map((q: any, i: number) => (
                <Box pt={1} key={i}>
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Text variantText="h6">{q.question}</Text>
                    </AccordionSummary>
                    <AccordionDetails>
                      {q.type && q.type == "video" ? (
                        <iframe
                          style={{
                            height: "auto",
                            width: "100%",
                            aspectRatio: "16 / 9",
                          }}
                          className="video"
                          title="Youtube player"
                          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                          src={q.answer}
                          allowFullScreen
                        ></iframe>
                      ) : (
                        <Text
                          variantText="body1"
                          style={{ textTransform: "none", textAlign: "left" }}
                        >
                          <Markdown children={q.answer} />
                        </Text>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </div>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
