import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import MapCardItems from "../cardItem/MapCardItems";
import { FanfireSDK } from "fanfire-sdk";

interface Props {
  sdkService: FanfireSDK;
  appConfig: any;
}

export default function LikedNFTs({ sdkService, appConfig }: Props) {
  const [likedNFTs, setLikedNFTs] = useState<any>([]);
  const [loadingLikedNFTs, setLoadingLikedNFTs] = useState<boolean>(true);

  // Get Liked NFTs
  const getLikedNFTs = async () => {
    try {
      console.log("Getting Liked NFTs");
      setLoadingLikedNFTs(true);
      const userWallet = sdkService.wallet.walletAddress;
      const _likedNFTs = await sdkService.marketplace.getLikedNFTs(
        userWallet ?? ""
      );
      console.log("likedNFTs: ", _likedNFTs);
      return _likedNFTs;
    } catch (error) {
      console.error(error);
      setLoadingLikedNFTs(false);
      // showSnackBar('Error getting your liked NFTs', 'error', error)
    }
  };

  // Load NFT based on tab (liked / my_items)
  useEffect(() => {
    let mounted = true;
    const prep = async () => {
      const nfts = await getLikedNFTs();
      if (mounted) {
        setLikedNFTs(nfts);
        setLoadingLikedNFTs(false);
      }
    };
    prep();
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Grid item xs={12} alignItems="center" justifyContent="center">
      <MapCardItems
        nftItemsIn={likedNFTs}
        loading={loadingLikedNFTs}
        appConfig={appConfig}
        sdkService={sdkService}
      />
    </Grid>
  );
}
