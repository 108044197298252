import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { currencyFormat } from "../../helpers/formatters";
import { useSelector, RootStateOrAny } from "react-redux";
import { NetworkStatusEnums } from "../../helpers/enums";
import { NavLink as RouterLink } from "react-router-dom";
import { FanfireSDK } from "fanfire-sdk";

//COMPS
import TransferNFT from "../transfer/TransferNFT";
import BasicLoader from "../loaders/BasicLoader";
import ListNFT from "../listing/ListNFT";
import Text from "../text/Text";
import Checkout from "../checkout/Checkout";

//MUI
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useIsMobile from "../../hooks/useIsMobile";
import Paper from "@mui/material/Paper";
import SecondaryButton from "../buttons/SecondaryButton";
import UnlistNFT from "../listing/UnlistNFT";
import Reserved from "../text/Reserved";
import useFetchExchange from "../../hooks/useFetchExchange";

interface Props {
  sdkService: FanfireSDK;
  nft: any;
  sliceStore: any;
  appConfig: any;
  type?: string;
  loadingFastProps: boolean;
  nftDetailListing: any;
}

export default function NFT1155Sellers({
  sdkService,
  nft,
  sliceStore,
  appConfig,
  type,
  loadingFastProps,
  nftDetailListing,
}: Props) {
  // const [currencyLoading, setCurrencyLoading] = useState<boolean>(true);
  const [currency, setCurrency] = useState<Number>(0);
  const [loading, setLoading] = useState(true);
  const [isMarketPlaceNFT, setisMarketPlaceNFT] = useState<boolean>(true);
  const [listings, setListings] = useState<any>([]);

  const { mobileView } = useIsMobile();
  const isWeb3 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb3);
  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);
  const { currencyError, currencyLoading, currencyPrice, getApiExchangeData } =
    useFetchExchange({
      coin: "usd-coin",
      currency: "zar",
      lazy: true,
    });

  const filterListings = (
    listings: Array<any>,
    type: string,
    userWallet: String
  ) => {
    if (type === "owned") {
      return listings.filter(function (el) {
        return el.sellerAddress.toLowerCase() === userWallet.toLowerCase();
      });
    } else if (type === "available") {
      if (isAuthenticated) {
        return listings.filter(function (el) {
          return (
            el.sellerAddress.toLowerCase() !== userWallet.toLowerCase() &&
            el.price
          );
        });
      } else {
        return listings.filter(function (el) {
          return el.price;
        });
      }
    } else {
      console.log("No type tab set - getting all Listings.");
      return listings;
    }
    // });
  };

  useEffect(() => {
    const prep = async () => {
      //currency
      // setCurrencyLoading(true);
      const currency = await getApiExchangeData();
      if (currency !== "error") {
        setCurrency(Number(currency));
      }
      //wallet
      const userWallet = sdkService.wallet.walletAddress;
      const listings: Array<any> | undefined = filterListings(
        nftDetailListing.listings,
        type ?? "",
        userWallet ?? ""
      );
      console.log(`1155 Filtered Listings for tab '${type}': `, listings);
      setListings(listings);
      //marketplace?
      let nftAddressesENV =
        process.env.REACT_APP_FANFIRE_NFT_CONTRACT_ADDRESSES?.split(",") ?? [];
      nftAddressesENV = nftAddressesENV.map(function (x) {
        return x.toLowerCase();
      });
      const isMarketPlaceNFT = nftAddressesENV.includes(
        nft.contractAddress.toLowerCase()
      );
      setisMarketPlaceNFT(isMarketPlaceNFT);
      setLoading(false);
    };
    setLoading(true);
    prep();
  }, [type, isAuthenticated, nftDetailListing]);

  const getOwned1155Qty = (listings: any) => {
    if (listings) {
      const listingOwned = listings.filter(
        (el: any) =>
          el.sellerAddress.toLowerCase() ===
          sdkService.wallet.walletAddress?.toLowerCase()
      );
      if (listingOwned.length) {
        return listingOwned
          .map((item: any) => item.ownedAmount ?? 0)
          .reduce((prev: any, next: any) => Number(prev) + Number(next));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const getOwnedIsListed1155 = (listings: any) => {
    const listingOwned = listings.filter(function (el: any) {
      return (
        el.sellerAddress.toLowerCase() ===
        sdkService.wallet.walletAddress?.toLowerCase()
      );
    });
    if (listingOwned.length) {
      const isListed = listingOwned.map((item: any) => {
        if (Number(item.amount) > 0) {
          return true;
        }
      });
      return isListed[0] ? true : false;
    }
    return false;
  };

  const formatNFTObjectForCheckout: any = (nft1155: any, seller: string) => {
    let nftToPurchase = nft1155.listings.filter(
      (x: any) => x.sellerAddress === seller
    )[0];
    return {
      ...nft,
      ...nftToPurchase,
      price: Number(
        ethers.utils.formatUnits(
          nftToPurchase.price,
          Number(process.env.REACT_APP_FANFIRE_COIN_DECIMALS ?? 18)
        )
      ),
      priceZar:
        Number(
          ethers.utils.formatUnits(
            nftToPurchase.price,
            Number(process.env.REACT_APP_FANFIRE_COIN_DECIMALS ?? 18)
          )
        ) * Number(currency),
      metadata: nft.metadata,
    };
  };

  const formatNFTObjectForListing: any = (
    nft1155Detail: any,
    nft1155DetailListing: any,
    seller: string
  ) => {
    let nftToPurchase = nft1155DetailListing.listings.filter(
      (x: any) => x.sellerAddress === seller
    )[0];
    return {
      ...nftToPurchase,
      ownedAmount: nft1155DetailListing.ownedAmount,
      tokenId: nft.tokenId,
      isERC1155: true,
      name: nft1155Detail.metadata
        ? nft1155Detail.metadata.name
        : "Fanfire Token",
    };
  };

  const isReserved = (nftListing: any) => {
    return nftListing.isReserved ? nftListing.isReserved : false;
  };

  useEffect(() => {
    console.log("_____________________________________________");
    console.log(nftDetailListing);
    console.log("_____________________________________________");
  }, [nftDetailListing]);

  return (
    <Box pt={2}>
      {!loading ? (
        listings && listings.length > 0 ? (
          listings.map((e: any, i: number) => (
            <Paper
              elevation={20}
              sx={{ width: "100%", marginBottom: 1 }}
              key={`${i}-paper-${e.sellerAddress}`}
            >
              <div key={`${i}-outerDiv-${e.sellerAddress}`}>
                <Grid
                  key={i}
                  container
                  paddingLeft={3}
                  paddingRight={3}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={4} lg={3}>
                    <Text variantText="h6" style={{ textAlign: "center" }}>
                      {type === "owned" && `Owned`}
                      {type === "available" && <>Available</>}
                    </Text>
                    <Text variantText="body1" style={{ textAlign: "center" }}>
                      {!loadingFastProps ? (
                        <>
                          {type === "owned" &&
                            `${nftDetailListing.ownedAmount ?? 0} units`}{" "}
                        </>
                      ) : (
                        type !== "available" && (
                          <BasicLoader size={10}></BasicLoader>
                        )
                      )}
                      {type === "available" &&
                      appConfig.general.app &&
                      appConfig.general.app.toLowerCase() === "vaulted"
                        ? `${e.amount ?? 0} units`
                        : type === "available" && `${e.amount ?? 0}`}
                    </Text>
                  </Grid>
                  {type === "owned" && (
                    <Grid item xs={4} lg={3}>
                      <Text variantText="h6" style={{ textAlign: "center" }}>
                        Listed
                      </Text>
                      <Text variantText="body1" style={{ textAlign: "center" }}>
                        {e.amount ?? 0}
                      </Text>
                    </Grid>
                  )}
                  {e.price != 0 && (
                    <Grid item xs={4} lg={3}>
                      <Text variantText="body1" style={{ textAlign: "center" }}>
                        {!currencyLoading && (
                          <Text
                            variantText="body1"
                            style={{ textAlign: "center" }}
                          >
                            {currencyFormat(
                              (
                                Number(
                                  ethers.utils.formatUnits(
                                    e.price,
                                    Number(
                                      process.env
                                        .REACT_APP_FANFIRE_COIN_DECIMALS ?? 18
                                    )
                                  )
                                ) * Number(currency)
                              ).toFixed(2),
                              "ZAR"
                            )}
                          </Text>
                        )}
                        <Text
                          variantText="body2"
                          style={{ textAlign: "center" }}
                        >
                          {currencyFormat(
                            Number(
                              ethers.utils.formatUnits(
                                e.price,
                                Number(
                                  process.env.REACT_APP_FANFIRE_COIN_DECIMALS ??
                                    18
                                )
                              )
                            ),
                            isWeb3 ? "USDC" : "USD"
                          )}
                        </Text>
                      </Text>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    {!isReserved(e) && !loading && isAuthenticated ? (
                      e.sellerAddress.toLowerCase() ===
                      sdkService.wallet.walletAddress?.toLowerCase() ? (
                        // owns Item
                        !e.price ? (
                          // not Listed
                          isMarketPlaceNFT ? (
                            // Exists within marketplace
                            <Box
                              display="flex"
                              justifyContent="center"
                              width="100%"
                            >
                              <Box m={1}>
                                <ListNFT
                                  nft={formatNFTObjectForListing(
                                    nft,
                                    nftDetailListing,
                                    e.sellerAddress
                                  )}
                                  address={nft.nftAddress}
                                  sdkService={sdkService}
                                  appConfig={appConfig}
                                />
                              </Box>
                              <Box m={1}>
                                <TransferNFT
                                  appConfig={appConfig}
                                  sdkService={sdkService}
                                  nft={{
                                    ...formatNFTObjectForListing(
                                      nft,
                                      nftDetailListing,
                                      e.sellerAddress
                                    ),
                                    contractAddress: nft.contractAddress,
                                  }}
                                />
                              </Box>
                            </Box>
                          ) : (
                            <Box m={1}>
                              <Text
                                variantText="body1"
                                style={{ textAlign: "center" }}
                              >
                                This{" "}
                                {appConfig.general.site_noun
                                  ? appConfig.general.site_noun
                                  : "item"}{" "}
                                is not tradable in the current Marketplace.
                              </Text>
                            </Box>
                          )
                        ) : // Listed

                        isMarketPlaceNFT ? (
                          !appConfig.general.disablePurchasing && (
                            <>
                              <Box display="flex" justifyContent="center">
                                <Box m={1}>
                                  <UnlistNFT
                                    sdkService={sdkService}
                                    nftAddress={
                                      nft.nftAddress
                                        ? nft.nftAddress
                                        : nft.contractAddress
                                        ? nft.contractAddress
                                        : undefined
                                    }
                                    tokenId={nft.tokenId}
                                    appConfig={appConfig}
                                    amount={Number(e.amount) ?? 0}
                                  />
                                </Box>
                                <Box m={1}>
                                  <ListNFT
                                    nft={formatNFTObjectForListing(
                                      nft,
                                      nftDetailListing,
                                      e.sellerAddress
                                    )}
                                    address={nft.nftAddress}
                                    sdkService={sdkService}
                                    appConfig={appConfig}
                                    relist={true}
                                  />
                                </Box>
                              </Box>
                            </>
                          )
                        ) : (
                          <Box m={1}>
                            <Text
                              variantText="body1"
                              style={{ textAlign: "center" }}
                            >
                              This{" "}
                              {appConfig.general.site_noun
                                ? appConfig.general.site_noun
                                : "item"}{" "}
                              is not tradable in the current Marketplace.
                            </Text>
                          </Box>
                        )
                      ) : !e.price ? (
                        <Text
                          variantText="body1"
                          style={{ textAlign: "center" }}
                        >
                          Unlisted
                        </Text>
                      ) : isMarketPlaceNFT ? (
                        <Box p={1}>
                          <Checkout
                            sdkService={sdkService}
                            nft={formatNFTObjectForCheckout(
                              nftDetailListing,
                              e.sellerAddress
                            )}
                            sliceStore={sliceStore}
                            appConfig={appConfig}
                            currency={currency}
                          />
                        </Box>
                      ) : (
                        <Box m={1}>
                          <Text
                            variantText="body1"
                            style={{ textAlign: "center" }}
                          >
                            This{" "}
                            {appConfig.general.site_noun
                              ? appConfig.general.site_noun
                              : "item"}{" "}
                            is not tradable in the current marketplace.
                          </Text>
                        </Box>
                      )
                    ) : !isAuthenticated ? (
                      <Text variantText="body1" style={{ textAlign: "center" }}>
                        Please log in to purchase{" "}
                        {appConfig.general.site_noun_plural
                          ? appConfig.general.site_noun_plural
                          : "items"}
                      </Text>
                    ) : (
                      <></>
                    )}
                    {
                      // Reserved
                      isReserved(e) && isAuthenticated ? (
                        e.sellerAddress.toLowerCase() ===
                        sdkService.wallet.walletAddress?.toLowerCase() ? (
                          // Logged in user owns item
                          e.reserveeAddress.toLowerCase() ===
                            sdkService.wallet.walletAddress?.toLowerCase() ||
                          e.reserveeAddress.toLowerCase() !==
                            "0x0000000000000000000000000000000000000000" ? (
                            // Item is reserved for current logged in user (and owns the item)
                            !appConfig.general.disablePurchasing && (
                              <UnlistNFT
                                sdkService={sdkService}
                                nftAddress={
                                  nft.nftAddress
                                    ? nft.nftAddress
                                    : nft.contractAddress
                                    ? nft.contractAddress
                                    : undefined
                                }
                                tokenId={nft.tokenId}
                                appConfig={appConfig}
                                amount={Number(e.amount) ?? 0}
                              />
                            )
                          ) : e.reserveeAddress.toLowerCase() !==
                            sdkService.wallet.walletAddress?.toLowerCase() ? (
                            // Item is reserved for someone else (logged in user owns the item)
                            <>
                              <Reserved appConfig={appConfig} />
                              <Text
                                variantText="body1"
                                style={{ textAlign: "center", padding: "5px" }}
                              >
                                You can not unlist it.
                              </Text>
                            </>
                          ) : (
                            <></>
                          )
                        ) : // user does not own item
                        e.reserveeAddress.toLowerCase() !==
                          sdkService.wallet.walletAddress?.toLowerCase() ? (
                          // user does not own item and it is reserved by someone else
                          <Reserved appConfig={appConfig} />
                        ) : e.reserveeAddress.toLowerCase() ===
                          sdkService.wallet.walletAddress?.toLowerCase() ? (
                          <Reserved appConfig={appConfig} />
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )
                    }
                  </Grid>
                  {type === "owned" &&
                    appConfig.general.enableQrRedemption &&
                    isAuthenticated &&
                    nft.isERC1155 &&
                    getOwned1155Qty(nftDetailListing.listings) > 0 &&
                    isMarketPlaceNFT &&
                    !getOwnedIsListed1155(nftDetailListing.listings) && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={3}
                        mb={mobileView ? 1 : 0}
                      >
                        <RouterLink
                          style={{ textDecoration: "none" }}
                          to="/profile#redemptions"
                        >
                          <SecondaryButton>
                            <Text
                              variantText={mobileView ? "body1" : "body1"}
                              style={{ textTransform: "capitalize" }}
                            >
                              {appConfig.general.redeemText
                                ? appConfig.general.redeemText
                                : `Redeem Your Owned${" "}
                            ${
                              appConfig.general.site_noun_plural
                                ? appConfig.general.site_noun_plural
                                : "Items"
                            }`}
                            </Text>
                          </SecondaryButton>
                        </RouterLink>
                      </Grid>
                    )}
                </Grid>
              </div>
            </Paper>
          ))
        ) : (
          <Box m={2}>
            {type === "available" && (
              <Text variantText="h3">
                There are no{" "}
                {appConfig.general.site_noun_plural
                  ? appConfig.general.site_noun_plural
                  : "items"}{" "}
                available.
              </Text>
            )}
          </Box>
        )
      ) : (
        <Box sx={{ minHeight: "300px" }}></Box>
      )}
    </Box>
  );
}
