import React, { useEffect, useState } from "react";
import { AlertColor } from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RegexPatterns } from "../../constants/regex";
import { passwordsMatch } from "../../helpers/validators";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import InputTextField from "../inputs/InputTextField";
import Text from "../text/Text";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BasicLoader from "../loaders/BasicLoader";
import { FanfireSDK } from "fanfire-sdk";

interface Props {
  appConfig: any;
  typeAuthModal: "login" | "signup" | "invitation";
  setEmail: ({}: string) => void;
  setPassword: ({}: string) => void;
  setPassConfirm: ({}: string) => void;
  handleEmailAndPasswordSignIn: () => void;
  handleEmailAndPasswordSignUp: () => void;
  email: string;
  password: string;
  passConfirm: string;
  setMessage: ({}: { severity: AlertColor; message: string }) => void;
  sliceStore: any;
  sdkService: FanfireSDK;
}

export default function EmailAndPassItems({
  appConfig,
  typeAuthModal,
  setEmail,
  setPassword,
  setPassConfirm,
  handleEmailAndPasswordSignIn,
  handleEmailAndPasswordSignUp,
  email,
  password,
  passConfirm,
  setMessage,
  sliceStore,
  sdkService,
}: Props) {
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passConfirmError, setPassConfirmError] = useState("");
  const [passwordResetLoading, setPasswordResetLoading] = useState(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const user = useSelector((state: RootStateOrAny) => state.user);

  const handleShowPassChange = () => {
    setShowPassword(!showPassword);
  };

  const handleShowPassConfirmChange = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const handleEmailChange = (email: string) => {
    setEmail(email);
    if (!RegexPatterns.email.pattern.test(email)) {
      setEmailError("Not a valid email");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (password: string) => {
    setPassword(password);
    if (
      typeAuthModal === "signup" &&
      !RegexPatterns.password.pattern.test(password)
    ) {
      setPasswordError(RegexPatterns.password.message);
    } else {
      setPasswordError("");
    }
  };

  const handlePasswordConfirmChange = (passwordConfirm: string) => {
    setPassConfirm(passwordConfirm);
    const testPass = passwordsMatch(password, passwordConfirm);
    if (!testPass.valid) {
      setPassConfirmError(testPass.message);
    } else {
      setPassConfirmError("");
    }
  };

  const handleForgotPassword = async () => {
    if (email && RegexPatterns.email.pattern.test(email)) {
      // await dispatch(sliceStore.resetPassword({ email: email }));
      setPasswordResetLoading(true);
      console.log("Resetting");
      console.log(email);
      try {
        await sdkService.mailer.resetPassword(
          email,
          process.env.REACT_APP_FANFIRE_APP_BASE_URL ?? "",
          appConfig.general.app ?? "fanfire"
        );
        setMessage({
          severity: "success",
          message: "Password reset email sent. Please check your Inbox.",
        });
        setPasswordResetLoading(false);
      } catch (error) {
        console.error(error);
        setPasswordResetLoading(false);
        setMessage({
          severity: "error",
          message: "Error sending password reset email. Please try again.",
        });
      }
    } else {
      setMessage({
        severity: "warning",
        message: "A valid email is required for this action.",
      });
    }
  };

  const checkboxRequestInput = () => {
    if (typeAuthModal == "login") {
      if (email && password && !emailError && !passwordError) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        email &&
        passConfirm &&
        password &&
        !emailError &&
        !passwordError &&
        !passConfirmError
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const SubmitButton = () => {
    if (user.status === "loading") {
      return (
        <BasicLoader
          text={
            typeAuthModal == "login" ? "Logging you in..." : "Signing you up..."
          }
        />
      );
    } else {
      return (
        <PrimaryButton type="submit" disabled={checkboxRequestInput()}>
          <Text
            variantText="body1"
            style={{
              fontWeight: "bold",
              ...appConfig.general.styleOverrides.buttonPrimaryTextColor,
            }}
          >
            {typeAuthModal == "login" ? "Log In" : "Sign Up"}
          </Text>
        </PrimaryButton>
      );
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        typeAuthModal == "login"
          ? handleEmailAndPasswordSignIn()
          : handleEmailAndPasswordSignUp();
      }}
    >
      <Text variantText="body1" style={{ textAlign: "center" }}>
        {typeAuthModal === "login"
          ? "Log in with email and password"
          : "Sign up with email and password"}
      </Text>
      <InputTextField
        type="email"
        label="Enter email"
        id="email"
        value={email}
        required={true}
        onChange={(e: any) => handleEmailChange(e.target.value)}
        error={emailError ? true : false}
        errorText={emailError ? emailError : undefined}
        icon="email"
        autocomplete="email"
        name="email"
        readonly={typeAuthModal === "invitation" ? true : false}
      />
      <InputTextField
        type={showPassword ? "text" : "password"}
        label="Enter Password"
        id="password"
        value={password}
        required={true}
        onChange={(e: any) => handlePasswordChange(e.target.value)}
        error={passwordError ? true : false}
        errorText={passwordError ? passwordError : undefined}
        icon="password"
        autocomplete="current-password"
        endIcon={showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        endIconPress={handleShowPassChange}
        name="password"
      />
      {password &&
      (typeAuthModal === "signup" || typeAuthModal === "invitation") ? (
        <InputTextField
          type={showPasswordConfirm ? "text" : "password"}
          label="Confirm Password"
          id="pass-confirm"
          value={passConfirm}
          onChange={(e: any) => handlePasswordConfirmChange(e.target.value)}
          error={passConfirmError ? true : false}
          errorText={passConfirmError ? passConfirmError : undefined}
          icon="passwordConfirm"
          autocomplete="confirm-password"
          endIcon={
            showPasswordConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />
          }
          endIconPress={handleShowPassConfirmChange}
          name="password"
        />
      ) : (
        <></>
      )}
      <SubmitButton />
      {typeAuthModal == "login" && (
        <SecondaryButton onClick={() => handleForgotPassword()}>
          {passwordResetLoading ? (
            <BasicLoader />
          ) : (
            <Text variantText="body1" style={{ textAlign: "right" }}>
              Forgot Password?
            </Text>
          )}
        </SecondaryButton>
      )}
    </form>
  );
}
