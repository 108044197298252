import React from "react";
import Text from "../text/Text";
import SocialMedia from "../socialMedia/SocialMedia";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

interface Props {
  appConfig: any;
}

export default function FooterMobileNav({ appConfig }: Props) {
  return (
    <Box position="absolute" paddingBottom='20%' bottom="0px" width="100%">
      <Paper className="primary">
        <Box pb={1} pt={1} px={3}>
          <Text
            variantText="h3"
            style={{ ...appConfig.footers.footerTextColor.styleOverrides.text }}
          >
            Contact Us
          </Text>
        </Box>
        <Box pb={1} px={3}>
          <Text
            variantText="body1"
            style={{
              display: "flex",
              ...appConfig.footers.footerTextColor.styleOverrides.text,
            }}
          >
            {appConfig.general.contactDetails.telephone ? (
              <LocalPhoneOutlinedIcon
                fontSize="small"
                style={{ marginRight: "5px" }}
              />
            ) : (
              <></>
            )}
            <a
              href={`tel:${appConfig.general.contactDetails.telephone}`}
              style={{
                textDecoration: "none",
                ...appConfig.footers.footerTextColor.styleOverrides.text,
              }}
            >
              {appConfig.general.contactDetails.telephone}
            </a>
          </Text>
        </Box>
        <Box pb={1} px={3}>
          <Text
            variantText="body1"
            style={{
              display: "flex",
              ...appConfig.footers.footerTextColor.styleOverrides.text,
            }}
          >
            <EmailOutlinedIcon
              fontSize="small"
              style={{ marginRight: "5px" }}
            />
            <a
              href={`mailto:${appConfig.general.contactDetails.email}?subject=${
                appConfig.general.contactDetails.email_subject
                  ? appConfig.general.contactDetails.email_subject
                  : "Fanfire Inquiry"
              }`}
              style={{
                textDecoration: "none",
                ...appConfig.footers.footerTextColor.styleOverrides.text,
              }}
            >
              {appConfig.general.contactDetails.email}
            </a>
          </Text>
        </Box>
        <Paper className="secondary">
          <SocialMedia appConfig={appConfig} />
        </Paper>
      </Paper>
    </Box>
  );
}
