import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { PasswordOutlined } from "@mui/icons-material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { IconButton } from "@mui/material";

interface Props {
  label?: string;
  onChange?: any;
  value?: string | number;
  type?: string;
  error?: boolean;
  errorText?: string;
  icon?: string;
  required?: boolean;
  id?: string;
  autocomplete?: string;
  disabled?: boolean;
  readonly?: boolean;
  name?: string;
  endIcon?: React.ReactChild;
  endIconPress?: any;
}

export default function InputTextField({
  error,
  errorText,
  label,
  onChange,
  value,
  type,
  icon,
  required,
  id,
  autocomplete,
  disabled,
  readonly,
  name,
  endIcon,
  endIconPress,
}: Props) {
  return (
    <Box pt={1} pb={1}>
      <TextField
        id={id ? id : "outlined-basic"}
        placeholder={label}
        name={name ? name : "input-field"}
        variant="outlined"
        size="small"
        value={value}
        type={type ? type : "text"}
        fullWidth
        className="primary"
        autoComplete={autocomplete}
        required={required ? required : false}
        disabled={disabled ? disabled : false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {icon === "email" && <EmailOutlinedIcon />}
              {icon === "search" && <SearchRoundedIcon />}
              {icon === "password" && <PasswordOutlined />}
              {icon === "passwordConfirm" && <DoneAllIcon />}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => endIconPress()}>{endIcon}</IconButton>
            </InputAdornment>
          ),
          readOnly: readonly ? readonly : false,
        }}
        error={error}
        helperText={error ? errorText : ""}
        sx={{
          // '& .MuiInputBase-root': {
          // color: 'white'
          // },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "black",
            },
            "&:hover fieldset": {
              borderColor: "black",
            },
            height: "45px",
            fontFamily: "raleway",
            // background: '#313131',
            borderRadius: "8px",
          },
        }}
        onChange={onChange}
      />
    </Box>
  );
}
