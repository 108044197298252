import React, { useEffect, useState, useContext } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEllipsisTxt } from "../../helpers/formatters";
import FooterMobileNav from "../footers/FooterMobileNav";
import Text from "../text/Text";
import CopyWalletAddress from "../wallet/CopyWalletAddress";
import { NetworkStatusEnums } from "../../helpers/enums";
import Identicon from "../avatars/Identicon";

//MUI imports
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Skeleton from "@mui/material/Skeleton";
import { FanfireSDK } from "fanfire-sdk";

//assets
import MetaMaskLogout from "../auth/MetaMaskLogout";
import WalletInfoBar from "./WalletInfoBar";
import AuthModal from "../auth/AuthModal";
import { useSnackBar } from "../../context/snackAlert-context";

const snackbarEnabled = false;
interface Props {
  authLoading: boolean;
  mobileView: boolean;
  sdkService: FanfireSDK;
  appConfig: any;
  sliceStore: any;
}

export default function NavBar({
  // authLoading,
  mobileView,
  sdkService,
  appConfig,
  sliceStore,
}: Props) {
  //state
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElProfile, setAnchorElProfile] =
    React.useState<null | HTMLElement>(null);
  const [walletMenuOpen, setWalletMenuOpen] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>({}); // update any
  const [metaMaskLogoutOpen, setMetaMaskLogoutOpen] = useState<boolean>(false);
  const [authLoading, setAuthLoading] = useState<boolean>(true);

  //auth
  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);
  const isWeb2 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb2);
  const isWeb3 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb3);
  const user = useSelector((state: RootStateOrAny) => state.user);

  const styles = {
    logo: {
      maxWidth: "200px",
      maxHeight: "60px",
      width: "auto",
      height: mobileView ? "30px" : "60px",
      // objectFit: 'contain',
    },
  };

  useEffect(() => {
    if (user.status === "failed") {
      // console.log("auth failed");
      console.log(user.error);
    }
    if (user.status === "idle") {
      // console.log("auth idle");
      setAuthLoading(false);
    }
    if (user.status === "succeeded") {
      // console.log("auth success");
      setAuthLoading(false);
    }
  }, [user]);

  //Other
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Pages
  let pages = [
    {
      text: "Redemptions",
      page: "redemptions",
    },
    {
      text: appConfig.navItems.forSaleNavbarText
        ? appConfig.navItems.forSaleNavbarText
        : "For Sale",
      page: "for-sale",
    },
    {
      text: "Browse Collection",
      page: "explore",
    },
    {
      text: "FAQ",
      page: "faq",
    },
    {
      text: appConfig.navItems.profileNavbarText
        ? appConfig.navItems.profileNavbarText
        : "My Profile",
      page: "profile",
    },
    {
      text: "Sign Out",
      page: "sign-out",
    },
  ];

  const removeItemOnce = (arr: any, value: any) => {
    var index = arr.findIndex((x: any) => x.page === value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  const checkPagesToInclude = (pages: any) => {
    let pagesToUse = pages;
    if (!appConfig.forSalePage.include) {
      pagesToUse = removeItemOnce(pagesToUse, "for-sale");
    }
    if (!appConfig.browseCollectionPage.include) {
      pagesToUse = removeItemOnce(pagesToUse, "explore");
    }
    return pagesToUse;
  };

  pages = checkPagesToInclude(pages);

  useEffect(() => {
    const setWeb3 = async () => {
      const userWallet = sdkService.wallet.walletAddress;
      setUserData({
        displayName: getEllipsisTxt(userWallet ?? ""),
        photoURL: "",
        walletAddress: userWallet,
      });
    };

    if (isWeb2) {
      setUserData(user.user);
    } else if (isWeb3) {
      setWeb3();
    }
  }, [authLoading, isAuthenticated]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenWalletMenu = () => {
    setWalletMenuOpen(true);
  };

  const handleSignOut = async () => {
    console.log("Signing Out");
    if (isWeb2) {
      await dispatch(sliceStore.signOutWithGoogle());
      localStorage.setItem("notification", "false");
      localStorage.setItem("notificationLoading", "false");
      window.location.reload();
      navigate("/", { replace: true });
    } else if (isWeb3) {
      setMetaMaskLogoutOpen(true);
    }
  };

  const MenuLogo = () => {
    return (
      <RouterLink style={{ textDecoration: "none" }} to="/">
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={
            !mobileView
              ? { ml: 15, display: { xs: "none", md: "flex" } }
              : { flexGrow: 1, display: { md: "none" } }
          }
        >
          <img style={styles.logo} src={appConfig.general.logo} alt="Logo" />
        </Typography>
      </RouterLink>
    );
  };

  const getRedemtionLocations = () => {
    let locations: Array<String> = [];
    const env: any = process.env.REACT_APP_FANFIRE_CHAIN ?? "";
    if (appConfig.general.enableQrRedemption) {
      if (env !== "" && appConfig.general.redemptionLocations) {
        appConfig.general.redemptionLocations.map((element: any) => {
          locations.push(element[env]);
        });
      }
    }
    return locations;
  };

  const { showSnackBar } = useSnackBar();

  if (snackbarEnabled) {
    useEffect(() => {
      // if (user.status === "failed") {
      //   showSnackBar(
      //     user.error,
      //     user.error.includes("Sign up link has already been used") ||
      //       user.error.includes("Sign up link has expired")
      //       ? "info"
      //       : "error"
      //   );
      // }
      if (
        user.creatingWallet === "creating" &&
        Object.keys(user.user).length === 0
      ) {
        showSnackBar("We are busy authenticating your user...", "info");
      }
      if (
        user.creatingWallet === "success" &&
        Object.keys(user.user).length === 0
      ) {
        showSnackBar(
          "We have created your wallet. You can now sign in.",
          "success"
        );
      }
      if (user.status === "loading") {
        if (
          !localStorage.getItem("notificationLoading") ||
          localStorage.getItem("notificationLoading") === "false"
        ) {
          showSnackBar("We are busy authenticating your user...", "info");
        }
      }
      if (user.status === "succeeded") {
        if (
          !localStorage.getItem("notification") ||
          localStorage.getItem("notification") === "false"
        ) {
          showSnackBar("You have signed in successfully.", "success");
          localStorage.setItem("notification", "true");
          localStorage.setItem("notificationLoading", "true");
        }
      }
    }, [user]);
  }

  return (
    <AppBar>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Desktop menu logo */}
          {!mobileView && <MenuLogo />}
          {/* Desktop menu logo */}

          {/* Mobile Menu bar */}
          <Box
            mt={1}
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            alignItems="center"
          >
            <MenuLogo />
            <IconButton
              className="primary"
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ flexGrow: 1, justifyContent: "right" }}
            >
              <MenuIcon
                style={{
                  color: appConfig.navItems.iconNavBarColor,
                }}
              />
            </IconButton>
          </Box>
          {/* Mobile Menu bar */}

          {/* Drawer for mobile View */}
          <Drawer
            anchor="right"
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            PaperProps={{
              sx: {
                ...appConfig.navItems.mobileNavItems.styleOverride.drawerColor,
                width: "60vw",
              },
            }}
          >
            <IconButton
              size="large"
              onClick={handleCloseNavMenu}
              color="inherit"
              style={{ justifyContent: "flex-end" }}
            >
              <CloseIcon />
            </IconButton>
            {isAuthenticated && !authLoading ? (
              <>
                <IconButton
                  color={"inherit"}
                  style={{ justifyContent: "center" }}
                >
                  {isWeb2 ? (
                    userData.photoURL ? (
                      <Avatar
                        alt={userData.email}
                        src={userData.photoURL}
                        sx={{ width: 64, height: 64 }}
                        style={{ margin: 20 }}
                        component={RouterLink}
                        to='/profile'
                        onClick={handleCloseNavMenu}
                      />
                    ) : (
                      <Avatar
                        alt={userData.email}
                        sx={{ width: 64, height: 64 }}
                        style={{ margin: 20 }}
                        component={RouterLink}
                        to='/profile'
                        onClick={handleCloseNavMenu}
                      >
                        <Identicon
                          account={userData.email}
                          size={64}
                          hAndW={"4rem"}
                        />
                      </Avatar>
                    )
                  ) : (
                    <Avatar
                      alt={userData.displayName}
                      sx={{ width: 40, height: 40 }}
                      component={RouterLink}
                      to='/profile'
                      onClick={handleCloseNavMenu}
                    >
                      {/* Mobile drawer */}
                      <Identicon
                        account={userData.walletAddress}
                        size={45}
                        hAndW={"3rem"}
                      />
                    </Avatar>
                  )}
                </IconButton>
                {!authLoading && (
                  <CopyWalletAddress
                    sdkService={sdkService}
                    textColor={
                      appConfig.navItems.mobileNavItems.styleOverride.text.color
                    }
                  />
                )}
                <Divider sx={{ background: "grey" }} />
                <IconButton
                  onClick={handleOpenWalletMenu}
                  color={"inherit"}
                  sx={{ justifyContent: "center" }}
                  className="primary"
                >
                  <AccountBalanceWalletIcon fontSize="medium" />
                </IconButton>
              </>
            ) : (
              <></>
            )}
            {pages.map((page) => (
              <RouterLink
                style={{ textDecoration: "none" }}
                key={page.page}
                to={page.page !== "sign-out" ? page.page : ""}
              >
                <Button className="navigation" style={{ width: "100%" }}>
                  <MenuItem
                    key={page.page}
                    onClick={handleCloseNavMenu}
                    style={{ justifyContent: "center", width: "100%" }}
                    className="navigation"
                  >
                    {!authLoading &&
                      isAuthenticated &&
                      page.page === "redemptions" &&
                      getRedemtionLocations().includes(
                        sdkService.wallet.walletAddress ?? ""
                      ) && (
                        <Text
                          variantText="subtitle1"
                          style={{
                            ...appConfig.navItems.mobileNavItems.styleOverride
                              .text,
                          }}
                        >
                          {page.text}
                        </Text>
                      )}
                    {page.page !== "sign-out" &&
                      page.page !== "profile" &&
                      page.page !== "redemptions" && (
                        <Text
                          variantText="subtitle1"
                          style={{
                            ...appConfig.navItems.mobileNavItems.styleOverride
                              .text,
                          }}
                        >
                          {page.text}
                        </Text>
                      )}
                    {!authLoading &&
                      isAuthenticated &&
                      page.page === "profile" && (
                        <Text
                          variantText="subtitle1"
                          style={{
                            ...appConfig.navItems.mobileNavItems.styleOverride
                              .text,
                          }}
                        >
                          {page.text}
                        </Text>
                      )}
                    {!authLoading &&
                      isAuthenticated &&
                      page.page === "sign-out" && (
                        <Text
                          variantText="subtitle1"
                          onClick={() => handleSignOut()}
                          style={{
                            ...appConfig.navItems.mobileNavItems.styleOverride
                              .text,
                          }}
                        >
                          {page.text}
                        </Text>
                      )}
                  </MenuItem>
                </Button>
              </RouterLink>
            ))}
            {!isAuthenticated && !authLoading && (
              <Box justifyContent="center" display="flex">
                <AuthModal
                  type="login"
                  sdkService={sdkService}
                  sliceStore={sliceStore}
                  appConfig={appConfig}
                />
                <AuthModal
                  type="signup"
                  sdkService={sdkService}
                  sliceStore={sliceStore}
                  appConfig={appConfig}
                />
              </Box>
            )}
            <FooterMobileNav appConfig={appConfig} />
          </Drawer>
          {/* Drawer for mobile View */}

          {/* Drawer for wallet View */}
          <WalletInfoBar
            mobileView={mobileView}
            walletMenuOpen={walletMenuOpen}
            setWalletMenuOpen={setWalletMenuOpen}
            sdkService={sdkService}
            appConfig={appConfig}
          />
          {/* Drawer for wallet View */}

          {/* Desktop Menu Items */}
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "right",
              display: { xs: "none", md: "flex" },
            }}
            alignItems="center"
          >
            {pages.map((page) => (
              <RouterLink
                style={({ isActive }) =>
                  isActive
                    ? {
                        textDecoration: `underline`,
                        textUnderlineOffset: "6px",
                        textDecorationThickness: "3px",
                        textDecorationColor: appConfig.navItems.navActiveColor,
                      }
                    : {
                        textDecoration: "none",
                      }
                }
                key={page.page}
                to={page.page !== "sign-out" ? page.page : ""}
              >
                {!authLoading &&
                  isAuthenticated &&
                  page.page === "redemptions" &&
                  getRedemtionLocations().includes(
                    sdkService.wallet.walletAddress ?? ""
                  ) && (
                    <Button
                      className="navigation"
                      key={page.page}
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, mx: 1, display: "flex" }}
                    >
                      <Text variantText="subtitle1">{page.text}</Text>
                    </Button>
                  )}
                {!authLoading && isAuthenticated && page.page === "profile" && (
                  <Button
                    className="navigation"
                    key={page.page}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, mx: 1, display: "flex" }}
                  >
                    {isWeb2 ? (
                      userData.photoURL ? (
                        <Avatar
                          alt={userData.email}
                          src={userData.photoURL}
                          onClick={handleClick}
                          sx={{
                            width: 25,
                            height: 25,
                            bottom: 0,
                            marginRight: "5px",
                          }}
                        />
                      ) : (
                        <Avatar
                          alt={userData.email}
                          onClick={handleClick}
                          sx={{
                            width: 25,
                            height: 25,
                            bottom: 0,
                            marginRight: "5px",
                          }}
                        >
                          <Identicon
                            account={userData.email}
                            size={64}
                            hAndW={"4rem"}
                          />
                        </Avatar>
                      )
                    ) : (
                      <Avatar
                        alt={userData.displayName}
                        onClick={handleClick}
                        sx={{ width: 25, height: 25, bottom: 0 }}
                      >
                        {/* top nav bar */}
                        <Identicon
                          account={userData.walletAddress}
                          size={27}
                          hAndW={"1.7rem"}
                        />
                      </Avatar>
                    )}
                    <Text variantText="subtitle1">{page.text}</Text>
                  </Button>
                )}
                {!authLoading && isAuthenticated && page.page === "sign-out" && (
                  <Button
                    className="navigation"
                    key={page.page}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, mx: 1, display: "flex" }}
                  >
                    <Text
                      variantText="subtitle1"
                      onClick={() => handleSignOut()}
                    >
                      {page.text}
                    </Text>
                  </Button>
                )}
                {page.page !== "sign-out" &&
                  page.page !== "profile" &&
                  page.page !== "redemptions" && (
                    <Button
                      className="navigation"
                      key={page.page}
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, mx: 1, display: "flex" }}
                    >
                      <Text variantText="subtitle1">{page.text}</Text>
                    </Button>
                  )}
              </RouterLink>
            ))}

            {isAuthenticated ? (
              <>
                <IconButton
                  onClick={handleOpenWalletMenu}
                  color={"inherit"}
                  sx={{ justifyContent: "right" }}
                  className="primary"
                >
                  <AccountBalanceWalletIcon
                    fontSize="medium"
                    style={{
                      color: appConfig.navItems.iconNavBarColor,
                    }}
                  />
                </IconButton>
              </>
            ) : !authLoading ? (
              <>
                <AuthModal
                  type="signup"
                  sdkService={sdkService}
                  sliceStore={sliceStore}
                  appConfig={appConfig}
                />
                <AuthModal
                  type="login"
                  sdkService={sdkService}
                  sliceStore={sliceStore}
                  appConfig={appConfig}
                />
              </>
            ) : (
              <></>
            )}

            {/* Desktop Menu Items */}

            {metaMaskLogoutOpen && (
              <MetaMaskLogout
                onClose={setMetaMaskLogoutOpen}
                appConfig={appConfig}
              />
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
