import { Grid, Pagination } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ScrollToTop from "../components/helpers/ScrollToTop";
import SelectField from "../components/inputs/SelectField";

/**
 * This hook is used handle pagination of items
 *
 *  @param { boolean } loading whether to load the 'skip' numbers when you are unsure about the total
 * @returns { skip, limit, setTotal, PaginationComp } Returns skip (page), limit (amount per page), setTotal (to set ho many pages there are), PaginationComp (pagination component to render)
 */
export default function usePagination(loading: boolean = false) {
  const [searchParams, setSearchParams] = useSearchParams();

  //use the items set in query param or if not defined, set it to default
  const [skip, setSkip] = useState<number>(
    Number(searchParams.get("skip") ?? 1)
  );
  //use the items set in query param or if not defined, set it to default
  const [limit, setLimit] = useState<number>(
    Number(searchParams.get("limit") ?? 10)
  );

  const [total, setTotal] = useState<number>(5); //5 is arbitrary, it is just to show something while loading

  /**
   * This function is used update a query string with new parameters
   *
   * @param {{ name: string; value: string }[]} paramsData data to update within the query param
   * @returns {string} URLSearchParams as a string
   */
  const updateQueryString = (paramsData: { name: string; value: string }[]) => {
    const params: any = {};

    //Get current query string
    searchParams.forEach((key, value) => {
      params[value] = key;
    });

    //Update query param for specific entry
    paramsData.forEach((element: any) => {
      params[element.name] = element.value;
    });

    //return new query string
    return new URLSearchParams(params);
  };

  useEffect(() => {
    //Update the query params every time limit or skip is changed
    let newQueryParam: URLSearchParams;
    newQueryParam = updateQueryString([
      { name: "skip", value: skip.toString() },
      { name: "limit", value: limit.toString() },
    ]);
    setSearchParams(newQueryParam);
  }, [skip, limit]);

  const PaginationComp = () => {
    const handleSkipChange = (event: any, value: number) => {
      setSkip(value);
      window.scrollTo({ top: 0, behavior: "smooth" }); //scroll the user to the top once they change the page
    };

    const handleLimitChange = (value: number) => {
      setLimit(value);
      setSkip(1); //set skip (page) to 1 when limit is changed as that page might not exist when limit is increased for example
      //TODO: We can figure out the amount of pages and adjust accordingly
      window.scrollTo({ top: 0, behavior: "smooth" }); //scroll the user to the top once they change the limit
    };

    return (
      <Grid container item xs={12} alignItems="center" justifyContent="center">
        {loading ? (
          //Only show page number that user is on while loading
          <Pagination count={total} page={Number(searchParams.get("skip"))} />
        ) : (
          <Pagination count={total} page={skip} onChange={handleSkipChange} />
        )}
        <SelectField
          label="per page"
          choice={limit}
          setChoice={handleLimitChange}
          choices={["5", "10", "20", "30", "50"]}
        />
      </Grid>
    );
  };

  return {
    skip,
    limit,
    setTotal,
    PaginationComp,
  };
}
