import React, { useState, useEffect, useContext, useDebugValue } from "react";
import Text from "../text/Text";
import Grid from "@mui/material/Grid";
import Loader from "../loaders/BasicLoader";
import FiatBuyProgressBasic from "./FiatBuyProgressBasic";
import useFetchAPI from "../../hooks/useFetchAPI";
import { getTxLabels } from "./functions/getTxBasicLabels";
import { groupBy } from "../../helpers/functions";
import axios from "axios";
import { FanfireSDK } from "fanfire-sdk";

interface Tx {
  id: string;
  createdAt: string;
  type: "buy" | "list" | "unlist" | "transfer" | "gift";
  priceCoin: number;
  priceCurrency: number;
  status: "pending" | "failed" | "complete" | "sent";
  heading: string;
  subHeading: string;
  name: string;
  step: "payment" | "transfer" | "complete";
  nftAddress: string;
  tokenId: string;
}

interface Props {
  appConfig: any;
  sdkService: FanfireSDK;
  isAuthenticated: boolean;
  isWeb2: boolean;
}

export default function TransactionListBasic({
  appConfig,
  sdkService,
  isAuthenticated,
  isWeb2,
}: Props) {
  const [fiatData, setFiatData] = useState<any>([]);
  const [refreshing, setRefreshing] = useState(true);

  // const { error, loading, exchange } = useFetchCurrency("usd-coin", "zar");
  // const { error, loading, data } = useFetchAPI({
  //   url: `${process.env.REACT_APP_FANFIRE_API_BASE_URL}/forex/exchange/usd-coin/zar`,
  // });

  const getCoinVsCurrency = async (coin: string, currency: string) => {
    // https://www.coingecko.com/en/api/documentation
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_FANFIRE_API_BASE_URL}/forex/exchange/usd-coin/zar`
      );

      const zar = resp.data["usd-coin"]["zar"];
      return zar;
    } catch (error) {
      console.log("error");
      return "error";
    }
  };

  const REFRESH_TIME = 5000;

  useEffect(() => {
    setRefreshing(true);
    getFiatProgressData();
    if (isAuthenticated && isWeb2) {
      const interval = setInterval(() => {
        if (isAuthenticated) {
          getFiatProgressData();
        }
      }, REFRESH_TIME);

      return () => clearInterval(interval);
    }
  }, [isAuthenticated, isWeb2]);

  const getFiatProgressData = async () => {
    const wallet = sdkService.wallet.walletAddress;
    const fiatProgress = await sdkService.marketplace.getUserTxs(wallet ?? ""); //txBuy;
    const exchange = await getCoinVsCurrency("usd-coin", "zar");
    const filteredFiatTxs = fiatProgress?.filter(
      (x: any) => x.intent !== "topup"
    );
    console.log("Refreshed Transactions: ", filteredFiatTxs);
    setFiatData(processTxForBasic(filteredFiatTxs?.reverse(), exchange));
    setRefreshing(false);
  };

  const groupTx = (tx: Tx) => {
    //Group By estate
    const grouped = groupBy(tx, (c: any) => c.groupId && c.groupId);
    return Object.keys(grouped).map((key) => {
      return { group: key !== "null" ? key : "Other", tx: grouped[key] };
    });
  };

  const getBuyStepAndStatus = (reserveTx: any, buyTx: any) => {
    let step: "payment" | "transfer" | "complete" = "transfer";
    let status: "pending" | "failed" | "complete" = "pending";
    if (buyTx.length !== 0) {
      step = "complete";
      status = buyTx[0].status;
    } else {
      status = "pending";
      if (reserveTx.status === "failed") {
        status = reserveTx.status;
      }
    }
    if (status.toLowerCase() === "sent") {
      status = "pending";
    }
    // console.log({ step, status });
    return { step, status };
  };

  const getBuyTxStatus = (intents: any, exchange: number) => {
    const reserveTx = intents.filter(
      (el: any) => el.intent === "reserve-and-transfer-erc20"
    )[0];
    const buyTx = intents.filter((el: any) => el.intent === "buy-nft"); //not taking first index here because there might not be a Buy-Nft intent yet
    const { step, status } = getBuyStepAndStatus(reserveTx, buyTx);
    const { heading, subHeading, name } = getTxLabels(
      reserveTx.metadata,
      appConfig.general.appCategory ? appConfig.general.appCategory : ""
    );
    const basicTxObject: Tx = {
      id: reserveTx.groupId,
      createdAt: reserveTx.createdAt,
      type: "buy",
      priceCoin: reserveTx.erc20Amount,
      priceCurrency: exchange * reserveTx.erc20Amount,
      status: status,
      heading: heading ? heading : "Buy Nft",
      subHeading: subHeading,
      name: name,
      step: step,
      nftAddress: reserveTx.nftAddress,
      tokenId: reserveTx.tokenId,
    };
    return basicTxObject;
  };

  const getSingleTxStatus = (intent: any, exchange: number) => {
    const { heading, subHeading, name } = getTxLabels(
      intent.metadata,
      appConfig.general.appCategory ? appConfig.general.appCategory : ""
    );
    const basicTxObject: Tx = {
      id: intent.groupId,
      createdAt: intent.createdAt,
      type: intent.intent,
      priceCoin: intent.erc20Amount,
      priceCurrency: exchange * intent.erc20Amount,
      status: intent.status,
      heading: heading ? heading : intent.intent, // if no header, so the intent as the header
      subHeading: subHeading,
      name: name ? name : "",
      step: "complete", // set to complete as there are no steps in single TX
      nftAddress: intent.nftAddress,
      tokenId: intent.tokenId,
    };
    return basicTxObject;
  };

  const processTxForBasic = (tx: any, exchange: number) => {
    let basicTxObject: Array<Tx> = [];
    //Group TXs by groupId
    //This basically is really only applicable to buy TXs because it is one whole TX with two intents
    const groups = groupTx(tx);
    console.log(groups);

    //Get TX info
    groups.map((item: any) => {
      if (item.tx[item.tx.length - 1].intent === "reserve-and-transfer-erc20") {
        //the last item in the buy tx will always be reserve-and-transfer-erc20
        //A "reserve-and-transfer-erc20" TX means it will be followed by buy-nft TX
        // console.log("double item");
        basicTxObject.push(getBuyTxStatus(item.tx, exchange));
      } else {
        //TXs such as list, unlist, gift, transfer which only has one intent
        // console.log("single item");
        // console.log(item);
        const singleTx = item.tx[0];
        basicTxObject.push(getSingleTxStatus(singleTx, exchange));
      }
    });
    return basicTxObject;
  };

  return (
    <>
      <Grid item xs={12}>
        {!refreshing ? (
          fiatData.length > 0 ? (
            fiatData.map(
              (
                purchase: any,
                i: number // update any
              ) => (
                <FiatBuyProgressBasic
                  purchase={purchase}
                  keyRef={i}
                  appConfig={appConfig}
                />
              )
            )
          ) : (
            <Text variantText="h3" style={{ textAlign: "center" }}>
              No Transactions
            </Text>
          )
        ) : (
          <>
            <Loader />
            <Text variantText="h3" style={{ textAlign: "center" }}>
              Loading Transactions
            </Text>
          </>
        )}
      </Grid>
    </>
  );
}
