import {Grid, Paper, Box, IconButton, styled} from "@mui/material";
import React from "react";
import Text from "../text/Text";

const ButtonWrapper = styled(Box)(({theme}) => ({
  paddingTop: 2,
  paddingBottom: 2,
  minHeight: '80px',
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  borderRadius: "8px",
}))

interface Props {
  nftDetail: any;
  appConfig: any;
}

export default function OpenSeaLinks({ nftDetail, appConfig }: Props) {
  const getOpenSeaNftUrl = () => {
    let url = "";
    if (process.env.REACT_APP_FANFIRE_CHAIN === "mumbai") {
      url = `https://testnets.opensea.io/assets/mumbai/${nftDetail.contractAddress}/${nftDetail.tokenId}`;
    } else {
      url = `https://opensea.io/assets/matic/${nftDetail.contractAddress}/${nftDetail.tokenId}`;
    }
    return url;
  };

  const getOpenSeaCollectionUrl = () => {
    let url = "";
    if (process.env.REACT_APP_FANFIRE_CHAIN === "mumbai") {
      url = `https://testnets.opensea.io/collection/${nftDetail.contractAddress.slice(
        2
      )}`;
    } else {
      url = `https://opensea.io/collection/${nftDetail.contractAddress.slice(
        2
      )}`;
    }
    return url;
  };

  return (
    <Grid container columns={24} justifyContent="center" spacing={3}>
      {nftDetail.contractAddress && nftDetail.tokenId && (
        <Grid item xs={11} md={5}>
          <Paper className="secondary">
            <a
              href={getOpenSeaNftUrl()}
              title="View on OpenSea"
              target="_blank"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ButtonWrapper>
                <IconButton sx={{ height: '100%' }}>
                  <img
                    style={{ height: 35, width: 35 }}
                    src={appConfig.general.openseaLogo}
                    alt="OpenSea Logo"
                  />
                </IconButton>
                <Box>
                  <Text
                    variantText="body1"
                    style={{
                      paddingLeft: "5px",
                      fontWeight: "bold",
                      textAlign: "left",
                      ...appConfig.footers.footerTextColor.styleOverrides
                        .socialMediaFooterTextColor.text,
                    }}
                  >
                    View NFT
                  </Text>
                  <Text
                    variantText="body1"
                    style={{
                      paddingLeft: "5px",
                      textAlign: "left",
                      ...appConfig.footers.footerTextColor.styleOverrides
                        .socialMediaFooterTextColor.text,
                    }}
                  >
                    on OpenSea
                  </Text>
                </Box>
              </ButtonWrapper>
            </a>
          </Paper>
        </Grid>
      )}
      {nftDetail.contractAddress && (
        <Grid item xs={11} md={5}>
          <Paper className="secondary">
            <a
              href={getOpenSeaCollectionUrl()}
              title="View on OpenSea"
              target="_blank"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ButtonWrapper>
                <IconButton sx={{ height: '100%' }}>
                  <img
                    style={{ height: 35, width: 35 }}
                    src={appConfig.general.openseaLogo}
                    alt="OpenSea Logo"
                  />
                </IconButton>
                <Box>
                  <Text
                    variantText="body1"
                    style={{
                      paddingLeft: "5px",
                      fontWeight: "bold",
                      textAlign: "left",
                      ...appConfig.footers.footerTextColor.styleOverrides
                        .socialMediaFooterTextColor.text,
                    }}
                  >
                    View Collection
                  </Text>
                  <Text
                    variantText="body1"
                    style={{
                      paddingLeft: "5px",
                      textAlign: "left",
                      ...appConfig.footers.footerTextColor.styleOverrides
                        .socialMediaFooterTextColor.text,
                    }}
                  >
                    on OpenSea
                  </Text>
                </Box>
              </ButtonWrapper>
            </a>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
