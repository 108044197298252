import React from 'react'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'

interface Props {
  label: string;
  choices: any;
  setChoice: any;
  choice: any;
}

export default function SelectField({ choices, label, setChoice, choice }: Props) {

  const handleChange = (event: any) => {
    setChoice(event.target.value);
  };
  
  return (
    <Box pt={1} pb={1}>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small">{label}</InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={choice}
          label={label}
          onChange={handleChange}
        >
          {choices.map((e: any, i: number) =>
            <MenuItem value={e} key={`${i}-${e}-menuItem`}>{e}</MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  )
}