import React, { useEffect, useState } from "react";
import Text from "../text/Text";
import Checkout from "../checkout/Checkout";
import PrimaryButton from "../buttons/PrimaryButton";
import RequestAPrint from "../userClaims/RequestAPrint";
import axios from "axios";
import { useSnackBar } from "../../context/snackAlert-context";
import { currencyFormat } from "../../helpers/formatters";
import { useSelector, RootStateOrAny } from "react-redux";
import { FanfireSDK } from "fanfire-sdk";

//MUI
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import AuthModal from "../auth/AuthModal";
import { NetworkStatusEnums } from "../../helpers/enums";
import useLikeNFT from "../../hooks/sdkMethods/useLikeNFT";
import useUnlikeNFT from "../../hooks/sdkMethods/useUnlikeNFT";
import Reserved from "../text/Reserved";

const styles = {
  footerContainer: {
    left: "0px",
    right: "0px",
    marginBottom: "0px",
    position: "fixed" as "fixed",
    bottom: 70,
    paddingBottom: 10,
    borderRadius: "8px",
    marginLeft: "8px",
    marginRight: "8px",
    zIndex: "1",
  },
  footerContent: {
    marginLeft: "10%",
  },
  chip: {
    margin: "5px",
  },
};

interface Props {
  userWallet: string;
  nft: any;
  nftDetailListing: any;
  loading: boolean;
  listed: boolean;
  reserved: boolean;
  isInCurrentMarketplace: boolean;
  sdkService: FanfireSDK;
  sliceStore: any;
  appConfig: any;
  loadingFastProps?: boolean;
}

export default function NFTDetailBottomBar({
  userWallet,
  nft,
  nftDetailListing,
  loading,
  listed,
  reserved,
  isInCurrentMarketplace,
  sdkService,
  sliceStore,
  appConfig,
  loadingFastProps,
}: Props) {
  const { showSnackBar } = useSnackBar();
  const [currency, setCurrency] = useState<Number>(0);

  const isWeb2 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb2);
  const isWeb3 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb3);
  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);

  useEffect(() => {
    const prep = async () => {
      const currency = await getCoinVsCurrency("usd-coin", "zar");
      if (currency !== "error") {
        setCurrency(currency);
      }
    };
    prep();
  }, []);

  const downloadImage = async (image: any, name: string) => {
    console.log("dowloading image");
    try {
      const file = await axios
        .get(image, { responseType: "blob" })
        .then((response: any) => {
          return response.data;
        });
      const url = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.jpg`); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
      showSnackBar(
        "There has been an error downloading this image. Please try again.",
        "error",
        error
      );
    }
  };

  const getCoinVsCurrency = async (coin: string, currency: string) => {
    // https://www.coingecko.com/en/api/documentation
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_FANFIRE_API_BASE_URL}/forex/exchange/usd-coin/zar`
      );

      const zar = resp.data["usd-coin"]["zar"];
      return zar;
    } catch (error) {
      console.log("error");
      return "error";
    }

    // const currency = await getCoinVsCurrency('usd-coin', 'zar')
  };

  const getOwned1155Qty = (listings: any) => {
    const listingOwned = listings.filter(function (el: any) {
      return (
        el.sellerAddress.toLowerCase() ===
        sdkService.wallet.walletAddress?.toLowerCase()
      );
    });
    if (listingOwned.length) {
      return listingOwned
        .map((item: any) => item.ownedAmount ?? 0)
        .reduce((prev: any, next: any) => Number(prev) + Number(next));
    } else {
      return 0;
    }
  };

  const getForSale1155Length = (listings: any) => {
    let listingsAvail = [];
    if (listings) {
      listingsAvail = listings.filter(function (el: any) {
        return (
          el.sellerAddress.toLowerCase() !==
            sdkService.wallet.walletAddress?.toLowerCase() && el.price
        );
      });
    }

    if (listingsAvail.length) {
      return listingsAvail
        .map((item: any) => item.amount ?? 0)
        .reduce((prev: any, next: any) => Number(prev) + Number(next));
    } else {
      return 0;
    }
  };

  const handleClickScrollOwned = () => {
    const element = document.getElementById("owned-items");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickScrollAvailable = () => {
    const element = document.getElementById("available-items");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (nftDetailListing.price && nftDetailListing.priceZar) {
    nft["price"] = nftDetailListing.price;
    nft["priceZar"] = nftDetailListing.priceZar;
  }

  // useEffect(() => {
  //   console.log("AWDAWDAWD");
  //   console.log(nftDetailListing);
  //   console.log("AWDAWDAWD");
  //   console.log(nft);
  // }, [nftDetailListing, nft]);

  const userOwned = () => {
    return (
      nftDetailListing.listings &&
      nftDetailListing.listings.length &&
      userWallet.toLowerCase() ===
        nftDetailListing.listings[0].sellerAddress.toLowerCase()
    );
  };

  return (
    <Paper className="secondary" style={styles.footerContainer}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={4}>
          {/* <Favorite sdkService={sdkService} nft={nft} /> */}
          {/* <Box m={1}>
            {/* {!loading ? (
              <Badge
                badgeContent={
                 <Favorite sdkService={sdkService} nft={nft} />
                }
                sx={{
                  "& .MuiBadge-badge": {
                    color: "black",
                    backgroundColor: "white",
                  },
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <ChipItem userWallet={userWallet} nft={nft} listed={listed} />
              </Badge>
            ) : (
              <Chip style={styles.chip} label="..." />
            )} */}
          {/* </Box> */}
        </Grid>
        {!nft.isERC1155 ? (
          <>
            <Grid item xs={4}>
              <Text variantText="h3" style={{ textAlign: "center" }}>
                {nftDetailListing.price === "Unlisted" ||
                nftDetailListing.price === 0 ? (
                  <Box p={1}>Unlisted</Box>
                ) : (
                  <>
                    {!loading &&
                      !isNaN(nftDetailListing.priceZar) &&
                      currencyFormat(nftDetailListing.priceZar, "R")}
                  </>
                )}
              </Text>
            </Grid>
            <Grid item xs={4}>
              <Text
                variantText="h6"
                style={{ textAlign: "right", paddingRight: "10px" }}
              >
                {nftDetailListing.price === "Unlisted" ||
                nftDetailListing.price === 0 ? (
                  <></>
                ) : (
                  <Text variantText="body2">
                    {!loading &&
                      !isNaN(nftDetailListing.price) &&
                      currencyFormat(
                        nftDetailListing.price,
                        isWeb3 ? "USDC" : "$"
                      )}
                    {isWeb2 && !isNaN(nftDetailListing.price) && " (USD)"}
                  </Text>
                )}
              </Text>
            </Grid>
            <Grid item xs={12} paddingLeft={3} paddingRight={3}>
              {!loading ? (
                //Not loading anymore
                isAuthenticated ? (
                  //Autheticated
                  userOwned() && isInCurrentMarketplace ? (
                    //User Owns NFT and is in current marketplace
                    <>
                      <PrimaryButton
                        onClick={() =>
                          downloadImage(nft.metadata.image, nft.name)
                        }
                      >
                        <Text
                          variantText="body1"
                          style={{
                            fontWeight: "bold",
                            ...appConfig.general.styleOverrides
                              .buttonPrimaryTextColor,
                          }}
                        >
                          Download
                        </Text>
                      </PrimaryButton>
                      <RequestAPrint
                        nft={nft}
                        sdkService={sdkService}
                        appConfig={appConfig}
                      />
                    </>
                  ) : // Item is reserved but NOT for current user
                  reserved ? (
                    <Reserved appConfig={appConfig} />
                  ) : !listed && isInCurrentMarketplace ? (
                    <Text variantText="body1" style={{ textAlign: "center" }}>
                      This{" "}
                      {appConfig.general.site_noun
                        ? appConfig.general.site_noun
                        : "item"}{" "}
                      is currently not listed on the Marketplace
                    </Text>
                  ) : (
                    !nft.isERC1155 &&
                    (isInCurrentMarketplace ? (
                      <Box m={1}>
                        <Checkout
                          nft={nft}
                          sdkService={sdkService}
                          sliceStore={sliceStore}
                          appConfig={appConfig}
                          currency={currency}
                        />
                      </Box>
                    ) : nft.metadata.external_url ? (
                      <Box m={2}>
                        <PrimaryButton
                          onClick={() =>
                            window.open(nft.metadata.external_url, "_blank")
                          }
                        >
                          <Text variantText="body1">View Item Marketplace</Text>
                        </PrimaryButton>
                      </Box>
                    ) : (
                      <Text variantText="body1" style={{ textAlign: "center" }}>
                        This{" "}
                        {appConfig.general.site_noun
                          ? appConfig.general.site_noun
                          : "item"}{" "}
                        is not tradable in the current Marketplace.
                      </Text>
                    ))
                  )
                ) : !listed && !loading ? (
                  <Text variantText="body1" style={{ textAlign: "center" }}>
                    This{" "}
                    {appConfig.general.site_noun
                      ? appConfig.general.site_noun
                      : "item"}{" "}
                    is not tradable in the current Marketplace
                  </Text>
                ) : (
                  !isAuthenticated && (
                    <Box display="flex" justifyContent="center">
                      <AuthModal
                        type="login"
                        sdkService={sdkService}
                        sliceStore={sliceStore}
                        appConfig={appConfig}
                      />
                      <AuthModal
                        type="signup"
                        sdkService={sdkService}
                        sliceStore={sliceStore}
                        appConfig={appConfig}
                      />
                    </Box>
                  )
                )
              ) : (
                <Box>
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    variant="text"
                    height={50}
                  />
                  <Skeleton sx={{ bgcolor: "grey.900" }} variant="text" />
                </Box>
              )}
            </Grid>
          </>
        ) : (
          <>
            {isAuthenticated ? (
              <Grid item xs={12}>
                <Box m={1} display="flex">
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                      <Badge
                        badgeContent={getForSale1155Length(
                          nftDetailListing.listings
                        )}
                        sx={{
                          "& .MuiBadge-badge": {
                            color: "black",
                            backgroundColor: "white",
                          },
                        }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <PrimaryButton
                          onClick={() => handleClickScrollAvailable()}
                        >
                          <Text
                            variantText="body1"
                            style={{
                              fontWeight: "bold",
                              ...appConfig.general.styleOverrides
                                .buttonPrimaryTextColor,
                            }}
                          >
                            Available
                          </Text>
                        </PrimaryButton>
                      </Badge>
                    </Grid>
                    {nftDetailListing.ownedAmount && (
                      <Grid item>
                        <Badge
                          badgeContent={nftDetailListing.ownedAmount}
                          sx={{
                            "& .MuiBadge-badge": {
                              color: "black",
                              backgroundColor: "white",
                            },
                          }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <PrimaryButton
                            onClick={() => handleClickScrollOwned()}
                          >
                            <Text
                              variantText="body1"
                              style={{
                                fontWeight: "bold",
                                ...appConfig.general.styleOverrides
                                  .buttonPrimaryTextColor,
                              }}
                            >
                              Owned
                            </Text>
                          </PrimaryButton>
                        </Badge>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} justifyContent="center" alignItems="center">
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item>
                    <Box display="flex">
                      <AuthModal
                        type="login"
                        sdkService={sdkService}
                        sliceStore={sliceStore}
                        appConfig={appConfig}
                      />
                      <AuthModal
                        type="signup"
                        sdkService={sdkService}
                        sliceStore={sliceStore}
                        appConfig={appConfig}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Paper>
  );
}
