import {ethers} from 'ethers'

export const networkMap: any = {
    polygon: {
      chainId: ethers.utils.hexValue(137), // '0x89'
      chainName: "Polygon Mainnet", 
      nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 6 },
      rpcUrls: ["https://polygon.fanfire.ai"],
      blockExplorerUrls: ["https://www.polygonscan.com/"],
    },
    mumbai: {
      chainId: ethers.utils.hexValue(80001), // '0x13881'
      chainName: "Mumbai",
      nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
      rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
      blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
    },
};