"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Redemptions = void 0;
var ethers_1 = require("ethers");
var Redemptions = /** @class */ (function () {
    function Redemptions(api, chain, nftService, marketplaceService, walletService) {
        var _this = this;
        // Method that is called when web3 is enabled in the FFSDK
        this.setWeb3 = function (web3Provider, web3Service) {
            _this.web3 = web3Provider;
            _this.web3Service = web3Service;
        };
        // Method that is called when web2 is enabled in the FFSDK
        this.setWeb2 = function (firebaseAuth) {
            _this.firebaseAuth = firebaseAuth;
        };
        this.createRedemption = function (walletAddress, redemptions, estateAddress, contactNumber, email) { return __awaiter(_this, void 0, void 0, function () {
            var redemptionEntry_1, i, transferResponse, err_1, transferPromiseArray, err_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 15, , 16]);
                        if (!this.firebaseAuth && !this.web3 && !this.web3Service)
                            throw Error("Authentication is required for this method");
                        return [4 /*yield*/, this.fanFireAPI.createRedemptionEntry(walletAddress.toLowerCase(), estateAddress.toLowerCase(), redemptions, contactNumber, email)];
                    case 1:
                        redemptionEntry_1 = _a.sent();
                        if (!redemptionEntry_1.isSuccess || !redemptionEntry_1.value || !redemptionEntry_1.value._id)
                            throw Error("".concat(redemptionEntry_1.errorMessage));
                        if (!(this.web3 && this.web3Service)) return [3 /*break*/, 11];
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < redemptions.length)) return [3 /*break*/, 10];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 7, , 9]);
                        return [4 /*yield*/, this.web3Service.giftNFT(walletAddress.toLowerCase(), estateAddress.toLowerCase(), redemptions[i].nftAddress.toLowerCase(), redemptions[i].tokenId, redemptions[i].totalAmount, redemptions[i].blockchain.toLowerCase())];
                    case 4:
                        transferResponse = _a.sent();
                        if (!transferResponse) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.fanFireAPI.updateRedemptionNftTransferStatus(redemptionEntry_1.value._id, redemptions[i].nftAddress.toLowerCase(), redemptions[i].tokenId, redemptions[i].blockchain.toLowerCase(), 'completed')];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [3 /*break*/, 9];
                    case 7:
                        err_1 = _a.sent();
                        return [4 /*yield*/, this.fanFireAPI.updateRedemptionNftTransferStatus(redemptionEntry_1.value._id, redemptions[i].nftAddress.toLowerCase(), redemptions[i].tokenId, redemptions[i].blockchain.toLowerCase(), 'failed')];
                    case 8:
                        _a.sent();
                        console.error("Web3 Transfer NFT ERROR: ".concat(err_1));
                        return [3 /*break*/, 9];
                    case 9:
                        i++;
                        return [3 /*break*/, 2];
                    case 10: return [3 /*break*/, 14];
                    case 11:
                        if (!this.firebaseAuth) return [3 /*break*/, 13];
                        if (!redemptionEntry_1.isSuccess || !redemptionEntry_1.value || !redemptionEntry_1.value._id)
                            throw Error("".concat(redemptionEntry_1.errorMessage));
                        transferPromiseArray = redemptions.map(function (redemption) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (redemptionEntry_1.value !== null) {
                                    return [2 /*return*/, this.fanFireAPI.transferNftForRedemption(redemptionEntry_1.value._id, walletAddress.toLowerCase(), estateAddress.toLowerCase(), redemption.nftAddress.toLowerCase(), redemption.tokenId, redemption.totalAmount, redemption.blockchain.toLowerCase())];
                                }
                                else {
                                    throw Error("".concat(redemptionEntry_1.errorMessage));
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(transferPromiseArray)];
                    case 12:
                        _a.sent();
                        return [3 /*break*/, 14];
                    case 13: throw Error("Authentication is required for this method");
                    case 14: return [2 /*return*/, redemptionEntry_1.value];
                    case 15:
                        err_2 = _a.sent();
                        throw Error("createRedemption SDK Error: ".concat(err_2));
                    case 16: return [2 /*return*/];
                }
            });
        }); };
        this.requestOtpForRedemption = function (orderNumber) { return __awaiter(_this, void 0, void 0, function () {
            var redemption, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fanFireAPI.requestRedemptionOTP(orderNumber)];
                    case 1:
                        redemption = _a.sent();
                        if (!redemption.isSuccess || !redemption.value)
                            throw Error("".concat(redemption.errorMessage));
                        return [2 /*return*/, redemption.value];
                    case 2:
                        err_3 = _a.sent();
                        throw Error("requestOtpForRedemption SDK Error: ".concat(err_3));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getRedemptionsForWalletAddress = function (walletAddress) { return __awaiter(_this, void 0, void 0, function () {
            var redemptions, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fanFireAPI.getRedemptionsForWalletAddress(walletAddress.toLowerCase())];
                    case 1:
                        redemptions = _a.sent();
                        if (!redemptions.isSuccess || !redemptions.value)
                            throw Error("".concat(redemptions.errorMessage));
                        return [2 /*return*/, redemptions.value];
                    case 2:
                        err_4 = _a.sent();
                        throw Error("getRedemptionsForWalletAddress SDK Error: ".concat(err_4));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        // This method is called by the vault when selecting which nfts to redeem and burn
        this.redeemOrder = function (callerAddress, redemptionObject, selectedRedemptions) { return __awaiter(_this, void 0, void 0, function () {
            var _loop_1, this_1, index, err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        _loop_1 = function (index) {
                            var redeemedNft, redeemableNft;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        redeemedNft = selectedRedemptions[index];
                                        redeemableNft = redemptionObject.redeemableNfts.find(function (nft) {
                                            return nft.nftAddress.toLowerCase() === redeemedNft.nftAddress.toLowerCase() &&
                                                nft.tokenId === redeemedNft.tokenId &&
                                                nft.blockchain.toLowerCase() === nft.blockchain.toLowerCase();
                                        });
                                        if (!redeemableNft)
                                            throw Error("Redeemed NFT not part of Redemption Object");
                                        if (!redeemableNft.redeemedAmount && redeemableNft.redeemedAmount != 0)
                                            throw Error('Redeemed amount error');
                                        if (!(redeemedNft.redeemedAmount === redeemableNft.totalAmount - redeemableNft.redeemedAmount)) return [3 /*break*/, 3];
                                        // The whole redeemable NFT has been redeemed
                                        // Mark redemption status as complete
                                        return [4 /*yield*/, this_1.fanFireAPI.updateRedemptionNftRedemptionStatus(redemptionObject._id, redeemableNft.nftAddress.toLowerCase(), redeemableNft.tokenId, redeemableNft.blockchain.toLowerCase(), 'completed', redeemableNft.totalAmount)];
                                    case 1:
                                        // The whole redeemable NFT has been redeemed
                                        // Mark redemption status as complete
                                        _b.sent();
                                        return [4 /*yield*/, this_1.fanFireAPI.burnNft(redemptionObject._id, callerAddress.toLowerCase(), redeemableNft.nftAddress.toLowerCase(), redeemableNft.tokenId, redeemableNft.totalAmount, redeemableNft.blockchain.toLowerCase())];
                                    case 2:
                                        _b.sent();
                                        return [3 /*break*/, 6];
                                    case 3:
                                        if (!redeemedNft.redeemedAmount)
                                            throw Error('You must specify how many NFTs you would like to redeem');
                                        return [4 /*yield*/, this_1.fanFireAPI.updateRedemptionNftRedemptionStatus(redemptionObject._id, redeemableNft.nftAddress.toLowerCase(), redeemableNft.tokenId, redeemableNft.blockchain.toLowerCase(), 'partial', redeemedNft.redeemedAmount + redeemableNft.redeemedAmount)];
                                    case 4:
                                        _b.sent();
                                        return [4 /*yield*/, this_1.fanFireAPI.burnNft(redemptionObject._id, callerAddress.toLowerCase(), redeemableNft.nftAddress.toLowerCase(), redeemableNft.tokenId, redeemedNft.redeemedAmount, redeemableNft.blockchain.toLowerCase())];
                                    case 5:
                                        _b.sent();
                                        _b.label = 6;
                                    case 6: return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        index = 0;
                        _a.label = 1;
                    case 1:
                        if (!(index < selectedRedemptions.length)) return [3 /*break*/, 4];
                        return [5 /*yield**/, _loop_1(index)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        index++;
                        return [3 /*break*/, 1];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_5 = _a.sent();
                        throw Error("redeemOrder SDK Error: ".concat(err_5));
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        this.getRedeemableNFTs = function (walletAddress, nftAddresses) { return __awaiter(_this, void 0, void 0, function () {
            var promArr, walletNFTs, listings_1, redeemableNFTs, err_6;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, Promise.all([
                                this.walletService.getAllWalletNFTs(walletAddress, 0, 9999),
                                this.marketplaceService.getAllListings(nftAddresses),
                            ])];
                    case 1:
                        promArr = _a.sent();
                        walletNFTs = promArr[0];
                        listings_1 = promArr[1];
                        return [4 /*yield*/, Promise.all(walletNFTs.tokens.map(function (nft) { return __awaiter(_this, void 0, void 0, function () {
                                var listedNft, sellerListing, isCallerAnOwner, ownedAmount, isCallerAnOwner, ownedAmount;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            listedNft = listings_1.find(function (listing) {
                                                return listing.nftAddress.toLowerCase() === nft.contractAddress.toLowerCase() &&
                                                    listing.tokenId === nft.tokenId;
                                            });
                                            if (!listedNft) return [3 /*break*/, 6];
                                            sellerListing = listedNft.listings.find(function (listing) { return listing.sellerAddress.toLowerCase() === walletAddress.toLowerCase(); });
                                            if (sellerListing)
                                                return [2 /*return*/, undefined]; // The seller has listed his NFT
                                            if (!nft.metadata) return [3 /*break*/, 4];
                                            if (!nft.metadata.redeemable) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.fanFireAPI.erc1155BalanceOf(walletAddress, nft.contractAddress, nft.tokenId, this.chain)];
                                        case 1:
                                            isCallerAnOwner = _a.sent();
                                            if (!isCallerAnOwner.isSuccess || !isCallerAnOwner.value)
                                                throw Error("isCallerAndOwner error: ".concat(isCallerAnOwner.errorMessage));
                                            ownedAmount = ethers_1.ethers.BigNumber.from(isCallerAnOwner.value.hex).toString();
                                            return [2 /*return*/, __assign(__assign({}, nft), { ownedAmount: ownedAmount })];
                                        case 2: return [2 /*return*/, undefined];
                                        case 3: return [3 /*break*/, 5];
                                        case 4: return [2 /*return*/, undefined];
                                        case 5: return [3 /*break*/, 11];
                                        case 6:
                                            if (!nft.metadata) return [3 /*break*/, 10];
                                            if (!nft.metadata.redeemable) return [3 /*break*/, 8];
                                            return [4 /*yield*/, this.fanFireAPI.erc1155BalanceOf(walletAddress, nft.contractAddress, nft.tokenId, this.chain)];
                                        case 7:
                                            isCallerAnOwner = _a.sent();
                                            if (!isCallerAnOwner.isSuccess || !isCallerAnOwner.value)
                                                throw Error("isCallerAndOwner error: ".concat(isCallerAnOwner.errorMessage));
                                            ownedAmount = ethers_1.ethers.BigNumber.from(isCallerAnOwner.value.hex).toString();
                                            return [2 /*return*/, __assign(__assign({}, nft), { ownedAmount: ownedAmount })];
                                        case 8: return [2 /*return*/, undefined];
                                        case 9: return [3 /*break*/, 11];
                                        case 10: return [2 /*return*/, undefined];
                                        case 11: return [2 /*return*/];
                                    }
                                });
                            }); }))];
                    case 2:
                        redeemableNFTs = _a.sent();
                        return [2 /*return*/, redeemableNFTs.filter(function (nft) { return nft !== undefined; })];
                    case 3:
                        err_6 = _a.sent();
                        throw Error("getRedeemableNFTs SDK Error: ".concat(err_6));
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.fanFireAPI = api;
        this.chain = chain;
        this.nftService = nftService;
        this.walletService = walletService;
        this.marketplaceService = marketplaceService;
    }
    return Redemptions;
}());
exports.Redemptions = Redemptions;
