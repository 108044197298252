// Identicon.tsx
import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
//@ts-ignore //need to also install @types here
import Jazzicon from "@metamask/jazzicon";

const StyledIdenticon = styled.div`
  height: 2rem;
  width: 2rem;
  background-color: black;
`;

interface Props {
  account: string;
  size: number;
  hAndW: string;
}

export default function Identicon({ account, size, hAndW }: Props) {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (account && ref.current) {
      ref.current.innerHTML = "";
      ref.current.appendChild(
        Jazzicon(size, parseInt(account.slice(2, 10), 16))
      );
    }
  }, [account]);

  return (
    <StyledIdenticon ref={ref as any} style={{ height: hAndW, width: hAndW }} />
  );
}
