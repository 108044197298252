import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import * as sliceStore from "../../store/userSlices/userSlice";
import { useDispatch } from "react-redux";
import { FanfireSDK } from "fanfire-sdk";
import SDKContext from "../../context/sdk-context";
import AppConfigContext from "../../context/appConfig-context";

// Components
import PrimaryButton from "../../components/buttons/PrimaryButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import Text from "../../components/text/Text";
import AuthModal from "../../components/auth/AuthModal";

// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import PlayCircleTwoToneIcon from "@mui/icons-material/PlayCircleTwoTone";

const styles = {
  landingImage: {
    filter: "drop-shadow(0px 1.27599px 15.6522px #000000)",
    borderRadius: "10px",
    width: "80%",
    height: "80%",
  },
  byAuthor: {
    maxWidth: "100%",
    height: "auto",
    filter: "drop-shadow(0px 1.27599px 15.6522px #000000)",
  },
  background: {
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    filter: "blur(4px)",
    position: "fixed" as "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    height: "100vh",
  },
  grid: {
    textAlign: "left" as "left",
    position: "relative" as "relative",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-around",
  },
  box: {
    display: "flex" as "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-around",
  },
  dropShadow: {
    filter: "drop-shadow(0px 1.27599px 15.6522px #000000)",
  },
  imageContainer: {
    position: "relative" as "relative",
  },
};

export default function Landing() {
  const { hash }: any = useLocation();
  const [registerOpen, setRegisterOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const appConfig: any = useContext(AppConfigContext).appConfig;
  const sdkService: FanfireSDK = useContext(SDKContext).ffsdk;

  useEffect(() => {
    const prep = async () => {
      if (hash.includes("invitation")) {
        await dispatch(sliceStore.signOutWithGoogle());
        setRegisterOpen(true);
      }
    };
    prep();
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {appConfig.landingPage.landingBackroundPath && (
        <div
          style={{
            ...styles.background,
            backgroundImage: `url(${appConfig.landingPage.landingBackroundPath})`,
          }}
        ></div>
      )}
      <div style={{ zIndex: "-1" }}>
        <Grid
          container
          paddingTop={15}
          pb={5}
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={10}
            sm={10}
            md={4}
            lg={3}
            style={styles.grid}
            order={{ xs: 2, sm: 1, md: 1 }}
            justifyContent="center"
          >
            <Box sx={styles.box}>
              <Box pb={3}>
                <Text variantText="h1" style={{ ...styles.dropShadow }}>
                  {appConfig.landingPage.header}
                </Text>
              </Box>
              <Box pb={1} display="flex" alignItems="center">
                {appConfig.landingPage.byText && (
                  <>
                    {appConfig.landingPage.byPreText && (
                      <Text
                        variantText="body1"
                        style={{
                          marginRight: "10px",
                          ...appConfig.landingPage.styleOverrides.text,
                        }}
                      >
                        {appConfig.landingPage.byPreText}
                      </Text>
                    )}
                    <Text
                      variantText="h3"
                      style={{ ...appConfig.landingPage.styleOverrides.text }}
                    >
                      {appConfig.landingPage.byText}
                    </Text>
                  </>
                )}
                {appConfig.landingPage.byImage && (
                  <>
                    {appConfig.landingPage.byPreText && (
                      <Text
                        variantText="body1"
                        style={{
                          marginRight: "10px",
                          ...appConfig.landingPage.styleOverrides.text,
                        }}
                      >
                        {appConfig.landingPage.byPreText}
                      </Text>
                    )}
                    <img
                      style={styles.byAuthor}
                      src={appConfig.landingPage.byImage}
                      alt="Logo"
                    />
                  </>
                )}
              </Box>
              <Box pb={1}>
                <Text
                  variantText="subtitle2"
                  style={{
                    ...styles.dropShadow,
                    ...appConfig.landingPage.styleOverrides.text,
                  }}
                >
                  {appConfig.landingPage.description}
                </Text>
              </Box>
              <Box display="inline">
                {appConfig.landingPage.primary.include && (
                  <RouterLink
                    style={{ textDecoration: "none" }}
                    to={appConfig.landingPage.primary.routeToPage}
                  >
                    <PrimaryButton>
                      <Text
                        variantText="body1"
                        style={{
                          fontWeight: "bold",
                          ...appConfig.general.styleOverrides
                            .buttonPrimaryTextColor,
                        }}
                      >
                        {appConfig.landingPage.primary.text}
                      </Text>
                      <ArrowForwardRoundedIcon />
                    </PrimaryButton>
                  </RouterLink>
                )}
                {appConfig.landingPage.secondary.include && (
                  <RouterLink
                    style={{ textDecoration: "none" }}
                    to={appConfig.landingPage.secondary.routeToPage}
                  >
                    <SecondaryButton>
                      <Text
                        variantText="h6"
                        style={{
                          fontWeight: "bold",
                          ...appConfig.landingPage.styleOverrides.text,
                        }}
                      >
                        {appConfig.landingPage.secondary.text}
                      </Text>
                      <ArrowForwardRoundedIcon
                        style={appConfig.landingPage.styleOverrides.text}
                      />
                    </SecondaryButton>
                  </RouterLink>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={6}
            lg={6}
            order={{ xs: 1, sm: 1, md: 2 }}
            style={styles.imageContainer}
          >
            {appConfig.landingPage.textAboveImage && (
              <Text
                variantText="h6"
                style={{
                  ...styles.dropShadow,
                  textAlign: "center",
                  color: "white",
                  textTransform: "none",
                }}
              >
                {appConfig.landingPage.textAboveImage}
              </Text>
            )}
            {appConfig.landingPage.landingImagePath && (
              <img
                style={styles.landingImage}
                src={appConfig.landingPage.landingImagePath}
                alt="landingImage"
              />
            )}
            {appConfig.landingPage.landingVideoPath && (
              <video
                style={styles.landingImage}
                loop
                autoPlay
                muted={true}
                playsInline
              >
                <source
                  src={appConfig.landingPage.landingVideoPath}
                  type="video/mp4"
                />
              </video>
            )}
            {appConfig.landingPage.linkBelowImage.include && (
              <SecondaryButton
                onClick={() =>
                  window.open(
                    appConfig.landingPage.linkBelowImage.link,
                    "_blank"
                  )
                }
              >
                <Text
                  variantText="h6"
                  style={{
                    ...styles.dropShadow,
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    ...appConfig.landingPage.styleOverrides.text,
                  }}
                >
                  {appConfig.landingPage.linkBelowImage.text}
                  {appConfig.landingPage.linkBelowImage.includeIcon && (
                    <PlayCircleTwoToneIcon fontSize="small" />
                  )}
                </Text>
              </SecondaryButton>
            )}
          </Grid>
        </Grid>
      </div>
      <AuthModal
        type="invitation"
        sdkService={sdkService}
        sliceStore={sliceStore}
        appConfig={appConfig}
        showButton={false}
        show={registerOpen}
      />
    </div>
  );
}
