import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getCurrencyExchange } from "../store/exchangeSlice/exchangeSlice";

interface Props {
  coin: string;
  currency: string;
  lazy?: boolean;
}

//TODO: make the coin and currency come from env or appConfig, with the post-fix also used through that on the display
/**
 * This hook is used to get the exchange rate between coin and currency
 *
 * @param {string} coin Coin used - i.e usdc etc
 * @param {string} currency Currency - i.e zar, usd, etc
 * @return { currencyError, currencyLoading, currencyPrice } returns a error, loading and data state
 */
export default function useFetchExchange({
  coin,
  currency,
  lazy = false,
}: Props) {
  const [currencyPrice, setCurrencyPrice] = useState<any>([]);
  const [currencyLoading, setCurrencyLoading] = useState<boolean>(true);
  const [currencyError, setCurrencyError] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Getting Currency data");
    if (!lazy) {
      getApiExchangeData(coin, currency);
    }
  }, []);

  const getApiExchangeData = async (
    from: string = coin,
    to: string = currency
  ) => {
    setCurrencyLoading(true);
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_FANFIRE_API_BASE_URL}/forex/exchange/${from}/${to}`
      );
      const currencyPrice = resp.data[coin][currency];
      setCurrencyPrice(currencyPrice);
      setCurrencyLoading(false);
      return currencyPrice;
    } catch (error) {
      console.log("There was an error API Data");
      console.error(error);
      setCurrencyError(true);
      setCurrencyLoading(false);
      return null;
    }
  };

  return { currencyError, currencyLoading, currencyPrice, getApiExchangeData };
}
