"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
// tslint:disable-next-line:no-var-requires
var DEFAULT_CHAIN = 'mumbai';
var User = /** @class */ (function () {
    function User(api, chain) {
        var _this = this;
        // Method that is called when web3 is enabled in the FFSDK
        this.setWeb3 = function (web3Provider, web3Service) {
            _this.web3 = web3Provider;
            _this.web3Service = web3Service;
        };
        // Method that is called when web2 is enabled in the FFSDK
        this.setWeb2 = function (firebaseAuth) {
            _this.firebaseAuth = firebaseAuth;
        };
        this.fanFireAPI = api;
        this.chain = chain;
    }
    return User;
}());
exports.User = User;
