import React, { useState, useEffect } from "react";
import { getEllipsisTxt } from "../../helpers/formatters";
import Text from "../text/Text";
import CopyWalletAddress from "../wallet/CopyWalletAddress";
import { useSnackBar } from "../../context/snackAlert-context";
import { NetworkStatusEnums } from "../../helpers/enums";
import Identicon from "../avatars/Identicon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FanfireSDK } from "fanfire-sdk";

// MUI
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
// import Badge from "@mui/material/Badge";
// import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// import UserQRCode from "./UserQRCode";
// import QrCodeIcon from "@mui/icons-material/QrCode";
import ScrollToTop from "../helpers/ScrollToTop";
import Box from "@mui/material/Box";

const styles = {
  paper: {
    borderRadius: "12px",
    width: "100%",
  },
};

interface Props {
  user: any;
  sdkService: FanfireSDK;
  appConfig: any;
  mobileView: boolean;
}

export default function ProfileDash({
  sdkService,
  user,
  appConfig,
  mobileView,
}: Props) {
  const [userData, setUserData] = useState<any>({});
  const [userWalletAddress, setUserWalletAddress] = useState<any>("0x000");
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [ownedDashInfo, setOwnedDashInfo] = useState<any>([]);
  const [loadingOwnedDashInfo, setLoadingOwnedDashInfo] = useState<any>(true);
  // const { showSnackBar } = useSnackBar();
  const [open, setOpen] = useState<boolean>(false);

  let isAuthenticated: boolean = false;
  let isWeb2: boolean = false;
  let isWeb3: boolean = false;
  if (user) {
    isAuthenticated = NetworkStatusEnums.SUCCESS === user.status;
    isWeb2 = user.auth.isWeb2;
    isWeb3 = user.auth.isWeb3;
  } else {
    isAuthenticated = false;
    isWeb2 = false;
    isWeb3 = false;
  }

  // Get Logged in user Info
  useEffect(() => {
    const prep = async () => {
      const userWallet = sdkService.wallet.walletAddress;
      setUserWalletAddress(userWallet);
      if (isWeb2) {
        setLoadingUser(false);
        setUserData({
          ...user.user,
          userWallet: userWallet,
          displayName: user.user.displayName
            ? user.user.displayName
            : user.user.email,
        });
      } else if (isWeb3) {
        setUserData({
          displayName: getEllipsisTxt(userWallet ?? ""),
          photoURL: "",
          walletAddress: userWallet,
        });
        setLoadingUser(false);
      }
    };
    if (isAuthenticated) {
      prep();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      getOwnedDashInfo();
    }
  }, [isAuthenticated]);

  // Get User Owned NFTs
  const getOwnedDashInfo = async () => {
    try {
      setLoadingOwnedDashInfo(true);
      const userWallet = sdkService.wallet.walletAddress;
      const ownedNFTs: any = await sdkService.wallet.getAllWalletNFTs(
        userWallet ?? "",
        0,
        1
      );
      const numItems = ownedNFTs.totalLength;
      setOwnedDashInfo({ numItems: numItems });
      setLoadingOwnedDashInfo(false);
    } catch (error) {
      console.log("Error getting Dash owned amount");
      console.error(error);
      setLoadingOwnedDashInfo("error");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ScrollToTop />
      {/* <UserQRCode sdkService={sdkService} appConfig={appConfig} open={open} setOpen={setOpen}><></></UserQRCode>
    <Badge badgeContent={
      appConfig.general.enableQrRedemption &&
        <IconButton aria-label="qr-code" size="small" onClick={() => handleClickOpen()}>
          <QrCodeIcon fontSize="inherit" />
        </IconButton>
    }
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    color="secondary"
    sx={ appConfig.general.enableQrRedemption ? {width:'100%'} : {width:'100%' , backgroundColor: "none"} }
    > */}
      <Paper className="primary" style={styles.paper}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={4} sm={4} md={4} lg={4}>
            {loadingUser ? (
              <Skeleton
                style={{ margin: 20 }}
                sx={{ bgcolor: "grey.900", width: 64, height: 64 }}
                variant="circular"
              />
            ) : isWeb2 ? (
              userData.photoURL ? (
                <Avatar
                  alt={userData.displayName}
                  src={userData.photoURL}
                  sx={{ width: 64, height: 64 }}
                  style={{ margin: 20 }}
                />
              ) : (
                <Avatar
                  alt={userData.displayName}
                  sx={{ width: 64, height: 64 }}
                  style={{ margin: 20 }}
                >
                  <Identicon
                    account={userData.email}
                    size={64}
                    hAndW={"4rem"}
                  />
                </Avatar>
              )
            ) : (
              <Avatar
                alt={userData.displayName}
                sx={{ width: 64, height: 64 }}
                style={{ margin: 20 }}
              >
                <Identicon
                  account={userWalletAddress}
                  size={64}
                  hAndW={"4rem"}
                />
              </Avatar>
            )}
          </Grid>
          <Grid item xs={8} sm={4} md={4} lg={4}>
            {loadingUser ? (
              <Skeleton sx={{ bgcolor: "grey.900" }} variant="text" />
            ) : isWeb3 ? (
              <CopyWalletAddress
                sdkService={sdkService}
                variant="h3"
                textColor={
                  appConfig.profilePage.dashboard.styleOverride.text.color
                }
              />
            ) : (
              <>
                <Text
                  style={{
                    textAlign: "center",
                    ...appConfig.profilePage.dashboard.styleOverride.text,
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  variantText={mobileView ? "h6" : "h3"}
                >
                  {userData.displayName}
                </Text>
                <CopyWalletAddress
                  sdkService={sdkService}
                  textColor={
                    appConfig.profilePage.dashboard.styleOverride.text.color
                  }
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            {loadingOwnedDashInfo && loadingOwnedDashInfo !== "error" ? (
              <>
                <Text
                  variantText="body1"
                  style={{
                    textAlign: "center",
                    ...appConfig.profilePage.dashboard.styleOverride.text,
                  }}
                >
                  ...
                </Text>
                <Text
                  variantText="body1"
                  style={{
                    textAlign: "center",
                    ...appConfig.profilePage.dashboard.styleOverride.text,
                  }}
                >
                  Items
                </Text>
              </>
            ) : loadingOwnedDashInfo === "error" ? (
              <>
                <Tooltip
                  placement="top"
                  enterTouchDelay={0}
                  title="Error getting your amount of items"
                >
                  <ErrorOutlineIcon sx={{ fontSize: 15, color: "red" }} />
                </Tooltip>
                <Text
                  variantText="body1"
                  style={{
                    textAlign: "center",
                    ...appConfig.profilePage.dashboard.styleOverride.text,
                  }}
                >
                  Items
                </Text>
              </>
            ) : (
              <Box pb={2}>
                <Text
                  variantText="h6"
                  style={{
                    textAlign: "center",
                    ...appConfig.profilePage.dashboard.styleOverride.text,
                  }}
                >
                  {ownedDashInfo.numItems}
                </Text>
                <Text
                  variantText="body1"
                  style={{
                    textAlign: "center",
                    ...appConfig.profilePage.dashboard.styleOverride.text,
                  }}
                >
                  Items
                </Text>
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
      {/* </Badge> */}
    </>
  );
}
