"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var httpCodes = __importStar(require("http-status-codes"));
var ethers_1 = require("ethers");
var Web3Service = /** @class */ (function () {
    function Web3Service(provider, api) {
        this.web3 = provider;
        this.ffAPI = api;
    }
    Web3Service.prototype.callContract = function (address, chain, contractMethod, params) {
        var _a;
        if (chain === void 0) { chain = 'mumbai'; }
        return __awaiter(this, void 0, void 0, function () {
            var result, ffContract, contract, _b, err_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        result = {
                            isSuccess: false,
                            value: null,
                            httpStatus: httpCodes.StatusCodes.OK,
                            errorMessage: '',
                        };
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, this.ffAPI.getContract(chain, address)];
                    case 2:
                        ffContract = _c.sent();
                        if (!ffContract.isSuccess) return [3 /*break*/, 4];
                        contract = new ethers_1.ethers.Contract(address.toLowerCase(), (_a = ffContract === null || ffContract === void 0 ? void 0 : ffContract.value) === null || _a === void 0 ? void 0 : _a.abi, this.web3);
                        _b = result;
                        return [4 /*yield*/, contract[contractMethod].apply(contract, __spreadArray([], __read(params), false))];
                    case 3:
                        _b.value = _c.sent();
                        result.isSuccess = true;
                        result.httpStatus = httpCodes.StatusCodes.OK;
                        return [3 /*break*/, 5];
                    case 4:
                        result.isSuccess = false;
                        result.value = null;
                        result.errorMessage = 'Error finding ABI code';
                        result.httpStatus = ffContract.httpStatus;
                        _c.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_1 = _c.sent();
                        result.isSuccess = false;
                        result.value = null;
                        result.errorMessage = "An error occurred while calling web3 contract: ".concat(err_1.message);
                        result.httpStatus = httpCodes.StatusCodes.INTERNAL_SERVER_ERROR;
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/, result];
                }
            });
        });
    };
    Web3Service.prototype.signRedemption = function (message) {
        return __awaiter(this, void 0, void 0, function () {
            var result, signedMessage, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = {
                            isSuccess: false,
                            value: null,
                            httpStatus: httpCodes.StatusCodes.OK,
                            errorMessage: '',
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.web3.signMessage(message)];
                    case 2:
                        signedMessage = _a.sent();
                        result.value = signedMessage;
                        result.isSuccess = true;
                        result.httpStatus = httpCodes.StatusCodes.OK;
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        result.isSuccess = false;
                        result.value = null;
                        result.errorMessage = "An error occurred while signing redemption: ".concat(err_2.message);
                        result.httpStatus = httpCodes.StatusCodes.INTERNAL_SERVER_ERROR;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, result];
                }
            });
        });
    };
    Web3Service.prototype.giftNFT = function (senderAddress, receiverAddress, nftAddress, tokenId, amount, chain) {
        return __awaiter(this, void 0, void 0, function () {
            var result, interfaces, unsignedTx, feeData, ffERC721, erc721Contract, ffERC1155, erc1155Contract, chainId, _a, _b, receipt, err_3;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        result = {
                            isSuccess: false,
                            value: null,
                            httpStatus: httpCodes.StatusCodes.OK,
                            errorMessage: '',
                        };
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 16, , 17]);
                        return [4 /*yield*/, this.ffAPI.getInterfaces(nftAddress, chain)];
                    case 2:
                        interfaces = _c.sent();
                        unsignedTx = void 0;
                        return [4 /*yield*/, this.web3.getFeeData()];
                    case 3:
                        feeData = _c.sent();
                        if (!interfaces.value.isERC721) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.ffAPI.getContractByName('ERC721')];
                    case 4:
                        ffERC721 = _c.sent();
                        if (ffERC721.value == null)
                            throw Error('ERC721 Contract Not Found');
                        erc721Contract = new ethers_1.ethers.Contract(nftAddress, ffERC721.value.abi, this.web3);
                        return [4 /*yield*/, erc721Contract
                                .connect(this.web3)
                                .populateTransaction['safeTransferFrom(address,address,uint256)'](senderAddress.toLowerCase(), receiverAddress.toLowerCase(), tokenId)];
                    case 5:
                        unsignedTx = _c.sent();
                        return [3 /*break*/, 10];
                    case 6:
                        if (!interfaces.value.isERC1155) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.ffAPI.getContractByName('ERC1155')];
                    case 7:
                        ffERC1155 = _c.sent();
                        if (ffERC1155.value == null)
                            throw Error('ERC721 Contract Not Found');
                        erc1155Contract = new ethers_1.ethers.Contract(nftAddress, ffERC1155.value.abi, this.web3);
                        return [4 /*yield*/, erc1155Contract.populateTransaction['safeTransferFrom'](senderAddress.toLowerCase(), receiverAddress.toLowerCase(), tokenId, amount, '0x')];
                    case 8:
                        unsignedTx = _c.sent();
                        return [3 /*break*/, 10];
                    case 9: throw Error("Unsupported NFT Interface");
                    case 10: return [4 /*yield*/, this.web3.getChainId()];
                    case 11:
                        chainId = _c.sent();
                        _a = unsignedTx;
                        return [4 /*yield*/, this.web3.estimateGas(unsignedTx)];
                    case 12:
                        _a.gasLimit = _c.sent();
                        unsignedTx.gasPrice = feeData.gasPrice;
                        unsignedTx.chainId = chainId;
                        _b = unsignedTx;
                        return [4 /*yield*/, this.web3.getTransactionCount('pending')];
                    case 13:
                        _b.nonce = _c.sent();
                        return [4 /*yield*/, this.web3.sendTransaction(unsignedTx)];
                    case 14:
                        receipt = _c.sent();
                        return [4 /*yield*/, receipt.wait()];
                    case 15:
                        _c.sent();
                        result.isSuccess = true;
                        result.value = receipt;
                        result.httpStatus = httpCodes.StatusCodes.OK;
                        return [3 /*break*/, 17];
                    case 16:
                        err_3 = _c.sent();
                        result.isSuccess = false;
                        result.value = null;
                        result.errorMessage = err_3.toString();
                        result.httpStatus = httpCodes.StatusCodes.INTERNAL_SERVER_ERROR;
                        return [3 /*break*/, 17];
                    case 17: return [2 /*return*/, result];
                }
            });
        });
    };
    Web3Service.prototype.getWalletNativeBalance = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, signer, _a, err_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        result = {
                            isSuccess: false,
                            value: null,
                            httpStatus: httpCodes.StatusCodes.OK,
                            errorMessage: '',
                        };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        signer = this.web3;
                        _a = result;
                        return [4 /*yield*/, signer.getBalance()];
                    case 2:
                        _a.value = _b.sent();
                        result.isSuccess = true;
                        result.httpStatus = httpCodes.StatusCodes.OK;
                        return [3 /*break*/, 4];
                    case 3:
                        err_4 = _b.sent();
                        result.isSuccess = false;
                        result.value = null;
                        result.errorMessage = "An error occurred while getting Web3 wallet native balance ".concat(err_4.message);
                        result.httpStatus = httpCodes.StatusCodes.INTERNAL_SERVER_ERROR;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, result];
                }
            });
        });
    };
    Web3Service.prototype.callAuthedContract = function (contractAddress, chain, method, config) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var result, paramValues, ffContract, contractABI, signer, c, filtered, res, receipt, err_5;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        result = {
                            isSuccess: false,
                            value: null,
                            httpStatus: httpCodes.StatusCodes.OK,
                            errorMessage: '',
                        };
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 5, , 6]);
                        paramValues = Object.values(config);
                        return [4 /*yield*/, this.ffAPI.getContract(chain, contractAddress)];
                    case 2:
                        ffContract = _c.sent();
                        if (!((_a = ffContract === null || ffContract === void 0 ? void 0 : ffContract.value) === null || _a === void 0 ? void 0 : _a.abi))
                            throw Error('Contract not found');
                        contractABI = (_b = ffContract === null || ffContract === void 0 ? void 0 : ffContract.value) === null || _b === void 0 ? void 0 : _b.abi;
                        return [4 /*yield*/, this.web3.provider.getSigner()];
                    case 3:
                        signer = _c.sent();
                        c = new ethers_1.ethers.Contract(contractAddress, contractABI, signer);
                        filtered = paramValues.filter(function (x) { return x !== undefined; });
                        return [4 /*yield*/, c[method].apply(c, __spreadArray([], __read(filtered), false))];
                    case 4:
                        res = _c.sent();
                        receipt = void 0;
                        do {
                            receipt = this.web3.provider.getTransactionReceipt(res.hash);
                        } while (!receipt);
                        result.value = res;
                        result.isSuccess = true;
                        result.httpStatus = httpCodes.StatusCodes.OK;
                        return [3 /*break*/, 6];
                    case 5:
                        err_5 = _c.sent();
                        result.isSuccess = false;
                        result.value = null;
                        result.errorMessage = "An error occurred while calling web3 authenticated contract: ".concat(err_5.message);
                        result.httpStatus = httpCodes.StatusCodes.INTERNAL_SERVER_ERROR;
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/, result];
                }
            });
        });
    };
    Web3Service.prototype.reserveNFT = function (marketplaceContractAddress, chain, nftAddress, tokenId, period, reservee) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var result, ffContract, contractABI, signer, c, res, err_6;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        result = {
                            isSuccess: false,
                            value: null,
                            httpStatus: httpCodes.StatusCodes.OK,
                            errorMessage: '',
                        };
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.ffAPI.getContract(chain, marketplaceContractAddress)];
                    case 2:
                        ffContract = _c.sent();
                        if (!((_a = ffContract === null || ffContract === void 0 ? void 0 : ffContract.value) === null || _a === void 0 ? void 0 : _a.abi))
                            throw Error('Marketplace contract not found');
                        contractABI = (_b = ffContract === null || ffContract === void 0 ? void 0 : ffContract.value) === null || _b === void 0 ? void 0 : _b.abi;
                        return [4 /*yield*/, this.web3.provider.getSigner()];
                    case 3:
                        signer = _c.sent();
                        c = new ethers_1.ethers.Contract(marketplaceContractAddress, contractABI, signer);
                        return [4 /*yield*/, c.reserve(nftAddress, tokenId, period, reservee)];
                    case 4:
                        res = _c.sent();
                        result.value = res;
                        result.isSuccess = true;
                        result.httpStatus = httpCodes.StatusCodes.OK;
                        return [3 /*break*/, 6];
                    case 5:
                        err_6 = _c.sent();
                        result.isSuccess = false;
                        result.value = null;
                        result.errorMessage = "An error occurred while calling web3 authenticated contract: ".concat(err_6.message);
                        result.httpStatus = httpCodes.StatusCodes.INTERNAL_SERVER_ERROR;
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/, result];
                }
            });
        });
    };
    Web3Service.prototype.listNFT = function (marketplaceContractAddress, chain, nftAddress, tokenId, price, paymentToken, amount) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var result, ffMarketplace, marketplaceContractABI, signer, marketplaceContract, erc165, erc721InterfaceID, erc1155InterfaceID, erc165Contract, is721, erc721, erc721Contract, approved, _f, _g, approveReceipt, receipt, is1155, erc1155, erc1155Contract, approved, _h, _j, approveReceipt, receipt, ffERC20, erc20Contract, decimals, weiPrice, res, err_7;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        result = {
                            isSuccess: false,
                            value: null,
                            httpStatus: httpCodes.StatusCodes.OK,
                            errorMessage: '',
                        };
                        _k.label = 1;
                    case 1:
                        _k.trys.push([1, 23, , 24]);
                        return [4 /*yield*/, this.ffAPI.getContract(chain, marketplaceContractAddress)];
                    case 2:
                        ffMarketplace = _k.sent();
                        if (!ffMarketplace)
                            throw Error('Marketplace contract not found');
                        marketplaceContractABI = (_a = ffMarketplace === null || ffMarketplace === void 0 ? void 0 : ffMarketplace.value) === null || _a === void 0 ? void 0 : _a.abi;
                        signer = this.web3;
                        marketplaceContract = new ethers_1.ethers.Contract(marketplaceContractAddress, marketplaceContractABI, signer);
                        return [4 /*yield*/, this.ffAPI.getContractByName('IERC165')];
                    case 3:
                        erc165 = _k.sent();
                        if (!erc165)
                            throw Error('ERC165 contract not found');
                        erc721InterfaceID = '0x80ac58cd';
                        erc1155InterfaceID = '0xd9b67a26';
                        erc165Contract = new ethers_1.ethers.Contract(nftAddress, (_b = erc165 === null || erc165 === void 0 ? void 0 : erc165.value) === null || _b === void 0 ? void 0 : _b.abi, this.web3);
                        return [4 /*yield*/, erc165Contract.supportsInterface(erc721InterfaceID)];
                    case 4:
                        is721 = _k.sent();
                        if (!is721) return [3 /*break*/, 13];
                        return [4 /*yield*/, this.ffAPI.getContractByName('IERC721')];
                    case 5:
                        erc721 = _k.sent();
                        if (!erc721)
                            throw Error('ERC721 contract not found');
                        erc721Contract = new ethers_1.ethers.Contract(nftAddress, (_c = erc721 === null || erc721 === void 0 ? void 0 : erc721.value) === null || _c === void 0 ? void 0 : _c.abi, signer);
                        _g = (_f = erc721Contract).isApprovedForAll;
                        return [4 /*yield*/, this.web3.getAddress()];
                    case 6: return [4 /*yield*/, _g.apply(_f, [_k.sent(), marketplaceContractAddress])];
                    case 7:
                        approved = _k.sent();
                        if (!!approved) return [3 /*break*/, 12];
                        return [4 /*yield*/, erc721Contract.setApprovalForAll(marketplaceContractAddress, tokenId)];
                    case 8:
                        approveReceipt = _k.sent();
                        receipt = void 0;
                        _k.label = 9;
                    case 9: return [4 /*yield*/, this.web3.provider.getTransactionReceipt(approveReceipt.hash)];
                    case 10:
                        receipt = _k.sent();
                        _k.label = 11;
                    case 11:
                        if (!receipt) return [3 /*break*/, 9];
                        _k.label = 12;
                    case 12: return [3 /*break*/, 19];
                    case 13: return [4 /*yield*/, erc165Contract.supportsInterface(erc1155InterfaceID)];
                    case 14:
                        is1155 = _k.sent();
                        if (!is1155)
                            throw Error('Token type not supported');
                        return [4 /*yield*/, this.ffAPI.getContractByName('IERC1155')];
                    case 15:
                        erc1155 = _k.sent();
                        if (!erc1155)
                            throw Error('ERC1155 contract not found');
                        erc1155Contract = new ethers_1.ethers.Contract(nftAddress, (_d = erc1155 === null || erc1155 === void 0 ? void 0 : erc1155.value) === null || _d === void 0 ? void 0 : _d.abi, signer);
                        _j = (_h = erc1155Contract).isApprovedForAll;
                        return [4 /*yield*/, this.web3.getAddress()];
                    case 16: return [4 /*yield*/, _j.apply(_h, [_k.sent(), marketplaceContractAddress])];
                    case 17:
                        approved = _k.sent();
                        if (!!approved) return [3 /*break*/, 19];
                        return [4 /*yield*/, erc1155Contract.setApprovalForAll(marketplaceContractAddress, tokenId)];
                    case 18:
                        approveReceipt = _k.sent();
                        receipt = void 0;
                        do {
                            receipt = this.web3.provider.getTransactionReceipt(approveReceipt.hash);
                        } while (!receipt);
                        _k.label = 19;
                    case 19: return [4 /*yield*/, this.ffAPI.getContractByName('ERC20')];
                    case 20:
                        ffERC20 = _k.sent();
                        if (!ffERC20)
                            throw Error('ERC20 contract not found');
                        erc20Contract = new ethers_1.ethers.Contract(paymentToken, (_e = ffERC20 === null || ffERC20 === void 0 ? void 0 : ffERC20.value) === null || _e === void 0 ? void 0 : _e.abi, signer);
                        return [4 /*yield*/, erc20Contract.decimals()];
                    case 21:
                        decimals = _k.sent();
                        weiPrice = ethers_1.ethers.utils.parseUnits(price.toString(), decimals).toString();
                        return [4 /*yield*/, marketplaceContract['list'](nftAddress, tokenId, amount, weiPrice, paymentToken)];
                    case 22:
                        res = _k.sent();
                        result.value = res;
                        result.isSuccess = true;
                        result.httpStatus = httpCodes.StatusCodes.OK;
                        return [3 /*break*/, 24];
                    case 23:
                        err_7 = _k.sent();
                        result.isSuccess = false;
                        result.value = null;
                        result.errorMessage = "An error occurred while listing an NFT: ".concat(err_7.message);
                        result.httpStatus = httpCodes.StatusCodes.INTERNAL_SERVER_ERROR;
                        return [3 /*break*/, 24];
                    case 24: return [2 /*return*/, result];
                }
            });
        });
    };
    Web3Service.prototype.buyNFT = function (sellerAddress, marketplaceContractAddress, chain, nftAddress, tokenId, price, paymentToken, amount) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var result, ffMarketplaceContract, ffERC20Contract, marketplaceABI, erc20abi, signer, erc20Contract, decimals, value, approve, approveReceipt, marketplaceContract, res, err_8;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        result = {
                            isSuccess: false,
                            value: null,
                            httpStatus: httpCodes.StatusCodes.OK,
                            errorMessage: '',
                        };
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 11, , 12]);
                        return [4 /*yield*/, this.ffAPI.getContract(chain, marketplaceContractAddress)];
                    case 2:
                        ffMarketplaceContract = _c.sent();
                        if (!ffMarketplaceContract)
                            throw Error("Marketplace contract not found");
                        return [4 /*yield*/, this.ffAPI.getContractByName('ERC20')];
                    case 3:
                        ffERC20Contract = _c.sent();
                        if (!ffERC20Contract)
                            throw Error("ERC20 contract not found");
                        marketplaceABI = (_a = ffMarketplaceContract === null || ffMarketplaceContract === void 0 ? void 0 : ffMarketplaceContract.value) === null || _a === void 0 ? void 0 : _a.abi;
                        erc20abi = (_b = ffERC20Contract === null || ffERC20Contract === void 0 ? void 0 : ffERC20Contract.value) === null || _b === void 0 ? void 0 : _b.abi;
                        signer = this.web3;
                        erc20Contract = new ethers_1.ethers.Contract(paymentToken, erc20abi, signer);
                        return [4 /*yield*/, erc20Contract.decimals()];
                    case 4:
                        decimals = _c.sent();
                        value = ethers_1.ethers.utils.parseUnits(price.toString(), decimals).toString();
                        return [4 /*yield*/, erc20Contract.approve(marketplaceContractAddress, value)];
                    case 5:
                        approve = _c.sent();
                        approveReceipt = void 0;
                        _c.label = 6;
                    case 6: return [4 /*yield*/, this.web3.provider.getTransactionReceipt(approve.hash)];
                    case 7:
                        approveReceipt = _c.sent();
                        _c.label = 8;
                    case 8:
                        if (!approveReceipt) return [3 /*break*/, 6];
                        _c.label = 9;
                    case 9:
                        marketplaceContract = new ethers_1.ethers.Contract(marketplaceContractAddress, marketplaceABI, signer);
                        return [4 /*yield*/, marketplaceContract['buy'](sellerAddress, nftAddress, tokenId, amount)];
                    case 10:
                        res = _c.sent();
                        result.value = res;
                        result.isSuccess = true;
                        result.httpStatus = httpCodes.StatusCodes.OK;
                        return [3 /*break*/, 12];
                    case 11:
                        err_8 = _c.sent();
                        result.isSuccess = false;
                        result.value = null;
                        result.errorMessage = "An error occurred while buying a NFT: ".concat(err_8.message);
                        result.httpStatus = httpCodes.StatusCodes.INTERNAL_SERVER_ERROR;
                        return [3 /*break*/, 12];
                    case 12: return [2 /*return*/, result];
                }
            });
        });
    };
    Web3Service.prototype.pullAllFunds = function (marketplaceContractAddress, walletAddress, erc20Address, chain) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var ffMarketplaceContract, signer, marketplaceContract, balance, pull, err_9;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.ffAPI.getContract(chain, marketplaceContractAddress)];
                    case 1:
                        ffMarketplaceContract = _b.sent();
                        if (!ffMarketplaceContract.value)
                            throw Error('Marketplace contract not found');
                        signer = this.web3;
                        marketplaceContract = new ethers_1.ethers.Contract(marketplaceContractAddress, (_a = ffMarketplaceContract === null || ffMarketplaceContract === void 0 ? void 0 : ffMarketplaceContract.value) === null || _a === void 0 ? void 0 : _a.abi, signer);
                        return [4 /*yield*/, marketplaceContract.getBalance(erc20Address, walletAddress)];
                    case 2:
                        balance = _b.sent();
                        return [4 /*yield*/, marketplaceContract.pullFunds(erc20Address, balance)];
                    case 3:
                        pull = _b.sent();
                        return [2 /*return*/, pull];
                    case 4:
                        err_9 = _b.sent();
                        throw Error("pullAllFunds Web3 Service Error: ".concat(err_9));
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    Web3Service.prototype.transferERC20 = function (erc20Address, erc20Amount, recipientAddress) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var result, ffERC20Contract, erc20abi, signer, erc20Contract, decimals, value, res, err_10;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        result = {
                            isSuccess: false,
                            value: null,
                            httpStatus: httpCodes.StatusCodes.OK,
                            errorMessage: '',
                        };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.ffAPI.getContractByName('ERC20')];
                    case 2:
                        ffERC20Contract = _b.sent();
                        if (!ffERC20Contract)
                            throw Error("Marketplace contract not found");
                        erc20abi = (_a = ffERC20Contract === null || ffERC20Contract === void 0 ? void 0 : ffERC20Contract.value) === null || _a === void 0 ? void 0 : _a.abi;
                        signer = this.web3;
                        erc20Contract = new ethers_1.ethers.Contract(erc20Address, erc20abi, signer);
                        return [4 /*yield*/, erc20Contract.decimals()];
                    case 3:
                        decimals = _b.sent();
                        value = ethers_1.ethers.utils.parseUnits(erc20Amount, decimals).toString();
                        return [4 /*yield*/, erc20Contract['transfer'](recipientAddress, value)];
                    case 4:
                        res = _b.sent();
                        result.value = res;
                        result.isSuccess = true;
                        result.httpStatus = httpCodes.StatusCodes.OK;
                        return [3 /*break*/, 6];
                    case 5:
                        err_10 = _b.sent();
                        result.isSuccess = false;
                        result.value = null;
                        result.errorMessage = "An error occurred while buying a NFT: ".concat(err_10.message);
                        result.httpStatus = httpCodes.StatusCodes.INTERNAL_SERVER_ERROR;
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/, result];
                }
            });
        });
    };
    Web3Service.prototype.getNFTOwner = function (nftAddress, tokenId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, ffERC165Contract, erc165, erc721InterfaceID, erc1155InterfaceID, owner, is721, erc721, erc721Contract, is1155, erc1155, erc1155Contract, err_11, err_12;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = {
                            isSuccess: false,
                            value: null,
                            httpStatus: httpCodes.StatusCodes.OK,
                            errorMessage: '',
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 14, , 15]);
                        return [4 /*yield*/, this.ffAPI.getContractByName('IERC165')];
                    case 2:
                        ffERC165Contract = _a.sent();
                        if (!ffERC165Contract.isSuccess || !ffERC165Contract.value)
                            throw Error("IERC165 contract not found");
                        erc165 = new ethers_1.ethers.Contract(nftAddress, ffERC165Contract.value.abi, this.web3);
                        erc721InterfaceID = '0x80ac58cd';
                        erc1155InterfaceID = '0xd9b67a26';
                        owner = void 0;
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 12, , 13]);
                        return [4 /*yield*/, erc165.supportsInterface(erc721InterfaceID)];
                    case 4:
                        is721 = _a.sent();
                        if (!is721) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.ffAPI.getContractByName('ERC721')];
                    case 5:
                        erc721 = _a.sent();
                        if (!erc721.isSuccess || !erc721.value)
                            throw Error('ERC721 contract not found');
                        erc721Contract = new ethers_1.ethers.Contract(nftAddress, erc721.value.abi, this.web3);
                        return [4 /*yield*/, erc721Contract.ownerOf(tokenId)];
                    case 6:
                        owner = _a.sent();
                        return [3 /*break*/, 11];
                    case 7: return [4 /*yield*/, erc165.supportsInterface(erc1155InterfaceID)];
                    case 8:
                        is1155 = _a.sent();
                        if (!is1155)
                            throw Error('Token type not supported');
                        return [4 /*yield*/, this.ffAPI.getContractByName('ERC1155')];
                    case 9:
                        erc1155 = _a.sent();
                        if (!erc1155.isSuccess || !erc1155.value)
                            throw Error('erc1155 contract not found');
                        erc1155Contract = new ethers_1.ethers.Contract(nftAddress, erc1155.value.abi, this.web3);
                        return [4 /*yield*/, erc1155Contract.ownerOf(tokenId)];
                    case 10:
                        owner = _a.sent();
                        _a.label = 11;
                    case 11: return [3 /*break*/, 13];
                    case 12:
                        err_11 = _a.sent();
                        throw Error("An error occurred while getting the NFT Owner: ".concat(err_11.toString()));
                    case 13:
                        result.value = owner;
                        result.isSuccess = true;
                        result.httpStatus = httpCodes.StatusCodes.OK;
                        return [3 /*break*/, 15];
                    case 14:
                        err_12 = _a.sent();
                        result.isSuccess = false;
                        result.value = null;
                        result.errorMessage = "An error occurred while getting NFT owner: ".concat(err_12.message);
                        result.httpStatus = httpCodes.StatusCodes.INTERNAL_SERVER_ERROR;
                        return [3 /*break*/, 15];
                    case 15: return [2 /*return*/, result];
                }
            });
        });
    };
    return Web3Service;
}());
exports.default = Web3Service;
