import { Paper, Grid, Box } from "@mui/material";
import React from "react";
import useIsMobile from "../../hooks/useIsMobile";
import AuthModal from "../auth/AuthModal";
import Checkout from "../checkout/Checkout";
import ListNFT from "../listing/ListNFT";
import TransferNFT from "../transfer/TransferNFT";
import Text from "../text/Text";
import { NetworkStatusEnums } from "../../helpers/enums";
import { useSelector, RootStateOrAny } from "react-redux";
import { useParams } from "react-router-dom";
import { FanfireSDK } from "fanfire-sdk";
import UnlistNFT from "../listing/UnlistNFT";
import Price from "../price/Price";
import Reserved from "../text/Reserved";
import ERC1155OwnershipDashboard from "./ERC1155OwnershipDashboard";

interface Props {
  nftDetail: any;
  nftDetailListing: any;
  appConfig: any;
  sdkService: FanfireSDK;
  isMarketPlaceNFT: boolean;
  isReserved: boolean;
  listed: boolean;
  setNFTDetail: any;
  setListed: any;
  sliceStore: any;
  currency: any;
  loadingFastProps: boolean;
}

export default function ActionBar({
  nftDetail,
  nftDetailListing,
  appConfig,
  sdkService,
  isMarketPlaceNFT,
  isReserved,
  listed,
  setNFTDetail,
  setListed,
  sliceStore,
  currency,
  loadingFastProps,
}: Props) {
  const { mobileView } = useIsMobile();
  const { address, id }: any = useParams();
  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);

  const styles = {
    actionBar: {
      borderRadius: "8px",
    },
  };

  const isOwner = () => {
    return (
      nftDetailListing.listings &&
      nftDetailListing.listings.length &&
      sdkService.wallet.walletAddress?.toLowerCase() ===
        nftDetailListing.listings[0].sellerAddress.toLowerCase()
    );
  };

  const isReservedByCurrentUser = () => {
    return (
      nftDetail.reservation &&
      nftDetail.reservation[0].reservedBy.toLowerCase() ===
        sdkService.wallet.walletAddress?.toLowerCase()
    );
  };

  if (nftDetail.isERC1155) {
    return (
      <Paper className="secondary">
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={styles.actionBar}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} p={1}>
            {!mobileView && (
              <Price
                isListed={listed}
                isMarketPlaceNFT={isMarketPlaceNFT}
                coinPrice={nftDetailListing.price}
                currencyPrice={nftDetailListing.priceZar}
                isERC1155={nftDetail.isERC1155}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {isAuthenticated
              ? !mobileView && (
                  <ERC1155OwnershipDashboard
                    marketplaceListing={nftDetailListing}
                    loading={loadingFastProps}
                  />
                )
              : !mobileView && (
                  <Box display="flex">
                    <AuthModal
                      type="login"
                      sdkService={sdkService}
                      sliceStore={sliceStore}
                      appConfig={appConfig}
                    />
                    <AuthModal
                      type="signup"
                      sdkService={sdkService}
                      sliceStore={sliceStore}
                      appConfig={appConfig}
                    />
                  </Box>
                )}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  //Will return this if it is 721
  return (
    <Paper className="secondary">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={styles.actionBar}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} p={1}>
          {!mobileView && (
            <Price
              isListed={listed}
              isMarketPlaceNFT={isMarketPlaceNFT}
              coinPrice={nftDetailListing.price}
              currencyPrice={nftDetailListing.priceZar}
              isERC1155={nftDetail.isERC1155}
            />
          )}
        </Grid>
        {/* ACTION ITEMS */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {isAuthenticated ? (
            // Is Authenticated
            isOwner() ? (
              // User Owns NFT
              listed ? (
                // Listed
                isMarketPlaceNFT ? (
                  isReserved ? (
                    isReservedByCurrentUser() ||
                    nftDetail.reserveeAddress.toLowerCase() ===
                      "0x0000000000000000000000000000000000000000" ? (
                      !appConfig.general.disablePurchasing && (
                        <UnlistNFT
                          sdkService={sdkService}
                          nftAddress={
                            nftDetail.nftAddress
                              ? nftDetail.nftAddress
                              : nftDetail.contractAddress
                              ? nftDetail.contractAddress
                              : undefined
                          }
                          tokenId={nftDetail.tokenId}
                          appConfig={appConfig}
                          amount={1}
                        />
                      )
                    ) : !isReservedByCurrentUser() || isOwner() ? (
                      // Item is reserved and owned by current user and not reserved by current user
                      <>
                        <Reserved appConfig={appConfig} />
                        <Text
                          variantText="body1"
                          style={{
                            textAlign: "center",
                            padding: "5px",
                          }}
                        >
                          You can not unlist it.
                        </Text>
                      </>
                    ) : (
                      //You can still unlist the item if it is owned by you and reserved by you
                      !appConfig.general.disablePurchasing && (
                        <UnlistNFT
                          sdkService={sdkService}
                          nftAddress={
                            nftDetail.nftAddress
                              ? nftDetail.nftAddress
                              : nftDetail.contractAddress
                              ? nftDetail.contractAddress
                              : undefined
                          }
                          tokenId={nftDetail.tokenId}
                          appConfig={appConfig}
                          amount={1}
                        />
                      )
                    )
                  ) : (
                    !appConfig.general.disablePurchasing && (
                      <UnlistNFT
                        sdkService={sdkService}
                        nftAddress={
                          nftDetail.nftAddress
                            ? nftDetail.nftAddress
                            : nftDetail.contractAddress
                            ? nftDetail.contractAddress
                            : undefined
                        }
                        tokenId={nftDetail.tokenId}
                        appConfig={appConfig}
                        amount={1}
                      />
                    )
                  )
                ) : (
                  <Box m={1}>
                    <Text variantText="body1" style={{ textAlign: "center" }}>
                      This{" "}
                      {isMarketPlaceNFT
                        ? appConfig.general.site_noun
                          ? appConfig.general.site_noun
                          : "item"
                        : "item"}{" "}
                      is not tradable in the current marketplace.
                    </Text>
                  </Box>
                )
              ) : // Not Listed
              isMarketPlaceNFT ? (
                // is Marketplace NFT
                !appConfig.general.disablePurchasing && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <ListNFT
                      nft={nftDetail}
                      address={address}
                      setListed={setListed}
                      setNFT={setNFTDetail}
                      sdkService={sdkService}
                      appConfig={appConfig}
                    />
                    <TransferNFT
                      nft={nftDetail}
                      sdkService={sdkService}
                      appConfig={appConfig}
                    />
                  </Box>
                )
              ) : (
                <Box m={1}>
                  <Text variantText="body1" style={{ textAlign: "center" }}>
                    This{" "}
                    {isMarketPlaceNFT
                      ? appConfig.general.site_noun
                        ? appConfig.general.site_noun
                        : "item"
                      : "item"}{" "}
                    is not tradable in the current marketplace.
                  </Text>
                </Box>
              )
            ) : // User Does not Own NFT
            !mobileView && listed ? (
              // Listed
              isReserved ? (
                // Item is reserved and NOT owned by current user
                <>
                  <Text variantText="h6" style={{ textAlign: "centered" }}>
                    This{" "}
                    {appConfig.general.site_noun
                      ? appConfig.general.site_noun
                      : "item"}{" "}
                    is reserved {isReservedByCurrentUser() && "for you"}.
                  </Text>
                </>
              ) : (
                !nftDetail.isERC1155 &&
                !appConfig.general.disablePurchasing &&
                isMarketPlaceNFT && (
                  <Box m={1} mr={2}>
                    <Checkout
                      nft={{
                        ...nftDetail,
                        price: nftDetailListing.price,
                        priceZar: nftDetailListing.priceZar,
                        sellerAddress:
                          nftDetailListing.listings[0].sellerAddress,
                        paymentToken: nftDetailListing.listings[0].paymentToken,
                      }}
                      sdkService={sdkService}
                      sliceStore={sliceStore}
                      appConfig={appConfig}
                      currency={currency}
                    />
                  </Box>
                )
              )
            ) : // Not Listed
            !mobileView ? (
              <Box m={1}>
                <Text variantText="body1" style={{ textAlign: "center" }}>
                  This{" "}
                  {appConfig.general.site_noun
                    ? appConfig.general.site_noun
                    : "item"}{" "}
                  is currently not listed on the Marketplace.
                </Text>
              </Box>
            ) : (
              <></>
            )
          ) : (
            // Is NOT Autheticated
            !mobileView && (
              <Box display="flex">
                <AuthModal
                  type="login"
                  sdkService={sdkService}
                  sliceStore={sliceStore}
                  appConfig={appConfig}
                />
                <AuthModal
                  type="signup"
                  sdkService={sdkService}
                  sliceStore={sliceStore}
                  appConfig={appConfig}
                />
              </Box>
            )
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
