import React, { useState, useEffect } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import PrimaryButton from "../buttons/PrimaryButton";
import { ethers } from "ethers";
import LogIn from "../auth/LogIn";
import SignUp from "../auth/SignUp";
import Text from "../text/Text";
import Loader from "../loaders/BasicLoader";
import { useSnackBar } from "../../context/snackAlert-context";
import { currencyFormat } from "../../helpers/formatters";
import { NetworkStatusEnums } from "../../helpers/enums";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import { FanfireSDK } from "fanfire-sdk";

// MUI
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import AuthModal from "../auth/AuthModal";
import {
  getBottles,
  getEstate,
  getVintage,
} from "../../helpers/catergorySpecific/wineCategoryFunctions";
import { sleep } from "../../helpers/functions";

const styles = {
  checkoutImage: {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "15vh",
    filter: "drop-shadow(0px 1.27599px 15.6522px #000000)",
    borderRadius: "10px",
    objectFit: "cover" as "cover",
    display: "block",
  },
  overlay: {
    position: "absolute" as "absolute",
    top: "10%",
    right: "10px",
    color: "white",
  },
};

interface CheckoutProps {
  nft: any;
  sdkService: FanfireSDK;
  sliceStore: any;
  appConfig: any;
  currency: any;
}

export default function Checkout({
  sdkService,
  nft,
  sliceStore,
  appConfig,
  currency,
}: CheckoutProps) {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [web3payLoading, setWeb3payLoading] = useState(false);
  const [web3payLoadingText, setWeb3payLoadingText] = useState("");
  const [userWallet, setUserWallet] = useState("");
  const { showSnackBar } = useSnackBar();
  // const [formData, setFormData] = useState<any>({}); // update any
  // const [signature, setSignature] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [buyAmount, setBuyAmount] = useState<any>(1);
  const [buyAmountError, setBuyAmountError] = useState<boolean>(false);
  const [buyAmountErrorText, setBuyAmountErrorText] = useState<string>("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);
  const isWeb2 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb2);
  const isWeb3 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb3);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    const prep = async () => {
      const wallet = sdkService.wallet.walletAddress;
      setUserWallet(wallet ?? "");
    };
    prep();
  }, []);

  const handleCheckoutWithFIAT = async ({ nft }: Props) => {
    // setFormData({});
    setLoading(!loading);
    const buyerAddress = sdkService.wallet.walletAddress;
    const fiatAmount = (nft.priceZar * buyAmount).toFixed(2);
    try {
      setLoadingText("Getting your payment ready...");
      const paymentObject: any = await sdkService.marketplace.buyNFTWithFiat(
        buyerAddress ?? "",
        nft.sellerAddress,
        nft.nftAddress
          ? nft.nftAddress
          : nft.contractAddress
          ? nft.contractAddress
          : undefined,
        nft.tokenId,
        Number(buyAmount),
        Number(nft.price * buyAmount.toFixed(2)),
        nft.paymentToken,
        Number(Number(fiatAmount).toFixed(2)),
        process.env.REACT_APP_FANFIRE_PAYFAST_RETURN_URL ?? "",
        process.env.REACT_APP_FANFIRE_PAYFAST_CANCEL_URL ?? "",
        process.env.REACT_APP_FANFIRE_PAYFAST_FAILED_URL ?? "",
        `${currency}`
      );
      setLoadingText("You will be redirected to Revio shortly");
      await new Promise((r) => setTimeout(r, 3000));
      window.location.replace(paymentObject["checkout_url"]);
    } catch (err: any) {
      setLoading(false);
      console.error(err);
      let errorMessage = err.toString();
      if (errorMessage.includes("ERR_TYPE")) {
        errorMessage = errorMessage.substring(
          errorMessage.indexOf("ERR_TYPE"),
          errorMessage.length
        );
        const errorType = errorMessage.split(":").pop().split(";")[0];
        if (errorType === "NFT_RESERVED") {
          setOpen(false);
          showSnackBar(
            "This NFT is already reserved for someone. Check in later for more offers.",
            "error",
            err
          );
          return;
        }
      }
      setOpen(false);
      showSnackBar(
        "A problem has been encountered while checking out your item. Please try again.",
        "error",
        err
      );
    }
  };

  interface Props {
    nft: any;
  }

  const handleCheckoutWithERC20 = async ({ nft }: Props) => {
    setWeb3payLoading(true);
    console.log("Purchasing with ERC20");
    const buyerAddress = sdkService.wallet.walletAddress;

    //ERC20 amount for NFT token
    const nftErc20Amount = nft.price;
    console.log("ERC20 amount for NFT token", nftErc20Amount);

    //ZAR amount for NFT token
    const nftZarAmount = nft.priceZar;
    console.log("ZAR amount for NFT token", nftZarAmount);

    //Balance of logged in Wallet (WEI)
    const walletBalance: any =
      await sdkService.fanFireAPI.getWalletERC20Balance(
        buyerAddress ?? "",
        process.env.REACT_APP_FANFIRE_CHAIN ?? "mumbai"
      );
    // console.log(walletBalance)

    //Getting wallet ERC20 balance
    let walletErc20Balance = "";
    walletBalance.value.forEach((entry: any) => {
      if (
        entry.token_address.toLowerCase() === nft.paymentToken.toLowerCase()
      ) {
        walletErc20Balance = ethers.utils.formatUnits(
          entry.balance,
          entry.decimals
        );
      }
    });
    console.log("Getting wallet ERC20 balance", walletErc20Balance);

    try {
      // If wallet balance is less than nft amount the user does not have enough dolla
      if (Number(walletErc20Balance) <= Number(nftErc20Amount)) {
        console.error("Insufficient funds");
        setWeb3payLoading(false);
        showSnackBar(
          "You have insufficient funds for this transaction",
          "error",
          "Insufficient funds"
        );
      } else {
        console.log("Enough Funds To Buy Token. Buying...");
        setWeb3payLoadingText(
          "Starting MetaMask Process. Please check your MetaMask Extension"
        );
        console.log(sdkService.web3Service);
        const buyERC20 = await sdkService.marketplace.buyNFTWithERC20(
          buyerAddress ?? "",
          nft.sellerAddress,
          nft.nftAddress
            ? nft.nftAddress
            : nft.contractAddress
            ? nft.contractAddress
            : undefined,
          nft.tokenId,
          nftErc20Amount,
          nft.paymentToken,
          Number(buyAmount)
        );
        console.log(buyERC20);
        setWeb3payLoadingText("Success!");
        sleep(3000);
        setWeb3payLoading(false);
        navigate("/profile");
      }
    } catch (err) {
      showSnackBar(
        "An error has occurred buying this item with ERC20. Please try again.",
        "error",
        err
      );
      setWeb3payLoading(false);
      setOpen(false);
      console.error(err);
    }
  };

  const handlebuyAmountChange = async (e: any) => {
    if (e) {
      setBuyAmount(Math.trunc(e));
    } else {
      setBuyAmount(null);
      e = null;
    }

    if (e == null) {
      setBuyAmountError(true);
      setBuyAmountErrorText(`The purchase quantity is required`);
    } else if (e < 1) {
      setBuyAmountError(true);
      setBuyAmountErrorText(
        `Minimum quantity of ${
          appConfig.general.site_noun_plural
            ? appConfig.general.site_noun_plural
            : "items"
        } to buy is 1.`
      );
    } else if (e > Number(nft.amount)) {
      setBuyAmountError(true);
      setBuyAmountErrorText(
        `Maximum quantity of ${
          appConfig.general.site_noun_plural
            ? appConfig.general.site_noun_plural
            : "items"
        } available to buy is ${nft.amount}.`
      );
    } else {
      setBuyAmountError(false);
    }
  };

  return (
    <div>
      <PrimaryButton onClick={() => handleClickOpen()}>
        <Text
          variantText="body1"
          style={{
            fontWeight: "bold",
            ...appConfig.general.styleOverrides.buttonPrimaryTextColor,
          }}
        >
          {appConfig.nftDetail.checkoutButtonText
            ? appConfig.nftDetail.checkoutButtonText
            : "Buy"}
        </Text>
      </PrimaryButton>
      <Dialog open={open} scroll="body" onClose={handleClose}>
        <Box px={{ sm: 3, md: 3 }} py={3}>
          <DialogTitle style={{ textAlign: "center" }} textAlign="center">
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <img
                style={styles.checkoutImage}
                src={nft.metadata.image}
                alt="CheckoutImage"
              />
              {getBottles(nft.metadata) && (
                <div style={styles.overlay}>
                  <Chip
                    className="primary"
                    label={
                      <Text variantText="h6" style={{ fontWeight: "bold" }}>
                        {getBottles(nft.metadata)}
                      </Text>
                    }
                  />
                </div>
              )}
            </Grid>
          </DialogTitle>
          <DialogContent>
            <LockOutlinedIcon sx={{ fontSize: "40px" }} />
            <Text variantText="h1" style={{ textTransform: "capitalize" }}>
              Checkout
            </Text>
            <Paper className="secondary">
              <Grid
                container
                spacing={0}
                p={1}
                pb={2}
                alignItems="center"
                style={{ borderRadius: "8px" }}
              >
                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={6}
                  lg={6}
                  alignContent="center"
                  justifyContent="left"
                >
                  {/* <Text variantText="h6" style={{ textAlign: "left" }}>
                    {nft.metadata.name}
                  </Text> */}

                  {getEstate(nft.metadata) && (
                    <Text
                      variantText="h3"
                      style={{
                        fontFamily: "RalewaySemiBold",
                        textAlign: "left",
                      }}
                    >
                      {getEstate(nft.metadata)}
                    </Text>
                  )}
                  {nft.metadata && nft.metadata.name && (
                    <Text
                      variantText="h6"
                      style={{
                        fontFamily: "RalewaySemiBold",
                        textAlign: "left",
                      }}
                    >
                      {nft.metadata.name}
                    </Text>
                  )}
                  {getVintage(nft.metadata) && (
                    <Text
                      variantText="h6"
                      style={{
                        fontFamily: "RalewaySemiBold",
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {getVintage(nft.metadata)}
                    </Text>
                  )}

                  {appConfig.general.rightsText && (
                    <Text variantText="body1" style={{ textAlign: "left" }}>
                      {appConfig.general.rightsText}
                    </Text>
                  )}
                </Grid>
                {nft.isERC1155 ? (
                  <Grid item xs={4} sm={4} md={6} lg={6}>
                    {nft.priceZar !== 0 && (
                      <Text variantText="h6" style={{ textAlign: "right" }}>
                        {currencyFormat(nft.priceZar * buyAmount, "ZAR")}
                      </Text>
                    )}
                    {nft.price && (
                      <Text variantText="body2" style={{ textAlign: "right" }}>
                        {currencyFormat(
                          nft.price * buyAmount,
                          isWeb3 ? "USDC" : "$"
                        )}
                        {isWeb2 && " (USD)"}
                      </Text>
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={4} sm={4} md={6} lg={6}>
                    {nft.priceZar !== 0 && !isNaN(nft.priceZar) && (
                      <Text variantText="h6" style={{ textAlign: "right" }}>
                        {currencyFormat(nft.priceZar, "ZAR")}
                      </Text>
                    )}
                    {nft.price && !isNaN(nft.price) && (
                      <Text variantText="body2" style={{ textAlign: "right" }}>
                        {currencyFormat(nft.price, isWeb3 ? "USDC" : "$")}
                        {isWeb2 && " (USD)"}
                      </Text>
                    )}
                  </Grid>
                )}
              </Grid>
            </Paper>
            {nft.isERC1155 && (
              <Box py={2}>
                <TextField
                  id="outlined-basic"
                  label="Amount"
                  variant="outlined"
                  size="small"
                  value={buyAmount}
                  type="number"
                  fullWidth
                  InputProps={{
                    inputProps: { min: 1, max: Number(nft.amount) },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Text variantText="body2">
                          {getBottles(nft.metadata) != null && (
                            <Tooltip
                              enterTouchDelay={0}
                              title={
                                <Text variantText="body1">
                                  Each unit contains {getBottles(nft.metadata)}{" "}
                                  bottles of wine.
                                </Text>
                              }
                              placement="top"
                            >
                              <InfoOutlinedIcon
                                fontSize="small"
                                style={{
                                  verticalAlign: "middle",
                                  alignSelf: "center",
                                  textAlign: "center",
                                }}
                              />
                            </Tooltip>
                          )}
                          {getBottles(nft.metadata)}
                        </Text>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ style: { fontFamily: "Raleway" } }}
                  error={buyAmountError}
                  helperText={
                    buyAmountError
                      ? buyAmountErrorText
                      : `The number of units you want purchase. Available: ${
                          nft.amount
                        } unit(s) ${
                          getBottles(nft.metadata)
                            ? `(${getBottles(nft.metadata)} each)`
                            : ""
                        }`
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black",
                      },
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      fontFamily: "raleway",
                    },
                  }}
                  onChange={(e: any) => handlebuyAmountChange(e.target.value)}
                />
              </Box>
            )}
            {isAuthenticated ? (
              <>
                <FormControl
                  sx={{ mt: 1 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckChange}
                        name="gilad"
                      />
                    }
                    label={
                      appConfig.general.rightsAgreeButtonText ? (
                        <Text variantText="body1">
                          {appConfig.general.rightsAgreeButtonText}
                        </Text>
                      ) : (
                        <Text variantText="body1">
                          I understand the rights and agreements with purchasing
                          this{" "}
                          {appConfig.general.site_noun
                            ? appConfig.general.site_noun
                            : "item"}
                          .
                        </Text>
                      )
                    }
                  />
                  {/* {appConfig.general.rightsText &&
                    <Text variantText="body1" style={{ textAlign: 'left' }}>
                      {appConfig.general.rightsText}
                    </Text>
                  } */}
                </FormControl>
                <RouterLink
                  style={{ textDecoration: "none" }}
                  to="/faq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text
                    variantText="body1"
                    style={{ textAlign: "right", color: "black" }}
                  >
                    Read more
                  </Text>
                </RouterLink>
                {checked ? (
                  isWeb2 ? (
                    <Box>
                      {loading ? (
                        <Loader text={loadingText} />
                      ) : (
                        <>
                          <Divider sx={{ background: "white" }} />
                          <Alert severity="info" sx={{ mb: 2 }}>
                            <Text
                              variantText="body1"
                              style={{ textTransform: "none" }}
                            >
                              Please complete the purchase transaction within 10
                              minutes.
                              {/* Please note that PayFast payments are currently unavailable. */}
                            </Text>
                            <Text
                              variantText="body1"
                              style={{ textTransform: "none" }}
                            >
                              Due to the exchange rate, ZAR pricing on checkout
                              might vary slightly.
                            </Text>
                          </Alert>
                          {buyAmountError ? (
                            <PrimaryButton disabled={true}>
                              <Text
                                variantText="body1"
                                style={{
                                  fontWeight: "bold",
                                  ...appConfig.general.styleOverrides
                                    .buttonPrimaryTextColor,
                                }}
                              >
                                Checkout
                              </Text>
                            </PrimaryButton>
                          ) : (
                            <PrimaryButton
                              type="submit"
                              disabled={nft.priceZar !== 0 ? false : true}
                              onClick={() => handleCheckoutWithFIAT({ nft })}
                            >
                              <Text
                                variantText="body1"
                                style={{
                                  fontWeight: "bold",
                                  ...appConfig.general.styleOverrides
                                    .buttonPrimaryTextColor,
                                }}
                              >
                                Checkout
                              </Text>
                            </PrimaryButton>

                            // <PrimaryButton type="submit" onClick={console.log('disabled')} disabled={true}>
                            //   <Text variantText="body1" style={{ fontWeight: 'bold', ...appConfig.general.styleOverrides.buttonPrimaryTextColor }}>
                            //     Checkout With PayFast (Currently unavailable)
                            //   </Text>
                            // </PrimaryButton>
                          )}
                          {nft.priceZar === 0 && (
                            <Text
                              variantText="body1"
                              style={{ textAlign: "center" }}
                            >
                              There has been a error getting the payment price.
                              Please retry.
                            </Text>
                          )}
                        </>
                      )}
                    </Box>
                  ) : !web3payLoading ? (
                    buyAmountError ? (
                      <Text variantText="h6" style={{ fontWeight: "bold" }}>
                        Invalid Amount
                      </Text>
                    ) : (
                      <PrimaryButton
                        onClick={() => handleCheckoutWithERC20({ nft })}
                      >
                        <Text
                          variantText="body1"
                          style={{
                            fontWeight: "bold",
                            ...appConfig.general.styleOverrides
                              .buttonPrimaryTextColor,
                          }}
                        >
                          Checkout With ERC20
                        </Text>
                      </PrimaryButton>
                    )
                  ) : (
                    <Loader text={web3payLoadingText} />
                  )
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Box textAlign="center">
                <Text variantText="h6" style={{ textAlign: "center" }}>
                  Please log in or sign up to purchase an item
                </Text>
                <AuthModal
                  type="login"
                  sdkService={sdkService}
                  sliceStore={sliceStore}
                  appConfig={appConfig}
                />
                <AuthModal
                  type="signup"
                  sdkService={sdkService}
                  sliceStore={sliceStore}
                  appConfig={appConfig}
                />
              </Box>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}
