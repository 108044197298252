import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, RootStateOrAny } from "react-redux";
import Text from "../../components/text/Text";
import { useSnackBar } from "../../context/snackAlert-context";
import ProfileDash from "../../components/profile/ProfileDash";
import LikedNFTs from "../../components/nfts/LikedNFTs";
import UserOwnedNFTs from "../../components/nfts/UserOwnedNFTs";
import { NetworkStatusEnums } from "../../helpers/enums";
import LogIn from "../../components/auth/LogIn";
import Redemptions from "../../components/profile/Redemptions";
import TransactionList from "../../components/transactions/TransactionList";
import TransactionListBasic from "../../components/transactions/TransactionListBasic";
import { FanfireSDK } from "fanfire-sdk";

// MUI
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import ScrollToTop from "../../components/helpers/ScrollToTop";
import AuthModal from "../../components/auth/AuthModal";
import { Tooltip } from "@mui/material";
import ProfileLoader from "../../components/loaders/ProfileLoader";
import AccordionSkeleton from "../../components/loaders/AccordianSkeleton";
import CardSkeleton from "../../components/loaders/CardSkeleton";
import Identicon from "../../components/avatars/Identicon";

interface ProfileProps {
  authLoading: boolean;
  sdkService: FanfireSDK;
  appConfig: any;
  sliceStore: any;
  mobileView?: boolean;
}

export default function Profile({
  authLoading,
  sdkService,
  appConfig,
  sliceStore,
  mobileView = false,
}: ProfileProps) {
  const styles = {
    paper: {
      borderRadius: "12px",
      padding: 2,
    },
    navActive: {
      content: "",
      position: "absolute" as "absolute",
      height: "3px",
      background: appConfig.profilePage.navActiveColor,
      top: "80%",
      width: "50%",
      left: "50%",
      borderRadius: "3px",
      transform: "translateX(-50%)",
    },
  };

  const [tab, setTab] = useState<string>("my_items");

  const navigate = useNavigate();
  const { showSnackBar } = useSnackBar();

  const user = useSelector((state: RootStateOrAny) => state.user);
  const isWeb2 = user.auth.isWeb2;
  const isAuthenticated = NetworkStatusEnums.SUCCESS === user.status;

  // Set Tab to show initially
  // ~Payfast~ (now Revio) redirect to either success (transactions) or cancel
  const { hash }: any = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const cancelPayfast = async (fiatTxId: string) => {
      const res = await sdkService.fanFireAPI.cancelPayfastTransaction(
        fiatTxId
      );
      console.log("res: ", res);
    };
    if (isAuthenticated) {
      if (hash.includes("success")) {
        setTab("transactions");
        showSnackBar(
          "You can follow the transaction's progress within the transactions tab in your profile.",
          "success",
          "",
          true
        );
        const url = window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
      } else if (hash.includes("cancel")) {
        setTab("my_items");
        showSnackBar("Payment Canceled by user.", "error", "Revio User Cancel");
        // const fiatTxId = searchParams.get("fiatTxId")
        // console.log('fiatTxId: ', fiatTxId)
        // if (fiatTxId) {
        //     // No need to await
        //     cancelPayfast(fiatTxId);
        // }
        // else {
        //     console.log('Couldn\'t get fiatTxId from cancelled payment')
        // }
        const url = window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
      } else if (hash.includes("failed")) {
        setTab("my_items");
        showSnackBar(
          "Unfortunately, the checkout process has failed. Please try again. If the issue persists, please contact support.",
          "error",
          "Payment Failure",
          true
        );
        const url = window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
      } else if (hash.includes("redemptions")) {
        setTab("redemptions");
        const url = window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
      } else if (hash.includes("redeemed")) {
        setTab("transactions");
        showSnackBar(
          "Redemption Successful! You have been sent a email confirmation with more details.",
          "success"
        );
        const url = window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
      } else if (hash.includes("transactionStarted")) {
        setTab("transactions");
        showSnackBar(
          "You can follow the transaction's progress within the transactions tab in your profile.",
          "success"
        );
        const url = window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
      } else {
        setTab("my_items");
      }
    }
  }, [hash, isAuthenticated]);

  interface NavItemProps {
    label: string;
    id: string;
  }

  const NavItem = ({ label, id }: NavItemProps) => {
    return (
      <MenuItem key={id} onClick={() => setTab(id)}>
        {tab === id ? (
          <>
            <Text variantText="h6">{label}</Text>
            <div style={styles.navActive}></div>
          </>
        ) : (
          <Text variantText="h6">{label}</Text>
        )}
      </MenuItem>
    );
  };

  return (
    <>
      <ScrollToTop />
      {!authLoading && !isAuthenticated ? (
        <Grid
          container
          padding={3}
          paddingTop={20}
          alignItems="center"
          justifyContent="center"
        >
          <Paper className="primary" style={styles.paper}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12} justifyContent="center">
                <Avatar
                  alt={"user"}
                  sx={{ width: 64, height: 64 }}
                  style={{ margin: 20, textAlign: "center" }}
                >
                  <Identicon account={"log-in"} size={64} hAndW={"4rem"} />
                </Avatar>
              </Grid>
              <Grid item xs={12}>
                <Text
                  variantText="h6"
                  style={{
                    textAlign: "center",
                    ...appConfig.footers.footerTextColor.styleOverrides.text,
                  }}
                >
                  {appConfig.profilePage.loggedOutText
                    ? appConfig.profilePage.loggedOutText
                    : "Please log in to view your profile"}
                </Text>
              </Grid>
              <Grid item xs={12} justifyContent="center">
                <Box sx={{ textAlign: "center" }} m={3}>
                  <AuthModal
                    type="login"
                    sdkService={sdkService}
                    sliceStore={sliceStore}
                    appConfig={appConfig}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ) : (
        <Grid
          container
          padding={3}
          paddingTop={20}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Avatar
              sx={{
                width: 40,
                height: 40,
                background: `${
                  appConfig.navItems.backButtonNavIconPaddingColor
                    ? appConfig.navItems.backButtonNavIconPaddingColor
                    : "rgba(255, 255, 255, 0.1)"
                }`,
                top: "-10vh",
              }}
            >
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIosNewRoundedIcon
                  sx={{
                    fontSize: 25,
                    color: `${
                      appConfig.navItems.backButtonNavIconColor
                        ? appConfig.navItems.backButtonNavIconColor
                        : "white"
                    }`,
                  }}
                />
              </IconButton>
            </Avatar>
            {authLoading ? (
              <ProfileLoader />
            ) : (
              <ProfileDash
                user={user}
                sdkService={sdkService}
                appConfig={appConfig}
                mobileView={mobileView}
              />
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Toolbar>
                <Grid container columns={{ xs: 4, sm: 12, md: 16 }}>
                  <Grid item>
                    <NavItem
                      label={
                        appConfig.profilePage.myItemsText
                          ? appConfig.profilePage.myItemsText
                          : "My Items"
                      }
                      id={"my_items"}
                    />
                  </Grid>
                  {/* <NavItem label={"All Items"} id={"all_items"} /> */}
                  <Grid item>
                    <NavItem
                      label={
                        appConfig.profilePage.navLikedText
                          ? appConfig.profilePage.navLikedText
                          : "Liked"
                      }
                      id={"liked"}
                    />
                  </Grid>
                  <Grid item>
                    {!mobileView && isWeb2 && (
                      <NavItem label={"Transactions"} id={"transactions"} />
                    )}
                  </Grid>
                  <Grid item>
                    {!mobileView && appConfig.general.enableQrRedemption && (
                      <Tooltip
                        title={
                          "Click to see wines that you have redeemed from the vault."
                        }
                      >
                        <div>
                          <NavItem
                            label={
                              appConfig.profilePage.navRedeemText
                                ? appConfig.profilePage.navRedeemText
                                : "Claim"
                            }
                            id={"redemptions"}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item>
                    {mobileView && isWeb2 && (
                      <NavItem label={"Transactions"} id={"transactions"} />
                    )}
                  </Grid>
                  <Grid item>
                    {mobileView && appConfig.general.enableQrRedemption && (
                      <div>
                        <NavItem
                          label={
                            appConfig.profilePage.navRedeemText
                              ? appConfig.profilePage.navRedeemText
                              : "Claim"
                          }
                          id={"redemptions"}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Toolbar>
            </Grid>
            <Grid
              item
              container
              spacing={5}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              alignItems="center"
              justifyContent="center"
            >
              {!authLoading
                ? tab === "my_items" && (
                    <UserOwnedNFTs
                      sdkService={sdkService}
                      appConfig={appConfig}
                      showAll={false}
                    />
                  )
                : tab === "my_items" && (
                    <Grid
                      item
                      xs={12}
                      alignItems="center"
                      justifyContent="center"
                    >
                      {appConfig.general.appCategory &&
                      appConfig.general.appCategory === "wine" ? (
                        <AccordionSkeleton />
                      ) : (
                        [1, 2, 3, 4].map((e, i) => (
                          <Grid
                            item
                            key={`${i}-grid-item`}
                            container
                            xs={12}
                            sm={6}
                            md={6}
                            lg={4}
                            xl={3}
                            style={{ display: "grid" }}
                            pb={5}
                          >
                            <CardSkeleton keyRef={i} />
                          </Grid>
                        ))
                      )}
                    </Grid>
                  )}
              {/* {tab === "all_items" && (
                  <UserOwnedNFTs
                    sdkService={sdkService}
                    appConfig={appConfig}
                    showAll={true}
                  />
                )} */}
              {!authLoading
                ? tab === "liked" && (
                    <LikedNFTs sdkService={sdkService} appConfig={appConfig} />
                  )
                : tab === "liked" &&
                  [1, 2, 3, 4].map((e, i) => (
                    <Grid
                      item
                      key={`${i}-grid-item`}
                      container
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={3}
                      style={{ display: "grid" }}
                      pb={5}
                    >
                      <CardSkeleton keyRef={i} />
                    </Grid>
                  ))}
              {tab === "transactions" && (
                // <TransactionList
                //   sdkService={sdkService}
                //   isAuthenticated={isAuthenticated}
                //   isWeb2={isWeb2}
                // />
                <TransactionListBasic
                  appConfig={appConfig}
                  sdkService={sdkService}
                  isAuthenticated={isAuthenticated}
                  isWeb2={isWeb2}
                />
              )}
              {appConfig.general.enableQrRedemption &&
                tab === "redemptions" && (
                  <Redemptions
                    sdkService={sdkService}
                    appConfig={appConfig}
                    mobileView={mobileView}
                  />
                )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
