import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Text from "../text/Text";
import SecondaryButton from "../buttons/SecondaryButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {
  appConfig: any;
  isMarketPlaceNFT: boolean;
}

export default function CustomAccordion({
  appConfig,
  isMarketPlaceNFT,
}: Props) {
  return (
    appConfig.nftDetail.customAccordian.include &&
    isMarketPlaceNFT && (
      <Box pt={1}>
        <Accordion className="primary">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Text
              variantText="body1"
              style={{
                textTransform: "none",
                fontWeight: "bolder",
              }}
            >
              {appConfig.nftDetail.customAccordian.header}
            </Text>
          </AccordionSummary>
          <AccordionDetails>
            <Box pt={1} pb={1}>
              {appConfig.nftDetail.customAccordian.items.map(
                (item: string, i: any) => (
                  <Box pb={2} key={`${i}-box-accordDetails`}>
                    <Text variantText="body1">{item}</Text>
                  </Box>
                )
              )}
            </Box>
            {appConfig.nftDetail.customAccordian.link &&
              appConfig.nftDetail.customAccordian.link.include && (
                <Link
                  to={appConfig.nftDetail.customAccordian.link.linkTo}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SecondaryButton>
                    {appConfig.nftDetail.customAccordian.link.text}
                  </SecondaryButton>
                </Link>
              )}
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  );
}
