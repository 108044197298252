import React from "react";
import { capitalize } from "../../helpers/formatters";

//MUI
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";

//Components
import Text from "../text/Text";
import AlertDialogSlide from "./modalAlert";

interface Props {
  open: boolean;
  typeColor: AlertColor;
  handleClose: () => void;
  message: string;
  modalType?: boolean;
}

/*
=================================================================
Component used to display an information message for the general app.
This works in combination with snackbar hook.
see: apps/fanfire-components/src/context/snackAlert-context.tsx
=================================================================
*/

export default function SnackBarAlert({
  open, // Wether component is Open
  typeColor, // Type (success / error / info) - severity options
  handleClose, // Closes snackbar
  message, // message displayed in snackbar
  modalType = false, // whether the type of popup is snackbar or modal
}: Props) {
  return modalType ? (
    <AlertDialogSlide
      open={open}
      message={message}
      type={typeColor}
      handleClose={handleClose}
    />
  ) : (
    <Snackbar
      open={open}
      autoHideDuration={null}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={typeColor}>
        <Text variantText="h6">
          {typeColor.includes("error") ? "Oops" : capitalize(typeColor)}!
        </Text>
        <Text
          variantText="body1"
          style={{ textAlign: "left", textTransform: "none" }}
        >
          {message}
        </Text>
      </Alert>
    </Snackbar>
  );
}
