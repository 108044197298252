"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vault = void 0;
var ethers_1 = require("ethers");
var Vault = /** @class */ (function () {
    function Vault(api, chain) {
        var _this = this;
        // Method that is called when web3 is enabled in the FFSDK
        this.setWeb3 = function (web3Provider, web3Service) {
            _this.web3 = web3Provider;
            _this.web3Service = web3Service;
        };
        // Method that is called when web2 is enabled in the FFSDK
        this.setWeb2 = function (firebaseAuth) {
            _this.firebaseAuth = firebaseAuth;
        };
        this.add = function (vaultContractAddress, vaultId, erc721Address, tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service ? this.web3Service : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error('Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize');
                        }
                        return [4 /*yield*/, service.callAuthedContract(vaultContractAddress, this.chain, 'add', { vaultId: vaultId, erc721Address: erc721Address, tokenId: tokenId })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_1 = _a.sent();
                        throw Error("add Error: ".concat(err_1));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.remove = function (vaultContractAddress, vaultId, erc721Address, tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service ? this.web3Service : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error('Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize');
                        }
                        return [4 /*yield*/, service.callAuthedContract(vaultContractAddress, this.chain, 'remove', { vaultId: vaultId, erc721Address: erc721Address, tokenId: tokenId })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_2 = _a.sent();
                        throw Error("remove Error: ".concat(err_2));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.destroy = function (vaultContractAddress, vaultId) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service ? this.web3Service : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error('Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize');
                        }
                        return [4 /*yield*/, service.callAuthedContract(vaultContractAddress, this.chain, 'destroy', { vaultId: vaultId })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_3 = _a.sent();
                        throw Error("destroy Error: ".concat(err_3));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.close = function (vaultContractAddress, vaultId) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service ? this.web3Service : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error('Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize');
                        }
                        return [4 /*yield*/, service.callAuthedContract(vaultContractAddress, this.chain, 'close', { vaultId: vaultId })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_4 = _a.sent();
                        throw Error("close Error: ".concat(err_4));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.open = function (vaultContractAddress, vaultId) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service ? this.web3Service : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error('Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize');
                        }
                        return [4 /*yield*/, service.callAuthedContract(vaultContractAddress, this.chain, 'open', { vaultId: vaultId })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_5 = _a.sent();
                        throw Error("open Error: ".concat(err_5));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.isAllVaultsClosed = function (vaultContractAddress, ownerAddress) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fanFireAPI.callContract(vaultContractAddress, this.chain, 'isAllVaultsClosed', { ownerAddress: ownerAddress })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_6 = _a.sent();
                        throw Error("isAllVaultsClosed Error: ".concat(err_6));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.isApprovedForAll = function (vaultContractAddress, ownerAddress, operatorAddress) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fanFireAPI.callContract(vaultContractAddress, this.chain, 'isApprovedForAll', { ownerAddress: ownerAddress, operatorAddress: operatorAddress })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_7 = _a.sent();
                        throw Error("isApprovedForAll Error: ".concat(err_7));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.setApprovedForAll = function (vaultContractAddress, operatorAddress) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service ? this.web3Service : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error('Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize');
                        }
                        return [4 /*yield*/, service.callAuthedContract(vaultContractAddress, this.chain, 'setApprovedForAll', { operatorAddress: operatorAddress })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_8 = _a.sent();
                        throw Error("setApprovedForAll Error: ".concat(err_8));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.approve = function (vaultContractAddress, addressTo, tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service ? this.web3Service : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error('Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize');
                        }
                        return [4 /*yield*/, service.callAuthedContract(vaultContractAddress, this.chain, 'approve', { addressTo: addressTo, tokenId: tokenId })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_9 = _a.sent();
                        throw Error("approve Error: ".concat(err_9));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getApproved = function (vaultContractAddress, tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fanFireAPI.callContract(vaultContractAddress, this.chain, 'getApproved', { tokenId: tokenId })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_10 = _a.sent();
                        throw Error("getApproved Error: ".concat(err_10));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.vaults = function (vaultContractAddress, ownerAddress) { return __awaiter(_this, void 0, void 0, function () {
            var res, resArray, err_11;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fanFireAPI.callContract(vaultContractAddress, this.chain, 'vaults', { ownerAddress: ownerAddress })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        resArray = res.value.map(function (vaultId) {
                            return ethers_1.ethers.BigNumber.from(vaultId.hex).toString();
                        });
                        return [2 /*return*/, resArray];
                    case 2:
                        err_11 = _a.sent();
                        throw Error("vaults Error: ".concat(err_11));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.state = function (vaultContractAddress, vaultId) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_12;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fanFireAPI.callContract(vaultContractAddress, this.chain, 'state', { vaultId: vaultId })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_12 = _a.sent();
                        throw Error("state Error: ".concat(err_12));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.tokens = function (vaultContractAddress, vaultId) { return __awaiter(_this, void 0, void 0, function () {
            var res, resArray, err_13;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fanFireAPI.callContract(vaultContractAddress, this.chain, 'tokens', { vaultId: vaultId })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        resArray = res.value.map(function (token) {
                            return {
                                nftAddress: token[0],
                                tokenId: ethers_1.ethers.BigNumber.from(token[1].hex).toString(),
                                listPtr: ethers_1.ethers.BigNumber.from(token[2].hex).toString(),
                            };
                        });
                        return [2 /*return*/, resArray];
                    case 2:
                        err_13 = _a.sent();
                        throw Error("tokens Error: ".concat(err_13));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.fanFireAPI = api;
        this.chain = chain;
    }
    return Vault;
}());
exports.Vault = Vault;
