import { AlertColor, Box, IconButton } from "@mui/material";
import React from "react";
import Text from "../text/Text";
import { GoogleLogo, MetaMaskLogo } from "./AuthModal";

interface Props {
  typeAuthModal: "login" | "signup" | "invitation";
  setMessage: ({}: { severity: AlertColor; message: string }) => void;
  handleGoogleLogin: () => void;
  handleMetaMaskLogin: () => void;
}

export default function OAuthItems({
  typeAuthModal,
  handleGoogleLogin,
  handleMetaMaskLogin,
}: Props) {
  return (
    <Box pb={1}>
      <Text variantText="h6" style={{ textAlign: "center" }}>
        - OR -
      </Text>
      <Text variantText="body1" style={{ textAlign: "center" }}>
        {typeAuthModal == "login" ? "Log in with" : "Sign up with"}
      </Text>
      <Box textAlign="center"
           sx={{
             marginTop: 1,
             marginBottom: 1,
           }}>
        <IconButton
          aria-label="delete"
          size="large"
          sx={{
            backgroundColor: "white",
            marginLeft: 3,
            marginRight: 3,
          }}
          onClick={() => handleGoogleLogin()}
        >
          <GoogleLogo />
        </IconButton>
        {typeAuthModal !== "invitation" && (
          <IconButton
            aria-label="delete"
            size="large"
            sx={{
              backgroundColor: "white",
              marginLeft: 3,
              marginRight: 3,
            }}
            onClick={() => handleMetaMaskLogin()}
          >
            <MetaMaskLogo />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
