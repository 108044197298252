import {ethers} from "ethers";

/*
==================================================================
GENERAL
==================================================================
*/

/**
 * Group objects into grouped whole.
 *
 * @param x - Array of objects to group
 * @param f - Function to base group one
 * @param r - Parameter to function
 * @returns Array of objects with group name and items in group
 */
const groupBy = (x: any, f: ({}) => any, r: any = {}) => (
  // {
  //   '<GROUP-NAME>': [
  //       {<items>},
  //       {<items>}
  //    ],
  //   '<GROUP-NAME>': [
  //       {<items>},
  //       {<items>}
  //    ],
  // }
  x.forEach((v: any) => (r[f(v)] ??= []).push(v)), r
);

/**
 * Set time to sleep.
 *
 * @param ms
 */
const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Function which determines if a call should be re-attempted.
 *
 * @param fn - The function to attempt to call.
 * @param args - The arguments of the function to call.
 * @param maxTry - Max number of calls that should be attempted.
 * @param retryCount - The current attempt number.
 * @param fk - The function to determine if the attempt has succeeded.
 * @param sleepMs - The number of milliseconds to sleep.
 */
export async function retry<T extends (...arg0: any[]) => any>(
  fn: T,
  args: Parameters<T>,
  maxTry: number,
  retryCount: number = 1,
  fk?: ({}: any) => boolean,
  sleepMs?: number
): Promise<Awaited<ReturnType<T>>> {
  const currRetry = retryCount;
  try {
    const result = await fn(...args);
    if (fk && !fk(result)) {
      throw new Error("Fail");
    }
    return result;
  } catch (e) {
    console.log(`Retry ${currRetry} failed.`);
    if (currRetry > maxTry) {
      console.log(`All ${maxTry} retry attempts exhausted`);
      throw e;
    }
    if (sleepMs) {
      console.log("sleeping ms:", sleepMs);
      await sleep(sleepMs);
    }
    return retry(fn, args, maxTry, currRetry + 1, fk, sleepMs);
  }
}

/**
 * Gets the addresses of the NFTs on the marketplace.
 *
 * @returns {string[]} - Array of addresses as strings.
 */
function getMarketplaceNFTAddresses(): string[] {
  let nftAddressesENV =
    process.env.REACT_APP_FANFIRE_NFT_CONTRACT_ADDRESSES?.split(",") ?? [];
  nftAddressesENV = nftAddressesENV.map(function (x) {
    return x.toLowerCase();
  });
  return nftAddressesENV;
}

/**
 * Get the location from the NFT metadata.
 *
 * @param nftMeta - The NFT metadata.
 * @returns {any} - The location if found or null if not found.
 */
function getNFTMetaLocation(nftMeta: any) {
  /** Check if the metadata is defined and that it has attributes. */
  if (!nftMeta || !nftMeta.attributes) {
    return null;
  }

  /** The index of the location in the metadata attributes array. */
  let locationIndex = nftMeta.attributes.findIndex(
    (e: any) => e.trait_type === "Town"
  );

  /** If the location index is not found, return null */
  if (locationIndex === -1) {
    return null;
  }

  return nftMeta.attributes[locationIndex].value;
}

/*
==================================================================
FILTERING
==================================================================
*/

/**
 * The method which determines how NFTs should be sorted by default.
 *
 * @param nfts - Array of NFTs.
 * @param appConfig - The app configuration settings.
 * @returns {any} - The sorted NFTs.
 */
function defaultSort(nfts: any, appConfig: any): any {
  /** Check if the default sort is configured in the app config. */
  if (
      !appConfig.general.default_FF_sort ||
      !appConfig.general.default_FF_sort.ff_meta_field
  ) {
    console.log("not sorting");
    return nfts;
  }

  /** The items that are able to be sorted according to the app config. */
  const sortedItems = nfts.filter(
    (e: any) =>
      e.fanfireMetadata[appConfig.general.default_FF_sort.ff_meta_field]
  );

  sortedItems.sort((a: any, b: any) => {
    const numA = Number(
        a.fanfireMetadata[appConfig.general.default_FF_sort.ff_meta_field]
    );

    const numB = Number(
        b.fanfireMetadata[appConfig.general.default_FF_sort.ff_meta_field]
    );

    let diff: number;
    appConfig.general.default_FF_sort.sortBy === "desc" ? diff = numB - numA : diff = numA - numB;

    return diff;
  });

  /** The NFTs that are not configured to be sorted in the app config. */
  const nullItems = nfts.filter(
    (e: any) =>
      !e.fanfireMetadata[appConfig.general.default_FF_sort.ff_meta_field]
  );

  nfts = sortedItems.concat(nullItems);
  // console.log("sorted nfts", nfts);
  // nfts.map((e: any) => {
  //   console.log(
  //     e.fanfireMetadata[appConfig.general.default_FF_sort.ff_meta_field]
  //   );
  // });
  return nfts;
}

/*
==================================================================
1155 SPECIFIC
==================================================================
*/

/**
 * Get the 1155 NFT listing with the lowest price.
 *
 * @param nft - The NFT which listings are to be examined.
 * @returns {number} - The lowest price found (or zero if none found).
 */
function getLowest1155Price(nft: any): number {
  if (!nft.listings) {
    return 0;
  }

  /** The array of listing prices */
  let prices: number[] = [];

  for (const listing of nft.listings) {
    if (listing.price) {
      prices.push(
        Number(
          ethers.utils.formatUnits(
            listing.price,
            Number(process.env.REACT_APP_FANFIRE_COIN_DECIMALS ?? 18)
          )
        )
      );
    }
  }

  return prices.length > 0 ? Math.min(...prices) : 0;
}

/*
==================================================================
STRIJDOM SPECIFIC
==================================================================
*/

// const getWeekNr = (nftMeta: any) => {
//   // VAULTED SPECIFIC
//   // Get the week nr from metadata attributes
//   if (nftMeta.attributes) {
//     let itemIndex = nftMeta.attributes.findIndex((e: any) => e.trait_type === 'Week Nr');
//     if (itemIndex !== -1) {
//       const weekNum = nftMeta.attributes[itemIndex].value;
//       return weekNum;
//     } else {
//       return null;
//     }
//   }
//   return null;
// };

export {
  getLowest1155Price,
  defaultSort,
  sleep,
  groupBy,
  getMarketplaceNFTAddresses,
  getNFTMetaLocation,
};
