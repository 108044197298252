import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Skeleton from "@mui/material/Skeleton";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Chip from "@mui/material/Chip";
import Text from "../text/Text";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";

const styles = {
  paper: {
    borderRadius: "12px",
    width: "100%",
  },
};

export default function ProfileLoader() {
  return (
    <Paper className="primary" style={styles.paper}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Skeleton
            style={{ margin: 20 }}
            sx={{ bgcolor: "grey", width: 64, height: 64 }}
            variant="circular"
          />
        </Grid>
        <Grid item xs={8} sm={4} md={4} lg={4}>
          <Skeleton sx={{ bgcolor: "grey", width: "80%" }} variant="text" />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
      </Grid>
    </Paper>
  );
}
