import React from "react";

//MUI
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";

export default function AccordionSkeleton() {
  return (
    <Grid container alignItems="center" justifyContent="center">
      {[1, 2, 3, 4].map((e, i) => (
        <Grid item xs={12}>
          <Box m={1} key={`box-${i}`}>
            <Accordion className="primary" key={`accordion-${i}`}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Stack spacing={2} width={"100%"}>
                  <Skeleton
                    sx={{ bgcolor: "grey.700" }}
                    height={20}
                    width={"40%"}
                    variant="text"
                  />
                  <Skeleton
                    sx={{ bgcolor: "grey.700" }}
                    height={10}
                    width={"20%"}
                    variant="text"
                  />
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Box pt={1} pb={1}>
                  <Skeleton
                    sx={{ bgcolor: "grey.700" }}
                    width={"100%"}
                    variant="text"
                  />
                  <Skeleton
                    sx={{ bgcolor: "grey.700" }}
                    width={"100%"}
                    variant="text"
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
