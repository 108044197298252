import { getWineCategoryTxLabels } from "../../../helpers/catergorySpecific/wineCategoryFunctions";

const getTxLabels = (metadata: any, category: string) => {
  let name: string = "";
  let heading: string = "";
  let subHeading: string = "";

  if (category === "wine") {
    return getWineCategoryTxLabels(metadata);
  } else {
    name = "";
    heading = metadata && metadata.name ? metadata.name : "";
    subHeading = "";

    return { heading, subHeading, name };
  }
};

export { getTxLabels };
