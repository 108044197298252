import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Text from "../text/Text";
import { JSXElement } from "@babel/types";

interface Props {
  text?: string;
  style?: any;
  size?: number;
}

const styles = {
  center: {
    textAlign: "center",
  },
};

/**
 * This is the basic loader show to show loading state
 * @param {object} props Component Props
 * @param {string} props.text The text to show under the loader
 * @param {number} props.size The size of the loader spinner
 * @param {any} props.style The style of the element
 * @returns {JSXElement} return the loader jsx element
 */
export default function BasicLoader({ style, text, size }: Props) {
  return (
    <Box sx={styles.center}>
      <CircularProgress size={size} />
      <Text variantText="h6" style={{ ...styles.center, ...style }}>
        {text}
      </Text>
    </Box>
  );
}
