import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../loaders/BasicLoader";
import { useSnackBar } from "../../context/snackAlert-context";
import { useSelector, RootStateOrAny } from "react-redux";
import { ethers } from "ethers";
import { FanfireSDK } from "fanfire-sdk";

// MUI
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Text from "../text/Text";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import TertiaryButton from "../buttons/TertiaryButton";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { RegexPatterns } from "../../constants/regex";
import InputTextField from "../inputs/InputTextField";
import { Typography, Tabs, Tab } from "@mui/material";

const styles = {
  dialogContainer: {
    borderRadius: "16px",
    width: "100%",
  },
};

interface Props {
  nft: any;
  sdkService: FanfireSDK;
  appConfig: any;
}

type TabType = "email" | "wallet";

export default function TransferNFT({ nft, sdkService, appConfig }: any) {
  const [open, setOpen] = useState(false);
  const [toWallet, setToWallet] = useState<string>("");
  const [toEmail, setToEmail] = useState<string>("");
  const [transferLoading, setTransferLoading] = useState<boolean>(false);
  const [transferAmount, setTransferAmount] = useState<number>(1);
  const [transferAmountError, setTransferAmountError] =
    useState<boolean>(false);
  const [transferAmountErrorText, setTransferAmountErrorText] =
    useState<string>("");
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [toWalletError, setToWalletError] = useState<boolean>(false);
  const [toWalletErrorText, setToWalletErrorText] = useState<string>("false");
  const [toEmailError, setToEmailError] = useState<boolean>(false);
  const [toEmailErrorText, setToEmailErrorText] = useState<string>("false");

  const [promptForSenderEmail, setPromptForSenderEmail] =
    useState<boolean>(false);
  const [web3Mail, setWeb3Mail] = useState<string>("");
  const [web3MailError, setWeb3MailError] = useState<boolean>(false);
  const [web3MailErrorText, setWeb3MailErrorText] = useState<string>("false");

  const [showSendOptions, setShowSendOptions] = useState<boolean>(false);
  const [sendOptionLink, setSendOptionLink] = useState<string>("");

  const [loaderText, setLoaderText] = useState<string>("Starting transfer");

  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  const isWeb2 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb2);
  const isWeb3 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb3);
  const user = useSelector((state: RootStateOrAny) => state.user);

  const handleWeb3EmailChange = (email: string) => {
    setWeb3Mail(email);
    if (!email || !RegexPatterns.email.pattern.test(email)) {
      setWeb3MailError(true);
      setWeb3MailErrorText("Valid email is required");
    } else {
      setWeb3MailError(false);
      setWeb3MailErrorText("");
    }
  };

  const handleToWalletChange = async (e: any) => {
    setToWallet(e);
    setToEmail("");
    if (e.length == 0) {
      setToWalletError(false);
      setToWalletErrorText("");
    } else if (ethers.utils.isAddress(e.toLowerCase())) {
      if (e.toLowerCase().substring(0, 2) !== "0x") {
        setToWalletError(true);
        setToWalletErrorText(
          "Wallet address valid, but '0x' is required at the start of the wallet address"
        );
      } else {
        setToWalletError(false);
        setToWalletErrorText("");
        setTimeLeft(10);
      }
    } else {
      setToWalletError(true);
      setToWalletErrorText("Wallet address not valid.");
    }
  };

  const handleToEmailChange = async (e: any) => {
    setToWallet("");
    setToEmail(e);
    if (e.length == 0) {
      setToEmailError(false);
      setToEmailErrorText("");
    } else if (RegexPatterns.email.pattern.test(e)) {
      setToEmailError(false);
      setToEmailErrorText("");
    } else {
      setToEmailError(true);
      setToEmailErrorText("invalid email address");
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(0);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const handleTransfer = async () => {
    console.log("Transferring NFT to: ", toWallet ? toWallet : toEmail);

    setTransferLoading(true);
    try {
      const walletAddress = sdkService.wallet.walletAddress;
      if (toWallet) {
        const transfer = await sdkService.nft.giftNFT(
          walletAddress,
          toWallet,
          nft.contractAddress,
          nft.tokenId,
          transferAmount
        );
      } else if (toEmail && !toWallet) {
        const transfer = await sdkService.nft.giftNFTviaEmail(
          walletAddress,
          toEmail,
          process.env.REACT_APP_FANFIRE_APP_BASE_URL,
          nft.contractAddress,
          nft.tokenId,
          transferAmount
        );
        if (transfer.value.sent) {
          navigate("/profile/#transactionStarted", { replace: true });
        } else {
          // showSnackBar(
          //   "This user has not sign up to our platform yet. Choose an option to share invitation link!",
          //   "info"
          // );
          setTransferLoading(false);
          setShowSendOptions(true);
          setSendOptionLink(transfer.value.link);
          // show pop up for link sharing
          // transfer.value.link
          return;
        }
      }
      // giftNFT(senderAddress: string, receiverAddress: string, nftAddress: string, tokenId: string, amount: number);
      if (isWeb2) {
        navigate("/profile/#transactionStarted", { replace: true });
      } else if (isWeb3) {
        navigate("/profile", { replace: true });
      }
    } catch (error) {
      showSnackBar(
        "There has been an error gifting you item to the specified wallet address. Please try again.",
        "error",
        error
      );
      console.log("Error", error);
    }
  };

  const handletransferAmountChange = async (e: any) => {
    setTransferAmount(Math.trunc(e));
    if (e < 1) {
      setTransferAmountError(true);
      setTransferAmountErrorText(
        `Minimum quantity of ${
          appConfig.general.site_noun_plural
            ? appConfig.general.site_noun_plural
            : "items"
        } to transfer is 1.`
      );
    } else if (
      e > Number(Number(nft.ownedAmount ?? 0) - Number(nft.amount ?? 0))
    ) {
      setTransferAmountError(true);
      setTransferAmountErrorText(
        `Maximum quantity of ${
          appConfig.general.site_noun_plural
            ? appConfig.general.site_noun_plural
            : "items"
        } available to transfer is ${Number(
          Number(nft.ownedAmount ?? 0) - Number(nft.amount ?? 0)
        )}.`
      );
    } else {
      setTransferAmountError(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const shareViaEmail = async () => {
    setShowSendOptions(false);
    setTransferLoading(true);
    setLoaderText("Sending Invitation Link");
    const link = sendOptionLink;
    try {
      if (isWeb3) {
        setPromptForSenderEmail(true);
      } else {
        const resp = await sdkService.mailer.shareLinkViaEmail(
          toEmail,
          web3Mail ? web3Mail : user.user.email,
          nft.name,
          link,
          appConfig.general.app &&
            appConfig.general.app.toLowerCase() === "vaulted"
            ? "vaulted"
            : "other"
        );
        showSnackBar("Successfully sent invitation email!", "success");
        await new Promise((r) => setTimeout(r, 1000));
        setTransferLoading(false);
        handleClose();
      }

      setShowSendOptions(false);
      setSendOptionLink("");
    } catch (error) {
      console.error(error);
      showSnackBar(
        "There has been an error sending invitation email. Please try again.",
        "error",
        error
      );
      return "error";
    }
  };

  // const shareViaWhatsapp = () => {
  //   const link = sendOptionLink;
  //   console.log(link);
  // };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [tabValue, setTabValue] = useState<TabType>(isWeb2 ? "email" : "wallet");

  const handleChange = (event: React.SyntheticEvent, newValue: TabType) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Box p={1}>
        <PrimaryButton
          onClick={handleClickOpen}
          // styleOverride={{ border: '1px solid #5A5A5A', width: '87px', height: '39px', textTransform: 'capitalize' }}
        >
          <Text variantText="body1">Gift</Text>
        </PrimaryButton>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: styles.dialogContainer,
        }}
        scroll="body"
      >
        <DialogTitle>
          <Avatar sx={{ width: 44, height: 44, background: "#000000" }}>
            <SendRoundedIcon sx={{ fontSize: 35, color: "white" }} />
          </Avatar>
        </DialogTitle>
        <DialogTitle id="alert-dialog-title" component="div">
          <Text variantText="h1">Gift</Text>
        </DialogTitle>
        <DialogContent>
          {!showSendOptions && (
            <DialogContentText id="alert-dialog-description" component="div">
              <Text variantText="body1">
                Transfer your{" "}
                {appConfig.general.site_noun
                  ? appConfig.general.site_noun
                  : "NFT"}{" "}
                to another person via
              </Text>
            </DialogContentText>
          )}
          {!showSendOptions && (
            <>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  {isWeb2 ? (
                      <Tabs
                          value={tabValue}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                      >
                        <Tab
                            value="email"
                            label={<Text variantText="body1">Email</Text>}
                        />
                        <Tab
                            value="wallet"
                            label={<Text variantText="body1">Wallet Address</Text>}
                        />
                      </Tabs>
                  ) : null}
                </Box>
                {tabValue === "email" && (
                  <Box pt={3}>
                    <TextField
                      id="outlined-basic"
                      label="Email Address"
                      variant="outlined"
                      size="small"
                      value={toEmail}
                      type="text"
                      fullWidth
                      error={toEmailError}
                      disabled={showSendOptions || toWallet ? true : false}
                      helperText={
                        toEmailError
                          ? toEmailErrorText
                          : `The email address you want to transfer to`
                      }
                      InputLabelProps={{ style: { fontFamily: "Raleway" } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "black",
                          },
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          fontFamily: "raleway",
                        },
                      }}
                      //defaultValue={minPrice}
                      onChange={(e: any) => handleToEmailChange(e.target.value)}
                    />
                  </Box>
                )}
                {tabValue === "wallet" && (
                  <Box pt={3}>
                    <TextField
                      id="outlined-basic"
                      label="Wallet Address"
                      variant="outlined"
                      size="small"
                      value={toWallet}
                      type="text"
                      fullWidth
                      error={toWalletError}
                      disabled={showSendOptions || toEmail ? true : false}
                      helperText={
                        toWalletError
                          ? toWalletErrorText
                          : `The wallet address you want to transfer to.`
                      }
                      InputLabelProps={{ style: { fontFamily: "Raleway" } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "black",
                          },
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          fontFamily: "raleway",
                        },
                      }}
                      //defaultValue={minPrice}
                      onChange={(e: any) =>
                        handleToWalletChange(e.target.value)
                      }
                    />
                  </Box>
                )}
                {nft.isERC1155 && (
                  <Box pt={3}>
                    <TextField
                      id="outlined-basic"
                      label="Quantity"
                      variant="outlined"
                      size="small"
                      value={transferAmount}
                      type="number"
                      fullWidth
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: Number(
                            Number(nft.ownedAmount ?? 0) -
                              Number(nft.amount ?? 0)
                          ),
                        },
                      }}
                      disabled={showSendOptions ? true : false}
                      InputLabelProps={{ style: { fontFamily: "Raleway" } }}
                      error={transferAmountError}
                      helperText={
                        transferAmountError
                          ? transferAmountErrorText
                          : `The quantity you want to transfer. Available: ${Number(
                              Number(nft.ownedAmount ?? 0) -
                                Number(nft.amount ?? 0)
                            )}`
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "black",
                          },
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          fontFamily: "raleway",
                        },
                      }}
                      onChange={(e: any) =>
                        handletransferAmountChange(e.target.value)
                      }
                    />
                  </Box>
                )}
              </Box>
            </>
          )}
          {showSendOptions && (
            <>
              <Box p={2}>
                <Text
                  variantText="h6"
                  style={{ textTransform: "none", textAlign: "center" }}
                >
                  This is user has not signed up to this platform yet.
                </Text>
                <Text
                  variantText="body1"
                  style={{ textTransform: "none", textAlign: "center" }}
                >
                  Choose an option to share the sign up link. Once the user has
                  signed up, the gift will appear in their wallet!
                </Text>
              </Box>
              {promptForSenderEmail ||
                (!user.user.email && (
                  <InputTextField
                    error={web3MailError}
                    errorText={web3MailErrorText}
                    label="From Email"
                    onChange={handleWeb3EmailChange}
                    value={web3Mail}
                    type="email"
                    icon="email"
                    required={true}
                  />
                ))}
              <Text
                variantText="h6"
                style={{ textTransform: "none", textAlign: "center" }}
              >
                Share Sign Up Link With
              </Text>
              <Text
                variantText="body1"
                style={{ textTransform: "none", textAlign: "center" }}
              >
                {toEmail}
              </Text>
              <Box m={2} display="flex" justifyContent="center">
                <Box m={1}>
                  <PrimaryButton
                    onClick={() => shareViaEmail()}
                    disabled={
                      isWeb2 ? false : isWeb3 && !web3MailError ? false : true
                    }
                  >
                    <Text
                      variantText="body1"
                      style={{
                        fontWeight: "bold",
                        ...appConfig.general.styleOverrides
                          .buttonPrimaryTextColor,
                      }}
                    >
                      via Email
                    </Text>
                  </PrimaryButton>
                </Box>
                {/* <Box m={1}>
                  <PrimaryButton
                    onClick={() => shareViaWhatsapp()}
                    disabled={true}
                  >
                    <Text
                      variantText="body1"
                      style={{
                        fontWeight: "bold",
                        ...appConfig.general.styleOverrides
                          .buttonPrimaryTextColor,
                      }}
                    >
                      via Whatsapp
                    </Text>
                  </PrimaryButton>
                </Box> */}
              </Box>
              <Text variantText="body1" style={{ textAlign: "center" }}>
                Choose an option
              </Text>
              <Alert severity="warning" sx={{ mb: 2, mt: 1 }}>
                <Text variantText="body1" style={{ textTransform: "none" }}>
                  Please refrain from doing any transactions with this item
                  while the gift is pending. This will interrupt the gift
                  process and might cause the recipient to not receive their
                  gift.
                </Text>
              </Alert>
            </>
          )}
        </DialogContent>
        {!transferLoading && !showSendOptions ? (
          <Box m={1}>
            <DialogActions>
              <SecondaryButton onClick={() => handleClose()}>
                <Text variantText="body1">Cancel</Text>
              </SecondaryButton>
              {((toWallet && !transferAmountError && !toWalletError) ||
                (toEmail && !transferAmountError && !toEmailError)) && (
                <PrimaryButton
                  onClick={() => handleTransfer()}
                  disabled={timeLeft == 0 ? false : true}
                >
                  <Text
                    variantText="body1"
                    style={{
                      fontWeight: "bold",
                      ...appConfig.general.styleOverrides
                        .buttonPrimaryTextColor,
                    }}
                  >
                    Transfer {timeLeft == 0 ? "" : `(${timeLeft})`}
                  </Text>
                </PrimaryButton>
              )}
            </DialogActions>
          </Box>
        ) : (
          !showSendOptions && (
            <>
              <Loader size={25} text={loaderText} />
              <Text
                variantText="body1"
                style={{ textAlign: "center", textTransform: "none" }}
              >
                This may take a few minutes.
              </Text>
            </>
          )
        )}
        {tabValue === "wallet" && (
          <Box pt={1} pb={1}>
            <Alert severity="info" sx={{ mb: 2 }}>
              <Text variantText="body1" style={{ textTransform: "none" }}>
                Transferring an item is a permanent and irreversible action.
                Please revise the wallet address you are transferring to and
                ensure it is a Polygon wallet.
              </Text>
            </Alert>
          </Box>
        )}
      </Dialog>
    </div>
  );
}
