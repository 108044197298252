import React from "react";

import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import useGetLikedNFT from "../../hooks/sdkMethods/useGetLikedNFT";
import useLikeNFT from "../../hooks/sdkMethods/useLikeNFT";
import useUnlikeNFT from "../../hooks/sdkMethods/useUnlikeNFT";
import { FanfireSDK } from "fanfire-sdk";
import { Box, IconButton } from "@mui/material";
import Text from "../../components/text/Text";

interface Props {
  sdkService: FanfireSDK;
  nft: any;
}

export default function Favorite({ sdkService, nft }: Props) {
  const { likeLoading, likeError, sendLikeNFT } = useLikeNFT();
  const { unlikeLoading, unlikeError, sendUnlikeNFT } = useUnlikeNFT();
  const {
    getLikedLoading,
    getLikedError,
    setLikedAmount,
    setLiked,
    likedAmount,
    liked,
  } = useGetLikedNFT({ sdkService: sdkService, nft: nft });

  const handleLike = (e: any, nft: any) => {
    e.preventDefault();
    if (liked) {
      unLikeNFT(nft);
    } else {
      likeNFT(nft);
    }
  };

  const likeNFT = (nft: any) => {
    sendLikeNFT({
      nftAddress: nft.contractAddress,
      tokenId: nft.tokenId,
      userAddress: sdkService.wallet.walletAddress ?? "",
      sdkService: sdkService,
      likedAmount: likedAmount,
      setLikedAmount: setLikedAmount,
      setLiked: setLiked,
    });
  };

  const unLikeNFT = (nft: any) => {
    sendUnlikeNFT({
      nftAddress: nft.contractAddress,
      tokenId: nft.tokenId,
      userAddress: sdkService.wallet.walletAddress ?? "",
      sdkService: sdkService,
      likedAmount: likedAmount,
      setLikedAmount: setLikedAmount,
      setLiked: setLiked,
    });
  };

  return (
    <Box height="45px">
      {nft.likesUserAddresses !== undefined && (
        <IconButton onClick={(e) => handleLike(e, nft)}>
          <>
            {liked ? (
              <FavoriteIcon fontSize="small" sx={{ color: "red" }} />
            ) : (
              <FavoriteBorderOutlinedIcon fontSize="small" />
            )}
            <Text variantText="body1" style={{ textAlign: "right" }}>
              {likedAmount ? likedAmount : ""}
            </Text>
          </>
        </IconButton>
      )}
    </Box>
  );
}
