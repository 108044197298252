import React, { useState } from "react";

import PrimaryButton from "../buttons/PrimaryButton";
import InputTextField from "../inputs/InputTextField";
import Text from "../text/Text";
import SocialMedia from "../socialMedia/SocialMedia";
import { useSnackBar } from "../../context/snackAlert-context";
import BasicLoader from "../loaders/BasicLoader";
import { FanfireSDK } from "fanfire-sdk";

//MUI
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const styles = {
  footerContainer: {
    bottom: "0px",
    width: "100%",
    position: "absolute" as "absolute",
  },
  footerConnect: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    padding: "10px",
    left: "0%",
    right: "0%",
  },
  logo: {
    maxWidth: "200px",
    maxHeight: "82px",
    width: "auto",
    height: "82px",
    // objectFit: 'contain',
  },
};

interface Props {
  appConfig: any;
  sdkService: FanfireSDK;
}

export default function DesktopFooter({ appConfig, sdkService }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [newsletterEmail, setNewsLetterEmail] = useState<string>("");
  const [newsletterEmailError, setNewsLetterEmailError] =
    useState<boolean>(false);
  const [newsletterEmailErrorText, setNewsLetterEmailErrorText] =
    useState<string>("");
  const { showSnackBar } = useSnackBar();

  const requestSubmit = async () => {
    try {
      if (newsletterEmail.trim() === "") {
        setLoading(false);
        setNewsLetterEmailError(true);
        setNewsLetterEmailErrorText("Required field");
      } else {
        console.log("Submitting news Letter request");
        setLoading(true);
        const newsletter = await sdkService.marketplace.requestPrint(
          "News Letter Subscription",
          "",
          newsletterEmail,
          0,
          "",
          ""
        );
        showSnackBar(
          "You have been successfully subscribed to get weekly listing information.",
          "success"
        );
        setLoading(false);
        setNewsLetterEmail("");
      }
    } catch (error) {
      showSnackBar(
        "There has been a error subscribing you to our newsletter! Please try again.",
        "error",
        error
      );
      console.error(error);
      setLoading(false);
    }
  };

  const handleNewsletterEmailChange = (e: string) => {
    setNewsLetterEmailError(false);
    setNewsLetterEmailErrorText("");
    setNewsLetterEmail(e);
  };

  return (
    <Paper className="primary" style={styles.footerContainer}>
      <Grid container pt={1}>
        <Grid item xs={6}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <img
                style={styles.logo}
                src={
                  appConfig.footers.footerLogo
                    ? appConfig.footers.footerLogo
                    : appConfig.general.logo
                }
                alt="Logo"
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "left" }}>
              <Box pb={3}>
                <Text
                  variantText="h6"
                  style={{
                    fontWeight: "bold",
                    ...appConfig.footers.footerTextColor.styleOverrides.text,
                  }}
                >
                  Contact Us
                </Text>
              </Box>
              <Box pb={1}>
                <Text
                  variantText="body1"
                  style={{
                    display: "flex",
                    ...appConfig.footers.footerTextColor.styleOverrides.text,
                  }}
                >
                  {appConfig.general.contactDetails.telephone ? (
                    <LocalPhoneOutlinedIcon
                      fontSize="small"
                      style={{
                        marginRight: "5px",
                        ...appConfig.footers.footerTextColor.styleOverrides
                          .text,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <a
                    href={`tel:${appConfig.general.contactDetails.telephone}`}
                    style={{
                      textDecoration: "none",
                      ...appConfig.footers.footerTextColor.styleOverrides.text,
                    }}
                  >
                    {appConfig.general.contactDetails.telephone}
                  </a>
                </Text>
              </Box>
              <Box pb={1}>
                <Text
                  variantText="body1"
                  style={{
                    display: "flex",
                    ...appConfig.footers.footerTextColor.styleOverrides.text,
                  }}
                >
                  <EmailOutlinedIcon
                    fontSize="small"
                    style={{
                      marginRight: "5px",
                      ...appConfig.footers.footerTextColor.styleOverrides.text,
                    }}
                  />
                  <a
                    href={`mailto:${
                      appConfig.general.contactDetails.email
                    }?subject=${
                      appConfig.general.contactDetails.email_subject
                        ? appConfig.general.contactDetails.email_subject
                        : "Fanfire Inquiry"
                    }`}
                    style={{
                      textDecoration: "none",
                      ...appConfig.footers.footerTextColor.styleOverrides.text,
                    }}
                  >
                    {appConfig.general.contactDetails.email}
                  </a>
                </Text>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} style={{ textAlign: "left" }}></Grid>{" "}
        {/* Placeholder Grid */}
        <Grid item xs={5} pb={1} style={{ textAlign: "left" }}>
          <Box pb={1}>
            <Text
              variantText="h6"
              style={{
                fontWeight: "bold",
                ...appConfig.footers.footerTextColor.styleOverrides.text,
              }}
            >
              Stay Informed
            </Text>
            <Text
              variantText="body1"
              style={{
                ...appConfig.footers.footerTextColor.styleOverrides.text,
              }}
            >
              {appConfig.footers.stayInformedText
                ? `${appConfig.footers.stayInformedText}`
                : "Sign up for FanFire newsletters and receive information on new items"}
            </Text>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <InputTextField
                label="Enter your email*"
                value={newsletterEmail}
                onChange={(e: any) =>
                  handleNewsletterEmailChange(e.target.value)
                }
                error={newsletterEmailError}
                errorText={newsletterEmailErrorText}
                icon="email"
              />
            </Grid>
            <Grid item xs={3}>
              <Box pt="5px">
                {!loading ? (
                  <PrimaryButton onClick={() => requestSubmit()}>
                    <Text
                      variantText="body1"
                      style={{
                        fontWeight: "bold",
                        ...appConfig.general.styleOverrides
                          .buttonPrimaryTextColor,
                      }}
                    >
                      Subscribe
                    </Text>
                  </PrimaryButton>
                ) : (
                  !newsletterEmailError && (
                    <BasicLoader
                      size={20}
                      text={"Subscribing..."}
                      style={{
                        ...appConfig.footers.footerTextColor.styleOverrides
                          .text,
                      }}
                    />
                  )
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Paper className="secondary" style={styles.footerConnect}>
        <SocialMedia appConfig={appConfig} />
        <Text
          variantText="body1"
          style={{
            fontWeight: "bold",
            ...appConfig.footers.footerTextColor.styleOverrides
              .socialMediaFooterTextColor.text,
          }}
        >
          Powered by Fanfire.
        </Text>
        <Text
          variantText="body1"
          style={{
            fontSize: "x-small",
            ...appConfig.footers.footerTextColor.styleOverrides
              .socialMediaFooterTextColor.text,
          }}
        >
          Copyright 2022 Fanfire (Pty) Ltd. All Rights Reserved.
        </Text>
        <Text
          variantText="body1"
          style={{
            fontSize: "xx-small",
            ...appConfig.footers.footerTextColor.styleOverrides
              .socialMediaFooterTextColor.text,
          }}
        >
          No part of this web page may be reproduced in any way without the
          prior written permission of Fanfire (Pty) Ltd.
        </Text>
      </Paper>
    </Paper>
  );
}
