import * as React from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
// import { green, orange, purple } from '@mui/material/colors';

//Global styles

const theme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#E4e4e3",
    },
    primary: {
      light: "#b19",
      main: "#38011a",
      dark: "#67734e",
      contrastText: "#fff",
    },
    secondary: {
      light: "#b1b",
      main: "#fff6e8",
      dark: "#b19",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontWeight: "800",
      fontSize: "40px",
      fontFamily: "Blacker-Sans-Display-Bold",
      fontStyle: "normal",
      lineHeight: "1",
      letterSpacing: "-0.02em",
    },
    h2: {
      // For Sale and Explore Headers Text
      color: "white",
      fontWeight: "800",
      fontSize: "35px",
      fontFamily: "Blacker-Sans-Display-Bold",
      fontStyle: "normal",
      lineHeight: "1",
      letterSpacing: "-0.02em",
    },
    h3: {
      fontFamily: "RalewayBold",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "20px",
      letterSpacing: "-0.02em",
      marginTop: "0",
      textAlign: "left" as "left",
    },
    h6: {
      fontFamily: "RalewaySemiBold",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      marginTop: "0",
      letterSpacing: "-0.01em",
      textAlign: "left" as "left",
    },
    body1: {
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      letterSpacing: "0.01em",
    },
    body2: {
      //Secondary Text
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      letterSpacing: "0.01em",
      color: "#334748", //Use this color to specidy the color of secodary text (USDC price etc)
    },
    subtitle2: {
      //Landing page description
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "0.01em",
      textShadow: "0 0 8px black",
    },
    subtitle1: {
      //NavBar Text
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      marginTop: "0",
      letterSpacing: "-0.01em",
      textAlign: "left" as "left",
    },
  },
  components: {
    //AppBar
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#38011a",
          position: "fixed", //'absolute' 'fixed' 'relative' 'static' 'sticky',
        },
      },
    },
    //Buttons
    MuiButton: {
      variants: [
        {
          // This is the styling PrimaryButtons
          props: { className: "primary" },
          style: {
            height: "45px",
            borderRadius: "20px",
          },
        },
        {
          // This is the styling SecondaryButtons
          props: { className: "secondary" },
          style: {
            height: "45px",
            borderRadius: "20px",
            margin: "2px",
            backgroundColor: "rgba(50,71,72, 0.0);",
          },
        },
        {
          // This is the styling tertiaryButtons
          props: { className: "tertiary" },
          style: {
            height: "45px",
            borderRadius: "20px",
            margin: "2px",
          },
        },
        {
          // This is the styling of the Desktop navigation bar items
          props: { className: "navigation" },
          style: {
            height: "45px",
            margin: "2px",
            color: "white",
          },
        },
        {
          // This is the styling of login and signup buttons
          props: { className: "auth" },
          style: {
            height: "45px",
            borderRadius: "10px",
            margin: "5px",
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              backgroundColor: "#fff6e8",
              color: "black",
            },
          },
        },
      ],
    },
    //Accordians
    MuiAccordion: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            borderRadius: "8px",
          },
        },
      ],
    },
    //Dialog PopUps (login /sign up etc)
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "#E4e4e3",
          borderRadius: "16px",
        },
      },
    },
    //CardItem
    MuiCard: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            borderRadius: "2px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            border: "none",
            boxShadow: "0 15px 15px rgba(0,0,0,0.50)",
            "&:before, &:after": {
              content: '""',
              height: "2px",
              position: "absolute",
              left: "0",
              right: "0",
              WebkitClipPath:
                "polygon(0% 0%, 5%  100%, 10% 0%, 15%  100%, 20% 0%, 25% 100%, 30% 0%, 35%  100%, 40% 0%, 45%  100%, 50% 0%, 55%  100%, 60% 0%, 65%  100%, 70% 0%, 75%  100%, 80% 0%, 85%  100%, 90% 0%, 95%  100%, 100% 0%)",
            },
            "&:before": {
              backgroundColor: "#E4e4e3",
              top: 0,
            },
            "&:after": {
              bottom: "-1px",
            },
          },
        },
      ],
    },
    //used for footer components and various background Paper coloring
    MuiPaper: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            background: "#38011a",
          },
        },
        {
          props: { className: "secondary" },
          style: {
            background: "#fff6e8",
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            background: "#38011a",
            color: "white",
          },
        },
      ],
    },
    //Color of Icons
    MuiIconButton: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            color: "black",
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            background: "white",
            borderRadius: "8px",
          },
        },
      ],
    },
  },
});

export default theme;

// overides:  TODO
export const globalOverrides = {
  landingPage: {
    text: {
      color: "",
    },
  },
  accordian: {
    text: {
      color: "",
    },
  },
  primaryButton: {
    text: {
      color: "",
    },
  },
  secondaryButton: {
    text: {
      color: "",
    },
  },
  footer: {
    text: {
      color: "",
    },
    icons: {
      color: "",
    },
  },
  socialMedia: {
    color: "",
  },
  navbar: {
    items: {
      color: "",
    },
    mobileDrawer: {
      background: "", //recommended to keep same color as navbar
    },
  },
  cardItem: {
    text: {
      color: "",
    },
  },
  profile: {
    dashboard: {
      text: {
        color: "",
      },
    },
    menuItems: {
      text: {
        color: "",
      },
      navActive: {
        background: "",
      },
    },
  },
  chipItem: {
    chipStyle: {
      // background: 'rgba(255, 255, 255, 0.1)',
      color: "",
    },
  },
  dialogs: {
    text: {
      color: "",
    },
  },
};
