import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { NetworkStatusEnums } from "../../helpers/enums";
import NFT1155Sellers from "./NFT1155Sellers";
import Text from "../text/Text";
import { getBottles } from "../../helpers/catergorySpecific/wineCategoryFunctions";
import { FanfireSDK } from "fanfire-sdk";

interface Props {
  appConfig: any;
  nftDetail: any;
  nftDetailListing: any;
  sdkService: FanfireSDK;
  sliceStore: any;
  loadingFastProps: boolean;
}

export default function NFT1155Info({
  appConfig,
  nftDetail,
  nftDetailListing,
  sdkService,
  sliceStore,
  loadingFastProps,
}: Props) {
  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);
  const getOwned1155Qty = (listings: any) => {
    if (listings) {
      const listingOwned = listings.filter(
        (el: any) =>
          el.sellerAddress.toLowerCase() ===
          sdkService.wallet.walletAddress?.toLowerCase()
      );
      if (listingOwned.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getOwnedIsListed1155 = (listings: any) => {
    const listingOwned = listings.filter(function (el: any) {
      return (
        el.sellerAddress.toLowerCase() ===
        sdkService.wallet.walletAddress?.toLowerCase()
      );
    });
    if (listingOwned.length) {
      const isListed = listingOwned.map((item: any) => {
        if (Number(item.amount) > 0) {
          return true;
        }
      });
      return isListed[0] ? true : false;
    }
    return false;
  };

  const getForSale1155Length = (listings: any) => {
    if (listings) {
      const listingsAvail = listings.filter(function (el: any) {
        if (el.sellerAddress && sdkService.wallet.walletAddress) {
          return (
            el.sellerAddress.toLowerCase() !==
              sdkService.wallet.walletAddress?.toLowerCase() && el.price
          );
        } else {
          return false;
        }
      });
      if (listingsAvail.length) {
        return listingsAvail
          .map((item: any) => item.amount ?? 0)
          .reduce((prev: any, next: any) => Number(prev) + Number(next));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };
  return (
    <Grid
      item
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
    >
      {isAuthenticated && nftDetail.isERC1155 && (
        <Grid item xs={10}>
          <>
            {/* {!loadingFastProps ? (
              getOwned1155Qty(nftDetailListing.listings) && (
                <>
                  <div id="owned-items" style={{ scrollMarginTop: "100px" }}>
                    <Text variantText="h6" style={{ fontWeight: "bold", m: 2 }}>
                      Owned
                    </Text>
                  </div>
                  <NFT1155Sellers
                    sdkService={sdkService}
                    nft={nftDetail}
                    sliceStore={sliceStore}
                    appConfig={appConfig}
                    type={"owned"}
                    loadingFastProps={loadingFastProps}
                    nftDetailListing={nftDetailListing}
                  />
                </>
              )
            ) : (
              <Text variantText="h6" style={{ fontWeight: "bold" }}>
                Loading Owned Items...
              </Text>
            )} */}
            {getOwned1155Qty(nftDetailListing.listings) ? (
              <>
                <div id="owned-items" style={{ scrollMarginTop: "100px" }}>
                  <Text variantText="h6" style={{ fontWeight: "bold", m: 2 }}>
                    Owned
                  </Text>
                </div>
                <NFT1155Sellers
                  sdkService={sdkService}
                  nft={nftDetail}
                  sliceStore={sliceStore}
                  appConfig={appConfig}
                  type={"owned"}
                  loadingFastProps={loadingFastProps}
                  nftDetailListing={nftDetailListing}
                />
              </>
            ) : (
              <></>
            )}
            {isAuthenticated &&
              getForSale1155Length(nftDetailListing.listings) > 0 && (
                <Box
                  pt={1}
                  id="available-items"
                  style={{ scrollMarginTop: "100px" }}
                >
                  <Text variantText="h6" style={{ fontWeight: "bold" }}>
                    Available To Buy
                  </Text>
                  {getBottles(nftDetail.metadata) && (
                    <Text variantText="body1" style={{ mb: 1 }}>
                      Each unit contains {getBottles(nftDetail.metadata)}{" "}
                      bottles
                    </Text>
                  )}
                  <NFT1155Sellers
                    sdkService={sdkService}
                    nft={nftDetail}
                    nftDetailListing={nftDetailListing}
                    sliceStore={sliceStore}
                    appConfig={appConfig}
                    type={"available"}
                    loadingFastProps={loadingFastProps}
                  />
                  {/* </Paper> */}
                </Box>
              )}
          </>
        </Grid>
      )}
    </Grid>
  );
}
