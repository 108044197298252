import React from "react";

//Components
import Text from "../text/Text";
import TertiaryButton from "../buttons/TertiaryButton";

//MUI
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Skeleton from "@mui/material/Skeleton";
import Chip from "@mui/material/Chip";
import Price from "../price/Price";

const styles = {
  card: {
    // borderRadius: "12px",
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
    overflow: "visible",
    position: "relative" as "relative",
  },
  image: {
    borderRadius: "9.2px",
    filter: "drop-shadow(0px 1.95652px 24px #000000) blur(8px)",
    objectFit: "cover" as "cover",
    width: "100%",
  },
  span: {
    color: "#8247E5",
  },
  chip: {
    float: "left" as "left",
  },
  viewArtButton: {
    marginLeft: "auto",
  },
  overlay: {
    position: "absolute" as "absolute",
    top: "20%",
    left: "10px",
    color: "white",
  },
};

interface Props {
  keyRef: number;
}

export default function CardItem({ keyRef }: Props) {
  return (
    <Box key={`${keyRef}-box`}>
      <Card
        style={styles.card}
        sx={{
          ":hover": {
            transform: "scale3d(1.05, 1.05, 1)",
          },
          ":hover > .cardImage": {
            transform: "scale3d(1.05, 1.05, 1)",
          },
        }}
        key={`${keyRef}-card`}
        className="primary"
      >
        <CardHeader
          title={
            <Text
              variantText="body1"
              style={{ textAlign: "left", display: "flex" }}
            >
              <LocationOnTwoToneIcon fontSize="small" />
              <Skeleton
                sx={{ bgcolor: "grey.400" }}
                width={"50%"}
                variant="text"
              />
            </Text>
          }
          action={<FavoriteBorderOutlinedIcon fontSize="small" />}
          key={`${keyRef}-card-header`}
        />
        <Skeleton
          key={`${keyRef}-skel1`}
          sx={{ bgcolor: "grey" }}
          width={"100%"}
          height={260}
          variant="rectangular"
          style={styles.image}
          className="cardImage"
          animation="wave"
        />
        <CardContent key={`${keyRef}-card-content`}>
          <Skeleton
            key={`${keyRef}-skel2`}
            sx={{ bgcolor: "grey.400" }}
            width={"60%"}
            height={35}
            variant="text"
          />
          <Skeleton
            key={`${keyRef}-skel22`}
            sx={{ bgcolor: "grey.400" }}
            width={"80%"}
            height={10}
            variant="text"
          />
          <Skeleton
            key={`${keyRef}-skel222`}
            sx={{ bgcolor: "grey.400" }}
            width={"30%"}
            height={15}
            variant="text"
          />
          <br />
          <br />
          <>
            <Price
              loading={true}
              isERC1155={false}
              isListed={true}
              isMarketPlaceNFT={true}
              coinPrice={0}
              currencyPrice={0}
            />
          </>
        </CardContent>
        <CardActions key={`${keyRef}-card-action`}>
          <Chip style={styles.chip} label="..." key={`${keyRef}-chip`} />
          <TertiaryButton styleOverride={styles.viewArtButton}>
            <Skeleton
              sx={{ bgcolor: "grey.400" }}
              width={100}
              height={30}
              variant="text"
              key={`${keyRef}-skel5`}
            />
            <ChevronRightIcon
              key={`${keyRef}-icon`}
              style={{ color: "rgba(255, 255, 255, 0.8)" }}
            />
          </TertiaryButton>
        </CardActions>
      </Card>
    </Box>
  );
}
