import React, { useEffect, useState, useContext } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import SecondaryButton from "../buttons/SecondaryButton";
import Text from "../text/Text";
import { ethers } from "ethers";
// import SDKContext from '../../context/sdk-context'
import CopyWalletAddress from "../wallet/CopyWalletAddress";
import TransactionListBasic from "../transactions/TransactionListBasic";
import { NetworkStatusEnums } from "../../helpers/enums";
import { useSnackBar } from "../../context/snackAlert-context";
import axios from "axios";
import Identicon from "../avatars/Identicon";
import { useNavigate } from "react-router-dom";
import { FanfireSDK } from "fanfire-sdk";

//MUI imports
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";

import Loader from "../loaders/BasicLoader";
import { getEllipsisTxt } from "../../helpers/formatters";

interface Props {
  mobileView: boolean;
  walletMenuOpen: boolean;
  setWalletMenuOpen: any;
  sdkService: FanfireSDK;
  appConfig: any;
}

export default function WalletInfoBar({
  mobileView,
  walletMenuOpen,
  setWalletMenuOpen,
  sdkService,
  appConfig,
}: Props) {
  const [walletData, setWalletData] = useState<any>({});
  const [nativeBalance, setNativeBalance] = useState<any>();
  const [loadingWallet, setLoadingWallet] = useState<boolean>(true);
  const [marketplaceBalances, setMarketplaceBalances] = useState<any>("");
  const [userData, setUserData] = useState<any>({}); // update any
  const [loadingPullFunds, setLoadingPullFunds] = useState<boolean>(false);
  const [currencyLoading, setCurrencyLoading] = useState<boolean>(true);

  //auth
  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);
  const isWeb2 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb2);
  const isWeb3 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb3);
  const user = useSelector((state: RootStateOrAny) => state.user);

  //Other
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  //SDK
  // const context = useContext(SDKContext)
  // const ffsdk = context.ffsdk

  const styles = {
    span: {
      color: "#8247E5",
    },
  };

  useEffect(() => {
    const setWeb3 = async () => {
      const userWallet = sdkService.wallet.walletAddress;
      setUserData({
        displayName: getEllipsisTxt(userWallet ?? ""),
        photoURL: "",
        walletAddress: userWallet,
      });
    };

    if (isWeb2) {
      setUserData(user.user);
    } else if (isWeb3) {
      setWeb3();
    }
  }, [isAuthenticated]);

  const getWalletData = async () => {
    try {
      setLoadingWallet(true);
      const walletAddress = sdkService.wallet.walletAddress;
      setCurrencyLoading(true);
      const usdcZarPromise = await getCoinVsCurrency("usd-coin", "zar");
      setCurrencyLoading(true);
      const maticZarPromise = getCoinVsCurrency("matic-network", "zar");
      const walletBalancePromise: any = sdkService.wallet.getWalletERC20Balance(
        walletAddress ?? "",
        process.env.REACT_APP_ERC20_PAYMENT_ADDRESS ?? ""
      );
      const nbPromise = sdkService.wallet
        .getWalletNativeBalance
        // walletAddress ?? ""
        ();
      const marketplaceBalanceForPromise: any =
        sdkService.marketplace.getMarketplaceContractBalanceFor(
          process.env.REACT_APP_ERC20_PAYMENT_ADDRESS ?? "",
          walletAddress ?? ""
        );
      const decimalsPromise = sdkService.marketplace.getERC20Decimals(
        process.env.REACT_APP_ERC20_PAYMENT_ADDRESS ?? ""
      );

      const walletValues = await Promise.all([
        usdcZarPromise,
        maticZarPromise,
        walletBalancePromise,
        nbPromise,
        marketplaceBalanceForPromise,
        decimalsPromise,
      ]);

      const nativeBalance = Number(
        ethers.utils.formatUnits(`${walletValues[3]}`, 18)
      );
      const marketplaceBalance: any = ethers.utils.formatUnits(
        walletValues[4],
        Number(walletValues[5])
      );
      const balance = Number(
        ethers.utils.formatUnits(walletValues[2], walletValues[5])
      );

      setMarketplaceBalances(marketplaceBalance);
      setNativeBalance(nativeBalance);
      setWalletData({
        walletAddress: walletAddress,
        walletBalance: balance,
        walletBalanceZar:
          walletValues[0] !== "error" ? balance * walletValues[0] : 0,
        marketplaceBalanceZar: Number(marketplaceBalance) * walletValues[0],
        nativeBalanceZar:
          walletValues[1] !== "error" ? nativeBalance * walletValues[1] : 0,
      });
      setLoadingWallet(false);
    } catch (err) {
      setLoadingWallet(false);
      console.error(err);
      setNativeBalance("Error Getting Value");
      setMarketplaceBalances("Error Getting Value");
      setWalletData({ ...walletData, walletBalance: "Error Getting Value" });
    }
  };

  useEffect(() => {
    if (walletMenuOpen) {
      getWalletData();
    }
  }, [walletMenuOpen]);

  const getCoinVsCurrency = async (coin: string, currency: string) => {
    // https://www.coingecko.com/en/api/documentation
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_FANFIRE_API_BASE_URL}/forex/exchange/usd-coin/zar`
      );

      const zar = resp.data["usd-coin"]["zar"];
      setCurrencyLoading(false);
      return zar;
    } catch (error) {
      console.log("error");
      return "error";
    }

    // const currency = await getCoinVsCurrency('usd-coin', 'zar')
  };

  const handleCloseWalletMenu = () => {
    setWalletMenuOpen(false);
  };

  const handlePullFunds = async () => {
    console.log("Pulling funds...");
    setLoadingPullFunds(true);
    try {
      const walletAddress = sdkService.wallet.walletAddress;
      if (Number(walletData.marketplaceBalanceZar) > 0) {
        const pull = await sdkService.marketplace.pullAllFunds(
          walletAddress ?? "",
          process.env.REACT_APP_ERC20_PAYMENT_ADDRESS ??
            "0x1e65Cd156fe76d68B15C5f2Fa8B42C32Af5af048"
        );
        console.log(pull);
        showSnackBar("Successfully pulled funds into your Wallet!", "success");
        if (isWeb2) {
          navigate("/profile/#transactionStarted", { replace: true });
        }
      } else {
        console.log("Marketplace mbalance is not more than 0");
        showSnackBar(
          "Marketplace balance should be more than 0 to be able to pull funds.",
          "info"
        );
      }
      setLoadingPullFunds(false);
      getWalletData();
    } catch (error) {
      console.error(error);
      showSnackBar("Error pulling Funds. Please try again.", "error", error);
      setLoadingPullFunds(false);
      getWalletData();
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={walletMenuOpen}
        onClose={handleCloseWalletMenu}
        PaperProps={
          mobileView ? { sx: { width: "70vw" } } : { sx: { width: "40vw" } }
        }
      >
        <IconButton
          size="large"
          onClick={handleCloseWalletMenu}
          color="inherit"
          style={{ justifyContent: "flex-end" }}
        >
          <CloseIcon
            style={{ color: appConfig.navItems.backButtonNavIconColor }}
          />
        </IconButton>
        <Box p={3}>
          <Text variantText="h3" style={{ textAlign: "left" }}>
            My Wallet
          </Text>
          <Divider sx={{ background: "white" }}></Divider>

          <Box display="flex" pt={1} justifyContent="space-between">
            {isWeb2 ? (
              <Avatar
                alt={userData.displayName}
                src={userData.photoURL}
                // onClick={handleClick}
                sx={{ width: 25, height: 25, bottom: 0, marginRight: "6px" }}
              />
            ) : (
              <Avatar
                alt={userData.displayName}
                // onClick={handleClick}
                sx={{ width: 25, height: 25, bottom: 0, marginRight: "6px" }}
              >
                <Identicon
                  account={userData.walletAddress}
                  size={30}
                  hAndW={"2.0rem"}
                />
              </Avatar>
            )}
            <CopyWalletAddress sdkService={sdkService} variant="h6" />
          </Box>
          <Box pt={3}>
            {isWeb3 && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text variantText="h6" style={{ textAlign: "left" }}>
                  <Tooltip
                    title="Your native balance is the amount you own of the coin native to the current chain you are transacting on. This site transacts on the Polygon chain, which uses MATIC as its native coin."
                    placement="top"
                    enterTouchDelay={0}
                  >
                    <InfoOutlinedIcon
                      fontSize="small"
                      style={{
                        verticalAlign: "middle",
                        alignSelf: "center",
                        textAlign: "center",
                      }}
                    />
                  </Tooltip>
                  Native Balance
                </Text>
                {!loadingWallet ? (
                  !String(nativeBalance).includes("Error") ? (
                    <Box display="block">
                      {Number(walletData.nativeBalanceZar) !== 0 && (
                        <Text
                          variantText="body1"
                          style={{ textAlign: "right" }}
                        >
                          R {Number(walletData.nativeBalanceZar).toFixed(2)}
                        </Text>
                      )}
                      <Text variantText="body2" style={{ textAlign: "center" }}>
                        {/* <span style={styles.span}> */}
                        {Number(nativeBalance).toFixed(4)} Matic
                        {/* </span> */}
                      </Text>
                    </Box>
                  ) : (
                    <Text
                      variantText="body1"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      {nativeBalance}
                    </Text>
                  )
                ) : (
                  <Skeleton
                    sx={{ bgcolor: "grey.600" }}
                    width={50}
                    variant="text"
                  />
                )}
              </Box>
            )}
            <Divider></Divider>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text variantText="h6" style={{ textAlign: "left" }}>
                <Tooltip
                  title="Your wallet balance is the amount you own of a specific coin. This site uses the USDC coin. 1 USDC = 1 US Dollar."
                  placement="top"
                  enterTouchDelay={0}
                >
                  <InfoOutlinedIcon
                    fontSize="small"
                    style={{
                      verticalAlign: "middle",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  />
                </Tooltip>
                Wallet Balance
              </Text>
              {!loadingWallet ? (
                !String(walletData.walletBalance).includes("Error") ? (
                  <Box display="block">
                    {Number(walletData.walletBalanceZar) !== 0 && (
                      <Text variantText="body1" style={{ textAlign: "right" }}>
                        R {Number(walletData.walletBalanceZar).toFixed(2)}
                      </Text>
                    )}
                    <Text variantText="body2" style={{ textAlign: "center" }}>
                      {/* <span style={styles.span}> */}
                      {Number(walletData.walletBalance).toFixed(2)} USDC
                      {/* </span> */}
                    </Text>
                  </Box>
                ) : (
                  <Text
                    variantText="body1"
                    style={{ textAlign: "center", color: "red" }}
                  >
                    {walletData.walletBalance}
                  </Text>
                )
              ) : (
                <Skeleton
                  sx={{ bgcolor: "grey.600" }}
                  width={50}
                  variant="text"
                />
              )}
            </Box>
            <Divider></Divider>
            {isWeb3 && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text variantText="h6" style={{ textAlign: "left" }}>
                  <Tooltip
                    title="Your marketplace balance is the amount of USDC currently available to you in our marketplace smart contract. This amount increases when your NFTs are sold and can be withdrawn to your wallet with the pull funds button. Funds are pulled automatically for users signed in with Google."
                    placement="top"
                    enterTouchDelay={0}
                  >
                    <InfoOutlinedIcon
                      fontSize="small"
                      style={{
                        verticalAlign: "middle",
                        alignSelf: "center",
                        textAlign: "center",
                      }}
                    />
                  </Tooltip>
                  Marketplace Balance
                </Text>
                {!loadingWallet ? (
                  !String(marketplaceBalances).includes("Error") ? (
                    <Box display="block">
                      <Text variantText="body1" style={{ textAlign: "right" }}>
                        R {Number(walletData.marketplaceBalanceZar).toFixed(2)}
                      </Text>
                      <Text variantText="body2" style={{ textAlign: "center" }}>
                        {/* <span style={styles.span}> */}
                        {Number(marketplaceBalances).toFixed(2)} USDC
                        {/* </span> */}
                      </Text>
                    </Box>
                  ) : (
                    <Text
                      variantText="body1"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      {marketplaceBalances}
                    </Text>
                  )
                ) : (
                  <Skeleton
                    sx={{ bgcolor: "grey.600" }}
                    width={50}
                    variant="text"
                  />
                )}
              </Box>
            )}
          </Box>
          {!loadingWallet && !loadingPullFunds ? (
            <IconButton sx={{ float: "right" }} onClick={() => getWalletData()}>
              <RefreshRoundedIcon
                fontSize="small"
                sx={{ color: "white", float: "right" }}
              />
            </IconButton>
          ) : (
            <></>
          )}
          {isWeb3 && (
            <Box pt={3}>
              {!loadingPullFunds ? (
                <SecondaryButton onClick={() => handlePullFunds()}>
                  <Text variantText="body1">Pull Funds</Text>
                </SecondaryButton>
              ) : (
                <Loader text="Pulling Funds..." />
              )}
            </Box>
          )}
          {isWeb2 && (
            <Box pt={3}>
              <Text variantText="h6" style={{ textAlign: "center" }}>
                Transactions
              </Text>
              {/* <TransactionList
                sdkService={sdkService}
                isAuthenticated={isAuthenticated}
                isWeb2={isWeb2}
              /> */}
              <TransactionListBasic
                appConfig={appConfig}
                sdkService={sdkService}
                isAuthenticated={isAuthenticated}
                isWeb2={isWeb2}
              />
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
}
