import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface initialStateLayout {
  currencyExchange: number | null;
  status: string;
  error: null | undefined | string;
  lastUpdated: number | null;
}

const initialState: initialStateLayout = {
  currencyExchange: null,
  status: "idle",
  error: null,
  lastUpdated: null,
};

export const getCurrencyExchange = createAsyncThunk(
  "exchange/getCurrencyExchange",
  async ({}) => {
    try {
      console.log("Updating Redux Currency data");
      const coin = "usd-coin";
      const currency = "zar";
      const resp = await axios.get(
        `${process.env.REACT_APP_FANFIRE_API_BASE_URL}/forex/exchange/${coin}/${currency}`
      );
      const currencyPrice = resp.data[coin][currency];
      return { currencyExchange: currencyPrice };
    } catch (error) {
      console.log("There was an error API Data");
      console.error(error);
      return null;
    }
  }
);

const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCurrencyExchange.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCurrencyExchange.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currencyExchange = action.payload?.currencyExchange;
        state.lastUpdated = Date.now();
      })
      .addCase(getCurrencyExchange.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

const exchangeReducer = exchangeSlice.reducer;

export default exchangeReducer;
