import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import React, { useState, useEffect } from "react";
import { getMarketplaceNFTAddresses } from "../../helpers/functions";
import MapCardItems from "../cardItem/MapCardItems";
import WineCategoryList from "../categoryList/WineCategoryList";
import SelectField from "../inputs/SelectField";
import { FanfireSDK } from "fanfire-sdk";

interface Props {
  sdkService: FanfireSDK;
  appConfig: any;
  showAll: boolean;
}

export default function UserOwnedNFTs({
  sdkService,
  appConfig,
  showAll,
}: Props) {
  const [userNFTs, setUserNFTs] = useState<any>([]);
  const [loadingNFTs, setLoadingNFTs] = useState<boolean>(true);
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>();
  const [choice, setChoice] = useState(showAll ? 10 : 50);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPaginationPage(value);
  };

  // Get User Owned NFTs
  const getUserNFTs = async () => {
    const lowerIndex: number = Number(choice) * paginationPage - Number(choice);
    const upperIndex: number = Number(choice) * paginationPage;
    try {
      console.log("Getting user NFTs");
      setLoadingNFTs(true);
      const userWallet = sdkService.wallet.walletAddress;
      let userOwnedNFTs: any = await sdkService.wallet.getAllWalletNFTs(
        userWallet ?? "",
        lowerIndex,
        upperIndex
      ); // update any
      console.log("User Owned NFTs: ", userOwnedNFTs);
      if (!showAll) {
        const marketplaceAddresses = getMarketplaceNFTAddresses();
        console.log("NFT ADDRESSES: ", marketplaceAddresses);
        const tokens = userOwnedNFTs.tokens.filter(
          (nft: any) =>
            nft &&
            (nft.nftAddress || nft.contractAddress) &&
            marketplaceAddresses.includes(
              nft.nftAddress ? nft.nftAddress : nft.contractAddress
            )
        );
        userOwnedNFTs = { ...userOwnedNFTs, tokens: tokens };
      }
      console.log("OWNED IN MARKETPLACE: ", userOwnedNFTs);
      return userOwnedNFTs;
    } catch (error) {
      console.log("There was an error getting owned amount of NFTS:");
      console.error(error);
      setLoadingNFTs(false);
      // showSnackBar('Error getting your owned NFTs', 'error', error)
    }
  };

  // Load NFT for user
  useEffect(() => {
    let mounted = true;
    const prep = async () => {
      const nfts = await getUserNFTs();
      if (mounted) {
        setUserNFTs(nfts.tokens);
        setPageCount(Math.ceil(nfts.totalLength / Number(choice)));
        setLoadingNFTs(false);
      }
    };
    prep();
    return () => {
      mounted = false;
    };
  }, [paginationPage, choice]);

  // Reset Page if amount per change is altered
  useEffect(() => {
    setPaginationPage(1);
  }, [choice]);

  return (
    <>
      <Grid item xs={12} alignItems="center" justifyContent="center">
        {!showAll &&
        appConfig.general.appCategory &&
        appConfig.general.appCategory === "wine" ? (
          <WineCategoryList
            nfts={userNFTs}
            loading={loadingNFTs}
            appConfig={appConfig}
          />
        ) : (
          <MapCardItems
            nftItemsIn={userNFTs}
            loading={loadingNFTs}
            appConfig={appConfig}
            sdkService={sdkService}
          />
        )}
      </Grid>
      <Grid container item xs={12} alignItems="center" justifyContent="center">
        <Pagination
          count={pageCount}
          page={paginationPage}
          onChange={handleChange}
        />
        <SelectField
          label="per page"
          choice={choice}
          setChoice={setChoice}
          choices={["5", "10", "20", "30", "50"]}
        />
      </Grid>
    </>
  );
}
