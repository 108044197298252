import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Skeleton from "@mui/material/Skeleton";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Chip from "@mui/material/Chip";
import Text from "../text/Text";
import { useNavigate } from "react-router-dom";

const styles = {
  grid: {
    textAlign: "left" as "left",
    position: "relative" as "relative",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-around",
  },
  box: {
    display: "flex" as "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-around",
    top: 0,
  },
  overlay: {
    position: "absolute" as "absolute",
    top: "50",
    right: "20",
    filter: "drop-shadow(0px 1.27599px 15.6522px #000000)",
  },
  imageContainer: {
    position: "relative" as "relative",
  },
  accordion: {
    // background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: "8px",
    // color: 'rgba(255, 255, 255, 0.8)',
    width: "100%",
  },
  actionBar: {
    // background: '#343434',
    borderRadius: "8px",
    // color: 'rgba(255, 255, 255, 0.8)',
    marginTop: "10px",
  },
  overlayBackButton: {
    position: "absolute" as "absolute",
    top: -50,
    color: "white",
  },
  chip: {
    float: "left" as "left",
    //   background: 'rgba(255, 255, 255, 0.1)',
    //   color: '#F5F5F5',
    maxWidth: "100%",
  },
};

interface Props {
  mobileView: boolean;
  appConfig: any;
}

export default function NFTDetailSkeleton({ mobileView, appConfig }: Props) {
  const navigate = useNavigate();
  return (
    <>
      <Grid container pt={25} pb={10} spacing={5} justifyContent="center">
        <Grid
          item
          xs={10}
          sm={10}
          md={3}
          lg={3}
          style={styles.grid}
          order={{ xs: 2, sm: 2, md: 1 }}
        >
          {!mobileView && (
            <div style={styles.overlayBackButton}>
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  background: `${
                    appConfig.navItems.backButtonNavIconPaddingColor
                      ? appConfig.navItems.backButtonNavIconPaddingColor
                      : "rgba(255, 255, 255, 0.1)"
                  }`,
                  top: "-10vh",
                }}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIosNewRoundedIcon
                    sx={{
                      fontSize: 25,
                      color: `${
                        appConfig.navItems.backButtonNavIconColor
                          ? appConfig.navItems.backButtonNavIconColor
                          : "white"
                      }`,
                    }}
                  />
                </IconButton>
              </Avatar>
            </div>
          )}
          <Box sx={styles.box}>
            <Chip style={styles.chip} label="..." />
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              height={50}
              width={"30%"}
              variant="text"
            />
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              height={120}
              width={"70%"}
              variant="text"
            />
            <Text
              variantText="body1"
              style={{ textAlign: "left", display: "flex" }}
            >
              <LocationOnTwoToneIcon fontSize="small" />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                height={20}
                width={"100%"}
                variant="text"
              />
            </Text>
            <Box pt={1}>
              <Accordion style={styles.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Text variantText="h6">
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      width={100}
                      variant="text"
                    />
                  </Text>
                </AccordionSummary>
                <AccordionDetails>
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    height={50}
                    width={"100%"}
                    variant="text"
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              p={1}
              pl={5}
              style={styles.actionBar}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Text
                  variantText="body1"
                  style={{ textAlign: "left", display: "flex" }}
                >
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    width={"50%"}
                    variant="text"
                  />
                </Text>
                <Text
                  variantText="body1"
                  style={{ textAlign: "left", display: "flex" }}
                >
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    width={"50%"}
                    variant="text"
                  />
                </Text>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          sm={10}
          md={6}
          lg={6}
          order={{ xs: 1, sm: 1, md: 2 }}
          style={styles.imageContainer}
        >
          {mobileView && (
            <div style={styles.overlayBackButton}>
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  background: `${
                    appConfig.navItems.backButtonNavIconPaddingColor
                      ? appConfig.navItems.backButtonNavIconPaddingColor
                      : "rgba(255, 255, 255, 0.1)"
                  }`,
                  top: "-5vh",
                }}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIosNewRoundedIcon
                    sx={{
                      fontSize: 25,
                      color: `${
                        appConfig.navItems.backButtonNavIconColor
                          ? appConfig.navItems.backButtonNavIconColor
                          : "white"
                      }`,
                    }}
                  />
                </IconButton>
              </Avatar>
            </div>
          )}
          <Skeleton
            animation="wave"
            sx={{ bgcolor: "grey.900", borderRadius: "16px" }}
            height={"100%"}
            width={"100%"}
            variant="rectangular"
          />
          {/* <FavoriteIcon
            style={styles.overlay}
            fontSize="medium"
            sx={{ color: "white" }}
          /> */}
        </Grid>
      </Grid>
    </>
  );
}
