import { Box, Skeleton } from "@mui/material";
import React from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { currencyFormat } from "../../helpers/formatters";
import Text from "../text/Text";

interface Props {
  isListed: boolean;
  isMarketPlaceNFT: boolean;
  coinPrice: number;
  currencyPrice: number;
  isERC1155: boolean;
  loading?: boolean;
}

export default function Price({
  isListed,
  isMarketPlaceNFT,
  coinPrice,
  currencyPrice,
  isERC1155,
  loading = false,
}: Props) {
  const isWeb3 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb3);

  return isMarketPlaceNFT && isListed ? (
    <>
      {isERC1155 && (
        <Text variantText="body1" style={{ textAlign: "left" }}>
          From:{" "}
        </Text>
      )}
      <Box pl={1}>
        <Text variantText="h6" style={{ textAlign: "left" }}>
          {loading ? (
            <Box display={"inline-flex"} width="100%">
              ZAR{"   "}
              <Skeleton
                sx={{ bgcolor: "grey.400", marginLeft: 1 }}
                width={"10%"}
                variant="text"
              />
            </Box>
          ) : currencyPrice === 0 ? (
            "..."
          ) : (
            currencyFormat(currencyPrice, "ZAR")
          )}
        </Text>
        <Text variantText="body2" style={{ textAlign: "left" }}>
          {loading ? (
            <Box display={"inline-flex"} width="100%">
              USDC{"  "}
              <Skeleton
                sx={{ bgcolor: "grey.400", marginLeft: 1 }}
                width={"10%"}
                variant="text"
              />
            </Box>
          ) : coinPrice === 0 ? (
            "..."
          ) : (
            currencyFormat(coinPrice, isWeb3 ? "USDC" : "USD")
          )}
        </Text>
      </Box>
    </>
  ) : (
    <Text variantText="body2" style={{ textAlign: "left" }}>
      Unlisted
    </Text>
  );
}
