import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Autocomplete, SelectChangeEvent } from "@mui/material";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

const FilterMultiSelect = (props: any) => {
  const [multiChoice, setMultiChoice] = React.useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { label, choices, setQueryString } = props;

  useEffect(() => {
    // set query values when started within the input fields
    searchParams.forEach((key, value) => {
      if (label == value) {
        setMultiChoice(key.split(","));
      }
    });
  }, []);

  const updateQueryString = (event: any) => {
    const params: any = {};

    //Get current query string
    searchParams.forEach((key, value) => {
      params[value] = key;
    });

    //Update query param for specific entry
    params[event.target.name] = event.target.value;

    // Delete key from query param if it is empty
    if (params[event.target.name].length == 0) {
      delete params[event.target.name];
    }

    //return new query string
    return new URLSearchParams(params).toString();
  };

  const handleChange = (event: SelectChangeEvent<typeof multiChoice>) => {
    const {
      target: { value },
    } = event;
    setMultiChoice(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const newQueryString = updateQueryString(event);
    setQueryString(newQueryString);
  };

  return (
    // <Box pt={1} pb={1} display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }}> // TODO: Implement sorting for each field
    <Box pt={1} pb={1}>
      <FormControl sx={{ m: 1, width: 160 }} size="small">
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
          name={label}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={multiChoice}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected: any) => selected.join(", ")}
        >
          {choices.map((item: any, i: number) => (
            <MenuItem key={`${item}-${i}-menuItem1`} value={item}>
              <Checkbox
                checked={multiChoice.includes(item)}
                key={`${item}-${i}-checkbox1`}
              />
              <ListItemText primary={item} key={`${item}-${i}-text1`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* <ArrowDownwardIcon fontSize="small" />  // TODO: Implement sorting for each field*/}
    </Box>
  );
};

const FilterSelect = (props: any) => {
  const [choice, setChoice] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();

  const { label, name, choices, setQueryString, ffSearch, defaultVal } = props;

  useEffect(() => {
    if (defaultVal) {
      // If default value, it first checks if there is already a filter for this field
      // if there is, it will use that, otherwise use the default value set in AppConfig
      const params: any = {};

      //Get current query string
      searchParams.forEach((key, value) => {
        params[value] = key;
      });
      if (params.hasOwnProperty(`ffSearch-${name}`)) {
        if (params[`ffSearch-${name}`] !== "null") {
          setChoice(params[`ffSearch-${name}`]);
        }
      } else if (Object.keys(params).length) {
        console.log("other filter present");
      } else {
        setChoice(defaultVal);
        const newQueryString = updateQueryString(defaultVal);
        setQueryString(newQueryString);
      }
    } else {
      // set query values when started within the input fields
      searchParams.forEach((key, value) => {
        if (name == value) {
          if (key !== "null") {
            setChoice(key);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    // set query values when started within the input fields
    searchParams.forEach((key, value) => {
      if (name == value || `ffSearch-${name}` == value) {
        if (key !== "null") {
          setChoice(key);
        } else {
          setChoice("");
        }
      }
    });
  }, [searchParams]);

  const handleChange = (newValue: any) => {
    setChoice(newValue);
    const newQueryString = updateQueryString(newValue);
    setQueryString(newQueryString);
  };

  const updateQueryString = (newValue: any) => {
    const params: any = {};

    //Get current query string
    searchParams.forEach((key, value) => {
      if (value !== "Name" && value !== "skip" && value !== "limit") {
        params[value] = "null";
      } else {
        params[value] = key;
      }
    });

    //Update query param for specific entry
    if (ffSearch) {
      //we need to cater for fanfire meta search because it comes from diff place on the BE
      params[`ffSearch-${name}`] = newValue;
    } else {
      params[name] = newValue;
    }

    // Delete key from query param if it is empty
    // if (ffSearch) {
    //   if (!params[`ffSearch-${name}`]) {
    //     delete params[`ffSearch-${name}`];
    //   }
    // } else {
    //   if (!params[name]) {
    //     delete params[name];
    //   }
    // }

    //return new query string

    return new URLSearchParams(params).toString();
  };

  return (
    <Box pt={1} pb={1} key={`${label}-box`}>
      <Autocomplete
        key={`${name}-auto`}
        disablePortal
        id="combo-box-demo"
        options={choices}
        value={choice}
        sx={{ minWidth: 220 }}
        onChange={(event: any, newValue: string | null) => {
          handleChange(newValue);
        }}
        size="small"
        renderInput={(params) => (
          <TextField name={label} {...params} label={label} />
        )}
      />
    </Box>
  );
};

const FilterFreeText = (props: any) => {
  const [value, setValue] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();

  const { label, filterVal, setQueryString } = props;

  useEffect(() => {
    // set query values when started within the input fields
    searchParams.forEach((key, value) => {
      if (filterVal == value) {
        setValue(key);
      }
    });
  }, []);

  useEffect(() => {
    if (searchParams.get("Name") === "null") {
      setValue("");
    }
  }, [searchParams]);

  const handleChange = (event: any) => {
    const newQueryString = updateQueryString(event);
    setQueryString(newQueryString);
    setValue(event.target.value);
  };

  const updateQueryString = (event: any) => {
    const params: any = {};

    //Get current query string
    searchParams.forEach((key, value) => {
      params[value] = key;
    });

    //Update query param for specific entry
    params[event.target.name] = event.target.value;

    // Delete key from query param if it is empty
    if (params[event.target.name].length == 0) {
      delete params[event.target.name];
    }

    //return new query string
    return new URLSearchParams(params).toString();
  };

  return (
    <Box pt={1} pb={1}>
      <FormControl fullWidth size="small">
        <TextField
          name={filterVal}
          id="free-text"
          value={value}
          label={label}
          sx={{ minWidth: 220 }}
          //  defaultValue = ''
          onChange={handleChange}
          size="small"
          InputProps={{
            endAdornment: <SearchIcon fontSize="small" />,
          }}
        />
      </FormControl>
    </Box>
  );
};

interface Props {
  appConfig: any;
  currentFilter: string;
  setFilter: any;
  setFilterName?: any;
}

export default function FilterComponent({
  appConfig,
  currentFilter,
  setFilter,
  setFilterName,
}: Props) {
  const [queryString, setQueryString] = useState(currentFilter);

  const navigate = useNavigate();
  const location = useLocation();

  const createSDKFilterParams = (queryString: any) => {
    let filter: Array<{}> = [];
    const searchParams = new URLSearchParams(queryString);
    searchParams.forEach(function (value, key) {
      if (Array.isArray(value.split(","))) {
        for (const item of value.split(",")) {
          filter.push({ trait_type: key, value: String(item) });
        }
      } else {
        filter.push({ trait_type: key, value: String(value) });
      }
    });
    const searchParamsCopy = new URLSearchParams(searchParams.toString());
    searchParams.delete("Name");
    const currentSearchParams = new URLSearchParams(currentFilter);
    currentSearchParams.delete("Name");
    if (currentSearchParams.toString() === searchParams.toString()) {
      setFilterName(searchParamsCopy.get("Name"));
    } else {
      setFilter(filter);
    }
  };

  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: queryString,
    });
    createSDKFilterParams(queryString);
  }, [queryString]);

  return (
    <Box pt={1} pb={1}>
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="center"
        direction="row"
      >
        {appConfig.general.avail_filters.map((e: any, i: number) => (
          <Grid item key={`${i}-gridItem1`} xs={8} sm={5} md={5} lg={3}>
            {e.type === "free-text" && (
              <FilterFreeText
                label={e.fe_name ? e.fe_name : e.name}
                filterVal={e.name}
                setQueryString={setQueryString}
              />
            )}
            {e.type === "select" && (
              <FilterSelect
                label={e.fe_name ? e.fe_name : e.name}
                name={e.name}
                choices={e.values}
                setQueryString={setQueryString}
                ffSearch={e.ff_search ? e.ff_search : false}
                defaultVal={e.default ? e.default : false}
              />
            )}
            {e.type === "multi-select" && (
              <FilterMultiSelect
                label={e.name}
                choices={e.values}
                setQueryString={setQueryString}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
