"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Steward = void 0;
var ethers_1 = require("ethers/lib/ethers");
// tslint:disable-next-line:no-var-requires
var DEFAULT_CHAIN = 'mumbai';
/**
 * @property {string} stewardAddress - address of the steward contract
 * @property {string} chain - blockhainchain being used, defaults to DEFUALT_CHAIN
 * @property {string} nftAddress - address of the token contract
 * @property {Array<any>} nftDetails - address of the owner of the steward contract
 * @property {string} owner - address of the steward owner
 * @property {string} beneficiaryAddress - address of the beneficiaryAddress contract
 * @property {string} beneficiaryFund - what is the value of the benficiary fund
 * @property {string} commissionFund - what is the value of the commission fund
 * @property {string} totalTokens - totalTokens in the nft contract (can be used to dynamically generate the metadata json's without going to each token within the NFT)
 * @property {string} patronageNumerator - numerator of the patronage percentage
 * @property {string} patronageDenominator - denominator of the patronage percentage
 * @property {string} beneficiaryCutNumerator - numerator of the beneficiary cut percentage
 * @property {string} beneficiaryCutDenominator - denominator of the beneficiary cut percentage
 * @property {string} commissionCutNumerator - numerator of the commission cut percentage
 * @property {string} commissionCutDenominator - denominator of the commission cut percentage
 * @property {Array<string, string>} prices - map of tokenId to price
 * @property {Array<string, string>} totalsCollected - totals collected for each tokenId
 * @property {Array<string, string>} timesLastCollected - totals burned for each tokenId
 * @property {Array<string, string>} deposits - deposit for each tokenId
 * @property {Array<string, string>} timesAcquired - timesAcquired for each tokenId
 * @property {Array<string, string>} floorPrices - floorPrices for each tokenId
 * @property {Array<string, boolean>} isForeclosed - whether a tokenId is foreclosed
 * @property {Array<Array<string, string>, string>} pullFunds - pullFunds for each address-tokenId pair
 * @property {Array<Array<string, string>, boolean>} patrons - whether a given address was ever a patron for each address-tokenId pair
 * @property {Array<Array<string, string>, boolean>} timesHeld - timesHeld for each address-tokenId pair
 */
var Steward = /** @class */ (function () {
    function Steward(api, chain) {
        var _this = this;
        // Method that is called when web3 is enabled in the FFSDK
        this.setWeb3 = function (web3Provider, web3Service) {
            _this.web3 = web3Provider;
            _this.web3Service = web3Service;
        };
        // Method that is called when web2 is enabled in the FFSDK
        this.setWeb2 = function (firebaseAuth) {
            _this.firebaseAuth = firebaseAuth;
        };
        this.init = function (address) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.stewardAddress = address;
                        // TODO: The init function loads basic NFT details for a typical UI render.
                        // We may need to call additional functions depending on what is required when an NFT is clicked on
                        _a = this;
                        return [4 /*yield*/, this.getNFTAddress()];
                    case 1:
                        // TODO: The init function loads basic NFT details for a typical UI render.
                        // We may need to call additional functions depending on what is required when an NFT is clicked on
                        _a.nftAddress = _b.sent();
                        if (this.nftAddress) {
                            // const contractNFTsRes = await this.fanFireAPI.getContractNFTs(this.nftAddress, this.chain, 'decimal');
                            // const nfts = contractNFTsRes?.value?.result;
                            // if (!nfts) throw Error('No NFTs found at this address');
                            // const contractNFTOwnersRes = await this.fanFireAPI.getContractNFTOwners(this.nftAddress, this.chain, 'decimal');
                            // const owners = contractNFTOwnersRes?.value?.result;
                            // if (!owners) throw Error('No owners found for these NFTs');
                            //
                            // const promiseArr = nfts.map((nft: any) => {
                            //   return this.fanFireAPI.getNFTDetails(nft.token_uri);
                            // });
                            // const re = await Promise.all(promiseArr);
                            //
                            // if (nfts && owners) {
                            //   this.nfts = nfts.map((nft: any, idx: number) => {
                            //     const ownerNFT = owners.find((o: any) => o.token_id === nft.token_id);
                            //     return { ...nft, ...ownerNFT, ...re[idx].value };
                            //   });
                            // }
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        /**
         *
         * Method that sets the attibutes that remain constant for the lifetime of the contract
         */
        this.setConstantAttributes = function () { return __awaiter(_this, void 0, void 0, function () {
            var promArr, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promArr = [
                            this.getNFTAddress(),
                            this.getBeneficiaryAddress(),
                            this.getTotalTokens(),
                            this.getPatronageNumerator(),
                            this.getPatronageDenominator(),
                            this.getBeneficiaryCutNumerator(),
                            this.getBeneficiaryCutDenominator(),
                            this.getCommissionCutNumerator(),
                            this.getCommissionCutDenominator(),
                        ];
                        return [4 /*yield*/, Promise.all(promArr)];
                    case 1:
                        res = _a.sent();
                        this.nftAddress = res[0];
                        this.beneficiaryAddress = res[1];
                        this.totalTokens = res[2];
                        this.patronageNumerator = res[3];
                        this.patronageDenominator = res[4];
                        this.beneficiaryCutNumerator = res[5];
                        this.beneficiaryCutDenominator = res[6];
                        this.commissionCutNumerator = res[7];
                        this.commissionCutDenominator = res[8];
                        return [2 /*return*/];
                }
            });
        }); };
        /**
         * Method that updates the non-constant attrinutes of the contract
         */
        this.updateVariableAttributes = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getBeneificiaryFund()];
                    case 1:
                        _a.beneficiaryFund = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.getCommissionFund()];
                    case 2:
                        _b.commissionFund = _c.sent();
                        // 'decimal' is the format of the token response from the API. Can choose Hex if preferred
                        if (this.nftAddress) {
                            // const contractNFTs = await this.fanFireAPI.getContractNFTs(this.nftAddress, this.chain, 'decimal');
                            // const nfts = contractNFTs?.value?.result;
                            // if (!nfts) throw Error('No NFTs found');
                            // const contractNFTOwners = await this.fanFireAPI.getContractNFTOwners(this.nftAddress, this.chain, 'decimal');
                            // const owners = contractNFTOwners?.value?.result;
                            // if (!owners) throw Error('No owners found');
                            //
                            // const promiseArr = nfts.map((nft: any) => {
                            //   return this.fanFireAPI.getNFTDetails(nft.token_uri);
                            // });
                            // const re = await Promise.all(promiseArr);
                            //
                            // if (nfts && owners) {
                            //   this.nfts = nfts.map((nft: any, idx: number) => {
                            //     const ownerNFT = owners.find((o: any) => o.token_id === nft.token_id);
                            //     return { ...nft, ...ownerNFT, ...re[idx].value };
                            //   });
                            // }
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.updateTokenDetails = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        }); };
        this.updateAddressTokenDetails = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        }); };
        this.getCustomTokenDetails = function (nft) { return __awaiter(_this, void 0, void 0, function () {
            var nftResults, promiseArr, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fanFireAPI.getNFTDetails(nft.token_uri)];
                    case 1:
                        nftResults = _a.sent();
                        promiseArr = [
                            this.getPrices(nft.token_id),
                            this.getTotalsCollected(nft.token_id),
                            this.getLastWithdrawalTime(nft.token_id),
                            this.getDeposits(nft.token_id),
                            this.getTimesAcquired(nft.token_id),
                            this.getFloorPrice(nft.token_id),
                            this.getIsForeclosed(nft.token_id),
                        ];
                        return [4 /*yield*/, Promise.all(promiseArr)];
                    case 2:
                        res = _a.sent();
                        return [2 /*return*/, this.nftDetails.push(__assign(__assign({}, nft), { price: res[1], name: nftResults.value.name, description: nftResults.value.description, imageUrl: nftResults.value.image, totalsCollected: res[2], timeLastCollected: res[3], deposit: res[4], timeAcquired: res[5], floorPrice: res[6], isForeclosed: res[7] }))];
                }
            });
        }); };
        // TODO: This is an example of how authorized requests will work.
        this.authorizedCallContract = function () { return __awaiter(_this, void 0, void 0, function () {
            var service, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        service = this.web3Service ? this.web3Service : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error('Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize');
                        }
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, service.callContract(this.stewardAddress, this.chain, 'nft', [])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getNFTAddress = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'nft', [])];
                    case 2:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.error(err_1);
                        throw Error('An error occurred while calling contract');
                    case 4: return [3 /*break*/, 6];
                    case 5: throw Error('Please initialize the steward class with a steward contract address');
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        this.getBeneficiaryAddress = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'beneficiary', [])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getBeneificiaryFund = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'beneficiaryFund', [])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, ethers_1.BigNumber.from(res.value).toString()];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getCommissionFund = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'commissionFund', [])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, ethers_1.BigNumber.from(res.value).toString()];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getTotalTokens = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'totalTokens', [])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, ethers_1.BigNumber.from(res.value).toString()];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getPatronageNumerator = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'patronageNumerator', [])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, ethers_1.BigNumber.from(res.value).toString()];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please intialise the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getPatronageDenominator = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'patronageDenominator', [])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, ethers_1.BigNumber.from(res.value).toString()];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getBeneficiaryCutNumerator = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'beneficiaryCutNumerator', [])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, ethers_1.BigNumber.from(res.value).toString()];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getBeneficiaryCutDenominator = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'beneficiaryCutDenominator', [])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, ethers_1.BigNumber.from(res.value).toString()];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getCommissionCutNumerator = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'commissionCutNumerator', [])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, ethers_1.BigNumber.from(res.value).toString()];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getCommissionCutDenominator = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'commissionCutDenominator', [])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, ethers_1.BigNumber.from(res.value).toString()];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getPrices = function (tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'prices', ["".concat(tokenId)])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getTotalsCollected = function (tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'totalsCollected', ["".concat(tokenId)])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getLastWithdrawalTime = function (tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'timesLastCollected', ["".concat(tokenId)])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getDeposits = function (tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'deposits', ["".concat(tokenId)])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getTimesAcquired = function (tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'timesAcquired', ["".concat(tokenId)])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getFloorPrice = function (tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'floorPrices', ["".concat(tokenId)])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getIsForeclosed = function (tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'isForeclosed', ["".concat(tokenId)])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getPullFunds = function (walletAddress, tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'pullFunds', ["".concat(walletAddress), "".concat(tokenId)])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getPatrons = function (walletAddress, tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'patrons', ["".concat(walletAddress), "".concat(tokenId)])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getTimesHeld = function (walletAddress, tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stewardAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.stewardAddress, this.chain, 'timesHeld', ["".concat(walletAddress), "".concat(tokenId)])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('Please initialize the steward class with a steward contract address');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getNFTOwner = function (tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.nftAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.nftAddress, this.chain, 'ownerOf', ["".concat(tokenId)])];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2: throw Error('An error occurred while determining the NFT address from the steward contract. Re-initialize the SDK with the correct Steward address.');
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getNFTDetails = function () { return __awaiter(_this, void 0, void 0, function () {
            var promiseArr, re;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.nfts) return [3 /*break*/, 2];
                        promiseArr = this.nfts.map(function (nft) { return _this.getCustomTokenDetails(nft); });
                        return [4 /*yield*/, Promise.all(promiseArr)];
                    case 1:
                        re = _a.sent();
                        return [2 /*return*/, re];
                    case 2: throw Error('An error occurred while loading contract NFTs and details could not be fetched.');
                }
            });
        }); };
        this.fanFireAPI = api;
        this.chain = chain ? chain : DEFAULT_CHAIN;
        this.nftDetails = [];
    }
    return Steward;
}());
exports.Steward = Steward;
