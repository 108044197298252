import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import {
  metaMaskLogIn,
  googleLogIn,
  emailAndPasswordSignUp,
  emailAndPasswordLogIn,
  airdropToken,
} from "./general/functions";
import { useSearchParams } from "react-router-dom";

//MUI
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import Icon from "@mui/material/Icon";
import Alert from "@mui/material/Alert";
import { AlertColor } from "@mui/material/Alert";
import Divider from "@mui/material/Divider";

//Components
import Text from "../text/Text";
import SecondaryButton from "../buttons/SecondaryButton";
import GoogleIcon from "../../assets/icons8-google.svg";
import MetaMaskIcon from "../../assets/icons8-metamask-logo-96.png";
import { useSnackBar } from "../../context/snackAlert-context";
import OAuthItems from "./OAuthItems";
import EmailAndPassItems from "./EmailAndPassItems";
import BasicLoader from "../loaders/BasicLoader";
import useIsMobile from "../../hooks/useIsMobile";
import { FanfireSDK } from "fanfire-sdk";

interface Props {
  type: "login" | "signup" | "invitation";
  attendance?: boolean;
  attendanceData?: {
    chain: string;
    nftAddress: string;
  };
  setLoading?: ({}: boolean) => void | undefined;
  sdkService: FanfireSDK;
  sliceStore: any;
  appConfig: any;
  // isMobile: boolean;
  showButton?: boolean;
  show?: boolean;
}

export const GoogleLogo = () => (
  <Icon>
    <img src={GoogleIcon} height={25} width={25} />
  </Icon>
);

export const MetaMaskLogo = () => (
  <Icon>
    <img src={MetaMaskIcon} height={25} width={25} />
  </Icon>
);

/*
=================================================================
Component used to login and sign up user to the application.
Login and Sign up can be done via email and password or google.
Forgot password and resend validation.
Component also allows for attendance tokens that airdrops tokens to user logging
in with a certain link. 
=================================================================
*/

export default function AuthModal({
  type, // login / signup / invitation - which modal to show
  attendance, // (optional) whether airdrop should happen on login
  attendanceData, // (optional) data containing airdrop info
  setLoading, // (optional) for loading screen
  sdkService, // sdkService used to retrieve backend data
  sliceStore, // slice redux that handles auth (apps/fanfire-components/src/store/userSlices/userSlice.tsx)
  appConfig, // white label appConfig
  // isMobile, // check if mobile - the firebase needs to redirect i/o popup on mobile
  showButton = true, // whether to show the login/signup button on parent when this component is used
  show = false, // wether to show this modal from parent
}: Props) {
  const [typeAuthModal, setTypeAuthModal] =
    useState<"login" | "signup" | "invitation">(type);
  const [open, setOpen] = useState(false);
  const [metaMaskGetOpen, setMetaMaskGetOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passConfirm, setPassConfirm] = useState("");
  const [message, setMessage] = useState<{
    severity: AlertColor;
    message: string;
  }>({
    severity: "error",
    message: "",
  });
  const [creatingWallet, setCreatingWallet] = useState<boolean>(false);

  const { showSnackBar } = useSnackBar();
  const user = useSelector((state: RootStateOrAny) => state.user);
  const { mobileView } = useIsMobile();
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      setOpen(true);
    }
  }, [show]);

  const handleClickOpen = () => {
    if (!open) {
      setOpen(true);
    }
    setMessage({ severity: "error", message: "" });
  };

  const handleClose = () => {
    setOpen(false);
    resetState();
    setTypeAuthModal(type);
  };

  const resetState = () => {
    setEmail("");
    setPassword("");
    setPassConfirm("");
    setMessage({ severity: "info", message: "" });
    setCreatingWallet(false);
  };

  useEffect(() => {
    //Reset state when the screen is moved to another option (login / signup)
    resetState();
    if (searchParams.get("email")) {
      setEmail(searchParams.get("email") ?? "");
    }
  }, [typeAuthModal]);

  const handleEmailAndPasswordSignIn = async () => {
    //Function that handles email and password (web2) login
    setMessage({ severity: "error", message: "" });
    emailAndPasswordLogIn(
      appConfig,
      email,
      password,
      dispatch,
      sdkService,
      sliceStore,
      attendance,
      attendanceData,
      setLoading,
      () => setOpen
    );
    if (attendance && attendanceData && setOpen) {
      airdropToken(sdkService, attendanceData, () => setOpen, setLoading);
    }
  };

  const handleEmailAndPasswordSignUp = async () => {
    //Function that handles email and password (web2) Sign Up
    setMessage({ severity: "error", message: "" });
    emailAndPasswordSignUp(
      appConfig,
      email,
      password,
      dispatch,
      sdkService,
      sliceStore,
      attendance,
      attendanceData,
      setLoading,
      () => setOpen
    );
  };

  const handleGoogleLogIn = async () => {
    //Function that handles google (web2) login
    setMessage({ severity: "error", message: "" });
    googleLogIn(mobileView, dispatch, sliceStore, sdkService, setLoading);
  };

  const handleMetaMaskLogIn = async () => {
    //Function that handles metamask (web3) login
    setMessage({ severity: "error", message: "" });
    metaMaskLogIn(
      setMetaMaskGetOpen,
      dispatch,
      sliceStore,
      sdkService,
      setLoading
    );
    if (attendance && attendanceData && setOpen) {
      airdropToken(sdkService, attendanceData, () => setOpen, setLoading);
    }
  };

  // const activateSDK = async () => {
  //   //enable the sdk over the whole app
  //   if (user.auth.isWeb2) {
  //     await dispatch(sliceStore.enableWeb2ForApp({ ffsdk: sdkService }));
  //   }
  // };

  useEffect(() => {
    //Looks at redux status to display message / activate sdk based on status
    if (user.status === "failed") {
      console.log("failed");
      console.log(user.error);
      setMessage({ severity: "error", message: user.error });
    }
    if (user.creatingWallet === "creating") {
      setCreatingWallet(true);
      // setTypeAuthModal("signup");
    }
    if (user.status === "signedUp") {
      setMessage({
        severity: "success",
        message:
          "Sign up successful! Please check you email for a verification link.",
      });
    }
    if (user.status === "succeeded") {
      setMessage({ severity: "info", message: user.error });
      handleClose();
    }
  }, [user]);

  const SetTypeButton = () => {
    return (
      <SecondaryButton
        onClick={() => {
          if (typeAuthModal === "login") {
            setTypeAuthModal("signup");
          } else {
            setTypeAuthModal("login");
          }
        }}
      >
        <Text variantText="h3" style={{ textAlign: "center" }}>
          {typeAuthModal === "login" ? "Sign Up" : "Log In"}
        </Text>
      </SecondaryButton>
    );
  };

  // console.log(`EMAIL STATE: ${email}`);
  return (
    <div>
      {showButton && (
        <Button className="auth" onClick={() => handleClickOpen()}>
          <Text variantText="h6">
            {type === "login" ? "Log In" : "Sign Up"}
          </Text>
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        className="primary"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>
          <Avatar sx={{ width: 40, height: 40, background: "#000000" }}>
            <LoginRoundedIcon
              sx={{
                fontSize: 30,
                transform: "rotate(180deg)",
                color: "white",
              }}
            />
          </Avatar>
        </DialogTitle>
        <DialogTitle component="div" style={{ textTransform: "uppercase" }}>
          <Text variantText="h1">
            {typeAuthModal === "login" ? "Log In" : "Sign Up"}
          </Text>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div" style={{ paddingBottom: "20%" }}>
            {!creatingWallet &&
              (typeAuthModal === "invitation" ? (
                <>
                  <Text
                    variantText="h6"
                    style={{ textTransform: "capitalize" }}
                  >
                    Welcome to{" "}
                    <strong>
                      {appConfig.general.app
                        ? appConfig.general.app
                        : "Fanfire"}
                    </strong>
                    !
                  </Text>
                  <Box pt={2}></Box>
                </>
              ) : (
                <></>
              ))}
            {/* Alert Messages for Auth */}
            {message.message && (
              <Alert severity={message.severity ? message.severity : "info"}>
                {message.message}
              </Alert>
            )}
            {message.message &&
              message.message.includes(
                "Your email has not been verified yet."
              ) && (
                <SecondaryButton
                  onClick={() => {
                    handleEmailAndPasswordSignIn();
                    showSnackBar("Email Verification Sent!", "success");
                  }}
                >
                  <Text variantText="body1" style={{ textAlign: "right" }}>
                    Resend Verification Email?
                  </Text>
                </SecondaryButton>
              )}
            {/* Alert Messages for Auth */}
            {creatingWallet ? (
              user.status == "failed" ? (
                <SetTypeButton />
              ) : user.status == "signedUp" ? (
                <SetTypeButton />
              ) : (
                <BasicLoader text="Finding your wallet..." />
              )
            ) : message.message &&
              message.message.includes("Sign up successful!") ? (
              <></>
            ) : (
              <>
                {/* Form that handles email and pass auth */}
                <EmailAndPassItems
                  appConfig={appConfig}
                  typeAuthModal={typeAuthModal}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  setPassConfirm={setPassConfirm}
                  handleEmailAndPasswordSignIn={handleEmailAndPasswordSignIn}
                  handleEmailAndPasswordSignUp={handleEmailAndPasswordSignUp}
                  email={searchParams.get("email") ?? email}
                  password={password}
                  passConfirm={passConfirm}
                  setMessage={setMessage}
                  sliceStore={sliceStore}
                  sdkService={sdkService}
                />
                {/* Form that handles OAuth auth */}
                <OAuthItems
                  typeAuthModal={typeAuthModal}
                  handleGoogleLogin={handleGoogleLogIn}
                  handleMetaMaskLogin={handleMetaMaskLogIn}
                  setMessage={setMessage}
                />

                {/* Form that handles OAuth auth */}
              </>
            )}
            {/* Form that handles email and pass auth */}
            {!creatingWallet && typeAuthModal !== "invitation" && (
              <>
                <Divider sx={{ background: "white" }}></Divider>
                <Text variantText="h6" style={{ textAlign: "center" }}>
                  {typeAuthModal === "login"
                    ? "Don't have an account?"
                    : "Already have an account?"}
                </Text>
                <SetTypeButton />
                <Divider sx={{ background: "white" }}></Divider>
              </>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {metaMaskGetOpen && (
        // <GetMetaMask onClose={setMetaMaskGetOpen}/>
        <></>
      )}
    </div>
  );
}
