import React, { useEffect, useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Text from "../text/Text";
import { Link as RouterLink } from "react-router-dom";
import { groupBy } from "../../helpers/functions";
import AccordionSkeleton from "../loaders/AccordianSkeleton";
import PrimaryButton from "../buttons/PrimaryButton";
import {
  getBottles,
  getBottlesHumanFriendly,
  getEstate,
  getVintage,
} from "../../helpers/catergorySpecific/wineCategoryFunctions";

const styles = {
  image: {
    borderRadius: "9.2px",
    // filter: "drop-shadow(0px 1.95652px 24px #000000)",
    objectFit: "contain" as "contain",
    // width: "100%",
    marginRight: "10px",
    height: "50px",
  },
};

interface PropsAccordionHeader {
  group: any;
}

const AccordionHeader = ({ group }: PropsAccordionHeader) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Text
            variantText="h3"
            style={{
              textTransform: "none",
              fontWeight: "bolder",
            }}
          >
            {group.group}
          </Text>
        </Grid>
        <Grid item xs={12}>
          {/* <Box display="flex" width="100%" justifyContent="space-between"> */}
          <Text
            variantText="h6"
            style={{
              textTransform: "none",
              fontWeight: "bolder",
            }}
          >
            {group.nfts.length}{" "}
            {group.nfts.length === 1 ? "Variety" : "Varieties"}
          </Text>
          {/* <Text
              variantText="h6"
              style={{
                textTransform: "none",
                fontWeight: "bolder",
                textAlign: "right",
              }}
            >
              {group.price}
              R12 000
            </Text> */}
          {/* </Box> */}
        </Grid>
      </Grid>
    </>
  );
};

interface PropsAccordionItems {
  nfts: any[];
}

const AccordionItems = ({ nfts }: PropsAccordionItems) => {
  return (
    <>
      {nfts.map((nftElement, i) => (
        <RouterLink
          key={i}
          style={{ textDecoration: "none", color: "inherit" }}
          to={{
            pathname: `/${nftElement.contractAddress}/${nftElement.tokenId}`,
          }}
          state={
            !location.pathname.includes("/profile")
              ? {
                  ref: nftElement.tokenId,
                  address: nftElement.contractAddress,
                  nft: nftElement,
                }
              : {}
          }
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="left"
            p={1}
            key={`item-${i}`}
          >
            <Divider sx={{ background: "grey" }} />
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              key={`sub-item-${i}`}
            >
              {/* <img
                style={styles.image}
                src={nftElement.metadata.image}
                alt="image"
              /> */}
              <Box>
                <Text
                  variantText="h6"
                  style={{
                    textAlign: "left",
                    fontWeight: "bolder",
                  }}
                >
                  {getVintage(nftElement.metadata)} {nftElement.metadata.name}
                </Text>
                <Text
                  variantText="body2"
                  style={{
                    textAlign: "left",
                    fontWeight: "bolder",
                  }}
                >
                  {getBottlesHumanFriendly(
                    nftElement.metadata,
                    Number(nftElement.amount)
                  )}
                </Text>
              </Box>
            </Grid>
            <Divider sx={{ background: "grey" }} />
          </Grid>
        </RouterLink>
      ))}
    </>
  );
};

interface MapNFTsProps {
  nfts: any[];
  loading: boolean;
  appConfig: any;
}

export default function WineCategoryList({
  nfts,
  loading,
  appConfig,
}: MapNFTsProps) {
  const [groupedNFTs, setGroupedNFTs] = useState<any>([]);

  const groupNfts = (nfts: any) => {
    //Group By estate
    const grouped: any = groupBy(nfts, (c: any) => getEstate(c.metadata));
    return Object.keys(grouped).map((key: any) => {
      return { group: key !== "null" ? key : "Other", nfts: grouped[key] };
    });
  };

  useEffect(() => {
    //group by
    setGroupedNFTs(groupNfts(nfts));
  }, [nfts]);

  return (
    <>
      {!loading ? (
        groupedNFTs.length === 0 ? (
          <Grid
            container
            padding={5}
            pt={10}
            px={"10%"}
            spacing={5}
            alignItems="center"
            justifyContent="center"
            direction="row"
          >
            <Grid item xs={6}>
              <Text
                variantText="h6"
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                }}
              >
                Your wine collection is still empty.
              </Text>

              {/* <PrimaryButton> */}
              <Text
                variantText="body1"
                style={{
                  fontWeight: "bold",
                  ...appConfig.general.styleOverrides.buttonPrimaryTextColor,
                }}
              >
                <RouterLink
                  style={{
                    textDecoration: "underline solid black",
                    textDecorationColor: "black",
                    color: "black",
                  }}
                  to="/for-sale"
                >
                  Browse the Vault
                </RouterLink>{" "}
                to find something exceptional.
              </Text>
              {/* </PrimaryButton> */}
            </Grid>
          </Grid>
        ) : (
          groupedNFTs.map((nftElement: any, i: number) => (
            <Box m={1} key={`box-${i}`}>
              <Accordion className="primary" key={`accordion-${i}`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <AccordionHeader group={nftElement} />
                </AccordionSummary>
                <AccordionDetails>
                  <Box pt={1} pb={1}>
                    <AccordionItems nfts={nftElement.nfts} />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))
        )
      ) : (
        <>
          <AccordionSkeleton />
        </>
      )}
    </>
  );
}

// import React, { useEffect, useState } from "react";

// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Box,
//   Grid,
//   Divider,
//   Card,
//   CardContent,
//   CardMedia,
//   IconButton,
//   Typography,
//   Collapse,
// } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Text from "../text/Text";
// import { Link as RouterLink } from "react-router-dom";
// import { groupBy } from "../../helpers/functions";
// import AccordionSkeleton from "../loaders/AccordianSkeleton";
// import PrimaryButton from "../buttons/PrimaryButton";
// import {
//   getEstate,
//   getVintage,
// } from "../../helpers/catergorySpecific/wineCategoryFunctions";
// import Header from "../text/Header";
// import theme from "../../app-config-styles";
// import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// const styles = {
//   image: {
//     borderRadius: "9.2px",
//     objectFit: "contain" as "contain",
//     marginRight: "5px",
//     maxHeight: "170px",
//   },
// };
// interface PropsAccordionItems {
//   nfts: any[];
// }

// function LeftArrow() {
//   const { isFirstItemVisible, scrollPrev } =
//     React.useContext(VisibilityContext);

//   return (
//     // <>Left</>
//     <Collapse
//       orientation="horizontal"
//       in={!isFirstItemVisible}
//       {...(!isFirstItemVisible ? { timeout: 2000 } : {})}
//     >
//       <IconButton onClick={() => scrollPrev()}>
//         <ArrowBackIosIcon />
//       </IconButton>
//     </Collapse>
//     // !isFirstItemVisible ? (

//     // <IconButton
//     //   disabled={isFirstItemVisible}
//     //   onClick={() => scrollPrev()}
//     //   sx={{ opacity: 0.1 }}
//     // >
//     //   <ArrowBackIosIcon />
//     // </IconButton>
//     // ) : (
//     //   <></>
//     // )

//     // <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
//     //   Left
//     // </Arrow>
//   );
// }

// function RightArrow() {
//   const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

//   return (
//     // <>Right</>
//     !isLastItemVisible ? (
//       <IconButton disabled={isLastItemVisible} onClick={() => scrollNext()}>
//         <ArrowForwardIosIcon />
//       </IconButton>
//     ) : (
//       <></>
//     )

//     // <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
//     //   Right
//     // </Arrow>
//   );
// }

// const AccordionItems = ({ nfts }: PropsAccordionItems) => {
//   return (
//     <>
//       <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
//         {nfts.map((nftElement, i) => (
//           <RouterLink
//             key={i}
//             style={{ textDecoration: "none", color: "inherit" }}
//             to={{
//               pathname: `/${nftElement.contractAddress}/${nftElement.tokenId}`,
//             }}
//             state={
//               !location.pathname.includes("/profile")
//                 ? {
//                     ref: nftElement.tokenId,
//                     address: nftElement.contractAddress,
//                     nft: nftElement,
//                   }
//                 : {}
//             }
//           >
//             <Box
//             // item
//             // sm={12}
//             // md={4}
//             // lg={4}
//             // display="flex"
//             // key={`sub-item-${i}`}
//             // p={1}
//             >
//               <img
//                 style={styles.image}
//                 src={nftElement.metadata.image}
//                 alt="image"
//               />
//               <Box sx={{ width: 150 }}>
//                 <Text
//                   variantText="body1"
//                   style={{
//                     textAlign: "left",
//                     fontWeight: "bolder",
//                   }}
//                 >
//                   {nftElement.metadata.name}
//                 </Text>
//                 <Text
//                   variantText="body1"
//                   style={{
//                     textAlign: "left",
//                   }}
//                 >
//                   {getVintage(nftElement.metadata)}
//                 </Text>
//                 <Text
//                   variantText="body2"
//                   style={{
//                     textAlign: "left",
//                   }}
//                 >
//                   2 Cases
//                 </Text>
//                 <Text
//                   variantText="body2"
//                   style={{
//                     textAlign: "left",
//                   }}
//                 >
//                   Valuation: <span style={{ fontWeight: "bolder" }}>R 123</span>
//                 </Text>
//               </Box>
//             </Box>
//           </RouterLink>
//         ))}
//       </ScrollMenu>
//       {/* <Grid container item sm={12} justifyContent="left" p={1}>
//         {nfts.map((nftElement, i) => (
//           <RouterLink
//             key={i}
//             style={{ textDecoration: "none", color: "inherit" }}
//             to={{
//               pathname: `/${nftElement.contractAddress}/${nftElement.tokenId}`,
//             }}
//             state={
//               !location.pathname.includes("/profile")
//                 ? {
//                     ref: nftElement.tokenId,
//                     address: nftElement.contractAddress,
//                     nft: nftElement,
//                   }
//                 : {}
//             }
//           >
//             <Grid
//               item
//               sm={12}
//               md={4}
//               lg={4}
//               display="flex"
//               key={`sub-item-${i}`}
//               p={1}
//             >
//               <img
//                 style={styles.image}
//                 src={nftElement.metadata.image}
//                 alt="image"
//               />
//               <Box sx={{ width: 150 }}>
//                 <Text
//                   variantText="body1"
//                   style={{
//                     textAlign: "left",
//                     fontWeight: "bolder",
//                   }}
//                 >
//                   {nftElement.metadata.name}
//                 </Text>
//                 <Text
//                   variantText="body1"
//                   style={{
//                     textAlign: "left",
//                   }}
//                 >
//                   {getVintage(nftElement.metadata)}
//                 </Text>
//                 <Text
//                   variantText="body2"
//                   style={{
//                     textAlign: "left",
//                   }}
//                 >
//                   2 Cases
//                 </Text>
//                 <Text
//                   variantText="body2"
//                   style={{
//                     textAlign: "left",
//                   }}
//                 >
//                   Valuation: <span style={{ fontWeight: "bolder" }}>R 123</span>
//                 </Text>
//               </Box>
//             </Grid>
//           </RouterLink>
//         ))}
//       </Grid> */}
//     </>
//   );
// };

// interface MapNFTsProps {
//   nfts: any[];
//   loading: boolean;
//   appConfig: any;
// }

// export default function WineCategoryList({
//   nfts,
//   loading,
//   appConfig,
// }: MapNFTsProps) {
//   const [groupedNFTs, setGroupedNFTs] = useState<any>([]);

//   const groupNfts = (nfts: any) => {
//     //Group By estate
//     const grouped: any = groupBy(nfts, (c: any) => getEstate(c.metadata));
//     return Object.keys(grouped).map((key: any) => {
//       return { group: key !== "null" ? key : "Other", nfts: grouped[key] };
//     });
//   };

//   useEffect(() => {
//     //group by
//     setGroupedNFTs(groupNfts(nfts));
//   }, [nfts]);

//   return (
//     <>
//       {!loading ? (
//         groupedNFTs.length === 0 ? (
//           <Grid
//             container
//             padding={5}
//             pt={10}
//             px={"10%"}
//             spacing={5}
//             alignItems="center"
//             justifyContent="center"
//             direction="row"
//           >
//             <Grid item xs={6}>
//               <Text
//                 variantText="h6"
//                 style={{
//                   textAlign: "center",
//                   fontWeight: "bolder",
//                 }}
//               >
//                 Your wine collection is still empty.
//               </Text>

//               {/* <PrimaryButton> */}
//               <Text
//                 variantText="body1"
//                 style={{
//                   fontWeight: "bold",
//                   ...appConfig.general.styleOverrides.buttonPrimaryTextColor,
//                 }}
//               >
//                 <RouterLink
//                   style={{
//                     textDecoration: "underline solid black",
//                     textDecorationColor: "black",
//                     color: "black",
//                   }}
//                   to="/for-sale"
//                 >
//                   Browse the Vault
//                 </RouterLink>{" "}
//                 to find something exceptional.
//               </Text>
//               {/* </PrimaryButton> */}
//             </Grid>
//           </Grid>
//         ) : (
//           groupedNFTs.map((nftElement: any, i: number) => (
//             <Box m={1} key={`box-${i}`}>
//               <Header heading={nftElement.group} full={false} />
//               <AccordionItems nfts={nftElement.nfts} />
//             </Box>
//           ))
//         )
//       ) : (
//         <>
//           <AccordionSkeleton />
//         </>
//       )}
//     </>
//   );
// }
