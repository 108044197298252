// Libraries
import React, { Fragment } from "react";

// Hooks & Functions
import { getBottles } from "../../../helpers/catergorySpecific/wineCategoryFunctions";
import useIsMobile from "../../../hooks/useIsMobile";

// MUI
import Chip from "@mui/material/Chip/Chip";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

// Components
import Text from "../../../components/text/Text";

const styles = {
  overlay: {
    position: "absolute" as "absolute",
    top: "10%",
    right: "10px",
    color: "white",
  },
  nftImage: {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "50vh",
    filter: "drop-shadow(0px 1.27599px 15.6522px #000000)",
    borderRadius: "10px",
    objectFit: "contain" as "contain",
    display: "block",
  },
};

interface Props {
  metadata: any;
  fallbackImage: string;
  onClick: () => void;
}

/**
 * The component which displays the bottle information, and if the NFT has one, its associated image.
 *
 * @param {any} metadata - The NFT's metadata
 * @param {string} fallbackImage - The path/url to the image to use if the NFT has no image data
 * @param {() => void} onClick - The function to call when clicking on the image.
 * @return {JSX.Element}
 * @constructor
 */
export default function ImageShowcase({
  metadata,
  fallbackImage,
  onClick,
}: Props) {
  const { mobileView } = useIsMobile();

  if (!metadata) return <div></div>;

  const bottles = getBottles(metadata);

  /**
   * Chip to display the number and volume of a wine purchase.
   *
   * @param {string} bottles - <number of bottles> x <volume of a bottle>
   * @return {JSX.Element}
   * @constructor
   */
  function BottlesChip({ bottles }: { bottles: string }): JSX.Element {
    return (
      <div style={styles.overlay}>
        <Chip
          className="primary"
          label={
            <Text variantText="h6" style={{ fontWeight: "bold" }}>
              {bottles}
            </Text>
          }
        />
      </div>
    );
  }

  /**
   * Displays the image contained within the NFT metadata.
   *
   * @return {JSX.Element}
   * @constructor
   */
  function NftImage(): JSX.Element {
    return (
      <Box
        component="img"
        sx={{
          ...styles.nftImage,
          ":hover": {
            transform:
              !mobileView && !metadata.video ? "scale3d(1.3, 1.3, 1.3)" : "",
          },
        }}
        alt="vault image"
        src={metadata.image}
        onClick={onClick}
      />
    );
  }

  /**
   * The image to the display if the NFT has no image metadata.
   *
   * @return {JSX.Element}
   * @constructor
   */
  function FallbackImage(): JSX.Element {
    return <img style={styles.nftImage} src={fallbackImage} alt="NFT" />;
  }

  return (
    <Fragment>
      {bottles ? <BottlesChip bottles={bottles} /> : null}
      {metadata.image ? <NftImage /> : <FallbackImage />}
    </Fragment>
  );
}
