// Libraries
import React, { useEffect, useState } from "react";
import {
  NavLink as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

// Helpers
import { useSnackBar } from "../../context/snackAlert-context";
import { defaultSort } from "../../helpers/functions";
import useIsMobile from "../../hooks/useIsMobile";
import { FanfireSDK } from "fanfire-sdk";

// MUI
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import InfoIcon from "@mui/icons-material/Info";
import FilterListIcon from "@mui/icons-material/FilterList";
import Grow from "@mui/material/Grow";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";

// Components
import Text from "../../components/text/Text";
import MapCardItems from "../../components/cardItem/MapCardItems";
import MapNFTsError from "../../components/errorComponents/MapNFTsError";
import TertiaryButton from "../../components/buttons/TertiaryButton";
import FilterComponent from "../../components/filter/FilterComponent";
import ScrollToTop from "../../components/helpers/ScrollToTop";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import usePagination from "../../hooks/usePagination";

const styles = {
  header: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "35vh",
    filter: "blur(4px)",
  },
  noHeader: {
    height: "35vh",
  },
  overlay: {
    position: "absolute" as "absolute",
    top: "20vh",
    left: "10%",
  },
  card: {
    borderRadius: "12px",
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
    overflow: "visible",
    position: "relative" as "relative",
  },
  image: {
    borderRadius: "9.2px",
    filter: "drop-shadow(0px 1.95652px 24px #000000)",
    objectFit: "cover" as "cover",
    width: "100%",
  },
};

interface Props {
  sdkService: FanfireSDK;
  appConfig: any;
}

export default function ForSale({ sdkService, appConfig }: Props) {
  const [allNFTs, setAllNfts] = useState<object[]>([]);
  const [searchedNFTs, setSearchedNFTs] = useState<object[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { showSnackBar } = useSnackBar();
  const { mobileView } = useIsMobile();
  const { skip, limit, setTotal, PaginationComp } = usePagination(loading);

  const createSDKFilterParams = (queryString: any) => {
    let filter: Array<{}> = [];
    const searchParams = new URLSearchParams(queryString);
    searchParams.forEach(function (value, key) {
      if (Array.isArray(value.split(","))) {
        for (const item of value.split(",")) {
          filter.push({ trait_type: key, value: item });
        }
      } else {
        filter.push({ trait_type: key, value: value });
      }
    });
    return filter;
  };

  const [filter, setFilter] = useState<any>(
    createSDKFilterParams(window.location.search)
  );
  const [filterName, setFilterName] = useState<String>("");

  const getAttributeFilters = (
    filter?: Array<{ trait_type: string; value: string }>
  ) => {
    return filter?.filter(
      (e: any) =>
        e.trait_type != "Name" &&
        !e.trait_type.includes("ffSearch-") &&
        e.value !== "null" &&
        e.trait_type !== "skip" &&
        e.trait_type !== "limit"
    );
  };

  const getFFMetaFilters = (
    filter?: Array<{ trait_type: string; value: string }>
  ) => {
    let ffMetaFilter: Array<{ trait_type: string; value: string }> | undefined =
      filter
        ?.filter((e: any) => e.trait_type.includes("ffSearch-"))
        .map((criteria): any => {
          if (criteria.value !== "null") {
            return {
              trait_type: criteria.trait_type.replace("ffSearch-", ""),
              value: criteria.value,
            };
          }
        });

    return ffMetaFilter;
  };

  const getNFTs = async (
    filter?: Array<{ trait_type: string; value: string }>
  ) => {
    setLoading(true);
    try {
      console.log("______________FILTER______________");
      console.log(filter);
      console.log("attr");
      console.log(getAttributeFilters(filter));
      console.log("ff");
      console.log(getFFMetaFilters(filter));
      console.log("______________FILTER______________");

      const result = await sdkService.marketplace.getMarketplaceNfts(
        process.env.REACT_APP_FANFIRE_NFT_CONTRACT_ADDRESSES?.split(",") ?? [],
        getAttributeFilters(filter),
        getFFMetaFilters(filter),
        skip,
        limit
      );
      setLoading(false);
      return result;
    } catch (error) {
      setIsError(true);
      console.log(
        `There was an error getting the marketplace ${
          appConfig.general.site_noun_plural
            ? appConfig.general.site_noun_plural
            : "items"
        }`
      );
      console.error(error);
      showSnackBar(
        `Error getting marketplace ${
          appConfig.general.site_noun_plural
            ? appConfig.general.site_noun_plural
            : "items"
        }. Please try again later.`,
        "error",
        error
      );
      setLoading(false);
    }
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    console.log("Getting for sale filter NFTs");
    const prep = async () => {
      const result = await getNFTs(
        filter.filter((e: any) => e.trait_type != "Name")
      );

      const nftArray = result ? result.listings : undefined;

      console.log("filtered tokens:", nftArray);
      if (result) console.log("total tokens:", result.count);

      setTotal(result ? Math.ceil(result.count / limit) : 1);
      setAllNfts(defaultSort(nftArray, appConfig));
      setSearchedNFTs(defaultSort(nftArray, appConfig));
    };
    if (filter.length === 0 && appConfig.general.default_FF_filter) {
      console.log(
        "there is a default filter but none assigned yet, not calling NFTs"
      );
      return;
    } else {
      prep();
    }
  }, [filter, skip, limit]);

  const clearFilters = () => {
    searchParams.forEach((value, key, parent) => {
      //Don't remove the skip and limit query params
      if (key === "skip" || key === "limit") {
        console.log("Not removing param: ", key);
      } else {
        parent.set(key, "null");
      }
    });
    setSearchParams(searchParams);
    setFilterName("");
  };

  const handleOnClearClicked = () => {
    clearFilters();
    setFilter(createSDKFilterParams(window.location.search));
  };

  useEffect(() => {
    if (allNFTs && allNFTs.length > 0) {
      setSearchedNFTs([]);
      let x: any = [];
      if (filterName) {
        x = allNFTs.filter(
          (e: any) =>
            e.metadata.name &&
            e.metadata.name.toLowerCase().includes(filterName.toLowerCase())
        );
      } else {
        x = allNFTs;
      }
      setSearchedNFTs(defaultSort(x, appConfig));
    }
  }, [filterName, allNFTs]);

  const countFilters = (
    filter: Array<{ trait_type: string; value: string }>
  ) => {
    return (
      filter.filter((value) => {
        return value.value !== "null";
      }).length + (filterName !== "null" && filterName ? 1 : 0)
    );
  };

  const checkForApplicableFilter = (filter: any) => {
    const isFilter: Array<String> = [];
    filter.map((e: any) => {
      console.log(e.trait_type);
      if (e.trait_type !== "skip" || e.trait_type !== "limit") {
        isFilter.push(e.trait_type);
      }
    });
    if (!isFilter.length) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <ScrollToTop />
      {appConfig.forSalePage.headerImage ? (
        <div
          style={{
            ...styles.header,
            backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.3) 60%, rgba(0,0,0,90) 95%), url(${appConfig.forSalePage.headerImage})`,
          }}
        ></div>
      ) : (
        <div style={styles.noHeader}></div>
      )}
      <div style={styles.overlay}>
        <Avatar
          sx={{
            width: 40,
            height: 40,
            background: `${
              appConfig.navItems.backButtonNavIconPaddingColor
                ? appConfig.navItems.backButtonNavIconPaddingColor
                : "rgba(255, 255, 255, 0.1)"
            }`,
            top: "-5vh",
          }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosNewRoundedIcon
              sx={{
                fontSize: 25,
                color: `${
                  appConfig.navItems.backButtonNavIconColor
                    ? appConfig.navItems.backButtonNavIconColor
                    : "white"
                }`,
              }}
            />
          </IconButton>
        </Avatar>
        <Text variantText="h2" style={{ textAlign: "left" }}>
          {appConfig.forSalePage.header}
        </Text>
      </div>
      {appConfig.general.avail_filters &&
      appConfig.general.avail_filters.length ? (
        <Grid
          container
          columns={12}
          alignItems="center"
          justifyContent="center"
        >
          {mobileView ? (
            <>
              <Box
                flex={1}
                flexDirection="column"
                alignContent={"center"}
                maxWidth={200}
                mt={2}
              >
                <Button
                  onClick={toggleDrawer(true)}
                  startIcon={<FilterListIcon />}
                  className="secondary"
                  sx={{ bgcolor: "snow" }}
                  fullWidth
                >
                  <Badge
                    badgeContent={countFilters(
                      filter.filter((e: any) => e.trait_type != "Name")
                    )}
                    color="primary"
                  >
                    <Text variantText={"body1"}>Filters</Text>
                  </Badge>
                </Button>
              </Box>
              <Drawer
                variant="temporary"
                open={open}
                onClose={toggleDrawer(false)}
                anchor="bottom"
                ModalProps={{
                  keepMounted: true,
                }}
                PaperProps={{
                  sx: {
                    minHeight: {
                      xs: "450px",
                      sm: "250px",
                    },
                    height: "70vh",
                    borderRadius: "18px",
                  },
                }}
              >
                <br />
                <FilterComponent
                  appConfig={appConfig}
                  currentFilter={window.location.search}
                  setFilter={setFilter}
                  setFilterName={setFilterName}
                />
                <Stack m={3} direction="row" spacing={2}>
                  <PrimaryButton onClick={toggleDrawer(false)}>
                    <Text variantText="body1">Apply</Text>
                  </PrimaryButton>

                  <SecondaryButton onClick={handleOnClearClicked}>
                    <Text variantText="body1">Clear</Text>
                  </SecondaryButton>
                </Stack>
              </Drawer>
            </>
          ) : (
            <Grid item xs={10} xl={8}>
              <FilterComponent
                appConfig={appConfig}
                currentFilter={window.location.search}
                setFilter={setFilter}
                setFilterName={setFilterName}
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <></>
      )}
      <Grid
        container
        padding={1}
        px={"10%"}
        spacing={5}
        alignItems="center"
        justifyContent="center"
        direction="row"
      >
        {!loading ? (
          isError ? (
            <MapNFTsError appConfig={appConfig} />
          ) : searchedNFTs.length === 0 ? (
            <>
              <Grid item xs={12}>
                <Text variantText="h3" style={{ textAlign: "center" }}>
                  {checkForApplicableFilter(filter)
                    ? `Looks like there are no ${
                        appConfig.general.site_noun_plural
                          ? appConfig.general.site_noun_plural
                          : "items"
                      } in this Collection at this moment!`
                    : `No ${
                        appConfig.general.site_noun_plural
                          ? appConfig.general.site_noun_plural
                          : "items"
                      } match this filter criteria.`}
                </Text>
              </Grid>
            </>
          ) : (
            allNFTs.length === 0 && (
              <>
                <Grid item xs={10}>
                  <Box>
                    <Card
                      style={styles.card}
                      sx={{
                        ":hover": {
                          transform: "scale3d(1.05, 1.05, 1)",
                        },
                        ":hover > img.cardImage": {
                          transform: "scale3d(1.05, 1.05, 1)",
                        },
                      }}
                    >
                      <Grow
                        in={true}
                        style={{ transformOrigin: "0 0 0 0" }}
                        {...(true ? { timeout: 3000 } : {})}
                      >
                        <CardContent>
                          <Text
                            variantText="h1"
                            style={{ textAlign: "center" }}
                          >
                            <InfoIcon sx={{ fontSize: 40, color: "info" }} />
                          </Text>
                          <Text
                            variantText="h3"
                            style={{
                              textAlign: "center",
                              textTransform: "none",
                            }}
                          >
                            All collections are currently sold out.
                          </Text>
                          <Text
                            variantText="h6"
                            style={{
                              textAlign: "center",
                              textTransform: "none",
                            }}
                          >
                            Check back later for any new releases.
                          </Text>
                          <RouterLink
                            style={{ textDecoration: "none" }}
                            to={{ pathname: "/explore" }}
                          >
                            <TertiaryButton
                              styleOverride={{
                                textAlign: "right",
                                textTransform: "none",
                                border: "1px solid white",
                              }}
                            >
                              View Collection
                            </TertiaryButton>
                          </RouterLink>
                        </CardContent>
                      </Grow>
                    </Card>
                  </Box>
                </Grid>
              </>
            )
          )
        ) : (
          <></>
        )}
        {!isError && (
          <>
            <Grid item xs={12} alignItems="center" justifyContent="center">
              {/* <MapCardItems nftItemsIn={allNFTs} loading={loading} sdkService={sdkService} appConfig={appConfig} /> */}
              <MapCardItems
                nftItemsIn={searchedNFTs}
                loading={loading}
                sdkService={sdkService}
                appConfig={appConfig}
              />
            </Grid>
            <Grid item xs={12} alignItems="center" justifyContent="center">
              {/* <MapCardItems nftItemsIn={allNFTs} loading={loading} sdkService={sdkService} appConfig={appConfig} /> */}
              <PaginationComp />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
