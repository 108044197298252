// Libraries
import React, { useContext, useState } from "react";

// Hooks & Context
import useIsMobile from "../../../hooks/useIsMobile";
import appConfigContext from "../../../context/appConfig-context";

// MUI
import Grid from "@mui/material/Grid";

// Components
import BackButton from "../../../components/backButton/BackButton";
import ImageShowcase from "./ImageShowcase";
import VideoShowcase from "./VideoShowcase";

interface Props {
  nftDetail: any;
}

/**
 * This component determines which media should be displayed for the NFT.
 *
 * @param {any} nftDetail - The data associated with the NFT.
 * @return {JSX.Element}
 * @constructor
 */
export default function MediaShowcase({ nftDetail }: Props) {
  /** Determines whether the video should be played or not. */
  const [playVideo, setPlayVideo] = useState<boolean>(false);

  const { mobileView } = useIsMobile();
  const appConfig: any = useContext(appConfigContext).appConfig;

  /** Image to be used in case the NFT has no image in its metadata */
  const fallbackImage: string = appConfig.general.nftImageNotFoundImage;

  /** Sets the video to start playing if the NFT has a video to display. */
  function handlePlayVideo() {
    setPlayVideo(!!nftDetail.metadata.video);
  }

  /** Stops playing the video. */
  function handleStopVideo() {
    setPlayVideo(false);
  }

  if (mobileView) {
    return (
      <div>
        <Grid container item justifyContent="left">
          <BackButton appConfig={appConfig} />
        </Grid>
        {!appConfig.nftDetail.lessImageMobileDetailView &&
          nftDetail &&
          (!nftDetail.metadata.video ? (
            <ImageShowcase
              metadata={nftDetail.metadata}
              fallbackImage={fallbackImage}
              onClick={handlePlayVideo}
            />
          ) : (
            <VideoShowcase
              source={nftDetail.metadata.video}
              type="video/mp4"
              onClose={handleStopVideo}
            />
          ))}
      </div>
    );
  } else if (nftDetail) {
    return (
      <div>
        {!nftDetail.metadata.video ? (
          <ImageShowcase
            metadata={nftDetail.metadata}
            fallbackImage={fallbackImage}
            onClick={handlePlayVideo}
          />
        ) : (
          <VideoShowcase
            source={nftDetail.metadata.video}
            type="video/mp4"
            onClose={handleStopVideo}
          />
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}
