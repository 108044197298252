import React from "react";
import { Grid, Avatar, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

interface Props {
  appConfig: any;
}

const styles = {
  overlayBackButton: {
    position: "absolute" as "absolute",
    top: -50,
    color: "white",
  },
};

export default function BackButton({ appConfig }: Props) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        ...styles.overlayBackButton,
        textAlign: "left",
      }}
    >
      <Avatar
        sx={{
          width: 40,
          height: 40,
          background: `${
            appConfig.navItems.backButtonNavIconPaddingColor
              ? appConfig.navItems.backButtonNavIconPaddingColor
              : "rgba(255, 255, 255, 0.1)"
          }`,
          top: "-2vh",
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIosNewRoundedIcon
            sx={{
              fontSize: 25,
              color: `${
                appConfig.navItems.backButtonNavIconColor
                  ? appConfig.navItems.backButtonNavIconColor
                  : "white"
              }`,
            }}
          />
        </IconButton>
      </Avatar>
    </div>
  );
}
