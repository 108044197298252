// Libraries
import React, { useState } from "react";
import Markdown from "markdown-to-jsx";

// MUI
import {
  Box,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
} from "@mui/material";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import CancelIcon from "@mui/icons-material/Cancel";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

// Components
import ReadMoreComponent from "../text/ReadMoreComponent";
import Text from "../text/Text";
import CustomAccordion from "./CustomAccordion";
import ActionBar from "./ActionBar";
import MoreDetailsAccordion from "./MoreDetailsAccordion";
import WineDetailPageHeading from "./wineCategory/WineDetailPageHeading";

// Helpers
import useIsMobile from "../../hooks/useIsMobile";
import { getNFTMetaLocation } from "../../helpers/functions";
import { FanfireSDK } from "fanfire-sdk";
import Tooltip from "@mui/material/Tooltip";

const styles = {
  box: {
    display: "flex" as "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-around",
    top: 0,
  },
  nftImage: {
    height: "100px",
    width: "auto",
  },
  nftImageOpen: {
    height: "90vh",
    width: "auto",
  },
  videoPlayer: {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "50vh",
    filter: "drop-shadow(0px 1.27599px 15.6522px #000000)",
    borderRadius: "10px",
    objectFit: "contain" as "contain",
    display: "flex",
  },
  overlay: {
    position: "absolute" as "absolute",
    top: "5%",
    right: "0",
    color: "white",
  },
};

interface Props {
  appConfig: any;
  nftDetail: any;
  nftDetailListing: any;
  sdkService: FanfireSDK;
  sliceStore: any;
  isReserved: boolean;
  isMarketPlaceNFT: boolean;
  loadingFastProps: boolean;
  listed: boolean;
  setListed: any;
  setNFTDetail: any;
  currency: any;
}

export default function BasicDetails({
                                       appConfig,
                                       nftDetail,
                                       nftDetailListing,
                                       sdkService,
                                       sliceStore,
                                       isReserved,
                                       isMarketPlaceNFT,
                                       loadingFastProps,
                                       listed,
                                       setListed,
                                       setNFTDetail,
                                       currency,
                                     }: Props) {
  const { mobileView } = useIsMobile();
  const [open, setOpen] = useState(false);
  const [playVideo, setPlayVideo] = useState<boolean>(false);

  /** Opens the drawer and sets the video to start playing if the NFT has a video to display. */
  function handleOpen() {
    setOpen(true);
    setPlayVideo(!!nftDetail.metadata.video);
  }

  /** Closes the drawer and stops playing the video. */
  function handleClose() {
    setOpen(false);
    setPlayVideo(false);
  }

  return (
    <Box sx={styles.box}>
      <>
        <Drawer
            variant="temporary"
            open={open}
            onClose={handleClose}
            anchor="bottom"
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: {
                height: "90vh",
                borderRadius: "18px",
              },
            }}
        >
          <DialogTitle
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "0 !important",
              }}
          >
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          {!playVideo ? (
              <Box
                  component="img"
                  sx={{
                    maxHeight: "90%",
                    width: "auto",
                    objectFit: "contain",
                    justifyContent: "center",
                    pb: 10,
                  }}
                  alt="vaulted image"
                  src={
                    nftDetail.metadata
                        ? nftDetail.metadata.image
                        : appConfig.general.nftImageNotFoundImage
                  }
              />
          ) : (
              <Box display="flex" maxHeight="90%" m="10px">
                <video
                    style={styles.videoPlayer}
                    loop
                    autoPlay
                    muted={true}
                    playsInline
                    controls
                >
                  <source
                      src={nftDetail.metadata.video}
                      type="video/mp4"
                  />
                </video>
              </Box>
          )}
        </Drawer>
        {appConfig.general.appCategory &&
        appConfig.general.appCategory.toLowerCase() === "wine" ? (
            <Grid container>
              <Grid item xs={8}>
                <WineDetailPageHeading nftDetail={nftDetail} />
              </Grid>
              {mobileView && appConfig.nftDetail.lessImageMobileDetailView && (
                  <Grid item xs={4}>
                    <div style={styles.overlay}>
                      <IconButton onClick={handleOpen}>
                        <ZoomInIcon sx={{ color: "black" }} />
                      </IconButton>
                    </div>
                    <Tooltip title="View" disableTouchListener={false} enterTouchDelay={100}>
                      <img
                          style={styles.nftImage}
                          src={
                            nftDetail.metadata
                                ? nftDetail.metadata.image
                                : appConfig.general.nftImageNotFoundImage
                          }
                          alt="NFT"
                          onClick={handleOpen}
                      />
                    </Tooltip>
                  </Grid>
              )}
            </Grid>
        ) : (
            <>
              {nftDetail.metadata && nftDetail.metadata.name && (
                  <Text variantText="h1">{nftDetail.metadata.name} </Text>
              )}
              {nftDetail.name && (
                  <Text variantText="h6" style={{ fontWeight: "bold" }}>
                    {nftDetail.name}
                  </Text>
              )}
            </>
        )}
        {appConfig.nftDetail.generalDescription && isMarketPlaceNFT && (
            <Box pt={1}>
              <Text variantText="body1" style={{ textTransform: "none" }}>
                {appConfig.nftDetail.generalDescription}
              </Text>
            </Box>
        )}
        <Box pt={2}>
          {nftDetail.metadata && nftDetail.metadata.description && (
              <ReadMoreComponent>
                <Markdown children={nftDetail.metadata.description} />
              </ReadMoreComponent>
          )}
        </Box>
        {getNFTMetaLocation(nftDetail.metadata) && (
            <Box pt={2}>
              <Text
                  variantText="body1"
                  style={{ textAlign: "left", display: "flex" }}
              >
                <LocationOnTwoToneIcon fontSize="small" />
                {getNFTMetaLocation(nftDetail.metadata)}
              </Text>
            </Box>
        )}
        <CustomAccordion
            appConfig={appConfig}
            isMarketPlaceNFT={isMarketPlaceNFT}
        />
        <MoreDetailsAccordion nftDetail={nftDetail} />
        <ActionBar
            nftDetail={nftDetail}
            nftDetailListing={nftDetailListing}
            appConfig={appConfig}
            sdkService={sdkService}
            isMarketPlaceNFT={isMarketPlaceNFT}
            isReserved={isReserved}
            listed={listed}
            setNFTDetail={setNFTDetail}
            setListed={setListed}
            sliceStore={sliceStore}
            currency={currency}
            loadingFastProps={loadingFastProps}
        />
        {nftDetail.metadata && nftDetail.metadata.license_url && (
          <Text variantText="body1">
            <a
                href={nftDetail.metadata.license_url}
                target="_blank"
                style={{
                  textDecoration: "underline",
                  color: "inherit",
                }}
            >
              Rights assignment agreement
            </a>
          </Text>
        )}
      </>
    </Box>
  );
}
