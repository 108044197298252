import * as React from "react";

//MUI
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

//Components
import SecondaryButton from "../buttons/SecondaryButton";
import Text from "../text/Text";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  message: string;
  type: string; // success / error
  handleClose: () => void;
}

/*
=================================================================
Component used to display an information message for payment success and failure.
This works in combination with snackbar alert and is shown through using an extra argument within the snackbarAlert hook.
see: apps/fanfire-components/src/context/snackAlert-context.tsx
=================================================================
*/

export default function AlertDialogSlide({
  open, // Wether dialog is open
  message, // Message displayed within dialog
  type, // Type (success / error) - shows the diff icons etc
  handleClose, // Closes the Dialog popup
}: Props) {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Text variantText="h1" style={{ textAlign: "center" }}>
            {type == "error" ? (
              <ErrorOutlineIcon sx={{ fontSize: 40, color: "red" }} />
            ) : (
              <CheckCircleOutlineIcon sx={{ fontSize: 40, color: "green" }} />
            )}
          </Text>
          <Text variantText="h3">
            {type == "error" ? "Payment Failed" : "Payment Success!"}
          </Text>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleClose}>
            <Text variantText="h6">Close</Text>
          </SecondaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
