// Libraries
import { ethers } from "ethers";

// Helpers
import { sleep } from "../../../helpers/functions";
import { networkMap } from "../../../helpers/metamaskNetworks";

/**
 * Function that handles google (web2) sign up.
 * @param appConfig
 * @param email
 * @param password
 * @param dispatch
 * @param sdkService
 * @param sliceStore
 * @param attendance
 * @param attendanceData
 * @param setLoading
 * @param setOpen
 */
const emailAndPasswordSignUp = async (
  appConfig: any,
  email: string,
  password: string,
  dispatch: any,
  sdkService: any,
  sliceStore: any,
  attendance?: boolean,
  attendanceData?: {
    chain: string;
    nftAddress: string;
  },
  setLoading?: ({}: boolean) => void,
  setOpen?: ({}: boolean) => void
) => {
  try {
    await dispatch(
      sliceStore.emailAndPasswordSignUp({
        email: email,
        password: password,
        ffsdk: sdkService,
        app: appConfig.general.app ?? "fanfire",
      })
    );
    if (attendance && attendanceData && setOpen) {
      airdropToken(sdkService, attendanceData, setOpen, setLoading);
    }
  } catch (err) {
    console.log("error");
    if (setLoading) setLoading(false);
    console.error(err);
  }
};

/**
 * Function that handles google (web2) login.
 * @param appConfig
 * @param email
 * @param password
 * @param dispatch
 * @param sdkService
 * @param sliceStore
 * @param attendance
 * @param attendanceData
 * @param setLoading
 * @param setOpen
 */
const emailAndPasswordLogIn = async (
  appConfig: any,
  email: string,
  password: string,
  dispatch: any,
  sdkService: any,
  sliceStore: any,
  attendance?: boolean,
  attendanceData?: {
    chain: string;
    nftAddress: string;
  },
  setLoading?: ({}: boolean) => void,
  setOpen?: ({}: boolean) => void
) => {
  try {
    await dispatch(
      sliceStore.emailAndPasswordSignIn({
        email: email,
        password: password,
        ffsdk: sdkService,
        app: appConfig.general.app ?? "fanfire",
      })
    );
    if (attendance && attendanceData && setOpen) {
      airdropToken(sdkService, attendanceData, setOpen, setLoading);
    }
  } catch (err) {
    console.log("error");
    if (setLoading) setLoading(false);
    console.error(err);
  }
};

/**
 * Function that handles google (web2) login.
 *
 * @param isMobile
 * @param dispatch
 * @param sliceStore
 * @param sdkService
 * @param setLoading
 */
const googleLogIn = async (
  isMobile: boolean,
  dispatch: any,
  sliceStore: any,
  sdkService: any,
  setLoading?: ({}: boolean) => void
) => {
  console.log("logging in with google");
  try {
    console.log(sdkService);
    await dispatch(
      sliceStore.signInWithGoogle({ isMobile: isMobile, ffsdk: sdkService })
    );
  } catch (err) {
    console.log("error");
    if (setLoading) setLoading(false);
    console.error(err);
  }
};

/**
 * Function that handles metamask (web3) login.
 *
 * @param setMetaMaskGetOpen
 * @param dispatch
 * @param sliceStore
 * @param sdkService
 * @param setLoading
 */
const metaMaskLogIn = async (
  setMetaMaskGetOpen: ({}: boolean) => void,
  dispatch: any,
  sliceStore: any,
  sdkService: any,
  setLoading?: ({}: boolean) => void
) => {
  console.log("Logging in with metamask");
  try {
    if (!window.ethereum) {
      //User does not have metamask installed
      setMetaMaskGetOpen(true);
      return;
    }
    const signer: any = await dispatch(sliceStore.signInWithMetaMask());
    await sdkService.enableWeb3(signer.payload.user, sdkService.fanFireAPI); // this enables web3 over whole app
    const chain = process.env.REACT_APP_FANFIRE_CHAIN ?? "polygon";
    const chainText = chain === "mumbai" ? "mumbai" : "polygon";
    const chainId = chainText === "mumbai" ? 80001 : 137;
    try {
      if (Number(window.ethereum.networkVersion) !== chainId) {
        // User not on correct chain
        console.log("Switching Network to: " + chain + `(${chainId})`);
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: ethers.utils.hexValue(chainId) }], // chainId must be in hexadecimal numbers
        });
        console.log("done");
      } else {
        console.log("User Already on right chain: " + chain + `(${chainId})`);
      }
    } catch (error: any) {
      if (error.code === 4902) {
        // chain not added on metamask
        console.log("chain not added to metamask");
        try {
          console.log("Attempting to add network");
          // add chain
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [networkMap[chainText]],
          });
          console.log("Added");
          console.log("Attempting to switch to chain");
          //switch chain
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: ethers.utils.hexValue(chainId) }], // chainId must be in hexadecimal numbers
          });
          console.log("done");
        } catch (addError) {
          console.error(addError);
        }
      }
    }
    console.log("Authenticated: ", signer.payload.user);
  } catch (err) {
    console.log("error");
    if (setLoading) setLoading(false);
    console.error(err);
  }
};

/**
 *  @summary Function that handles air drop attendance tokens.
 *
 *  When a user logs in or signs up with an specific link, we use the params to
 *  check if a user should get a free token.
 *
 * @param sdkService
 * @param attendanceData
 * @param setOpen
 * @param setLoading
 */
const airdropToken = async (
  sdkService: any,
  attendanceData: {
    chain: string;
    nftAddress: string;
  },
  setOpen: ({}: boolean) => void,
  setLoading?: ({}: boolean) => void | undefined
) => {
  /*

 */
  console.log("This is an attendance Login / sign up");
  let wallet = "";
  try {
    wallet = sdkService.wallet.walletAddress;
  } catch (error: any) {
    // If it is the first time a user is registering with the APP - it takes a short while to populate
    // the DB. This just does a quick retry (2 times) to cater for any delay in getting the wallet address
    console.error(error);
    if (error.code === 401) {
      try {
        sleep(1000);
        console.log("retry: 1");
        wallet = sdkService.wallet.walletAddress;
      } catch (err) {
        console.error(err);
        sleep(1000);
        console.log("retry: 2");
        wallet = sdkService.wallet.walletAddress;
      }
    }
  }

  console.log("airdrop token to: " + wallet);
  console.log("airdrop chain: " + attendanceData?.chain);
  console.log("airdrop nftAddress: " + attendanceData?.nftAddress);
  if (attendanceData?.nftAddress !== "") {
    console.log("air dropping token...");
    const airdrop = await sdkService.marketplace.airdropNFT(
      wallet,
      attendanceData?.nftAddress
    );
    console.log(airdrop);
    if (setLoading) setLoading(false);
  } else {
    if (setLoading) setLoading(false);
    console.log("nft address is not included");
  }
  setOpen(false);
};

export {
  airdropToken,
  googleLogIn,
  metaMaskLogIn,
  emailAndPasswordSignUp,
  emailAndPasswordLogIn,
};
