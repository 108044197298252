import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import initFirebase from "../../services/firebase";
const Firebase = initFirebase(
  process.env.REACT_APP_FANFIRE_DEPLOYMENT_ENV ?? "staging"
);
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  browserLocalPersistence,
  sendEmailVerification,
  UserCredential,
  signInWithEmailLink,
  isSignInWithEmailLink,
} from "firebase/auth";
import axios from "axios";
import { retry, sleep } from "../../helpers/functions";

const auth = getAuth(Firebase);
interface initialStateLayout {
  error: null | undefined | string;
  user: {};
  auth: {
    isWeb2: boolean;
    isWeb3: boolean;
  };
  status: String;
  creatingWallet: string;
  sdk: string;
}

const initialState: initialStateLayout = {
  user: {},
  auth: {
    isWeb2: false,
    isWeb3: false,
  },
  status: "idle",
  creatingWallet: "idle",
  error: null,
  sdk: "idle",
};

const checkWalletExist = async (email: string) => {
  try {
    const data = await retry(
      async (email: string) => {
        return await axios.get(
          `${process.env.REACT_APP_FANFIRE_API_BASE_URL}/wallets/has-wallet/${email}`
        );
      },
      [email],
      15,
      1,
      (result: any) => {
        if (result.data === true) {
          return true;
        } else {
          return false;
        }
      },
      1000
    );
  } catch (error) {
    throw new Error(
      "Your account has been created, but there has been an error your own web3 wallet! Please retry logging in with this email."
    );
  }
};

export const EmailLinkSignIn = createAsyncThunk(
  "user/EmailLinkSignIn",
  async (params: { email: string; reset?: boolean }) => {
    try {
      if (params.reset) {
        const resPasswordEmail = await sendPasswordResetEmail(
          auth,
          decodeURI(params.email),
          {
            url: process.env.REACT_APP_FANFIRE_APP_BASE_URL ?? "",
          }
        );
      } else if (isSignInWithEmailLink(auth, window.location.href)) {
        console.log("Is sign in with email link");
        const resSignIn = await signInWithEmailLink(
          auth,
          params.email,
          window.location.href
        );
        const resPasswordEmail = await sendPasswordResetEmail(
          auth,
          decodeURI(params.email),
          {
            url: process.env.REACT_APP_FANFIRE_APP_BASE_URL ?? "",
          }
        );
        console.log("send password done");
      } else {
        throw new Error("Invalid Link");
      }
    } catch (error: any) {
      console.log("error with registration");
      console.error(error);
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === "auth/invalid-email") {
        throw new Error("Invalid Email");
      } else if (errorCode === "auth/user-not-found") {
        throw new Error("User Not Found");
      } else if (errorCode === "auth/too-many-requests") {
        throw new Error("Too many failed attempts. Please try again later.");
      } else if (errorCode === "auth/invalid-action-code") {
        throw new Error("Sign up link has already been used.");
      } else if (errorCode === "auth/expired-action-code") {
        throw new Error("Sign up link has expired.");
      } else {
        throw new Error(errorMessage);
      }
    }
  }
);

export const signInWithMetaMask = createAsyncThunk(
  "user/MetaMaskSignIn",
  async ({}, thunkAPI) => {
    try {
      await thunkAPI.dispatch(signOutWithGoogle());
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const user = signer;
      return { user: user };
    } catch (err: any) {
      console.error(err);
      if (err.code === 4001) {
        throw new Error("Operation Rejected");
      }
    }
  }
);

export const signInWithGoogle = createAsyncThunk(
  "user/googleSignIn",
  async (params: { isMobile: boolean; ffsdk: any }, thunkAPI) => {
    const provider = new GoogleAuthProvider();
    try {
      let res;
      res = await signInWithPopup(auth, provider);
      // if (!params.isMobile) {
      //   console.log("Signing in with redirect");
      //   res = await signInWithPopup(auth, provider);
      // } else {
      //   console.log("Signing in with popup");
      //   res = await signInWithRedirect(auth, provider);
      // }
      thunkAPI.dispatch(setAuthLoad());

      await checkWalletExist(res.user.email!);

      auth.setPersistence(browserLocalPersistence);
      await thunkAPI.dispatch(enableWeb2ForApp(params.ffsdk));
      return { user: res.user };
    } catch (err: any) {
      const errorCode = err.code;
      const errorMessage = err.message;

      thunkAPI.dispatch(signOutWithGoogle());

      if (errorCode === "auth/invalid-email") {
        throw new Error("Invalid Email");
      } else if (errorCode === "auth/user-not-found") {
        throw new Error("User Not Found");
      } else if (errorCode === "auth/wrong-password") {
        throw new Error("Wrong Password");
      } else {
        throw new Error(errorMessage);
      }
    }
  }
);

export const emailAndPasswordSignUp = createAsyncThunk(
  "user/emailAndPasswordSignUp",
  async (
    params: { email: string; password: string; ffsdk: any; app: any },
    thunkAPI
  ) => {
    console.log("Sign Up with Email and Password");
    try {
      const res: UserCredential = await createUserWithEmailAndPassword(
        auth,
        params.email,
        params.password
      );
      thunkAPI.dispatch(setAuthLoad());
      // await checkWalletExist(res.user.email!); // not needed as gift only happens after verify, which is the next step
      params.ffsdk.mailer.verifyEmail(
        params.email,
        process.env.REACT_APP_FANFIRE_APP_BASE_URL ?? "",
        params.app
      );
      // const resSendEmial = await sendEmailVerification(res.user, {
      //   url: process.env.REACT_APP_FANFIRE_APP_BASE_URL ?? "",
      // });
      // console.log(resSendEmial);
      console.log("Verification sent to user: ", res.user.email);
      return { user: { signup: true } };
    } catch (err: any) {
      const errorCode = err.code;
      const errorMessage = err.message;
      console.log(errorMessage);
      if (errorCode === "auth/invalid-email") {
        throw new Error("Invalid Email");
      } else if (errorCode === "auth/too-many-requests") {
        throw new Error("Too many failed attempts. Please try again later.");
      } else if (errorCode === "auth/email-already-in-use") {
        throw new Error("Email already exists. Please try to login instead.");
      } else {
        throw new Error(errorMessage);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (params: { email: string }) => {
    console.log("Reset Password");

    sendPasswordResetEmail(auth, params.email, {
      url: process.env.REACT_APP_FANFIRE_APP_BASE_URL ?? "",
    })
      .then(() => {
        console.log("Password Reset Email Sent");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === "auth/invalid-email") {
          throw new Error("Invalid Email");
        } else if (errorCode === "auth/user-not-found") {
          throw new Error("User Not Found");
        } else if (errorCode === "auth/too-many-requests") {
          throw new Error("Too many failed attempts. Please try again later.");
        } else {
          throw new Error(errorMessage);
        }
      });
  }
);

export const emailAndPasswordSignIn = createAsyncThunk(
  "user/emailAndPasswordSignIn",
  async (
    params: { email: string; password: string; ffsdk: any; app: string },
    thunkAPI
  ) => {
    console.log("Sign in with Email and Password");
    try {
      const res = await signInWithEmailAndPassword(
        auth,
        params.email,
        params.password
      );

      if (!res.user.emailVerified) {
        params.ffsdk.mailer.verifyEmail(
          params.email,
          process.env.REACT_APP_FANFIRE_APP_BASE_URL ?? "",
          params.app
        );
        // sendEmailVerification(res.user, {
        //   url: process.env.REACT_APP_FANFIRE_APP_BASE_URL ?? "",
        // }).then(() => {
        //   console.log("Verification sent to user: ", res.user.email);
        // });
        thunkAPI.dispatch(signOutWithGoogle());
        throw new Error("notVerified");
      } else {
        auth.setPersistence(browserLocalPersistence);
        await thunkAPI.dispatch(enableWeb2ForApp(params.ffsdk));
        return { user: res.user };
      }
    } catch (err: any) {
      thunkAPI.dispatch(signOutWithGoogle());
      const errorCode = err.code;
      const errorMessage = err.message;
      console.log(errorCode);

      if (err.message === "notVerified") {
        throw new Error(
          "Your email has not been verified yet. Please check your emails for a verification link."
        );
      }

      if (
        errorCode === "auth/invalid-email" ||
        errorCode === "auth/user-not-found" ||
        errorCode === "auth/wrong-password"
      ) {
        throw new Error("Incorrect email and password combination");
      } else if (errorCode === "auth/too-many-requests") {
        throw new Error("Too many failed attempts. Please try again later.");
      } else {
        throw new Error(errorMessage);
      }
    }
  }
);

export const enableWeb2ForApp = createAsyncThunk(
  "user/enableWeb2ForApp",
  async (ffsdk: any) => {
    try {
      console.log("ACTIVATING WEB 2 FOR THE APP");
      console.log(ffsdk);
      await ffsdk.enableWeb2(auth);
    } catch (error) {
      console.log("error in enable web2");
      console.error(error);
      // throw new Error("Error enabling SDK");
    }
  }
);

export const setAuthLoad = createAsyncThunk("user/setAuthLoad", async () => {
  console.log("setting redux auth to reset");
  return true;
});

export const persistFireBaseAuth = createAsyncThunk(
  "user/googleSignInPersist",
  async (param: { ffsdk: any }, thunkAPI) => {
    await sleep(2000);
    try {
      const user: any = await thunkAPI.getState();
      if (user && user.user && user.user.creatingWallet !== "creating") {
        await thunkAPI.dispatch(enableWeb2ForApp(param.ffsdk));
      }
      if (auth && auth.currentUser && !auth.currentUser?.emailVerified) {
        // params.ffsdk.mailer.verifyEmail(
        //   auth.currentUser.email,
        //   process.env.REACT_APP_FANFIRE_APP_BASE_URL ?? "",
        //   params.app
        // );
        thunkAPI.dispatch(signOutWithGoogle());
        await sendEmailVerification(auth.currentUser, {
          url: process.env.REACT_APP_FANFIRE_APP_BASE_URL ?? "",
        }).then(() => {
          console.log(
            "Verification sent to user: ",
            auth.currentUser ? auth.currentUser.email : ""
          );
        });
        await thunkAPI.dispatch(signOutWithGoogle());
        throw new Error("notVerified");
      } else {
        return { user: auth.currentUser };
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const signOutWithGoogle = createAsyncThunk(
  "user/googleSignOut",
  async (_, thunkAPI) => {
    console.log("Signing Out Of google");
    try {
      await signOut(auth);
      thunkAPI.dispatch({ type: "app/resetAppState" });
      return;
    } catch (err: any) {
      const errorCode = err.code;
      const errorMessage = err.message;
      throw new Error(errorMessage);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(enableWeb2ForApp.pending, (state, action) => {
        state.sdk = "loading";
      })
      .addCase(enableWeb2ForApp.fulfilled, (state, action) => {
        state.sdk = "succeeded";
        state.auth.isWeb2 = true;
        state.auth.isWeb3 = false;
      })
      .addCase(enableWeb2ForApp.rejected, (state, action) => {
        state.sdk = "failed";
        state.error = action.error.message;
      })
      .addCase(setAuthLoad.fulfilled, (state, action) => {
        state.status = "loading";
        state.user = initialState.user;
        state.auth.isWeb2 = false;
        state.auth.isWeb3 = false;
        state.creatingWallet = "creating";
      })
      .addCase(signInWithGoogle.pending, (state, action) => {
        state.status = "loading";
        state.creatingWallet = "creating";
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload.user;
        state.auth.isWeb2 = true;
        state.auth.isWeb3 = false;
        state.creatingWallet = "success";
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.creatingWallet = "idle";
      })
      .addCase(emailAndPasswordSignIn.pending, (state, action) => {
        state.status = "loading";
        state.creatingWallet = "idle";
      })
      .addCase(emailAndPasswordSignIn.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload.user;
        state.auth.isWeb2 = true;
        state.auth.isWeb3 = false;
        state.creatingWallet = "idle";
        state.creatingWallet = "idle";
      })
      .addCase(emailAndPasswordSignIn.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.creatingWallet = "idle";
      })
      .addCase(EmailLinkSignIn.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.creatingWallet = "idle";
      })
      .addCase(EmailLinkSignIn.pending, (state, action) => {
        state.status = "loading";
        state.creatingWallet = "idle";
      })
      .addCase(EmailLinkSignIn.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.creatingWallet = "idle";
      })
      .addCase(emailAndPasswordSignUp.pending, (state, action) => {
        state.status = "loading";
        state.creatingWallet = "idle";
      })
      .addCase(emailAndPasswordSignUp.fulfilled, (state, action) => {
        state.status = "signedUp";
        state.user = {};
        state.auth.isWeb2 = false;
        state.auth.isWeb3 = false;
        state.creatingWallet = "idle";
      })
      .addCase(emailAndPasswordSignUp.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.creatingWallet = "idle";
      })
      .addCase(persistFireBaseAuth.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(persistFireBaseAuth.fulfilled, (state, action) => {
        if (state.creatingWallet === "creating") {
          console.log("not persisting, wallet busy being created");
        } else {
          state.status = "succeeded";
          state.user = action?.payload?.user ?? {};
          state.auth.isWeb2 = true;
          state.auth.isWeb3 = false;
        }
      })
      .addCase(persistFireBaseAuth.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.creatingWallet = "idle";
      })
      .addCase(signOutWithGoogle.pending, (state, action) => {
        state.status = "loading";
        state.creatingWallet = "idle";
      })
      .addCase(signOutWithGoogle.fulfilled, (state, action) => {
        state.user = initialState.user;
        state.status = "idle";
        state.creatingWallet = "idle";
      })
      .addCase(signOutWithGoogle.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.creatingWallet = "idle";
      })
      .addCase(signInWithMetaMask.pending, (state, action) => {
        state.status = "loading";
        state.creatingWallet = "idle";
      })
      .addCase(signInWithMetaMask.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action?.payload?.user ?? {};
        state.auth.isWeb3 = true;
        state.auth.isWeb2 = false;
        state.creatingWallet = "idle";
      })
      .addCase(signInWithMetaMask.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.creatingWallet = "idle";
      });
  },
});

const userReducer = userSlice.reducer;

export default userReducer;
