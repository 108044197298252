import React from 'react'
import Button from '@mui/material/Button'

interface Props {
  onClick?: any; // update any
  disabled?: boolean;
  type?: any; // update any
  children: React.ReactNode;
}

export default function PrimaryButton({ children, type, onClick, disabled=false }: Props) {
  return (
        <Button 
          className="primary"
          sx={{ "&:hover": { transform: "scale3d(1.05, 1.05, 1)"} }} 
          fullWidth 
          type={type} 
          onClick={onClick} 
          disabled={disabled}
          variant='contained'
        >
          {children}
        </Button>
    
  )
}
