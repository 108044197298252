import { Box } from "@mui/material";
import { FanfireSDK } from "fanfire-sdk";
import React from "react";
import useUnlist from "../../hooks/sdkMethods/useUnlist";
import PrimaryButton from "../buttons/PrimaryButton";
import BasicLoader from "../loaders/BasicLoader";
import Text from "../text/Text";

interface Props {
  sdkService: FanfireSDK;
  nftAddress: string;
  tokenId: string;
  appConfig: any;
  amount: number;
}

export default function UnlistNFT({
  sdkService,
  nftAddress,
  tokenId,
  appConfig,
  amount,
}: Props) {
  const { unListLoading, unListText, unlistError, sendUnlist } = useUnlist();

  const handleUnlist = async () => {
    sendUnlist({
      nftAddress: nftAddress,
      tokenId: tokenId,
      sellerAddress: sdkService.wallet.walletAddress ?? "",
      sdkService: sdkService,
      appConfig: appConfig,
      amount: amount,
    });
  };

  return (
    <Box display="flex" justifyContent="center" textAlign="center" p={1}>
      {unListLoading && !unlistError ? (
        <BasicLoader size={15} text={unListText} />
      ) : (
        <PrimaryButton onClick={handleUnlist}>
          <Text variantText="body1">Unlist</Text>
        </PrimaryButton>
      )}
    </Box>
  );
}
