"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Marketplace = void 0;
var ethers_1 = require("ethers");
var nft_1 = require("../NFTs/nft");
var vault_1 = require("../Vault/vault");
var moralisService_1 = require("../services/moralisService");
var Marketplace = /** @class */ (function () {
    function Marketplace(api, chain) {
        var _this = this;
        // Method that is called when web3 is enabled in the FFSDK
        this.setWeb3 = function (web3Provider, web3Service) {
            _this.web3 = web3Provider;
            _this.web3Service = web3Service;
        };
        // Method that is called when web2 is enabled in the FFSDK
        this.setWeb2 = function (firebaseAuth) {
            _this.firebaseAuth = firebaseAuth;
        };
        this.init = function (marketplaceContractAddress) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.marketplaceContractAddress = marketplaceContractAddress;
                return [2 /*return*/];
            });
        }); };
        this.isListed = function (nftAddress, tokenId, sellerAddress) { return __awaiter(_this, void 0, void 0, function () {
            var res, listings, listing, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!this.marketplaceContractAddress)
                            throw Error("Marketplace contract must be initialized with an address.");
                        if (!sellerAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fanFireAPI.isListed(this.marketplaceContractAddress, nftAddress, tokenId, sellerAddress, this.chain)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.value];
                    case 2: return [4 /*yield*/, this.getAllListings([nftAddress])];
                    case 3:
                        listings = _a.sent();
                        if (!listings)
                            throw Error("No listings in marketplace");
                        listing = listings.find(function (l) { return l.nftAddress.toLowerCase() === nftAddress.toLowerCase() && l.tokenId == tokenId; });
                        if (listing)
                            return [2 /*return*/, true];
                        return [2 /*return*/, false];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        throw Error("isListed Error: ".concat(err_1));
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        this.getLikedNFTs = function (walletAddress) { return __awaiter(_this, void 0, void 0, function () {
            var _likedNFTs, likedDBNFTs, err_2;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fanFireAPI.getLikedNFTs(walletAddress)];
                    case 1:
                        _likedNFTs = _b.sent();
                        likedDBNFTs = (_a = _likedNFTs.value) !== null && _a !== void 0 ? _a : [];
                        return [2 /*return*/, likedDBNFTs];
                    case 2:
                        err_2 = _b.sent();
                        console.error(err_2);
                        throw Error("getLikedNFTs: ".concat(err_2));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        /**
         * This function must be used to get the database database details of all
         * NFTs in a list of NFT contract addresses
         * @param {[{string, string}]} addresses
         * @param {[NFTFilter]} filters These filters filter on the blockchain metadata of the NFT
         * @param {[fanfireFilters]} fanfireFilters These filters filter on the fanfire Metadata of the NFT
         * @return {[NftVM]}
         */
        this.getNftDetails = function (addresses, filters, fanfireFilters, skip, limit) { return __awaiter(_this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fanFireAPI.getContractNFTs(addresses, this.chain, filters, fanfireFilters, skip, limit)];
                    case 1: 
                    // const proms = nftContractAddresses.map((add) => this.fanFireAPI.getContractNFTs(add.toLowerCase(), this.chain, filters, fanfireFilters))
                    // const result: NftVM[] = []
                    // const res = await Promise.all(proms)
                    // res.forEach((nftArr) => {
                    //     result.push(...nftArr)
                    // })
                    return [2 /*return*/, _a.sent()];
                    case 2:
                        err_3 = _a.sent();
                        throw Error("getNftDetails SDK Error: ".concat(err_3));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getExploreDetails = function (addresses, filters, fanfireFilters, skip, limit) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fanFireAPI.getNftCollection(addresses, this.chain, filters, fanfireFilters, skip, limit)];
                    case 1:
                        res = _a.sent();
                        console.log(res);
                        return [2 /*return*/, res];
                    case 2:
                        err_4 = _a.sent();
                        throw Error("getNftDetails SDK Error: ".concat(err_4));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        /**
         * This function must be used to get the database database details
         * of a specific NFT. Will typicall be used on the NFT Detail view
         * @param {string} nftContractAddress
         * @param {string} tokenId
         * @param {string} walletAddress?
         * @return {NftVM}
         */
        this.getNftDetail = function (nftContractAddress, tokenId, walletAddress) { return __awaiter(_this, void 0, void 0, function () {
            var nftDetail, ownedAmount, err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.fanFireAPI.getSpecificNFT(nftContractAddress, tokenId, this.chain)];
                    case 1:
                        nftDetail = _a.sent();
                        if (!walletAddress) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.fanFireAPI.getNFTOwner(nftContractAddress.toLowerCase(), tokenId, walletAddress.toLowerCase(), this.chain, nftDetail.tokenType)];
                    case 2:
                        ownedAmount = _a.sent();
                        if (ownedAmount.isSuccess) {
                            return [2 /*return*/, __assign(__assign({}, nftDetail), ownedAmount.value)];
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/, nftDetail];
                    case 4:
                        err_5 = _a.sent();
                        throw Error("getNftDetail SDK Error: ".concat(err_5));
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        /**
         * This function must be used to get all the listings on the explore page
         * @param {[string]} nftContractAddresses is a list of the supported nftAddresses
         * @return {[MarketplaceListing]}
         */
        this.getNftListings = function (nftContractAddresses) { return __awaiter(_this, void 0, void 0, function () {
            var err_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.getAllListings(nftContractAddresses)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        err_6 = _a.sent();
                        throw Error("getNftListings Error: ".concat(err_6));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getMarketplaceNfts = function (nftContractAddresses, filters, fanfireFilters, skip, limit) { return __awaiter(_this, void 0, void 0, function () {
            var listings, uniqueNftAddresses, mappedAddress, nftDetails, mergedListings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getNftListings(nftContractAddresses)];
                    case 1:
                        listings = _a.sent();
                        uniqueNftAddresses = __spreadArray([], __read(new Set(listings.map(function (l) { return l.nftAddress; }))), false);
                        mappedAddress = uniqueNftAddresses.map(function (addr) {
                            var nfts = listings.filter(function (l) { return l.nftAddress.toLowerCase() === addr.toLowerCase(); });
                            var tokenIds = nfts.map(function (nft) { return nft.tokenId; });
                            return { nftAddress: addr, tokenIds: tokenIds };
                        });
                        if (!mappedAddress.length) {
                            return [2 /*return*/, { listings: [], count: 0 }];
                        }
                        return [4 /*yield*/, this.getNftDetails(mappedAddress, filters, fanfireFilters, skip, limit)];
                    case 2:
                        nftDetails = _a.sent();
                        mergedListings = [];
                        listings.forEach(function (listing) {
                            var nftDetail = nftDetails.data.find(function (deet) { return deet.tokenId === listing.tokenId && deet.contractAddress.toLowerCase() === listing.nftAddress.toLowerCase(); });
                            if (nftDetail) {
                                var detailedListing = __assign(__assign({}, listing), nftDetail);
                                mergedListings.push(detailedListing);
                            }
                        });
                        return [2 /*return*/, { listings: mergedListings, count: nftDetails.count }];
                }
            });
        }); };
        /**
         * This function must be used to get a specific listing of an NFT
         * It will typically be used on the NFT Detail view
         * @param {string} nftAddress
         * @param {string} tokenId
         * @param {string?} callerAddress the wallet address of the logged in user
         * @return {MarketplaceListing}
         */
        this.getNftListing = function (nftAddress, tokenId, callerAddress) { return __awaiter(_this, void 0, void 0, function () {
            var res, listings, walletNfts, listing, walletListing, ownedListing, fakeListing, listings, listing, err_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        res = void 0;
                        if (!callerAddress) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([this.getAllListings([nftAddress]), this.moralisAPI.getWalletNFTs(callerAddress, this.chain)])];
                    case 1:
                        res = _a.sent();
                        listings = res[0];
                        walletNfts = res[1];
                        listing = listings.find(function (l) { return l.nftAddress.toLowerCase() === nftAddress.toLowerCase() && tokenId === l.tokenId; });
                        // there aren't any listings of this nft so we c
                        if (!listing) {
                            listing = {
                                nftAddress: nftAddress,
                                tokenId: tokenId,
                                listings: []
                            };
                        }
                        walletListing = walletNfts.find(function (nft) { return nft.token_address.toLowerCase() === nftAddress.toLowerCase() && nft.token_id === tokenId; });
                        // the caller does not own this nft so we just return the listings
                        if (!walletListing)
                            return [2 /*return*/, __assign(__assign({}, listing), { ownedAmount: '0' })];
                        ownedListing = listing.listings.find(function (l) { return l.sellerAddress.toLowerCase() === callerAddress.toLowerCase(); });
                        if (ownedListing) {
                            // caller has listed his nft. No update needed
                            return [2 /*return*/, __assign(__assign({}, listing), { ownedAmount: walletListing.amount })];
                        }
                        else {
                            fakeListing = {
                                amount: "0",
                                isReserved: false,
                                listingPointer: "0",
                                nftAddress: nftAddress,
                                nftType: "0",
                                paymentToken: "0x",
                                price: 0,
                                reservedUntil: "0",
                                reserveeAddress: "0x0000000000000000000000000000000000000000",
                                sellerAddress: callerAddress,
                                tokenId: tokenId,
                            };
                            listing.listings.push(fakeListing);
                            return [2 /*return*/, __assign(__assign({}, listing), { ownedAmount: walletListing.amount })];
                        }
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.getAllListings([nftAddress])];
                    case 3:
                        listings = _a.sent();
                        listing = listings.find(function (l) { return l.nftAddress.toLowerCase() === nftAddress.toLowerCase() && tokenId === l.tokenId; });
                        if (!listing) {
                            listing = {
                                nftAddress: nftAddress,
                                tokenId: tokenId,
                                listings: []
                            };
                        }
                        return [2 /*return*/, listing];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_7 = _a.sent();
                        throw Error("getNftListing Error: ".concat(err_7));
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        // getMarketplaceCollectionNFTs = async (
        //   nftContractAddress: string,
        //   filters?: Array<NFTFilter>,
        //   fanfireFilters?: Array<NFTFilter>
        // ): Promise<Array<any>> => {
        //   try {
        //     const listings = await this.getAllListings(
        //       [nftContractAddress],
        //       filters,
        //       fanfireFilters
        //     );
        //     const nfts = await this.nftSDK.getNFTCollection(
        //       nftContractAddress,
        //       filters,
        //       fanfireFilters
        //     );
        //
        //     const returnNFTs = nfts.map((nft) => {
        //       if (listings.length > 0) {
        //         const listing = listings.map((l) => {
        //           return (
        //             l.contractAddress.toLowerCase() ===
        //               nft.contractAddress.toLowerCase() && l.tokenId === nft.tokenId
        //           );
        //         });
        //         return { ...nft, listings: listing };
        //       } else {
        //         return { ...nft, ownedAmount: nft.amount };
        //       }
        //     });
        //
        //     return returnNFTs;
        //   } catch (err) {
        //     throw Error(`getMarketplaceCollectionNFTsError: ${err}`);
        //   }
        // };
        // reserveAll = (nftAddress: string, tokenId: string) => {
        //   if (!this.marketplaceContractAddress) throw Error('Marketplace contract address not set');
        //   try {
        //     return this.fanFireAPI.reserveAllNFTs(this.marketplaceContractAddress, this.chain, nftAddress, tokenId, 10);
        //   } catch (err) {
        //     throw Error(`reserveAll Error: ${err}`);
        //   }
        // };
        // reserveAllMarketplaceNFTs = async (nftContractAddresses: Array<string>) => {
        //   if (!this.marketplaceContractAddress) {
        //     throw Error('Marketplace contract address not set');
        //   }
        //   if (!this.firebaseAuth) {
        //     throw Error('Firebase authentication is required to authorize this request. Call enableWeb2 to initialize');
        //   }
        //   try {
        //     let listings = await this.getAllListings();
        //     const nftAddresses = nftContractAddresses.map((add) => add.toLowerCase());
        //     listings = listings.filter((list) => {
        //       return nftAddresses.includes(list.nftAddress.toLowerCase());
        //     });
        //     for (const list of listings) {
        //       const res = await this.reserveAll(list.nftAddress, list.tokenId);
        //     }
        //   } catch (err) {
        //     throw Error(`ReserveAllMarketplaceNFTs Error: ${err}`);
        //   }
        // };
        // Takes datetime in format "2022-03-29T12:31:38.253Z" and converts to time since epoch
        // getMarketplaceNFTs = async (
        //   nftContractAddresses: Array<string>,
        //   filters?: Array<NFTFilter>,
        //   fanfireFilters?: Array<NFTFilter>
        // ): Promise<any> => {
        //   try {
        //     console.info('CALLING SDK')
        //     const listings = await this.getAllListings(
        //       nftContractAddresses,
        //       filters,
        //       fanfireFilters
        //     );
        //
        //     // if no listings return empty array
        //     if (!listings[0]) return [];
        //
        //     // get the unique nft addresses in listings
        //     const uniqueNftAdresses = [
        //       ...new Set(nftContractAddresses.map((add) => add.toLowerCase())),
        //     ];
        //     // promise array of details
        //     const listingDetailsPromises = uniqueNftAdresses.map(
        //       (nftAddress: string) => {
        //         // get all the listings relating to this NFT Address
        //         const thisAddressListings = listings.filter((list) => {
        //           return (
        //             list.nftAddress.toLowerCase() === nftAddress.toLowerCase()
        //           );
        //         });
        //
        //         if(thisAddressListings.length === 0) return
        //
        //         // check if this is a vault NFT address
        //         if (
        //           nftAddress.toLowerCase() ===
        //             "0x59974e728098f39ccdac62dbb17978612450d8e2".toLowerCase() ||
        //           nftAddress.toLowerCase() ===
        //             "0xc053eb2c0d59cdb2b8686d414c636b72fefc40a3".toLowerCase() ||
        //           nftAddress.toLowerCase() ===
        //             "0xd7cb3d1d12c5d54041d68ab057d5356e080ff428".toLowerCase() ||
        //           nftAddress.toLowerCase() ===
        //             "0x01c2be5bcfb42433732a6bbd4f12e26093cece43".toLowerCase()
        //         ) {
        //           // get the details for this vault NFT
        //           return this.nftSDK.getVaultNFTForMarketplace(
        //             nftAddress.toLowerCase(),
        //             thisAddressListings
        //           );
        //         }
        //
        //
        //         if (thisAddressListings[0].isERC721) {
        //           // get the details for this ERC721 NFT
        //           return this.nftSDK.getERC721ForMarketplace(thisAddressListings);
        //         } else if (thisAddressListings[0].isERC1155) {
        //           // get the details for this ERC1155 NFT
        //           return this.nftSDK.getERC1155ForMarketplace(
        //             nftAddress,
        //             thisAddressListings
        //           );
        //         } else {
        //           throw Error(`Contract Address ${nftAddress} is not supported`);
        //         }
        //       }
        //     );
        //     const listedNFTs = await Promise.all(listingDetailsPromises);
        //     const responseObj = [].concat.apply([], listedNFTs);
        //
        //     return responseObj;
        //   } catch (err) {
        //     throw Error(`GetMarketplaceNFTS Error: ${err}`);
        //   }
        // };
        this.unlistStale = function (nftAddress, tokenId, sellerAddress) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.marketplaceContractAddress)
                            throw Error("Marketplace contract must be initialized with an address.");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fanFireAPI.unlistStale(nftAddress, tokenId, sellerAddress, this.marketplaceContractAddress, this.chain)];
                    case 2:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_8 = _a.sent();
                        throw Error("unlistStale Error: ".concat(err_8));
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.getNFTStaleStatus = function (nftAddress, tokenId, sellerAddress) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // @TODO: Require proper return model
                        if (!this.marketplaceContractAddress)
                            throw Error("Marketplace contract must be initialized with an address.");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fanFireAPI.callContract(this.marketplaceContractAddress, this.chain, "status", {
                                sellerAddress: sellerAddress,
                                nftAddress: nftAddress,
                                tokenId: tokenId,
                            })];
                    case 2:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_9 = _a.sent();
                        throw Error("getNFTStaleStatus Error: ".concat(err_9));
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        // unlistStaleNfts = async (
        //   nftContractAddresses: Array<string>
        // ): Promise<{ staleCount: number }> => {
        //   let staleCount = 0;
        //   const nfts = await this.getMarketplaceNFTs(nftContractAddresses);
        //   const unlistProms = nfts.map(async (nft) => {
        //     const nftAddress = nft.contractAddress;
        //     const tokenId = nft.tokenId;
        //     const sellerAddress = nft.sellerAddress;
        //     const staleStatus = await this.getNFTStaleStatus(
        //       nftAddress,
        //       tokenId,
        //       sellerAddress
        //     );
        //     if (!staleStatus[0] || !staleStatus[1]) {
        //       staleCount += 1;
        //       return this.unlistStale(nftAddress, tokenId, sellerAddress);
        //     } else {
        //       return false;
        //     }
        //   });
        //   const result = await Promise.allSettled(unlistProms);
        //   return { staleCount };
        // };
        // TODO also not sure if this is working
        this.getNFTReservedState = function (nftAddress, tokenId, sellerAddress) { return __awaiter(_this, void 0, void 0, function () {
            var listings, listedNFT, sellerListing, reservedByAddress, reservedUntil, err_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.getAllListings([nftAddress])];
                    case 1:
                        listings = _a.sent();
                        listedNFT = listings.find(function (l) {
                            return (l.nftAddress.toLowerCase() === nftAddress.toLowerCase() &&
                                l.tokenId.toLowerCase() === tokenId.toLowerCase());
                        });
                        // If NFT is not reserved just return
                        if (!listedNFT)
                            return [2 /*return*/];
                        sellerListing = listedNFT.listings.find(function (listing) { return listing.sellerAddress.toLowerCase() === sellerAddress.toLowerCase(); });
                        if (!sellerListing)
                            return [2 /*return*/];
                        reservedByAddress = sellerListing.reserveeAddress;
                        reservedUntil = new Date(sellerListing.reservedUntil);
                        return [2 /*return*/, {
                                reservedByAddress: reservedByAddress,
                                reservedUntil: reservedUntil,
                            }];
                    case 2:
                        err_10 = _a.sent();
                        throw Error("getNFTReservedState Error: ".concat(err_10));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.reserveNFT = function (nftAddress, tokenId, period, reservee) { return __awaiter(_this, void 0, void 0, function () {
            var buy, err_11;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.firebaseAuth && !this.web3Service) {
                            throw Error("Firebase or Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        if (!this.firebaseAuth) return [3 /*break*/, 2];
                        return [2 /*return*/];
                    case 2:
                        if (!this.web3Service) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.web3Service.reserveNFT(this.marketplaceContractAddress, this.chain, nftAddress, tokenId, period, reservee)];
                    case 3:
                        buy = _a.sent();
                        if (buy.isSuccess) {
                            return [2 /*return*/, buy.value];
                        }
                        else {
                            throw Error(buy.errorMessage);
                        }
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_11 = _a.sent();
                        throw Error("reserveNFT Error: ".concat(err_11));
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        this.listNFT = function (nftAddress, tokenId, price, paymentToken, amount, sellerAddress) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_12;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error("Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        res = void 0;
                        console.log("LISTING AMOUNT");
                        console.log(amount);
                        if (!this.web3Service) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.web3Service.listNFT(this.marketplaceContractAddress, this.chain, nftAddress, tokenId, price, paymentToken, amount)];
                    case 1:
                        res = _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.fanFireAPI.listNFT(this.marketplaceContractAddress, this.chain, nftAddress, tokenId, price, paymentToken, amount, sellerAddress)];
                    case 3:
                        res = _a.sent();
                        _a.label = 4;
                    case 4:
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 5:
                        err_12 = _a.sent();
                        throw Error("listNFT Error: ".concat(err_12));
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        this.addPaymentToken = function (paymentToken) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_13;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service
                            ? this.web3Service
                            : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error("Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        return [4 /*yield*/, service.callAuthedContract(this.marketplaceContractAddress, this.chain, "addPaymentToken", { paymentToken: paymentToken })];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_13 = _a.sent();
                        throw Error("addPaymentToken Error: ".concat(err_13));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.isPaymentToken = function (paymentToken) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_14;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service
                            ? this.web3Service
                            : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error("Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        return [4 /*yield*/, service.callAuthedContract(this.marketplaceContractAddress, this.chain, "isPaymentToken", { paymentToken: paymentToken })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_14 = _a.sent();
                        throw Error("isPaymentToken Error: ".concat(err_14));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getListingByPointer = function (listingPointer) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_15;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service
                            ? this.web3Service
                            : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error("Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        return [4 /*yield*/, service.callAuthedContract(this.marketplaceContractAddress, this.chain, "getListingByPointer", { listingPointer: listingPointer })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_15 = _a.sent();
                        throw Error("getListingByPointer Error: ".concat(err_15));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getListingPointer = function (nftAddress, tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, BN, err_16;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service
                            ? this.web3Service
                            : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error("Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        return [4 /*yield*/, service.callAuthedContract(this.marketplaceContractAddress, this.chain, "getListingPointer", {
                                nftAddress: nftAddress,
                                tokenId: tokenId,
                            })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        BN = ethers_1.ethers.BigNumber.from(res.value.hex);
                        return [2 /*return*/, BN.toString()];
                    case 2:
                        err_16 = _a.sent();
                        throw Error("getListingPointer Error: ".concat(err_16));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.swapFiatForERC20 = function (buyerAddress, erc20Amount, erc20Address, fiatAmount) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_17;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.firebaseAuth) {
                            throw Error("Firebase is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fanFireAPI.swapFiatForERC20(buyerAddress, erc20Address, erc20Amount, fiatAmount, this.chain)];
                    case 2:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 3:
                        err_17 = _a.sent();
                        console.error(err_17);
                        throw Error("swapFiatForERC20 Error: ".concat(err_17));
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.buyNFTWithFiat = function (buyerAddress, sellerAddress, nftAddress, tokenId, amount, erc20Amount, erc20Address, fiatAmount, successRedirect, cancelRedirect, failureRedirect, spotPrice) { return __awaiter(_this, void 0, void 0, function () {
            var staleStatus, reservedState, buy, err_18;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.firebaseAuth) {
                            throw Error("Firebase is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        return [4 /*yield*/, this.getNFTStaleStatus(nftAddress, tokenId, sellerAddress)];
                    case 2:
                        staleStatus = _a.sent();
                        if (!(!staleStatus[0] || !staleStatus[1])) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.unlistStale(nftAddress, tokenId, sellerAddress)];
                    case 3:
                        _a.sent();
                        throw Error("Stale NFT: NFT Unlisted");
                    case 4: return [4 /*yield*/, this.getNFTReservedState(nftAddress, tokenId, sellerAddress)];
                    case 5:
                        reservedState = _a.sent();
                        if (reservedState &&
                            new Date() <= reservedState.reservedUntil &&
                            reservedState.reservedByAddress !== buyerAddress)
                            throw Error("ERR_TYPE:NFT_RESERVED;");
                        return [4 /*yield*/, this.fanFireAPI.buyNFTWithFIAT(this.marketplaceContractAddress, buyerAddress, sellerAddress, nftAddress, tokenId, amount, erc20Address, erc20Amount, fiatAmount, this.chain, successRedirect, cancelRedirect, failureRedirect, spotPrice)];
                    case 6:
                        buy = _a.sent();
                        if (!buy.isSuccess)
                            throw Error(buy.errorMessage);
                        return [2 /*return*/, buy.value];
                    case 7:
                        err_18 = _a.sent();
                        throw Error("".concat(err_18));
                    case 8: return [2 /*return*/];
                }
            });
        }); };
        this.buyNFTWithERC20 = function (buyerAddress, sellerAddress, nftAddress, tokenId, erc20Amount, erc20Address, amountOfTokens) { return __awaiter(_this, void 0, void 0, function () {
            var staleStatus, reservedState, buyNFT, err_19, buy, err_20, err_21;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error("Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 14, , 15]);
                        return [4 /*yield*/, this.getNFTStaleStatus(nftAddress, tokenId, sellerAddress)];
                    case 2:
                        staleStatus = _a.sent();
                        if (!(!staleStatus[0] || !staleStatus[1])) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.unlistStale(nftAddress, tokenId, sellerAddress)];
                    case 3:
                        _a.sent();
                        throw Error("Stale NFT: NFT Unlisted");
                    case 4: return [4 /*yield*/, this.getNFTReservedState(nftAddress, tokenId, sellerAddress)];
                    case 5:
                        reservedState = _a.sent();
                        if (reservedState &&
                            new Date() <= reservedState.reservedUntil &&
                            reservedState.reservedByAddress !== buyerAddress)
                            throw Error("NFT Reserved");
                        if (!this.web3Service) return [3 /*break*/, 10];
                        _a.label = 6;
                    case 6:
                        _a.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, this.web3Service.buyNFT(sellerAddress, this.marketplaceContractAddress, this.chain, nftAddress, tokenId, erc20Amount, erc20Address, amountOfTokens)];
                    case 7:
                        buyNFT = _a.sent();
                        if (!buyNFT.isSuccess)
                            throw Error(buyNFT.errorMessage);
                        return [2 /*return*/, buyNFT.value];
                    case 8:
                        err_19 = _a.sent();
                        throw Error("Web3 buyNFTWithERC20 Error: ".concat(err_19));
                    case 9: return [3 /*break*/, 13];
                    case 10:
                        _a.trys.push([10, 12, , 13]);
                        return [4 /*yield*/, this.fanFireAPI.buyNFTWithERC20(buyerAddress, sellerAddress, this.marketplaceContractAddress, nftAddress, erc20Address, erc20Amount, tokenId, this.chain)];
                    case 11:
                        buy = _a.sent();
                        if (!buy.isSuccess)
                            throw Error(buy.errorMessage);
                        return [2 /*return*/, buy.value];
                    case 12:
                        err_20 = _a.sent();
                        throw Error("Web2 buyNFTWithERC20 Error: ".concat(err_20));
                    case 13: return [3 /*break*/, 15];
                    case 14:
                        err_21 = _a.sent();
                        throw Error("buyNFTWithERC20 Error: ".concat(err_21));
                    case 15: return [2 /*return*/];
                }
            });
        }); };
        this.pullFunds = function (paymentTokenAddress, amount) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_22;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service
                            ? this.web3Service
                            : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error("Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        return [4 /*yield*/, service.callAuthedContract(this.marketplaceContractAddress, this.chain, "pullFunds", {
                                paymentToken: paymentTokenAddress,
                                amount: amount,
                            })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_22 = _a.sent();
                        throw Error("pullFunds Error: ".concat(err_22));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.pullAllFunds = function (accountAddress, paymentTokenAddress) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, pull, err_23;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        service = this.web3Service
                            ? this.web3Service
                            : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error("Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        if (!this.web3Service) return [3 /*break*/, 3];
                        return [4 /*yield*/, service.callAuthedContract(this.marketplaceContractAddress, this.chain, "getBalance", {
                                paymentToken: paymentTokenAddress,
                                accountAddress: accountAddress,
                            })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [4 /*yield*/, service.callAuthedContract(this.marketplaceContractAddress, this.chain, "pullFunds", {
                                paymentToken: paymentTokenAddress,
                                amount: res.value,
                            })];
                    case 2:
                        pull = _a.sent();
                        if (!pull.isSuccess)
                            throw Error(pull.errorMessage);
                        return [2 /*return*/, pull.value];
                    case 3: return [4 /*yield*/, this.fanFireAPI.pullAllFunds(this.marketplaceContractAddress, paymentTokenAddress, this.chain)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_23 = _a.sent();
                        throw Error("pullAllFunds Error: ".concat(err_23));
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        this.getAllListings = function (nftContractAddresses) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_24;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.marketplaceContractAddress)
                            throw Error("Marketplace contract must be initialized with an address.");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fanFireAPI.getAllMarketplaceListings(this.marketplaceContractAddress, this.chain, nftContractAddresses)];
                    case 2:
                        res = _a.sent();
                        if (!res.isSuccess || !res.value)
                            throw Error("getAllListings Error: ".concat(res.errorMessage));
                        return [2 /*return*/, res.value];
                    case 3:
                        err_24 = _a.sent();
                        console.log(err_24);
                        throw Error("getAllListings Error: ".concat(err_24));
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.getRoyalties = function (nftAddress, tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_25;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service
                            ? this.web3Service
                            : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error("Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        return [4 /*yield*/, service.callAuthedContract(this.marketplaceContractAddress, this.chain, "getRoyalties", { nftAddress: nftAddress, tokenId: tokenId })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_25 = _a.sent();
                        throw Error("getRoyalties Error: ".concat(err_25));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getMarketplaceContractBalanceFor = function (paymentToken, accountAddress) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_26;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service
                            ? this.web3Service
                            : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error("Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        return [4 /*yield*/, service.callAuthedContract(this.marketplaceContractAddress, this.chain, "getBalance", {
                                paymentToken: paymentToken,
                                accountAddress: accountAddress,
                            })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_26 = _a.sent();
                        throw Error("getMarketplaceContractBalanceFor Error: ".concat(err_26));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.unlist = function (nftAddress, tokenId, sellerAddress) { return __awaiter(_this, void 0, void 0, function () {
            var service, staleStatus, res, res, err_27;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 9, , 10]);
                        service = this.web3Service
                            ? this.web3Service
                            : this.fanFireAPI;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error("Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        return [4 /*yield*/, this.getNFTStaleStatus(nftAddress, tokenId, sellerAddress)];
                    case 1:
                        staleStatus = _a.sent();
                        if (!(staleStatus[0] && staleStatus[1])) return [3 /*break*/, 6];
                        if (!this.web3Service) return [3 /*break*/, 3];
                        return [4 /*yield*/, service.callAuthedContract(this.marketplaceContractAddress, this.chain, "unlist", { nftAddress: nftAddress, tokenId: tokenId })];
                    case 2:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 3: return [4 /*yield*/, this.fanFireAPI.unlistNFT(this.marketplaceContractAddress, this.chain, nftAddress, tokenId)];
                    case 4:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 5: return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, this.unlistStale(nftAddress, tokenId, sellerAddress)];
                    case 7:
                        _a.sent();
                        throw Error("The NFT was stale: NFT unlisted");
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        err_27 = _a.sent();
                        throw Error("unlist Error: ".concat(err_27));
                    case 10: return [2 /*return*/];
                }
            });
        }); };
        this.likeNFT = function (walletAddress, nftAddress, tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var challenge, signature, sign, res, err_28, challenge, signature, sign, res, err_29;
            var _a, _b, _c, _d, _e, _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error("Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        if (!(this.web3Service && this.web3)) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.fanFireAPI.requestNFTChallenge(walletAddress)];
                    case 1:
                        challenge = _g.sent();
                        if (!challenge.isSuccess || !((_a = challenge.value) === null || _a === void 0 ? void 0 : _a.message))
                            throw Error("requestNFTChallenge Error: ".concat(challenge.errorMessage));
                        _g.label = 2;
                    case 2:
                        _g.trys.push([2, 6, , 7]);
                        return [4 /*yield*/, this.web3.signMessage((_b = challenge.value) === null || _b === void 0 ? void 0 : _b.message)];
                    case 3:
                        signature = _g.sent();
                        return [4 /*yield*/, this.fanFireAPI.verifyWallet(walletAddress, (_c = challenge.value) === null || _c === void 0 ? void 0 : _c.message, signature)];
                    case 4:
                        sign = _g.sent();
                        if (!sign.isSuccess || !((_d = sign.value) === null || _d === void 0 ? void 0 : _d._id))
                            throw Error("verifyWallet Error: ".concat(sign.errorMessage));
                        return [4 /*yield*/, this.fanFireAPI.likeNFT(nftAddress, tokenId, walletAddress, this.chain, sign.value._id)];
                    case 5:
                        res = _g.sent();
                        if (!res.isSuccess)
                            throw Error("likeNFT Error: ".concat(res.errorMessage));
                        return [2 /*return*/, res];
                    case 6:
                        err_28 = _g.sent();
                        throw Error("Web3 likeNFT Error: ".concat(err_28));
                    case 7: return [3 /*break*/, 15];
                    case 8: return [4 /*yield*/, this.fanFireAPI.requestNFTChallenge(walletAddress)];
                    case 9:
                        challenge = _g.sent();
                        _g.label = 10;
                    case 10:
                        _g.trys.push([10, 14, , 15]);
                        if (!challenge.isSuccess || !((_e = challenge.value) === null || _e === void 0 ? void 0 : _e.message))
                            throw Error("requestNFTChallenge Error: ".concat(challenge.errorMessage));
                        return [4 /*yield*/, this.fanFireAPI.signMessage(walletAddress, challenge.value.message)];
                    case 11:
                        signature = _g.sent();
                        if (!signature.isSuccess || !signature.value)
                            throw Error("signMessage Error: ".concat(signature.errorMessage));
                        return [4 /*yield*/, this.fanFireAPI.verifyWallet(walletAddress, challenge.value.message, signature.value)];
                    case 12:
                        sign = _g.sent();
                        if (!sign.isSuccess || !((_f = sign.value) === null || _f === void 0 ? void 0 : _f._id))
                            throw Error("verifyWallet Error: ".concat(sign.errorMessage));
                        return [4 /*yield*/, this.fanFireAPI.likeNFT(nftAddress, tokenId, walletAddress, this.chain, sign.value._id)];
                    case 13:
                        res = _g.sent();
                        if (!res.isSuccess)
                            throw Error("likeNFT Error: ".concat(res.errorMessage));
                        return [2 /*return*/, res];
                    case 14:
                        err_29 = _g.sent();
                        throw Error("Web2 likeNFT Error: ".concat(err_29));
                    case 15: return [2 /*return*/];
                }
            });
        }); };
        this.unlikeNFT = function (walletAddress, nftAddress, tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var challenge, signature, verify, res, err_30, challenge, signature, verify, res, err_31;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        // TODO implement same comments from like NFT
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error("Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.marketplaceContractAddress) {
                            throw Error("Marketplace SDK has not yet been initialized");
                        }
                        if (!(this.web3Service && this.web3)) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.fanFireAPI.requestNFTChallenge(walletAddress)];
                    case 1:
                        challenge = _e.sent();
                        _e.label = 2;
                    case 2:
                        _e.trys.push([2, 6, , 7]);
                        if (!challenge.isSuccess || !((_a = challenge.value) === null || _a === void 0 ? void 0 : _a.message))
                            throw Error("requestNFTChallenge Error: ".concat(challenge.errorMessage));
                        return [4 /*yield*/, this.web3.signMessage(challenge.value.message)];
                    case 3:
                        signature = _e.sent();
                        return [4 /*yield*/, this.fanFireAPI.verifyWallet(walletAddress, challenge.value.message, signature)];
                    case 4:
                        verify = _e.sent();
                        if (!verify.isSuccess || !((_b = verify.value) === null || _b === void 0 ? void 0 : _b._id))
                            throw Error("verifyWallet Error: ".concat(verify.errorMessage));
                        return [4 /*yield*/, this.fanFireAPI.unlikeNFT(nftAddress, tokenId, walletAddress, this.chain, verify.value._id)];
                    case 5:
                        res = _e.sent();
                        if (!res.isSuccess)
                            throw Error("unlikeNFT Error: ".concat(res.errorMessage));
                        return [2 /*return*/, res];
                    case 6:
                        err_30 = _e.sent();
                        throw Error("Web3 unlikeNFT Error: ".concat(err_30));
                    case 7: return [3 /*break*/, 15];
                    case 8: return [4 /*yield*/, this.fanFireAPI.requestNFTChallenge(walletAddress)];
                    case 9:
                        challenge = _e.sent();
                        if (!challenge.isSuccess || !((_c = challenge.value) === null || _c === void 0 ? void 0 : _c.message))
                            throw Error("requestNFTChallenge Error: ".concat(challenge.errorMessage));
                        _e.label = 10;
                    case 10:
                        _e.trys.push([10, 14, , 15]);
                        return [4 /*yield*/, this.fanFireAPI.signMessage(walletAddress, challenge.value.message)];
                    case 11:
                        signature = _e.sent();
                        if (!signature.isSuccess || !signature.value)
                            throw Error("signMessage Error: ".concat(signature.errorMessage));
                        return [4 /*yield*/, this.fanFireAPI.verifyWallet(walletAddress, challenge.value.message, signature.value)];
                    case 12:
                        verify = _e.sent();
                        if (!verify.isSuccess || !((_d = verify.value) === null || _d === void 0 ? void 0 : _d._id))
                            throw Error("verifyWallet Error: ".concat(verify.errorMessage));
                        return [4 /*yield*/, this.fanFireAPI.unlikeNFT(nftAddress, tokenId, walletAddress, this.chain, verify.value._id)];
                    case 13:
                        res = _e.sent();
                        if (!res.isSuccess)
                            throw Error("unlikeNFT Error: ".concat(res.errorMessage));
                        return [2 /*return*/, res];
                    case 14:
                        err_31 = _e.sent();
                        throw Error("Web2 unlikeNFT Error: ".concat(err_31));
                    case 15: return [2 /*return*/];
                }
            });
        }); };
        this.requestPrint = function (name, contactNumber, address, numPrint, size, type) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_32;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fanFireAPI.requestPrint(name, contactNumber, address, numPrint, size, type)];
                    case 1:
                        res = _a.sent();
                        if (res.isSuccess) {
                            return [2 /*return*/, res.value];
                        }
                        else {
                            throw Error(res.errorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_32 = _a.sent();
                        throw Error("requestPrint Error: ".concat(err_32));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getUserTxs = function (walletAddress) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_33;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.firebaseAuth) {
                            throw Error("Firebase required to authorize this request. Call enableWeb2 to initialize");
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fanFireAPI.getUserIntents(walletAddress, this.chain)];
                    case 2:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 3:
                        err_33 = _a.sent();
                        throw Error("getUserPendingTxs Error: ".concat(err_33));
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.getERC20Decimals = function (paymentTokenAddress) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_34;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.fanFireAPI.callContract(paymentTokenAddress, this.chain, "decimals", undefined)];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_34 = _a.sent();
                        throw Error("getERC20Decimals Error: ".concat(err_34));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.airdropNFT = function (walletAddress, nftAddress) { return __awaiter(_this, void 0, void 0, function () {
            var res, err_35;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.web3Service && !this.firebaseAuth)
                            throw Error("Authentication is required, call enableWeb2 or enableWeb3 to use this method");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fanFireAPI.airdropNFT(walletAddress, nftAddress, this.chain)];
                    case 2:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 3:
                        err_35 = _a.sent();
                        throw Error("airdropNFT Error: ".concat(err_35));
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.nftSDK = new nft_1.NFT(api, chain);
        this.vaultSDK = new vault_1.Vault(api, chain);
        this.fanFireAPI = api;
        this.chain = chain;
        this.moralisAPI = new moralisService_1.MoralisAPI();
    }
    return Marketplace;
}());
exports.Marketplace = Marketplace;
