import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Text from "../../components/text/Text";
import {useSnackBar} from "../../context/snackAlert-context";
import MapCardItems from "../../components/cardItem/MapCardItems";
import {useNavigate} from "react-router-dom";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import MapNFTsError from "../../components/errorComponents/MapNFTsError";
import FilterComponent from "../../components/filter/FilterComponent";
import ScrollToTop from "../../components/helpers/ScrollToTop";
import {FanfireSDK} from "fanfire-sdk";

const styles = {
    header: {
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "35vh",
        filter: "blur(3px)",
    },
    noHeader: {
        height: "35vh",
    },
    overlay: {
        position: "absolute" as "absolute",
        top: "20vh",
        left: "10%",
    },
};

interface Props {
    sdkService: FanfireSDK;
    appConfig: any;
}

export default function Explore({sdkService, appConfig}: Props) {
    const [allNFTs, setAllNfts] = useState<object[]>([]);
    const [searchedNFTs, setSearchedNFTs] = useState<object[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);

    const createSDKFilterParams = (queryString: any) => {
        let filter: Array<{}> = [];
        const searchParams = new URLSearchParams(queryString);
        searchParams.forEach(function (value, key) {
            if (Array.isArray(value.split(","))) {
                for (const item of value.split(",")) {
                    filter.push({trait_type: key, value: item});
                }
            } else {
                filter.push({trait_type: key, value: value});
            }
        });
        return filter;
    };

    const [filter, setFilter] = useState<any>(
        createSDKFilterParams(window.location.search)
    );
    const [filterName, setFilterName] = useState<String>("");

    const navigate = useNavigate();
    const {showSnackBar} = useSnackBar();

    const getCollection = async (
        nftContractAddress: string[],
        filter?: Array<{ trait_type: string; value: string }>
    ) => {
        const collection = await sdkService.marketplace.getExploreDetails(
            nftContractAddress,
            filter
        );
        if (collection.length > 0) {
            //start showing after first collection is recieved
            setLoading(false);
        }
        return collection;
    };

    const getNFTs = async (
        filter?: Array<{ trait_type: string; value: string }>
    ) => {
        setLoading(true);
        try {
            let nfts: any = [];
            if (filter && filter.length > 0) {
                console.log("NFT FILTER: ", filter);
            }
            const NFTAddresses =
                process.env.REACT_APP_FANFIRE_NFT_CONTRACT_ADDRESSES?.split(",") ?? [];
            const searchedNfts = await getCollection(NFTAddresses)
            setAllNfts(searchedNfts)
            setSearchedNFTs(searchedNfts)
            // await Promise.all(
            //     NFTAddresses.map(async (nftContractAddress) => {
            //         const nftCollection = await getCollection(
            //             nftContractAddress,
            //             filter?.filter((e: any) => e.trait_type != "Name")
            //         );
            //         nfts = nfts.concat(nftCollection);
            //         setAllNfts(nfts);
            //         setSearchedNFTs(nfts);
            //     })
            // );
            console.log("Explore NFTs: ", nfts);
            setLoading(false);
        } catch (error) {
            setIsError(true);
            console.log(
                `There has been an error getting the collection ${
                    appConfig.general.site_noun_plural
                        ? appConfig.general.site_noun_plural
                        : "items"
                }`
            );
            console.error(error);
            showSnackBar(
                `There has been an error getting the collection of ${
                    appConfig.general.site_noun ? appConfig.general.site_noun : "items"
                }. Please try again later`,
                "error",
                error
            );
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log("Getting Collection NFTs");
        const prep = async () => {
            await getNFTs(filter.filter((e: any) => e.trait_type != "Name"));
        };
        prep();
    }, [filter]);

    useEffect(() => {
        if (allNFTs.length > 0) {
            setSearchedNFTs([]);
            let x: any = [];
            if (filterName) {
                x = allNFTs.filter(
                    (e: any) =>
                        e.metadata.name &&
                        e.metadata.name.toLowerCase().includes(filterName.toLowerCase())
                );
            } else {
                x = allNFTs;
            }
            setSearchedNFTs(x);
        }
    }, [filterName, allNFTs]);

    return (
        <>
            <ScrollToTop/>
            {appConfig.browseCollectionPage.headerImage ? (
                <div
                    style={{
                        ...styles.header,
                        backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.3) 60%, rgba(0,0,0,90) 95%), url(${appConfig.browseCollectionPage.headerImage})`,
                    }}
                ></div>
            ) : (
                <div style={styles.noHeader}></div>
            )}
            <div style={styles.overlay}>
                <Avatar
                    sx={{
                        width: 40,
                        height: 40,
                        background: `${
                            appConfig.navItems.backButtonNavIconPaddingColor
                                ? appConfig.navItems.backButtonNavIconPaddingColor
                                : "rgba(255, 255, 255, 0.1)"
                        }`,
                        top: "-5vh",
                    }}
                >
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBackIosNewRoundedIcon
                            sx={{
                                fontSize: 25,
                                color: `${
                                    appConfig.navItems.backButtonNavIconColor
                                        ? appConfig.navItems.backButtonNavIconColor
                                        : "white"
                                }`,
                            }}
                        />
                    </IconButton>
                </Avatar>
                <Text variantText="h2" style={{textAlign: "left"}}>
                    {appConfig.browseCollectionPage.header}
                </Text>
            </div>
            {appConfig.general.avail_filters && (
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                >
                    <FilterComponent
                        appConfig={appConfig}
                        currentFilter={window.location.search}
                        setFilter={setFilter}
                        setFilterName={setFilterName}
                    />
                </Grid>
            )}
            <Grid
                container
                padding={5}
                pt={1}
                px={"10%"}
                spacing={5}
                alignItems="center"
                justifyContent="center"
                direction="row"
            >
                {!loading ? (
                    isError ? (
                        <MapNFTsError appConfig={appConfig}/>
                    ) : (
                        searchedNFTs.length === 0 && (
                            <>
                                <Grid item xs={12}>
                                    <Text variantText="h3" style={{textAlign: "center"}}>
                                        {!filter.length
                                            ? `Looks like there are no ${
                                                appConfig.general.site_noun_plural
                                                    ? appConfig.general.site_noun_plural
                                                    : "items"
                                            } in this Collection at this moment!`
                                            : `No ${
                                                appConfig.general.site_noun_plural
                                                    ? appConfig.general.site_noun_plural
                                                    : "items"
                                            } match this filter criteria.`}
                                    </Text>
                                </Grid>
                            </>
                        )
                    )
                ) : (
                    <></>
                )}
                {!isError && (
                    <Grid item xs={12} alignItems="center" justifyContent="center">
                        <MapCardItems
                            nftItemsIn={searchedNFTs}
                            loading={loading}
                            sdkService={sdkService}
                            appConfig={appConfig}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
}
