import React, { useEffect, useState } from "react";
import Text from "../../components/text/Text";
import { useSnackBar } from "../../context/snackAlert-context";

import InputTextField from "../../components/inputs/InputTextField";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import BasicLoader from "../../components/loaders/BasicLoader";
import { useSelector, RootStateOrAny } from "react-redux";

import { NetworkStatusEnums } from "../../helpers/enums";
import RedeemIcon from "@mui/icons-material/Redeem";
import CheckboxRequestInput from "../../components/inputs/CheckboxRequestInput";
// import { stringify } from 'uuid';
// import { getEllipsisTxt } from '../helpers/formatters';
import { FanfireSDK } from "fanfire-sdk";

//MUI
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
// import Checkbox from '@mui/material/Checkbox';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
// import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";

const styles = {
  dialogContainer: {
    borderRadius: "16px",
    width: "100%",
  },
};

interface ConfirmationProps {
  sdkService: FanfireSDK;
  appConfig: any;
  formData: any;
  hasFormData: any;
  userOTPResponse: any;
}

const RedeemConfirmation = ({
  sdkService,
  appConfig,
  formData,
  hasFormData,
  userOTPResponse,
}: ConfirmationProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showSnackBar } = useSnackBar();

  const user = useSelector((state: RootStateOrAny) => state.user);
  // const isWeb2 = user.auth.isWeb2;
  // const isWeb3 = user.auth.isWeb3;
  // const isAuthenticated = NetworkStatusEnums.SUCCESS === user.status;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatDataForRedemption = (formData: any) => {
    let redeemableNFTs: any = [];
    formData.forEach((el: any) => {
      redeemableNFTs.push({
        nftAddress: el.item.nftAddress,
        tokenId: el.item.tokenId,
        blockchain: process.env.REACT_APP_FANFIRE_CHAIN,
        redeemedAmount: Number(el.qty),
      });
    });
    console.log("redemption NFTS:", redeemableNFTs);
    return redeemableNFTs;
  };

  // const sendBackTransfer = () => {
  //   console.log("sending back unclaimed NFTs to owner.")
  // }

  const requestSubmit = async () => {
    setLoading(true);
    try {
      console.log("Submitting redemtion Completion");
      const burnNFTs = await sdkService.redemptions.redeemOrder(
        sdkService.wallet.walletAddress ?? "",
        userOTPResponse, //redemption object from otp
        formatDataForRedemption(formData) //rededmable nfts
      );
      showSnackBar("Redemption Request Successful!", "success");
      setLoading(false);
      setOpen(false);
      await new Promise((r) => setTimeout(r, 3000));
      window.location.reload();
    } catch (error) {
      showSnackBar("Redemption Request Failed", "error", error);
      console.error(error);
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <div>
      <Slide direction="down" in={hasFormData} mountOnEnter unmountOnExit>
        <Fab
          onClick={() => handleClickOpen()}
          type="submit"
          variant="extended"
          sx={{
            margin: 0,
            top: "auto",
            right: 20,
            bottom: 80,
            left: "auto",
            position: "fixed",
          }}
        >
          <RedeemIcon sx={{ mr: 1 }} />
          <Text variantText="h3">Complete Redemtion</Text>
        </Fab>
      </Slide>

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: styles.dialogContainer,
        }}
      >
        <DialogTitle>
          <Avatar sx={{ width: 64, height: 64, background: "#000000" }}>
            <RedeemIcon sx={{ fontSize: 50, color: "white" }} />
          </Avatar>
        </DialogTitle>
        <DialogTitle id="alert-dialog-title" component="div">
          <Text variantText="h1">Redeem</Text>
        </DialogTitle>
        <DialogContent>
          <Text variantText="h6">Please Review Redeemed Items</Text>
          <DialogContentText
            id="alert-dialog-slide-description"
            component="div"
          ></DialogContentText>
          <Text
            variantText="h6"
            style={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {appConfig.general.site_noun_plural
              ? appConfig.general.site_noun_plural
              : "items"}{" "}
            to Redeem
          </Text>
          {formData.map((element: any) => (
            <>
              <Text variantText="body1">
                {element.item.name} x {element.qty}
              </Text>
              <Divider style={{ backgroundColor: "white" }}></Divider>
            </>
          ))}
        </DialogContent>
        <Alert severity="info" sx={{ mb: 2 }}>
          <Text variantText="body1" style={{ textTransform: "none" }}>
            This transaction will burn all redeemed items.
          </Text>
          <Text variantText="body1" style={{ textTransform: "none" }}>
            Make sure the client has all the above items.
          </Text>
        </Alert>
        <Box m={2}>
          <DialogActions style={{ textAlign: "center" }}>
            {!loading && (
              <>
                <PrimaryButton onClick={() => requestSubmit()}>
                  <Text
                    variantText="body1"
                    style={{
                      fontWeight: "bold",
                      ...appConfig.general.styleOverrides
                        .buttonPrimaryTextColor,
                    }}
                  >
                    Confirm
                  </Text>
                </PrimaryButton>
                <SecondaryButton onClick={() => handleClose()}>
                  <Text variantText="body1">Cancel</Text>
                </SecondaryButton>
              </>
            )}
          </DialogActions>
        </Box>
        {loading && <BasicLoader text="Processing Redemption Completion" />}
      </Dialog>
    </div>
  );
};

interface OTPProps {
  sdkService: FanfireSDK;
  appConfig: any;
  setOTPSuccess: any;
  otpSuccess: any;
  setOrderRedemptions: any;
  orderNum: any;
  setUserOTPResponse: any;
}

const OTPConfirmation = ({
  sdkService,
  appConfig,
  orderNum,
  otpSuccess,
  setOTPSuccess,
  setOrderRedemptions,
  setUserOTPResponse,
}: OTPProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userOTP, setUserOTP] = useState();
  const [otp, setOTP] = useState(false);
  const [orderN, setOrderN] = useState();
  const [orderNumError, setOrderNumError] = useState(false);
  const [orderNumErrorText, setOrderNumErrorText] = useState("");
  const { showSnackBar } = useSnackBar();
  const [allreadyRequested, setAllreadyRequested] = useState(false);

  const user = useSelector((state: RootStateOrAny) => state.user);
  // const isWeb2 = user.auth.isWeb2;
  // const isWeb3 = user.auth.isWeb3;
  // const isAuthenticated = NetworkStatusEnums.SUCCESS === user.status;

  const getOTP = async () => {
    setOrderNumError(false);
    try {
      if (!allreadyRequested || orderNum !== orderN) {
        console.log(orderNum);
        const otpResp = await sdkService.redemptions.requestOtpForRedemption(
          orderNum
        );
        console.log(otpResp);
        if (
          sdkService.wallet.walletAddress?.toLowerCase() !==
          otpResp.estateAddress.toLowerCase()
        ) {
          throw Error("notVaultOrder");
        }
        setUserOTPResponse(otpResp);
        setOTP(otpResp.OTP ?? false ? true : false);
        let newRedeemableNFTs: any = [];
        otpResp.redeemableNfts.forEach((el: any) => {
          if (el.transferStatus === "completed") {
            newRedeemableNFTs.push({
              ...el,
              email: otpResp.email,
              redeemer: otpResp.redeemerAddress,
            });
          }
        });
        const orderRedemp = { ...otpResp, redeemableNfts: newRedeemableNFTs };
        console.log(orderRedemp);
        setOrderRedemptions(orderRedemp);
        setLoading(false);
        setAllreadyRequested(true);
        setOrderN(orderNum);
        setOrderNumError(false);
      } else {
        setOrderNumError(false);
        setLoading(false);
      }
    } catch (error: any) {
      if (error.message === "notVaultOrder") {
        setOrderNumErrorText("This order is for another Vault.");
      } else {
        setOrderNumErrorText(`Order number "${orderNum}" not found!`);
      }
      setOrderNumError(true);
      setLoading(false);
      console.error(error);
      console.log("error getting orderNum or OTP");
    }
  };

  useEffect(() => {
    const prep = async () => {
      getOTP();
    };
    if (open) {
      setLoading(true);
      prep();
    }
  }, [open]);

  const handleClickOpen = () => {
    setOTPSuccess();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkOTP = async () => {
    try {
      console.log("Checking OTP");
      if (userOTP == otp) {
        showSnackBar("Valid OTP!", "success");
        setOTPSuccess(true);
        setOpen(false);
      } else {
        setOTPSuccess(false);
      }
    } catch (error) {
      showSnackBar("Error", "error", error);
      console.error(error);
      setOTPSuccess(false);
    }
  };

  return (
    <div>
      <PrimaryButton onClick={() => handleClickOpen()} type="submit">
        <Text variantText="h6" style={{ fontWeight: "bold" }}>
          Search
        </Text>
      </PrimaryButton>

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: styles.dialogContainer,
        }}
      >
        <DialogTitle>
          <Avatar sx={{ width: 64, height: 64, background: "#000000" }}>
            <RedeemIcon sx={{ fontSize: 50, color: "white" }} />
          </Avatar>
        </DialogTitle>
        <DialogTitle id="alert-dialog-title">
          <Text variantText="h1">Redeem</Text>
        </DialogTitle>
        {!loading ? (
          orderNumError ? (
            <DialogContent>
              <Text variantText="h3">{orderNumErrorText}</Text>
            </DialogContent>
          ) : (
            <>
              <DialogContent>
                <Text variantText="h3">Order Number: {orderNum}</Text>
                <Text variantText="h6">
                  Please enter OTP sent to Redemtion user
                </Text>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  component="div"
                >
                  <InputTextField
                    label="OTP"
                    onChange={(e: any) => setUserOTP(e.target.value)}
                  />
                  {otpSuccess == false && (
                    <Text variantText="h6" style={{ color: "red" }}>
                      OTP invalid. Please retry.
                    </Text>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ textAlign: "center" }}>
                {!loading && otp && (
                  <SecondaryButton onClick={() => checkOTP()}>
                    <Text variantText="h6">Check</Text>
                  </SecondaryButton>
                )}
              </DialogActions>
            </>
          )
        ) : (
          <>
            <BasicLoader text="Loading..." />
          </>
        )}
      </Dialog>
    </div>
  );
};

interface Props {
  sdkService: FanfireSDK;
  appConfig: any;
  authLoading: boolean;
}

export default function VaultRedemptions({
  sdkService,
  appConfig,
  authLoading,
}: Props) {
  const [orderNum, setOrderNum] = useState<any>();
  const [otpSuccess, setOTPSuccess] = useState<boolean>();
  // const [otp, setOTP] = useState<number>();
  const [orderRedemptionLoading, setOrderRedemptionLoading] =
    useState<boolean>();
  const [orderRedemptions, setOrderRedemptions] = useState<any>([]);
  const [showOrderRedemptions, setShowOrderRedemptions] =
    useState<boolean>(false);
  const [formData, setFormData] = useState<any[]>([]);
  const [hasFormData, setHasFormData] = useState<boolean>(false);
  const [userOTPResponse, setUserOTPResponse] = useState<any>();
  const [sendBackNFTs, SetSendBackNFTs] = useState<any>();

  const user = useSelector((state: RootStateOrAny) => state.user);
  // const isWeb2 = user.auth.isWeb2;
  // const isWeb3 = user.auth.isWeb3;
  const isAuthenticated = NetworkStatusEnums.SUCCESS === user.status;

  const handleOrderNumChange = async (e: any) => {
    setOrderNum(e);
  };

  const handleShowOrderRedemptions = async () => {
    setShowOrderRedemptions(true);
  };

  useEffect(() => {
    const prep = async () => {
      handleShowOrderRedemptions();
    };
    if (otpSuccess == true) {
      prep();
    }
  }, [otpSuccess]);

  const handleFieldChange = (data: any) => {
    let df = formData;
    let itemIndex = df.findIndex(
      (e) => e.item === data.item && e.type === data.type
    ); // Check if item is already in form data
    if (!data.remove) {
      // if item is unchecked or made emty it should be removed from form data
      if (itemIndex !== -1) {
        // In form data - updating with new value
        console.log("Updating user Selection");
        df[itemIndex] = data;
      } else {
        // not in form data - inserting value
        console.log("Adding user Selection");
        df = [...df, data];
      }
    } else {
      // if item is unchecked or made emty it should be removed from form data
      console.log("Removing Item");
      df.splice(itemIndex, 1); // removing item from form data
    }
    if (df.length > 0) {
      setHasFormData(true);
    } else {
      setHasFormData(false);
    }
    setFormData(df);
  };

  const getRedemtionLocations = () => {
    let locations: Array<String> = [];
    const env: any = process.env.REACT_APP_FANFIRE_CHAIN ?? "";
    if (appConfig.general.enableQrRedemption) {
      if (env !== "" && appConfig.general.redemptionLocations) {
        appConfig.general.redemptionLocations.map((element: any) => {
          locations.push(element[env]);
        });
      }
    }
    console.log("Current Redemptions Locations", locations);
    return locations;
  };

  // const formatDataForTransfer = (formData: any) => {
  //   let nftsToSendBack: any = [];
  //   formData.forEach((el: any) => {
  //     nftsToSendBack.push({
  //       nftAddress: el.item.nftAddress,
  //       tokenId: el.item.tokenId,
  //       blockchain: process.env.REACT_APP_FANFIRE_CHAIN,
  //       amount: Number(el.qty),
  //     });
  //   });
  //   console.log(nftsToSendBack);
  //   return nftsToSendBack;
  // };

  // const updateSenbackNFTs = (e: any, qty: number) => {
  //   console.log(e);
  //   console.log(qty)
  //   // SetSendBackNFTs(formatDataForRedemption(e))
  // }

  // const canSendBack = (e: any) => {
  //   let canSendBack = false
  //   const itemIncludedInFormData: any = formData.filter((k:any) => k.item.name == e.name)
  //   console.log('sendback')
  //   console.log(e.name)
  //   console.log(formData)
  //   console.log(formData.filter((k:any) => k.name == e.name))
  //   if(formData.length > 0){
  //     console.log(formData[0].item.name)
  //   }
  //   canSendBack = itemIncludedInFormData.length === 0 ? true : false
  //   if(itemIncludedInFormData.length > 0 && (e.amount > itemIncludedInFormData[0].qty)){
  //     canSendBack = true
  //   }
  //   if(canSendBack){
  //     const numAvailToSenBack = itemIncludedInFormData.length > 0 ? Number(itemIncludedInFormData[0].qty) : 0
  //     updateSenbackNFTs(e, (Number(e.amount) - numAvailToSenBack))
  //   }
  //   return canSendBack
  // }

  return (
    <>
      {!authLoading &&
      isAuthenticated &&
      getRedemtionLocations().includes(
        sdkService.wallet.walletAddress ?? ""
      ) ? (
        <Grid container justifyContent="center" alignItems="center" pt={30}>
          {!showOrderRedemptions ? (
            <Grid item xs={6} lg={4}>
              <Text variantText="h3" style={{ textAlign: "center" }}>
                Order Number
              </Text>
              <InputTextField
                label="Enter redemtion order number (required)"
                value={orderNum}
                onChange={(e: any) => handleOrderNumChange(e.target.value)}
                icon="search"
              />
              {orderNum && (
                <OTPConfirmation
                  appConfig={appConfig}
                  sdkService={sdkService}
                  setOTPSuccess={setOTPSuccess}
                  otpSuccess={otpSuccess}
                  orderNum={orderNum}
                  setOrderRedemptions={setOrderRedemptions}
                  setUserOTPResponse={setUserOTPResponse}
                />
              )}
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Text variantText="h3" style={{ textAlign: "center" }}>
                  Order Number: {orderRedemptions._id}
                </Text>
                <Text variantText="h3" style={{ textAlign: "center" }}>
                  Status: {orderRedemptions.status}
                </Text>
                <Text variantText="h6" style={{ textAlign: "center" }}>
                  Redemption User:
                </Text>
                <Text variantText="body1" style={{ textAlign: "center" }}>
                  {orderRedemptions.email}
                </Text>
                <Text variantText="body1" style={{ textAlign: "center" }}>
                  {orderRedemptions.redeemerAddress}
                </Text>
              </Grid>
              <Grid item xs={12} pt={5}>
                <Text variantText="h3" style={{ textAlign: "center" }}>
                  Order Number Redemptions:
                </Text>
              </Grid>
              <Grid item xs={10} sm={10} md={8}>
                {!orderRedemptionLoading ? (
                  orderRedemptions.status.toLowerCase() == "completed" ? (
                    <Text variantText="h3" style={{ textAlign: "center" }}>
                      Order has been Completed.
                    </Text>
                  ) : (
                    orderRedemptions.redeemableNfts.map(
                      (e: any, i: number) =>
                        Number(e.totalAmount) - Number(e.redeemedAmount) >
                          0 && (
                          <>
                            <Divider
                              style={{ backgroundColor: "white" }}
                              key={`${e.name}-divider`}
                            ></Divider>
                            <CheckboxRequestInput
                              typeRequest="redeem"
                              label={e}
                              onChange={handleFieldChange}
                              qtyLimit={
                                Number(e.totalAmount) - Number(e.redeemedAmount)
                              }
                            />
                            {/* { 
                      canSendBack(e) &&
                        <FormControlLabel key={`${e.name}-form-label`} control={
                          <Checkbox
                            key={`${e.name}-checkbox`}
                            checked={true}
                            // onChange={cono}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          } 
                          sx={{float: 'right'}}
                          label={
                            <>
                            <Text variantText='body1' style={{textAlign: "left"}}>Send unclaimed items back to user wallet?</Text>
                            </>
                          } 
                        />
                      } */}
                            <Divider
                              style={{ backgroundColor: "white" }}
                            ></Divider>
                          </>
                        )
                    )
                  )
                ) : (
                  <BasicLoader text="Retrieving Order Redemptions" />
                )}
              </Grid>
              {hasFormData && (
                <RedeemConfirmation
                  appConfig={appConfig}
                  formData={formData}
                  sdkService={sdkService}
                  hasFormData={hasFormData}
                  userOTPResponse={userOTPResponse}
                />
              )}
            </>
          )}
        </Grid>
      ) : (
        // <Text variantText='h3' style={{textAlign: "center"}}>Loading</Text>
        <></>
      )}
    </>
  );
}
