import { initializeApp } from 'firebase/app';

const initFirebase = (deployment_env: string) => {
  const firebaseConfig: Record<string, any> = {
    staging: {
      apiKey: "AIzaSyBbcqxnVtrcfA3C1A1ISoXj1oSOQ34SGGY",
      authDomain: "fanfire-staging-2c22d.firebaseapp.com",
      projectId: "fanfire-staging-2c22d",
      storageBucket: "fanfire-staging-2c22d.appspot.com",
      messagingSenderId: "533997615884",
      appId: "1:533997615884:web:42d3795531028f8626a729",
      measurementId: "G-GTQCQ0EL7G"
    },
    production: {
      apiKey: "AIzaSyCNmFE9OToGWduLVTnGCdyicWqhrDM2XkQ",
      authDomain: "auth.fanfire.ai",
      projectId: "fanfire-01",
      storageBucket: "fanfire-01.appspot.com",
      messagingSenderId: "864150236251",
      appId: "1:864150236251:web:43810138c4ec4e3035637e",
      measurementId: "G-SD3Q92Y85N"
    } 
  };
  
  const Firebase = initializeApp(firebaseConfig[deployment_env]);
  return Firebase
}


//Initialize Firebase
export default initFirebase;

