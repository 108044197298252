import { FanfireSDK } from "fanfire-sdk";
import { useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "../../context/snackAlert-context";

interface Props {
  nftAddress: string;
  tokenId: string;
  sellerAddress: string;
  sdkService: FanfireSDK;
  appConfig: any;
  amount: number;
  redirect?: boolean;
}

/**
 * This hook is used handle a unlist of an item along with its state
 *
 * @returns { unListLoading, unListText, error, sendUnlist } Returns whether the unlist is loading, the text that needs to be displayed, whether there is an error and the function to run the unlist sdk method
 */
const useUnlist = () => {
  const [unListLoading, setUnListLoading] = useState(false);
  const [unListText, setUnListText] = useState("");
  const [unlistError, setUnlistError] = useState(false);

  const isWeb2 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb2);
  const isWeb3 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb3);
  const navigate = useNavigate();
  const { showSnackBar } = useSnackBar();

  const createUnlistingText = (appConfig: any, amount: number) => {
    let text;
    if (appConfig.general.site_noun_plural && amount > 1) {
      text = `Unlisting ${appConfig.general.site_noun_plural ?? ""}...`;
    } else if (appConfig.general.site_noun && amount == 1) {
      text = `Unlisting ${appConfig.general.site_noun ?? ""}...`;
    } else {
      text = "Unlisting...";
    }
    return text;
  };

  /**
   * This function is used handle a unlist of an item
   *
   * @param {string} nftAddress nft address of the token
   * @param {string} tokenId token id of the token
   * @param {string} sellerAddress the owner of the token
   * @param {FanfireSDK} sdkService sdk service to run unlist
   * @param {any} appConfig appconfig for metadata
   * @param {number} amount amount of tokens being unlisted, this is only used for display purposes
   */
  const sendUnlist = async ({
    nftAddress,
    tokenId,
    sellerAddress,
    sdkService,
    appConfig,
    amount,
    redirect = true,
  }: Props) => {
    console.log("unlisting item from marketplace", {
      nftAddress: nftAddress,
      tokenId: tokenId,
      sellerAddress: sellerAddress,
      sdkService: sdkService,
    });

    const text = createUnlistingText(appConfig, amount);

    setUnListText(text);
    setUnListLoading(true);

    try {
      await sdkService.marketplace.unlist(nftAddress, tokenId, sellerAddress);
      showSnackBar("Unlisting Item was a Success", "success");
      if (redirect) {
        if (isWeb2) {
          navigate("/profile/#transactionStarted", { replace: true });
        } else if (isWeb3) {
          navigate("/profile", { replace: true });
        }
      }
    } catch (error) {
      console.error(error);
      setUnlistError(true);
      showSnackBar(
        "An error has occurred unlisting your item. Please try again.",
        "error",
        error
      );
    }
    setUnListLoading(false);
  };

  return { unListLoading, unListText, unlistError, sendUnlist };
};

export default useUnlist;
