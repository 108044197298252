import React from "react";
import Text from "../text/Text";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useScrollTrigger } from "@mui/material";
import { useLocation } from "react-router-dom";

const styles = {
  footerContainer: {
    left: "0px",
    right: "0px",
    marginBottom: "0px",
    bottom: 0,
    paddingBottom: 30,
    zIndex: 9000,
  },
  footerContent: {
    marginLeft: "10%",
  },
};

interface Props {
  appConfig: any;
}

export default function FooterMobile({ appConfig }: Props) {
  const scrollTrigger = useScrollTrigger();
  const location = useLocation();

  return (
    <Paper
      className="primary"
      style={styles.footerContainer}
      sx={{
        position: location.pathname.includes("/0x")
          ? ("fixed" as "fixed")
          : scrollTrigger
          ? "static"
          : ("fixed" as "fixed"),
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          <Text
            variantText="h6"
            style={{
              textAlign: "center",
              ...appConfig.footers.footerTextColor.styleOverrides.text,
            }}
          >
            {appConfig.footers.mobileText}
          </Text>
          <Divider
            sx={{
              borderBottomWidth: 3,
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
