import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import { useLocation } from "react-router-dom";

const styles = {
  chip: {
    float: "left" as "left",
  },
};

interface Props {
  userWallet: string;
  listed: boolean;
  nft: any;
}

export default function ChipItem({ userWallet, nft, listed }: Props) {
  const [chipText, setChipText] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    let chip = "...";
    if (location.pathname.includes("/profile")) {
      chip = "";
    } else {
      const isOwner = nft.owner?.toLowerCase() === userWallet?.toLowerCase();
      let isReserved = false;

      if (nft.isERC1155) {
        // Check if there is any item that is not reserved
        let listingsReserved: Array<boolean> = [];
        if (nft.listings) {
          for (let index = 0; index < nft.listings.length; index++) {
            if (
              nft.listings[index].consolidatdReservedUntil !== 0 &&
              Date.now() / 1000 <= nft.consolidatdReservedUntil
            ) {
              listingsReserved.push(true);
            } else {
              listingsReserved.push(false);
            }
          }
        } else {
          listingsReserved.push(false);
        }
        if (listingsReserved.includes(false)) {
          isReserved = false;
        }
      } else {
        if (
          nft.consolidatdReservedUntil &&
          nft.consolidatdReservedUntil !== 0 &&
          Date.now() / 1000 <= nft.consolidatdReservedUntil
        ) {
          isReserved = true;
        }
      }

      if (isReserved) {
        chip = "Reserved";
      } else if (isOwner) {
        if (listed) {
          chip = "Listed";
        } else {
          chip = "Owned";
        }
      } else if (!listed) {
        chip = "Unlisted";
      } else {
        chip = "For Sale";
      }
    }

    setChipText(chip);
    // setLoading(false)
  }, [userWallet, nft, listed]);

  return (
    <>
      {chipText && (
        <Chip className="primary" style={{ ...styles.chip }} label={chipText} />
      )}
    </>
  );
}
