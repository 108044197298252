const formatIntentText = (intentText: string) => {
  const intentSplit = intentText.split("-");
  let intentTextFormatted: string = intentSplit.join(" ");
  if (
    intentTextFormatted.toLowerCase() ===
    "Reserve And Transfer Erc20".toLowerCase()
  ) {
    intentTextFormatted = "Processing Payment";
  }
  return intentTextFormatted;
};

export { formatIntentText };
