// Libraries
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import axios from "axios";
import { ethers } from "ethers";

// Helpers
import { NetworkStatusEnums } from "../../helpers/enums";
import { FanfireSDK } from "fanfire-sdk";
import {
  getBottles,
  getEstate,
  getVintage,
} from "../../helpers/catergorySpecific/wineCategoryFunctions";
import { getLowest1155Price } from "../../helpers/functions";

//Components
import ChipItem from "../chip/ChipItem";
import Text from "../text/Text";
import TertiaryButton from "../buttons/TertiaryButton";
import Favorite from "../favourite/Favorite";
import Price from "../price/Price";

//MUI
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import Chip from "@mui/material/Chip";
import Grow from "@mui/material/Grow";

const styles = {
  card: {
    // borderRadius: '12px',
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
    overflow: "visible",
    position: "relative" as "relative",
  },
  image: {
    borderRadius: "9.2px",
    filter: "drop-shadow(0px 1.95652px 24px #000000)",
    objectFit: "contain" as "contain",
    width: "100%",
  },
  video: {
    borderRadius: "9.2px",
    filter: "drop-shadow(0px 1.95652px 24px #000000)",
    objectFit: "contain" as "contain",
  },
  span: {
    color: "#8247E5",
  },
  chip: {
    float: "left" as "left",
    background: "rgba(255, 255, 255, 0.1)",
    color: "#F5F5F5",
  },
  viewArtButton: {
    marginLeft: "auto",
  },
  overlay: {
    position: "absolute" as "absolute",
    top: "10%",
    right: "10px",
    color: "white",
  },
};

interface Props {
  keyRef: string;
  nft: any;
  currency: number;
  appConfig: any;
  sdkService: FanfireSDK;
}

/**
 * The component used to display information about an NFT on the ForSale and Explore pages among others
 *
 * @param {string} keyRef - The unique key value to set for the card
 * @param {any} nft - An NFT object containing the necessary information
 * @param {number} currency - The number to multiply the NFT price by to get the value in a specified currency.
 * @param {any} appConfig - The global app config
 * @param {FanfireSDK} sdkService - The global sdk service
 * @return {JSX.Element}
 * @constructor
 */
export default function CardItem({
  keyRef,
  nft,
  currency,
  appConfig,
  sdkService,
}: Props): JSX.Element {
  const [nftMeta, setNftMeta] = useState<any>({});
  const [loading, setLoading] = useState<boolean>();
  const [listed, setListed] = useState<boolean>(false);
  const [zar, setZar] = useState<number>(0);
  const [userWallet, setUserWallet] = useState("");
  const [showVideo, setShowVideo] = useState<boolean>(false);

  const nftPriceUSDC: number = nft.listings
    ? Number(
        ethers.utils.formatUnits(
          nft.listings[0].price,
          Number(process.env.REACT_APP_FANFIRE_COIN_DECIMALS ?? 18)
        )
      )
    : 0;

  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);

  useEffect(() => {
    const priceZar = nft.isERC1155
      ? getLowest1155Price(nft)
      : nftPriceUSDC * currency;
    setZar(priceZar);
  }, [currency]);

  useEffect(() => {
    let mounted = true;
    const prep = async () => {
      if (nft.metadata) {
        const meta = nft.metadata;
        setNftMeta(meta);
      } else if (nft.tokenUri ?? nft.tokenUri.startsWith("https://")) {
        const res = await axios.get(nft.tokenUri);
        if (mounted) {
          setNftMeta(res.data);
        }
      }
    };
    prep();
    return () => {
      mounted = false;
    };
  }, []);

  const isMarketPlaceNFT = () => {
    let nftAddressesENV =
      process.env.REACT_APP_FANFIRE_NFT_CONTRACT_ADDRESSES?.split(",") ?? [];
    nftAddressesENV = nftAddressesENV.map(function (x) {
      return x.toLowerCase();
    });
    return nftAddressesENV.includes(nft.contractAddress.toLowerCase());
  };

  useEffect(() => {
    let mounted = true;
    const prep = async () => {
      if (mounted) {
        //TODO: IS THIS THE BEST WAY
        setListed(!!(nft.listings && nft.listings.length));
      }
      if (isAuthenticated) {
        const userWallet = await sdkService.wallet.walletAddress;
        if (mounted) {
          setUserWallet(userWallet ?? "");
        }
      }

      setLoading(false);
    };
    setLoading(true);
    prep();
    return () => {
      mounted = false;
    };
  }, []);

  const getNFTMetaLocation = (nftMeta: any) => {
    //TODO: Make this better
    if (nftMeta.attributes) {
      let locationIndex = nftMeta.attributes.findIndex(
        (e: any) => e.trait_type === "Town"
      );
      if (locationIndex !== -1) {
        return nftMeta.attributes[locationIndex].value;
      } else {
        return null;
      }
    }
    return null;
  };

  const getQtyAvaliable1155 = (nft: any) => {
    if (nft.listings) {
      let listingsAvail;
      if (isAuthenticated && sdkService.wallet.walletAddress) {
        const wallet = sdkService.wallet.walletAddress;
        listingsAvail = nft.listings.filter(function (el: any) {
          return (
            el.sellerAddress.toLowerCase() !== wallet.toLowerCase() && el.price
          );
        });
      } else {
        listingsAvail = nft.listings.filter(function (el: any) {
          return el.price;
        });
      }
      if (listingsAvail.length) {
        return listingsAvail
          .map((item: any) => item.amount ?? 0)
          .reduce((prev: any, next: any) => Number(prev) + Number(next));
      } else {
        return 0;
      }
    }
  };

  /**
   * Sets the video component to play if the NFT has a video tag
   */
  function handleVideoOpen() {
    setShowVideo(!!nft.video);
  }

  /**
   * Stops playing the video
   */
  function handleVideoClose() {
    setShowVideo(false);
  }

  function MediaGrow({ children }: { children: React.ReactElement<any, any> }) {
    return (
      <Grow
        in={true}
        style={{ transformOrigin: "0 0 0 0" }}
        {...{ timeout: 3000 }}
      >
        {children}
      </Grow>
    );
  }

  return (
    <Box>
      <Card
        style={styles.card}
        className="primary"
        sx={{
          ":hover": {
            transform: "scale3d(1.05, 1.05, 1)",
          },
          ":hover > img.cardImage": {
            transform: "scale3d(1.05, 1.05, 1)",
            display: "flex",
          },
        }}
        key={keyRef}
        onMouseEnter={handleVideoOpen}
        onMouseLeave={handleVideoClose}
      >
        <CardHeader
          title={
            <>
              {getNFTMetaLocation(nftMeta) && (
                <Text
                  variantText="body1"
                  style={{ textAlign: "left", display: "flex" }}
                >
                  <LocationOnTwoToneIcon fontSize="small" />
                  {getNFTMetaLocation(nftMeta)}
                </Text>
              )}
              {nft.isERC1155 && nft.amount && (
                <Text
                  variantText="body1"
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "small",
                  }}
                >
                  {window.location.pathname === "/profile" &&
                    `Owned: ${nft.amount}`}
                  {window.location.pathname === "/for-sale" &&
                    `Available: ${getQtyAvaliable1155(nft)}`}
                </Text>
              )}
            </>
          }
          action={<Favorite sdkService={sdkService} nft={nft} />}
        />
        {!nftMeta.video ? (
          // <MediaGrow>
          <CardMedia
            className="cardImage"
            style={styles.image}
            component="img"
            height="230px"
            // width="100%"
            image={nftMeta.image ?? appConfig.general.nftImageNotFoundImage}
            alt="CardItem"
          />
        ) : (
          // </MediaGrow>
          // <MediaGrow>
          <CardMedia
            className="cardVideo"
            style={styles.video}
            height="230px"
            component="video"
            src={nftMeta.video}
            loop
            // autoPlay
            controls
            playsInline
            muted
            controlsList="nofullscreen nodownload"
          />
          // </MediaGrow>
        )}
        {getBottles(nftMeta) && (
          <div style={styles.overlay}>
            <Chip
              className="primary"
              style={{ background: "rgba(255, 255, 255, 0.1)", color: "black" }}
              label={
                <Text variantText="h6" style={{ fontWeight: "bold" }}>
                  {getBottles(nftMeta)}
                </Text>
              }
            />
          </div>
        )}
        <CardContent>
          {nft.name && (
            <Text
              variantText="body1"
              style={{
                fontWeight: "bold",
                textAlign: "right",
                fontSize: "xx-small",
              }}
            >
              {nft.name}
            </Text>
          )}
          {getEstate(nftMeta) && (
            <>
              <Text
                variantText="h6"
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "large",
                }}
              >
                {getEstate(nftMeta)}
              </Text>
            </>
          )}
          <Box minHeight="80px">
            {nftMeta.name && (
              <>
                <Text
                  variantText="h6"
                  style={
                    !nft.isERC1155
                      ? { fontWeight: "bold", fontSize: "large" }
                      : { fontSize: "small" }
                  }
                >
                  {nftMeta.name.includes(getEstate(nftMeta))
                    ? nftMeta.name.replace(getEstate(nftMeta), "").trim()
                    : nftMeta.name}
                </Text>
                {getVintage(nftMeta) && (
                  <Text
                    variantText="h6"
                    style={{ fontWeight: "bold", fontSize: "medium" }}
                  >
                    {getVintage(nftMeta)}
                  </Text>
                )}
              </>
            )}
          </Box>
          <Price
            isListed={listed}
            isMarketPlaceNFT={isMarketPlaceNFT()}
            coinPrice={nft.isERC1155 ? getLowest1155Price(nft) : nftPriceUSDC}
            currencyPrice={
              nft.isERC1155 ? getLowest1155Price(nft) * currency : zar
            }
            isERC1155={nft.isERC1155}
          />
        </CardContent>
        <CardActions>
          {!loading ? (
            <ChipItem userWallet={userWallet} nft={nft} listed={listed} />
          ) : (
            <Chip style={styles.chip} label="..." />
          )}
          <TertiaryButton styleOverride={styles.viewArtButton}>
            <Text variantText="h6">
              {isMarketPlaceNFT()
                ? appConfig.general.cardItemViewText
                : "View Item"}
            </Text>
            <ChevronRightIcon />
          </TertiaryButton>
        </CardActions>
      </Card>
    </Box>
  );
}
