import React from "react";
import Text from "./Text";

interface Props {
  appConfig: any;
}

export default function Reserved({ appConfig }: Props) {
  return (
    <>
      <Text variantText="h6" style={{ textAlign: "center" }}>
        This{" "}
        {appConfig.general.site_noun ? appConfig.general.site_noun : "item"} is
        reserved.
      </Text>
    </>
  );
}
