import { Box, Grid } from "@mui/material";
import { MarketplaceListing } from "fanfire-sdk/dist/models/models/marketplace";
import React from "react";
import BasicLoader from "../loaders/BasicLoader";
import Text from "../text/Text";
import getForSale1155Length from "../../helpers/functions/getNumAvailableToBuy";

interface Props {
  marketplaceListing: MarketplaceListing;
  loading: Boolean;
}

export default function ERC1155OwnershipDashboard({
  marketplaceListing,
  loading,
}: Props) {
  return (
    <Box m={1} display="flex">
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <Text variantText="h6" style={{ textAlign: "center" }}>
            {getForSale1155Length(marketplaceListing)} units
          </Text>
          <Text variantText="body1" style={{ textAlign: "center" }}>
            Available to Buy
          </Text>
        </Grid>
        <Grid item>
          <Text variantText="h6" style={{ textAlign: "center" }}>
            {!loading ? (
              `${marketplaceListing.ownedAmount} units`
            ) : (
              <BasicLoader size={10}></BasicLoader>
            )}
          </Text>
          <Text variantText="body1" style={{ textAlign: "center" }}>
            Owned by You
          </Text>
        </Grid>
      </Grid>
    </Box>
  );
}
