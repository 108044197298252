import { FanfireSDK } from "fanfire-sdk";
import { useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "../../context/snackAlert-context";
import { NetworkStatusEnums } from "../../helpers/enums";

interface Props {
  nftAddress: string;
  tokenId: string;
  userAddress: string;
  sdkService: FanfireSDK;
  likedAmount: number;
  setLikedAmount: (amount: number) => void;
  setLiked: (liked: boolean) => void;
}

/**
 * This hook is used handle unliking of an item along with its state
 *
 * @returns { unListLoading, unListText, error, sendUnlist } Returns whether the like is loading, whether there is an error and the function to run the unlist sdk method
 */
const useUnlikeNFT = () => {
  const [unlikeLoading, setUnlikeLoading] = useState<boolean>(false);
  const [unlikeError, setUnlikeError] = useState<boolean>(false);
  const { showSnackBar } = useSnackBar();
  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);

  /**
   * This function is used handle a unliking of an item
   *
   * @param {string} nftAddress nft address of the token
   * @param {string} tokenId token id of the token
   * @param {string} userAddress the user unliking the item
   * @param {number} likedAmount the liked amount
   * @param {function} setUnlikedAmount set the liked amount
   */
  const sendUnlikeNFT = async ({
    nftAddress,
    tokenId,
    userAddress,
    sdkService,
    likedAmount,
    setLikedAmount,
    setLiked,
  }: Props) => {
    if (isAuthenticated) {
      console.log("Liking NFT", {
        nftAddress: nftAddress,
        tokenId: tokenId,
        userAddress: userAddress,
        sdkService: sdkService,
      });
      setLikedAmount(likedAmount - 1);
      setUnlikeLoading(true);
      setLiked(false);

      try {
        await sdkService.marketplace.unlikeNFT(
          userAddress,
          nftAddress,
          tokenId
        );
      } catch (error) {
        setLiked(true);
        setLikedAmount(likedAmount);
        console.error(error);
        setUnlikeError(true);
        showSnackBar(
          "An error has occurred unliking this item. Please try again.",
          "error",
          error
        );
      }
      setUnlikeLoading(false);
    } else {
      showSnackBar("You need to be logged in to unlike a NFT!", "warning");
    }
  };

  return { unlikeLoading, unlikeError, sendUnlikeNFT };
};

export default useUnlikeNFT;
