"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Transact = void 0;
var Transact = /** @class */ (function () {
    function Transact(api, chain) {
        var _this = this;
        // Method that is called when web3 is enabled in the FFSDK
        this.setWeb3 = function (web3Provider, web3Service) {
            _this.web3 = web3Provider;
            _this.web3Service = web3Service;
        };
        // Method that is called when web2 is enabled in the FFSDK
        this.setWeb2 = function (firebaseAuth) {
            _this.firebaseAuth = firebaseAuth;
        };
        // getBalance = async (address: string, chain?: string): Promise<components['schemas']['nativeBalance'] | BigNumberish | null> => {
        //   const service: FanFireAPI | Web3Service = this.web3Service ? this.web3Service : this.fanFireApi;
        //   if (!this.web3Service && !this.firebaseAuth) {
        //     throw Error('Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize');
        //   }
        //   try {
        //     let res;
        //     if (service === this.fanFireApi) {
        //       if (chain) {
        //         res = await service.getWalletNativeBalance(chain);
        //         return res.value;
        //       } else {
        //         throw Error('The chain is required wen using web2 authentication');
        //       }
        //     } else {
        //       res = await service.getWalletNativeBalance(chain);
        //       return res.value;
        //     }
        //   } catch (err) {
        //     console.error(err);
        //     throw Error('An error occurred while getting balance');
        //   }
        // };
        this.transferERC20 = function (recipientAddress, erc20Amount, erc20Address) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        service = this.web3Service ? this.web3Service : this.fanFireApi;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error('Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize');
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, service.transferERC20(erc20Address, erc20Amount, recipientAddress, this.chain)];
                    case 2:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 3:
                        err_1 = _a.sent();
                        console.error(err_1);
                        throw Error("transferERC20 Error: ".concat(err_1));
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.fanFireApi = api;
        this.chain = chain;
    }
    return Transact;
}());
exports.Transact = Transact;
