import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Variant } from '@mui/material/styles/createTypography';

interface Props {
    variantText: Variant;
    children: React.ReactNode;
    style?: object;
    onClick?: any;
}

export default function Text({ onClick, children, variantText, style }: Props) {
    return (
        <Typography onClick={onClick} style={{textTransform: 'none', ...style}} variant={variantText}>{children}</Typography>
    );
}