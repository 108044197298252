// Libraries
import React from "react";

// MUI
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { Close } from "@mui/icons-material";

const styles = {
  videoPlayer: {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "50vh",
    filter: "drop-shadow(0px 1.27599px 15.6522px #000000)",
    borderRadius: "10px",
    objectFit: "contain" as "contain",
    display: "flex",
  },
  closeButton: {
    position: "absolute" as "absolute",
    top: "8%",
    right: "10px",
  },
};

interface Props {
  source: string;
  type: string;
  onClose?: () => void;
}

/**
 * The component displaying the video on the NFT detail page.
 *
 * @param {string} source - The URL or location of the video to display.
 * @param {string} type - The type of the video (e.g. video/mp4)
 * @param {(() => void) | undefined} onClose - The method to call on clicking the close button.
 * @return {JSX.Element}
 * @constructor
 */
export default function VideoShowcase({ source, type, onClose }: Props) {
  return (
    <Box pt={5}>
      {/* --- Close Button --- */}
      {/* {onClose ? (
          <Fab className="primary" size="small" style={styles.closeButton} onClick={onClose}>
            <Close />
          </Fab>
        ) : null} */}

      {/* --- Video --- */}
      <video
        style={styles.videoPlayer}
        loop
        // autoPlay
        muted={true}
        playsInline
        controls
        controlsList="nodownload"
      >
        <source src={source} type={type} />
      </video>
    </Box>
  );
}
