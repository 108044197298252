import { useEffect, useReducer, useState } from "react";
import * as React from "react";
import Text from "../text/Text";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ethers } from "ethers";
import axios from "axios";
import { FanfireSDK } from "fanfire-sdk";

//MUI
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ScrollToTop from "../helpers/ScrollToTop";

const styles = {
  overlay: {
    position: "absolute" as "absolute",
    top: "20vh",
    left: "10%",
    //   color: 'white',
  },
  dialogContainer: {
    // backgroundColor: 'white',
    borderRadius: "16px",
    height: "90%",
    width: "60%",
    flex: 1,
  },
};

function createData(
  e: any,
  name: any,
  image: any,
  attributes: string[],
  description: any,
  address: string,
  id: string
) {
  let data: any = {
    name,
    image,
    description,
    address: address ?? "",
    id: id ?? "",
  };
  for (const attr of attributes) {
    data = {
      ...data,
      [attr]:
        e.attributes[e.attributes.findIndex((k: any) => k.trait_type === attr)]
          ?.value ?? "NA",
    };
  }
  return data;
}

const RowSkeleton = () => {
  return (
    <TableBody>
      {Array(5)
        .fill(1)
        .map((e: any, i: number) => (
          <TableRow
            key={`${i}_row`}
            sx={{ "& > *": { borderBottom: "unset" }, paddingVertical: 5 }}
          >
            {Array(6)
              .fill(1)
              .map((e: any, i: number) => (
                <TableCell key={`${i}_cell`}>
                  <Skeleton height={70} />
                </TableCell>
              ))}
          </TableRow>
        ))}
    </TableBody>
  );
};
const TableSkeleton = (appConfig: any) => {
  const attributes = appConfig.appConfig.nftVaultTable.table.attributesFromMeta;
  return (
    <Grid item xs={12} sm={12} md={8}>
      <TableContainer
        component={Paper}
        style={{ maxHeight: "80vh", textTransform: "none" }}
      >
        <Table
          stickyHeader
          aria-label="collapsible table"
          style={{ maxHeight: "70vh" }}
        >
          <TableHead
            key="table_head1"
            style={{ height: 75 }}
            className="primary"
          >
            <TableRow>
              <TableCell
                key={`filter_cell1`}
                sx={{ fontSize: 18, fontWeight: "bold" }}
                align="center"
              >
                Details
              </TableCell>
              <TableCell
                key={`filter_cell2`}
                sx={{ fontSize: 18, fontWeight: "bold" }}
                align="center"
              >
                Name
              </TableCell>
              <TableCell
                key={`filter_cell3`}
                sx={{ fontSize: 18, fontWeight: "bold" }}
                align="center"
              >
                Image
              </TableCell>
              {attributes.map((e: string, i: number) => (
                <TableCell
                  key={`${i}_filter_cell`}
                  sx={{ fontSize: 18, fontWeight: "bold" }}
                  align="center"
                >
                  {e}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <RowSkeleton />
        </Table>
      </TableContainer>
    </Grid>
  );
};

const getOpenSeaNftUrl = (address: any, id: any) => {
  let url = "";
  if (process.env.REACT_APP_FANFIRE_CHAIN === "mumbai") {
    url = `https://testnets.opensea.io/assets/mumbai/${address}/${id}`;
  } else {
    url = `https://opensea.io/assets/matic/${address}/${id}`;
  }
  return url;
};

interface ItemDetailsProps {
  open: boolean;
  closeHandler: () => void;
  row: any;
}

const ItemDetails = (props: ItemDetailsProps) => {
  const { open, closeHandler, row } = props;

  return (
    <Dialog
      fullWidth={true}
      fullScreen={true}
      open={open}
      onClose={closeHandler}
      scroll="body"
      PaperProps={{
        style: styles.dialogContainer,
      }}
    >
      <DialogTitle style={{ textTransform: "capitalize" }}>
        {row.name}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={2} sm={4} md={7}>
            <Box
              component="img"
              sx={{
                maxHeight: 700,
                width: "auto",
                objectFit: "contain",
              }}
              alt="vault image"
              src={row.image}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Text variantText="h6">Critic Ratings</Text>
            <Table aria-label="purchases">
              <TableBody>
                <Text
                  variantText="body1"
                  style={{ textTransform: "none", fontSize: "medium" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: row.description }} />
                </Text>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

function Row(props: { row: ReturnType<typeof createData>; appConfig: any }) {
  const { row, appConfig } = props;
  const [open, setOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align={"center"} component="th" scope="row">
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
          >
            <Grid item sx={{ pl: 1, pr: 1 }}>
              <Tooltip title={"Show Details"}>
                <IconButton
                  style={{ borderRadius: "8px" }}
                  aria-label="expand row"
                  size="small"
                  onClick={() => setPopupOpen(true)}
                >
                  <FullscreenIcon sx={{ fontSize: 30 }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item sx={{ pl: 1, pr: 1 }}>
              <Tooltip title={"View on OpenSea"}>
                <a
                  href={getOpenSeaNftUrl(row.address, row.id)}
                  //title="View on OpenSea"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    p={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="left"
                    style={{ borderRadius: "8px" }}
                  >
                    <IconButton size="small">
                      <img
                        style={{ height: 28, width: 28 }}
                        src={appConfig.general.openseaLogo}
                        alt="OpenSea Logo"
                      />
                    </IconButton>
                  </Box>
                </a>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {row.name}
        </TableCell>
        <TableCell align="center">
          <IconButton sx={{ height: 70 }} onClick={() => setPopupOpen(true)}>
            <Box
              component="img"
              sx={{
                height: 70,
                width: "auto",
                transition: "transform 0.15s ease-in-out",
                position: "relative",
                objectFit: "contain",
                ":hover": {
                  transform: "scale3d(3, 3, 1)",
                  zIndex: 9,
                },
              }}
              alt="vault image"
              src={row.image}
            />
          </IconButton>
        </TableCell>
        {appConfig.nftVaultTable.table.attributesFromMeta.map(
          (e: string, i: number) => (
            <>
              {/* <TableCell sx={{ fontSize: 18, fontWeight: 'bold'}} align="center">{e}</TableCell> */}
              <TableCell key={`${i}_meta_filter_cell`} align="center">
                {row[e] ?? "N/A"}
              </TableCell>
            </>
          )
        )}
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Text variantText="h6" style={{ color: "black" }}>
                Critic Ratings
              </Text>
              <Table aria-label="purchases">
                <TableBody>
                  <Text
                    variantText="body1"
                    style={{ textTransform: "none", fontSize: "medium" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: row.description }}
                    />
                  </Text>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ItemDetails
        open={popupOpen}
        closeHandler={() => setPopupOpen(false)}
        row={row}
      />
    </React.Fragment>
  );
}

function vaultReducer(state: any, action: any) {
  if (action.type === "add") {
    // console.log('payload: ', action.payload);
    let { token_id } = action.payload;
    if (!token_id) token_id = action.payload.tokenId ?? "";
    //console.log('token id', token_id)
    // console.log('state', state)
    const currentTokenIndex = state.findIndex(
      (token: any) => token.tokenId === token_id
    );
    // console.log('currentTokenIndex:', currentTokenIndex)
    if (currentTokenIndex !== -1) {
      let updatedToken = action.payload;
      updatedToken = { ...updatedToken, vaultLoaded: true };
      let updatedState = state;
      updatedState[currentTokenIndex] = updatedToken;
      //console.log("UPDATED STATE: ");
      //console.log(updatedState)
      return updatedState;
    }
    console.log("Cannot find token to update");
    return state;
  }
}
const applyPagination = (tokens: any, page: any, limit: any) =>
  tokens.slice(page * limit, page * limit + limit);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  selectDropdown: { color: "#fff", backgroundColor: "#1b1f38" },
  menuItem: {
    "&:hover": {
      backgroundColor: "#3b3f58",
    },
  },
});

const CollectionTable = ({
  vaultTokensInterface,
  loading,
  ffsdk,
  appConfig,
}: any) => {
  const initialState = vaultTokensInterface;
  // vaultTokensInterface.map((token) => {
  //     return {id: token.tokenId, ...token}
  // })
  // console.log('init state: ', vaultTokensInterface)
  const [vaultState, vaultDispatch] = useReducer(vaultReducer, initialState);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowLoading, setRowLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(10);
  // const tableRef = useRef>();

  const getVaultTokenDetails = async (vaultTokens: any) => {
    let tokensToUpdate = vaultTokens.filter(
      (token: any) => !token.vaultLoaded ?? true
    );
    // console.log('tokensToUpdate:', tokensToUpdate)
    if (tokensToUpdate.length === 0) {
      createRows(appConfig);
      return;
    }
    let fixedTokens: Array<any> = [];
    tokensToUpdate.map((token: any) => {
      let contractAddress = token.contractAddress;
      fixedTokens.push({ ...token, contractAddress });
    });
    setRowLoading(true);
    const tokens = await ffsdk.nft.getBulkVaultTokenDetails(fixedTokens);
    console.log("getVaultTokenDetails: ", tokens);
    const res = await tokens.map(async (token: any) => {
      let nft = token;
      if (token.tokenUri && !token.metadata) {
        const res = await axios.get(nft.tokenUri);
        if (res.data) {
          nft = { ...nft, metadata: res.data };
        }
      } else if (token.metadata) {
        nft = { ...nft, metadata: nft.metadata };
      }
      vaultDispatch({ type: "add", payload: nft });
    });
    await Promise.all(res);
    setRowLoading(false);
    createRows(appConfig);
  };

  const getTokenUriMeta = async (tokenUri: string) => {
    const res = await axios.get(tokenUri);
    return res.data;
  };

  const createRows = async (appConfig: any) => {
    if (!rowLoading) {
      let rows1: any = [];
      const sliced = applyPagination(vaultState, currentPage, limit);
      await Promise.all(
        sliced.map(async (t: any) => {
          // console.log('t:', t)
          if (t.metadata) {
            //const e = t.metadata ?? {}
            const e = JSON.parse(t.metadata);
            rows1.push(
              createData(
                e,
                e.name ?? "NA",
                e.image ?? "NA",
                appConfig.nftVaultTable.table.attributesFromMeta,
                e.description ?? "NA",
                t.token_address,
                t.token_id
              )
            );
          } else if (t.token_uri && t.token_uri.startsWith("https://")) {
            try {
              console.log("There is no metadata getting from it tokenURI...");
              const e: any = await getTokenUriMeta(t.token_uri);
              rows1.push(
                createData(
                  e,
                  e.name ?? "NA",
                  e.image ?? "NA",
                  appConfig.nftVaultTable.table.attributesFromMeta,
                  e.description ?? "NA",
                  t.token_address,
                  t.token_id
                )
              );
            } catch (error) {
              console.log(
                "There was an error fetching the NFT metadata from token URI"
              );
              console.error(error);
            }
          }
          // else {
          //     console.log("metadata not available for: ", t);
          // }
        })
      );
      setRows(rows1);
    }
  };

  useEffect(() => {
    getVaultTokenDetails(
      vaultState.slice(currentPage * limit, currentPage * limit + limit)
    );
    // console.log('vaultState:', vaultState)
  }, [vaultTokensInterface, currentPage, limit]);

  const classes = useStyles2();

  const pageChange = (page: any) => {
    // tableRef.current && tableRef.current?.scrollIntoView();
    setCurrentPage(page);
  };

  return (
    <Grid item xs={12} sm={12} md={8}>
      <TableContainer
        component={Paper}
        style={{ maxHeight: "80vh", textTransform: "none" }}
      >
        <Table
          component={Paper}
          stickyHeader
          aria-label="collapsible table"
          style={{ maxHeight: "70vh" }}
        >
          {/* <Table ref={tableRef} component={Paper} stickyHeader aria-label="collapsible table" style={{ maxHeight: '70vh' }}> */}
          <TableHead
            key="table_head1"
            style={{ height: 75 }}
            className="primary"
          >
            <TableRow className="tableHead">
              <TableCell
                key={`cell_header1`}
                className="tableHead"
                sx={{ fontSize: 18, fontWeight: "bold" }}
                align="center"
              >
                Details
              </TableCell>
              <TableCell
                key={`cell_header2`}
                className="tableHead"
                sx={{ fontSize: 18, fontWeight: "bold" }}
                align="center"
              >
                Name
              </TableCell>
              <TableCell
                key={`cell_header3`}
                className="tableHead"
                sx={{ fontSize: 18, fontWeight: "bold" }}
                align="center"
              >
                Image
              </TableCell>
              {appConfig.nftVaultTable.table.attributesFromMeta.map(
                (e: string, i: number) => (
                  <>
                    <TableCell
                      key={`${i}_cell_header`}
                      className="tableHead"
                      sx={{ fontSize: 18, fontWeight: "bold" }}
                      align="center"
                    >
                      {e}
                    </TableCell>
                  </>
                )
              )}
            </TableRow>
          </TableHead>

          {
            rowLoading ? (
              <RowSkeleton />
            ) : (
              <TableBody>
                {rows.map((row: any, i: number) => (
                  <Row
                    key={`${row.name}_${i}`}
                    row={row}
                    appConfig={appConfig}
                  />
                ))}
              </TableBody>
            )
            // <TableBody>
            // {tokenRows.map((row: any) => (
            //         <Row key={row.name} row={row} />
            //     ))}
            // </TableBody>
          }
        </Table>
        <TableFooter>
          <TablePagination
            color={"black"}
            component="div"
            count={vaultTokensInterface.length}
            onPageChange={(event, page) => pageChange(page)}
            page={currentPage}
            rowsPerPage={limit}
            onRowsPerPageChange={(event) =>
              setLimit(parseInt(event.target.value))
            }
            rowsPerPageOptions={[5, 10]}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              MenuProps: { classes: { paper: classes.selectDropdown } },
            }}
            classes={{ menuItem: classes.menuItem }}
          />
        </TableFooter>
      </TableContainer>
    </Grid>
  );
};

interface Props {
  sdkService: FanfireSDK;
  appConfig: any;
}

export default function ListCollectionNFTs({ sdkService, appConfig }: Props) {
  const [loading, setLoading] = useState(true);
  const [nftLoading, setNftLoading] = useState(true);
  const [nftCollections, setNftCollections] = useState<any>({});
  const location: any = useLocation();
  const navigate = useNavigate();
  const { address, id }: any = useParams();
  const [error, setError] = useState(false);

  const getNFT = async (): Promise<any> => {
    let nft: any;
    try {
      nft = await sdkService.marketplace.getNftDetail(
        ethers.utils.getAddress(address),
        id,
        sdkService.wallet.walletAddress
      );
      console.log("aawe");
      console.log(nft);
      if (nft[0].metadata) {
        nft = { ...nft, metadata: nft[0].metadata };
      } else if (nft[0].tokenUri ?? nft[0].tokenUri.startsWith("https://")) {
        const res = await axios.get(nft[0].tokenUri);
        nft = { ...nft, metadata: res.data };
      }
      // if (!nft.vaultTokens) {
      // ffSDK.nft.getVaultNFTForMarketplace(nftAddress, listings)
      console.log('DIE IS "N ARRAY');
      const vaultTokens = await sdkService.vault.tokens(
        nft[0].contractAddress,
        nft[0].tokenId
      );
      console.log("awjidhkajhwd");
      console.log(vaultTokens);
      // const nftCollection = await sdkService.nft.getvaultTokensVaultNFTForMavaultTokensrketplace(
      //   nft.contractAddress,
      //   listings
      // );
      // const vaultTokens = nftCollection.find(
      //   (token: any) =>
      //     token.token_id === nft.token_id &&
      //     token.contractAddress === nft.contractAddress
      // );
      if (!vaultTokens) {
        console.log("could not find collection");
      }
      // console.log('nftCollection:', nftCollection)
      nft = { ...nft[0], vaultTokens: vaultTokens };
      // }
      return nft;
    } catch (error) {
      console.log(error);
      console.log("There was an error fetching the NFT:");
      console.error(error);
      return undefined; //hiers hy
    }
  };

  const getRows = async () => {
    setLoading(true);
    console.log("getting rows");
    const nftCollection: any = location.state?.nft ?? null;
    console.log("nftCollection: ", nftCollection);
    // if (nftCollection) {
    //   if (Array.isArray(nftCollection.tokens)) {
    //     setNftCollections(nftCollection);
    //   } else {
    //     console.log("!Array");
    //     const vaultTokens = [nftCollection.tokens];
    //     console.log("vaultTokens: ", vaultTokens);
    //     setNftCollections({ ...nftCollection, vaultTokens });
    //   }
    // } else {
    console.log("fetching nft");
    const nft = await getNFT();
    console.log("fetched nft:", nft);
    if (nft && nft.vaultTokens) {
      if (Array.isArray(nft.vaultTokens)) {
        setNftCollections(nft);
      } else {
        const vaultTokens = [nft.vaultTokens];
        setNftCollections({ ...nft, vaultTokens });
      }
      // }
      setNftLoading(false);
      setLoading(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    getRows();
  }, []);

  return (
    <>
      {error ? (
        <>ERROR</>
      ) : (
        <>
          <div style={styles.overlay}>
            <ScrollToTop />
            {/* <Avatar sx={{ width: 40, height: 40, background: 'rgba(255, 255, 255, 0.1)', top: '-5vh'}}>
        <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosNewRoundedIcon sx={{ fontSize: 25, color: 'white'}}/>
        </IconButton>
        </Avatar> */}
          </div>
          <Grid>
            <Grid item xs={12}>
              <Box sx={{ px: 10, mt: 25 }}>
                <ScrollToTop />
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    background: `${
                      appConfig.navItems.backButtonNavIconPaddingColor
                        ? appConfig.navItems.backButtonNavIconPaddingColor
                        : "rgba(255, 255, 255, 0.1)"
                    }`,
                    top: "-5vh",
                  }}
                >
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIosNewRoundedIcon
                      sx={{
                        fontSize: 25,
                        color: `${
                          appConfig.navItems.backButtonNavIconColor
                            ? appConfig.navItems.backButtonNavIconColor
                            : "white"
                        }`,
                      }}
                    />
                  </IconButton>
                </Avatar>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ px: 10 }}>
                {nftLoading ? (
                  <Skeleton
                    sx={{ bgcolor: "grey.700" }}
                    width={300}
                    height={50}
                    variant="text"
                  />
                ) : (
                  <Text variantText="h1" style={{ textAlign: "left" }}>
                    {nftCollections.metadata?.name ?? ""}
                  </Text>
                )}
                {nftLoading ? (
                  <Skeleton
                    sx={{ bgcolor: "grey.700" }}
                    width={200}
                    height={40}
                    variant="text"
                  />
                ) : (
                  <Text variantText="h3" style={{ textAlign: "left" }}>
                    {nftCollections.name ?? ""}
                  </Text>
                )}
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              pt={5}
              pl={5}
              pr={5}
              justifyContent="center"
              alignItems="center"
            >
              {!loading ? (
                <CollectionTable
                  vaultTokensInterface={nftCollections.vaultTokens ?? []}
                  loading={loading}
                  ffsdk={sdkService}
                  appConfig={appConfig}
                />
              ) : (
                <TableSkeleton appConfig={appConfig} />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
