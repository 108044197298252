import React from "react";
import Text from "../../text/Text";
import {
  getEstate,
  getVintage,
} from "../../../helpers/catergorySpecific/wineCategoryFunctions";
import useIsMobile from "../../../hooks/useIsMobile";

interface Props {
  nftDetail: any;
}

export default function WineDetailPageHeading({ nftDetail }: Props) {
  const { mobileView } = useIsMobile();
  return (
    <>
      {getEstate(nftDetail.metadata) && (
        <Text
          variantText={mobileView ? "h3" : "h1"}
          style={{ fontFamily: "RalewaySemiBold", fontWeight: "bolder" }}
        >
          {getEstate(nftDetail.metadata)}
        </Text>
      )}
      {nftDetail.metadata && nftDetail.metadata.name && (
        <Text
          variantText={mobileView ? "body1" : "h3"}
          style={{
            fontFamily: "RalewaySemiBold",
            fontWeight: "bold",
          }}
        >
          {nftDetail.metadata.name}
        </Text>
      )}
      {getVintage(nftDetail.metadata) && (
        <Text
          variantText={mobileView ? "h6" : "h2"}
          style={{
            fontFamily: "RalewaySemiBold",
            fontWeight: "bolder",
            color: "black",
          }}
        >
          {getVintage(nftDetail.metadata)}
        </Text>
      )}
      {nftDetail.name && (
        <Text variantText="h3" style={{ fontFamily: "RalewaySemiBold" }}>
          {nftDetail.name}
        </Text>
      )}
    </>
  );
}
