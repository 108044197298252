import React from 'react'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Grid from '@mui/material/Grid';

interface Props{
    appConfig: any;
}

export default function SocialMedia({ appConfig }: Props) {
    return (
        <Grid container alignItems="center" justifyContent="center" paddingBottom='1rem'>
            <Grid item>
                {appConfig.general.socialMedia.twitter !== '' &&
                    <IconButton className='primary' onClick={()=> window.open(appConfig.general.socialMedia.twitter, "_blank")}>
                        <TwitterIcon/>
                    </IconButton>
                }
                {appConfig.general.socialMedia.facebook !== '' &&
                    <IconButton className='primary' onClick={()=> window.open(appConfig.general.socialMedia.facebook, "_blank")}>
                        <FacebookIcon />
                    </IconButton>
                }
                {appConfig.general.socialMedia.youtube !== '' &&
                    <IconButton className='primary' onClick={()=> window.open(appConfig.general.socialMedia.youtube, "_blank")}>
                        <YouTubeIcon />
                    </IconButton>
                }
                {appConfig.general.socialMedia.instagram !== '' &&
                    <IconButton className='primary' onClick={()=> window.open(appConfig.general.socialMedia.instagram, "_blank")}>
                        <InstagramIcon />
                    </IconButton>
                }
                {appConfig.general.socialMedia.custom.map((e: any, i: any)=>
                    <IconButton className='primary' key={i} onClick={()=> window.open(e.link, "_blank")}>
                        <img style={{height: 23, width: 24}} src={e.logo} alt="ff Logo" />
                    </IconButton>
                )}
                
            </Grid>
        </Grid>
    )
}