/**
 * Gets the name, heading and subheading of a wine listing.
 *
 * @param nftMeta - The NFT metadata.
 * @return {name: string, heading: string, subHeading: string}
 */
function getWineCategoryTxLabels(nftMeta: any): {
  name: string;
  heading: string;
  subHeading: string;
} {
  let name: string = nftMeta && nftMeta.name ? nftMeta.name : "";
  const estate: string | null = getEstate(nftMeta);
  let heading: string = estate ?? "";
  let subHeading: string = `${
    getVintage(nftMeta) ? getVintage(nftMeta) : ""
  } | ${getBottles(nftMeta) ? getBottles(nftMeta) : ""}`;

  if (subHeading === " | ") {
    subHeading = "";
  }

  return { heading, subHeading, name };
}

/**
 * Get the Estate from the token metadata attributes.
 *
 * @param nftMeta - The NFT metadata.
 */
function getEstate(nftMeta: any): string | null {
  return getTrait(nftMeta, "estate", true);
}

/**
 * Get the Vintage from the token metadata attributes.
 *
 * @param nftMeta - The NFT metadata.
 */
function getVintage(nftMeta: any): string | null {
  return getTrait<string>(nftMeta, "vintage", true);
}

type TraitType = "vintage" | "estate" | "Bottles" | "Volume (ml)";

/**
 * Get the specified trait from the token metadata attributes.
 *
 * @param nftMeta - The NFT metadata.
 * @param {TraitType} trait - The type of the trait to find.
 * @param isLowercase
 * @returns {any}
 */
function getTrait<T>(
  nftMeta: any,
  trait: TraitType,
  isLowercase: boolean
): T | null {
  if (!nftMeta || !nftMeta.attributes) return null;

  try {
    /** Index of the trait */
    let itemIndex = nftMeta.attributes.findIndex(
      isLowercase
        ? (e: any) => e.trait_type.toLowerCase() === trait
        : (e: any) => e.trait_type === trait
    );

    /** Check if the index of the trait has been found */
    if (itemIndex === -1) return null;

    return nftMeta.attributes[itemIndex].value;
  } catch (error) {
    return null;
  }
}

/**
 * Get the bottles and the volume of each bottle and returns them in a string
 * formatted like this: <bottles> x <volume>
 *
 * @param nftMeta - The NFT metadata.
 */
function getBottles(nftMeta: any): string | null {
  if (!nftMeta || !nftMeta.attributes) return null;

  /** The number of bottles */
  const bottles: string | null = getTrait(nftMeta, "Bottles", false);
  /** The volume of each bottle */
  const ml: string | null = getTrait(nftMeta, "Volume (ml)", false);

  if (!bottles || !ml) return null;

  return `${bottles} x ${ml}ml`;
}

/**
 * Get the bottles and the volume of each bottle and returns them in a string
 * This deduces the bottles and then translates it to cases if needed
 *
 * @param nftMeta - The NFT metadata. Used to get the bottles and ml metadata from token
 * @param amount - The amount owned.
 */
function getBottlesHumanFriendly(nftMeta: any, amount: number): string | null {
  if (!nftMeta || !nftMeta.attributes) return null;

  /** The number of bottles */
  const bottles: string | null = getTrait(nftMeta, "Bottles", false);
  /** The volume of each bottle */
  const ml: string | null = getTrait(nftMeta, "Volume (ml)", false);

  if (!bottles || !ml) return `${amount} unit${amount === 1 ? "" : "s"}`;

  let postfix = "";
  if (Number(bottles) === 6 && amount > 1) {
    postfix = "cases";
  }

  if (Number(bottles) === 6 && amount === 1) {
    postfix = "case";
  }

  if (Number(bottles) !== 6) {
    if (Number(bottles) !== 1) {
      if (amount === 1) {
        postfix = `case (${bottles} per case)`;
      } else {
        postfix = `cases (${bottles} per case)`;
      }
    } else {
      if (amount === 1) {
        postfix = "bottle";
      } else {
        postfix = "bottles";
      }
    }
  }

  return `${amount} ${postfix}`;
}

export {
  getWineCategoryTxLabels,
  getBottles,
  getVintage,
  getEstate,
  getBottlesHumanFriendly,
};
