import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { trusted } from "mongoose";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  hidden: {
    display: "-webkit-box",
    WebkitLineClamp: 4,
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
  },
}));

function ReadMoreComponent({ children }: any) {
  const classes: any = useStyles();
  const [isHidden, setIsHidden] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const size = document.querySelector(".description");
    if (size && size.clientHeight > 150) {
      setIsHidden(true);
      setShow(true);
    } else {
      setShow(false);
      setIsHidden(false);
    }
  }, []);

  return (
    <>
      <div className={isHidden ? classes.hidden : "description"}>
        {children}
      </div>
      {show && (
        <Button size="small" onClick={() => setIsHidden(!isHidden)}>
          {isHidden ? "⬇ More" : "⬆ Less"}
        </Button>
      )}
    </>
  );
}

export default ReadMoreComponent;
