import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import store from "./store/store";
import {store} from "fanfire-components";
import {Provider} from "react-redux";

import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

Sentry.init({
    dsn: "https://f4a285c0ddf64dd8a0ce689ca2ec22e9@o1343579.ingest.sentry.io/6618478",
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    replaysSessionSampleRate:
        process.env.REACT_APP_FANFIRE_DEPLOYMENT_ENV === "staging" ? 1.0 : 0.1, // record a whole session
    replaysOnErrorSampleRate: 1.0, // record with buffer when error occurs

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    environment: process.env.REACT_APP_FANFIRE_DEPLOYMENT_ENV ?? "unknown",
    release:
        process.env.REACT_APP_FANFIRE_DEPLOYMENT_ENV === "production"
            ? "1.0.0"
            : undefined, // only set up release number for production
    tracesSampleRate: 1.0,
});

if (process.env.REACT_APP_FANFIRE_DEPLOYMENT_ENV === "production") {
    console.log = () => {
    };
    console.error = (error) => {
        Sentry.captureException(error);
    };
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
