import React, { useEffect, useState } from "react";
import CheckboxRequestInput from "../inputs/CheckboxRequestInput";
import { useSnackBar } from "../../context/snackAlert-context";
import { NetworkStatusEnums } from "../../helpers/enums";
import { useSelector, RootStateOrAny } from "react-redux";
import { NavLink as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FanfireSDK } from "fanfire-sdk";

// MUI
import Grid from "@mui/material/Grid";
import Text from "../text/Text";
import PrimaryButton from "../buttons/PrimaryButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import DialogContentText from "@mui/material/DialogContentText";
import SecondaryButton from "../buttons/SecondaryButton";
import BasicLoader from "../loaders/BasicLoader";
import DialogActions from "@mui/material/DialogActions";
import InputTextField from "../inputs/InputTextField";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import RedeemIcon from "@mui/icons-material/Redeem";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getEllipsisTxt } from "../../helpers/formatters";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Slide from "@mui/material/Slide";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const styles = {
  dialogContainer: {
    borderRadius: "16px",
    width: "100%",
  },
};

interface ConfirmationProps {
  sdkService: FanfireSDK;
  appConfig: any;
  formData: any;
  hasFormData: any;
  mobileView?: boolean;
}

const RedeemConfirmation = ({
  sdkService,
  appConfig,
  formData,
  hasFormData,
  mobileView,
}: ConfirmationProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const [phone, setPhone] = useState<string>("");
  const [choice, setChoice] = useState<string>("");
  const { showSnackBar } = useSnackBar();

  const user = useSelector((state: RootStateOrAny) => state.user);
  const isWeb2 = user.auth.isWeb2;
  const isWeb3 = user.auth.isWeb3;
  const isAuthenticated = NetworkStatusEnums.SUCCESS === user.status;
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>(isWeb2 ? user.user.email : "");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatDataForRedemption = (formData: any) => {
    let redeemableNFTs: any = [];
    formData.forEach((el: any) => {
      redeemableNFTs.push({
        name: el.item.metadata.name,
        nftAddress: el.item.nftAddress,
        tokenId: el.item.tokenId,
        blockchain: process.env.REACT_APP_FANFIRE_CHAIN,
        totalAmount: el.qty,
      });
    });
    console.log("Items selected to be redeemed", redeemableNFTs);
    return redeemableNFTs;
  };

  const requestSubmit = async () => {
    setLoading(true);
    try {
      console.log("Submitting redemption request");
      const redemption = await sdkService.redemptions.createRedemption(
        sdkService.wallet.walletAddress ?? "",
        formatDataForRedemption(formData),
        choice,
        "0",
        email
      );
      showSnackBar("Redemption Request Successful!", "success");
      setLoading(false);
      setOpen(false);
      if (isWeb2) {
        navigate("/profile/#redeemed", { replace: true });
      } else if (isWeb3) {
        navigate("/profile#redemptions", { replace: true });
      }
    } catch (error) {
      showSnackBar(
        "Redemption Request Failed. Please try again.",
        "error",
        error
      );
      console.error(error);
      setLoading(false);
      setOpen(false);
    }
  };

  const getRedemtionLocations = () => {
    let locations: Array<{}> = [];
    const env: any = process.env.REACT_APP_FANFIRE_CHAIN;
    if (appConfig.general.enableQrRedemption) {
      if (env !== "" && appConfig.general.redemptionLocations) {
        appConfig.general.redemptionLocations.map((element: any) => {
          locations.push({ name: element.name, address: element[env] });
        });
      }
    }
    return locations;
  };

  return (
    <div>
      {mobileView ? (
        <Slide direction="down" in={hasFormData} mountOnEnter unmountOnExit>
          <Fab
            onClick={() => handleClickOpen()}
            type="submit"
            variant="extended"
            sx={{
              margin: 0,
              top: "auto",
              right: 20,
              bottom: 80,
              left: "auto",
              position: "fixed",
            }}
          >
            <Text variantText="h3">Redeem</Text>
          </Fab>
        </Slide>
      ) : (
        <PrimaryButton onClick={() => handleClickOpen()}>
          <Text variantText="h6">Redeem</Text>
        </PrimaryButton>
      )}
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: styles.dialogContainer,
        }}
      >
        <DialogTitle component="div">
          <Avatar sx={{ width: 64, height: 64, background: "#000000" }}>
            <RedeemIcon sx={{ fontSize: 50, color: "white" }} />
          </Avatar>
        </DialogTitle>
        <DialogTitle id="alert-dialog-title" component="div">
          <Text variantText="h1">Redeem</Text>
        </DialogTitle>
        <DialogContent>
          <Text variantText="h6">
            Unsure about how our redemption process works?
          </Text>
          <Text variantText="body1">
            <RouterLink
              style={{ textDecoration: "none" }}
              to="/faq"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more
            </RouterLink>{" "}
            on our FAQ page.
          </Text>
          <DialogContentText
            id="alert-dialog-slide-description"
            component="div"
          >
            <InputTextField
              label="Email (Required)"
              onChange={(e: any) => setEmail(e.target.value)}
              value={email}
            />
            <Box pt={1} pb={1}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small">{`Vault`}</InputLabel>
                <Select
                  name={`Vault`}
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={choice}
                  label={`Vault`}
                  defaultValue=""
                  onChange={(e: any) => setChoice(e.target.value)}
                >
                  <MenuItem value={""}>---</MenuItem>
                  {getRedemtionLocations().map((e: any, i: number) => (
                    <MenuItem key={`${e.name}-${i}`} value={e.address}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {/* <InputTextField label='Phone Number' onChange={(e: any)=>setPhone(e.target.value)}/> */}
          </DialogContentText>
          <Text
            variantText="h6"
            style={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {appConfig.general.site_noun_plural
              ? appConfig.general.site_noun_plural
              : "items"}{" "}
            to Redeem
          </Text>
          {formData.map((element: any, i: number) => (
            <div key={`${i}-outerDiv-dialog`}>
              <Text variantText="body1">
                {element.item.metadata.name} x {element.qty}
              </Text>
              <Divider
                key={`${i}-divider1`}
                style={{ backgroundColor: "white" }}
              ></Divider>
            </div>
          ))}
        </DialogContent>

        <Box m={2}>
          <DialogActions style={{ textAlign: "center" }}>
            {!loading && (
              <>
                {email && choice && (
                  <PrimaryButton onClick={() => requestSubmit()}>
                    <Text
                      variantText="body1"
                      style={{
                        fontWeight: "bold",
                        ...appConfig.general.styleOverrides
                          .buttonPrimaryTextColor,
                      }}
                    >
                      Confirm
                    </Text>
                  </PrimaryButton>
                )}
                <SecondaryButton onClick={() => handleClose()}>
                  <Text variantText="body1">Cancel</Text>
                </SecondaryButton>
              </>
            )}
          </DialogActions>
        </Box>
        {loading && <BasicLoader text="Processing Redemption" />}
      </Dialog>
    </div>
  );
};

interface Props {
  sdkService: FanfireSDK;
  appConfig: any;
  mobileView?: any;
}

export default function Redemptions({
  sdkService,
  appConfig,
  mobileView,
}: Props) {
  const [formData, setFormData] = useState<any[]>([]);
  const [hasFormData, setHasFormData] = useState<boolean>(false);
  const [redeemable, setRedeemable] = useState<any[]>([]);
  const [activeRedemptions, setActiveRedemptions] = useState<any[]>([]);
  const [redeemableLoading, setRedeemableLoading] = useState<boolean>(true);
  const [activeRedemptionsLoading, setActiveRedemptionsLoading] =
    useState<boolean>(false);

  const user = useSelector((state: RootStateOrAny) => state.user);
  const isWeb2 = user.auth.isWeb2;
  const isWeb3 = user.auth.isWeb3;
  const isAuthenticated = NetworkStatusEnums.SUCCESS === user.status;

  const getRedeemable = async () => {
    try {
      console.log("Getting user NFTs");
      const userWallet = sdkService.wallet.walletAddress;
      let nftAddressesENV =
        process.env.REACT_APP_FANFIRE_NFT_CONTRACT_ADDRESSES?.split(",") ?? [];
      nftAddressesENV = nftAddressesENV.map(function (x) {
        return x.toLowerCase();
      });

      let redeemableNFTs = await sdkService.redemptions.getRedeemableNFTs(
        userWallet ?? "",
        nftAddressesENV
      );
      for (let i = 0; i < redeemableNFTs.length; i++) {
        if (
          !nftAddressesENV.includes(
            redeemableNFTs[i].contractAddress.toLowerCase()
          )
        ) {
          redeemableNFTs.splice(i, 1);
        }
      }

      console.log("Redeemable NFTs: ", redeemable);
      setRedeemable(redeemableNFTs);
      setRedeemableLoading(false);
    } catch (error) {
      console.log("There was an error getting owned amount of NFTS:");
      console.error(error);
      setRedeemableLoading(false);
      // showSnackBar('Error getting Redeemable items', 'error', error)
    }
  };

  const getActiveRedemptions = async () => {
    try {
      const activeRedemptios =
        await sdkService.redemptions.getRedemptionsForWalletAddress(
          sdkService.wallet.walletAddress ?? ""
        );
      console.log("Active redemptions: ", activeRedemptios);
      setActiveRedemptions(activeRedemptios);
      setActiveRedemptionsLoading(false);
    } catch (error) {
      console.log("There was an error active redemptions:");
      console.error(error);
      setActiveRedemptionsLoading(false);
      // showSnackBar('Error getting active redemption items', 'error', error)
    }
  };

  useEffect(() => {
    const prep = async () => {
      getRedeemable();
    };
    setRedeemableLoading(true);
    if (isAuthenticated) {
      prep();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const prep = async () => {
      getActiveRedemptions();
    };
    setActiveRedemptionsLoading(true);
    if (isAuthenticated) {
      prep();
    }
  }, [isAuthenticated]);

  const handleFieldChange = (data: any) => {
    let df = formData;
    let itemIndex = df.findIndex(
      (e) => e.item === data.item && e.type === data.type
    ); // Check if item is already in form data
    if (!data.remove) {
      // if item is unchecked or made emty it should be removed from form data
      if (itemIndex !== -1) {
        // In form data - updating with new value
        console.log("Updating user Selection");
        df[itemIndex] = data;
      } else {
        // not in form data - inserting value
        console.log("Adding user Selection");
        df = [...df, data];
      }
    } else {
      // if item is unchecked or made emty it should be removed from form data
      console.log("Removing Item");
      df.splice(itemIndex, 1); // removing item from form data
    }
    if (df.length > 0) {
      setHasFormData(true);
    } else {
      setHasFormData(false);
    }
    setFormData(df);
  };

  return (
    <>
      <Grid container pt={5} alignItems="center" justifyContent="center">
        <Grid item xs={10} sm={10} md={8}>
          <Text
            variantText="h3"
            style={{
              textAlign: "center",
              textTransform: "capitalize",
              paddingBottom: "10px",
            }}
          >
            Redeemable{" "}
            {appConfig.general.site_noun_plural
              ? appConfig.general.site_noun_plural
              : "items"}
          </Text>
          {redeemable.length !== 0 && (
            <Text
              variantText="body1"
              style={{ textAlign: "center", paddingBottom: "10px" }}
            >
              The following{" "}
              {appConfig.general.site_noun_plural
                ? appConfig.general.site_noun_plural
                : "items"}{" "}
              may be redeemed from the vault when you are ready to collect or
              ship it. Note that{" "}
              {appConfig.general.site_noun_plural
                ? appConfig.general.site_noun_plural
                : "items"}{" "}
              that you have listed for sale cannot be redeemed unless you unlist
              them again from the marketplace.
            </Text>
          )}

          {!redeemableLoading ? (
            redeemable.length === 0 ? (
              <>
                <Text variantText="h6" style={{ textAlign: "center" }}>
                  You currently do not own any{" "}
                  {appConfig.general.site_noun_plural
                    ? appConfig.general.site_noun_plural
                    : "items"}{" "}
                  that can be redeemed from the vault. Note that{" "}
                  {appConfig.general.site_noun_plural
                    ? appConfig.general.site_noun_plural
                    : "items"}{" "}
                  that you have listed for sale cannot be redeemed unless you
                  unlist them again from the marketplace.
                </Text>
              </>
            ) : (
              <>
                {redeemable.map((e: any, i: number) => (
                  <div key={`${i}-outer-div-redeemable`}>
                    <Divider
                      key={`${i}-divider1`}
                      style={{ backgroundColor: "white" }}
                    ></Divider>
                    <CheckboxRequestInput
                      typeRequest="redeem"
                      label={e}
                      onChange={handleFieldChange}
                      qtyLimit={Number(e.ownedAmount)}
                    />
                    <Box pt={1} pb={1} key={`${i}-box1`}>
                      <Accordion className="primary">
                        <AccordionSummary
                          key={`${i}-accordianSum`}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Text
                            variantText="body1"
                            style={{
                              textTransform: "none",
                              fontWeight: "bolder",
                            }}
                          >
                            More Info
                          </Text>
                        </AccordionSummary>
                        <AccordionDetails key={`${i}-accordianDet`}>
                          <Grid
                            container
                            justifyContent="center"
                            spacing={1}
                            key={`${i}-grid-container`}
                          >
                            {e.metadata.attributes ? (
                              e.metadata.attributes.map((k: any, i: any) => (
                                <Grid
                                  key={`${i}-${e.tokenId}-${e.nftAddress}-grid`}
                                  item
                                >
                                  <Box
                                    sx={{ border: "solid 1px white" }}
                                    p={1}
                                    key={`${i}-${e.tokenId}-${e.nftAddress}-box1`}
                                  >
                                    <Text
                                      variantText="h6"
                                      style={{ textAlign: "center" }}
                                    >
                                      {k.trait_type}
                                    </Text>
                                    <Text
                                      variantText="body1"
                                      style={{ textAlign: "center" }}
                                    >
                                      {k.display_type &&
                                      k.display_type === "date" ? (
                                        <>
                                          {new Date(
                                            e.value * 1000
                                          ).toLocaleString("en-GB", {
                                            day: "numeric",
                                            month: "short",
                                            year: "numeric",
                                            hour: "numeric",
                                            minute: "2-digit",
                                          })}
                                        </>
                                      ) : (
                                        <>{k.value}</>
                                      )}
                                    </Text>
                                  </Box>
                                </Grid>
                              ))
                            ) : (
                              <Text
                                variantText="h6"
                                style={{ textAlign: "center" }}
                              >
                                No Attributes
                              </Text>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                    <Divider
                      key={`${i}-${e.tokenId}-${e.nftAddress}-divider2`}
                      style={{ backgroundColor: "white" }}
                    ></Divider>
                  </div>
                ))}
                {hasFormData && (
                  <RedeemConfirmation
                    appConfig={appConfig}
                    formData={formData}
                    sdkService={sdkService}
                    hasFormData={hasFormData}
                    mobileView={mobileView}
                  />
                )}
              </>
            )
          ) : (
            <BasicLoader text="Loading Redeemable Items..." />
          )}
        </Grid>
        <Grid item xs={10} sm={10} pt={5}>
          <Text
            variantText="h3"
            style={{ textAlign: "center", paddingBottom: "10px" }}
          >
            Ready to Collect
          </Text>
          {activeRedemptions.length !== 0 && (
            <Text
              variantText="body1"
              style={{ textAlign: "center", paddingBottom: "10px" }}
            >
              You have redeemed the following{" "}
              {appConfig.general.site_noun_plural
                ? appConfig.general.site_noun_plural
                : "item"}{" "}
              for shipping or collection:
            </Text>
          )}

          {!activeRedemptionsLoading ? (
            activeRedemptions.length === 0 ? (
              <>
                <Text variantText="h6" style={{ textAlign: "center" }}>
                  The{" "}
                  {appConfig.general.site_noun
                    ? appConfig.general.site_noun
                    : "item"}{" "}
                  waiting to be collected or shipped from the vault will be
                  listed here.
                </Text>
              </>
            ) : (
              <>
                {activeRedemptions.map(function (e: any, i: number) {
                  return (
                    <Paper
                      key={`${i}-active-red-paper`}
                      className="secondary"
                      sx={{ marginTop: 2 }}
                    >
                      <Text variantText="h6" style={{ textAlign: "center" }}>
                        <Tooltip
                          title={`The email sent to you will include more details on claiming your ${
                            appConfig.general.site_noun_plural
                              ? appConfig.general.site_noun_plural
                              : "items"
                          }. 
                          Alternatively, visit our FAQ for more information.`}
                          placement="top"
                          enterTouchDelay={0}
                        >
                          <InfoOutlinedIcon
                            fontSize="small"
                            style={{
                              verticalAlign: "middle",
                              alignSelf: "center",
                              textAlign: "center",
                            }}
                          />
                        </Tooltip>
                        Order Number: {e._id}
                      </Text>
                      <Text variantText="h6" style={{ textAlign: "center" }}>
                        Status: {e.status}
                      </Text>
                      <Accordion
                        className="primary"
                        key={`${i}-active-red-accord`}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          key={`${i}-active-red-accordSum`}
                        >
                          <Text
                            variantText="body1"
                            style={{
                              textTransform: "none",
                              fontWeight: "bolder",
                            }}
                          >
                            Redemption Details
                          </Text>
                        </AccordionSummary>
                        <AccordionDetails key={`${i}-active-red-accordDetails`}>
                          {/* <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            key={`${i}-active-red-grid1`}
                            width="100%"
                          > */}
                          {e.redeemableNfts.map(function (k: any) {
                            return (
                              <div key={`${i}-outer-div`}>
                                <Divider
                                  style={{
                                    backgroundColor: "white",
                                    width: "100%",
                                  }}
                                  key={`${i}-active-red-divider1`}
                                ></Divider>
                                {/* <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    key={`${i}-active-red-gridItem`}
                                  > */}
                                <Text
                                  variantText="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  <strong>NFT Address:</strong>{" "}
                                  {getEllipsisTxt(k.nftAddress)}
                                </Text>
                                <Text
                                  variantText="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  <strong>Token Id:</strong> {k.tokenId}
                                </Text>
                                <Text
                                  variantText="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  <strong>Quantity:</strong> {k.redeemedAmount}/
                                  {k.totalAmount}
                                </Text>
                                {/* </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    key={`${i}-active-red-gridItem2`}
                                  > */}
                                <Text
                                  variantText="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  <strong>Transfer Status:</strong>{" "}
                                  {k.transferStatus}
                                </Text>
                                <Text
                                  variantText="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  <strong>Redemption Status:</strong>{" "}
                                  {k.redemptionStatus}
                                </Text>
                                <Text
                                  variantText="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  <strong>Chain:</strong> {k.blockchain}
                                </Text>
                                {/* </Grid> */}
                              </div>
                            );
                          })}
                          {/* </Grid> */}
                        </AccordionDetails>
                      </Accordion>
                    </Paper>
                  );
                })}
              </>
            )
          ) : (
            <BasicLoader text="Loading Ready to Collect Items..." />
          )}
        </Grid>
      </Grid>
    </>
  );
}
