"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NFT = void 0;
var vault_1 = require("../Vault/vault");
var rateLimit = 10;
var NFT = /** @class */ (function () {
    function NFT(api, chain) {
        var _this = this;
        // Method that is called when web3 is enabled in the FFSDK
        this.setWeb3 = function (web3Provider, web3Service) {
            _this.web3 = web3Provider;
            _this.web3Service = web3Service;
        };
        // Method that is called when web2 is enabled in the FFSDK
        this.setWeb2 = function (firebaseAuth) {
            _this.firebaseAuth = firebaseAuth;
        };
        this.giftNFT = function (senderAddress, receiverAddress, nftAddress, tokenId, amount) { return __awaiter(_this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!this.firebaseAuth && !this.web3Service) {
                            throw Error("Firebase or Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.web3Service) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.web3Service.giftNFT(senderAddress, receiverAddress, nftAddress, tokenId, amount, this.chain)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        if (!this.fanFireApi) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.fanFireApi.giftNFT(senderAddress, receiverAddress, nftAddress, tokenId, amount, this.chain)];
                    case 3: return [2 /*return*/, _a.sent()];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        throw Error("gifNFT SDK Error: ".concat(err_1));
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        this.giftNFTviaEmail = function (senderAddress, receiverEmail, redirectUrl, nftAddress, tokenId, amount) { return __awaiter(_this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        if (!this.firebaseAuth && !this.web3Service) {
                            throw Error("Firebase or Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        if (!this.web3Service) return [3 /*break*/, 1];
                        throw Error('Only web2 users can use this method');
                    case 1:
                        if (!this.fanFireApi) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.fanFireApi.giftNFTviaEmail(senderAddress, receiverEmail, redirectUrl, nftAddress, tokenId, amount, this.chain)];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_2 = _a.sent();
                        throw Error("giftNFTviaEmail SDK Error: ".concat(err_2));
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.burnNFT = function (orderNumber, callerAddress, nftAddress, tokenId, amount) { return __awaiter(_this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (!this.firebaseAuth && !this.web3Service) {
                            throw Error("Firebase or Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize");
                        }
                        return [4 /*yield*/, this.fanFireApi.burnNft(orderNumber, callerAddress.toLowerCase(), nftAddress.toLowerCase(), tokenId, amount, this.chain)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        err_3 = _a.sent();
                        throw Error("burnNFT SDK Error: ".concat(err_3));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getNFTOwner = function (nftAddress, tokenId, walletAddress, type) { return __awaiter(_this, void 0, void 0, function () {
            var res, res, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('NFT SDK GET OWNER: ', type);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!this.web3Service) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.web3Service.getNFTOwner(nftAddress, tokenId)];
                    case 2:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 3: return [4 /*yield*/, this.fanFireApi.getNFTOwner(nftAddress, tokenId, walletAddress, type, this.chain)];
                    case 4:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_4 = _a.sent();
                        throw Error("getNFTOwner Error: ".concat(err_4));
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        this.getERC721NFTsForExplore = function (tokens) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    return [2 /*return*/, tokens.map(function (token) {
                            return __assign(__assign({}, token), { isVault: false, isERC721: true, isERC1155: false });
                        })];
                }
                catch (err) {
                    throw Error("getERC721NFTsForExplore Error: ".concat(err));
                }
                return [2 /*return*/];
            });
        }); };
        this.getERC721ForMarketplace = function (listings) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    return [2 /*return*/, listings.map(function (listing) {
                            var returnObj = __assign(__assign({}, listing), { isVault: false, isERC721: true, isERC1155: false });
                            var dbReservedUntil = 0;
                            if (returnObj["reservation"].length > 0) {
                                var datetime = returnObj["reservation"][0]["reservedUntil"];
                                dbReservedUntil = new Date(datetime).getTime() / 1000;
                            }
                            var consolidatdReservedUntil = Math.max(dbReservedUntil, parseInt(listing.reservedUntil));
                            var reservedUntilDateTime = new Date(consolidatdReservedUntil * 1000).toISOString();
                            returnObj = __assign(__assign({}, returnObj), { reservedUntilDateTime: reservedUntilDateTime, consolidatdReservedUntil: consolidatdReservedUntil });
                            return returnObj;
                        })];
                }
                catch (err) {
                    throw Error("getERC721ForMarketplace Error: ".concat(err));
                }
                return [2 /*return*/];
            });
        }); };
        // TODO: Add reservations!!!
        this.getERC1155ForMarketplace = function (nftAddress, listings) {
            try {
                // get the unique tokenIDs in listings
                var uniqueTokenIds = __spreadArray([], __read(new Set(listings.map(function (listing) { return listing.tokenId; }))), false);
                return uniqueTokenIds.map(function (id) {
                    var listingsWithUpdatedReserves = listings.map(function (listing) {
                        var dbReservedUntil = 0;
                        var reservation;
                        if (listing.reservation.length > 0) {
                            reservation = listing.reservation.find(function (res) {
                                return res.sellerAddress.toLowerCase() ===
                                    listing.sellerAddress.toLowerCase();
                            });
                            if (reservation) {
                                var datetime = reservation["reservedUntil"];
                                dbReservedUntil = new Date(datetime).getTime() / 1000;
                            }
                        }
                        var consolidatdReservedUntil = Math.max(dbReservedUntil, parseInt(listing.reservedUntil));
                        var reservedUntilDateTime = new Date(consolidatdReservedUntil * 1000).toISOString();
                        return __assign(__assign({}, listing), { reservedUntilDateTime: reservedUntilDateTime, consolidatdReservedUntil: consolidatdReservedUntil, reservation: [reservation] });
                    });
                    var listingsOfThisTokenId = listingsWithUpdatedReserves.filter(function (listing) { return listing.tokenId === id; });
                    return __assign(__assign({ contractAddress: nftAddress }, listingsOfThisTokenId[0]), { tokenId: id, listings: listingsOfThisTokenId, isVault: false, isERC721: false, isERC1155: true });
                });
            }
            catch (err) {
                throw Error("getNFTDetails Error: ".concat(err));
            }
        };
        // TODO: Add reservations!!!
        this.getERC1155ForExplore = function (nftAddress, tokens) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    return [2 /*return*/, tokens.map(function (token) {
                            return __assign(__assign({ nftAddress: nftAddress }, token), { isVault: false, isERC721: false, isERC1155: true });
                        })];
                }
                catch (err) {
                    throw Error("getNFTDetails Error: ".concat(err));
                }
                return [2 /*return*/];
            });
        }); };
        // This is used to return a Vault NFT which is listed on the marketplace
        this.getVaultNFTForMarketplace = function (contractAddress, listings) { return __awaiter(_this, void 0, void 0, function () {
            var tokensPromiseArray, tokens_1, err_5;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        tokensPromiseArray = listings.map(function (listing) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                return [2 /*return*/, this.vaultSdk.tokens(contractAddress, listing.tokenId)];
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(tokensPromiseArray)];
                    case 1:
                        tokens_1 = _a.sent();
                        return [2 /*return*/, listings.map(function (listing, index) {
                                var returnObj = __assign(__assign({}, listing), { tokens: tokens_1[index], isVault: true, isERC721: false, isERC1155: false });
                                var dbReservedUntil = 0;
                                if (returnObj["reservation"].length > 0) {
                                    var datetime = returnObj["reservation"][0]["reservedUntil"];
                                    dbReservedUntil = new Date(datetime).getTime() / 1000;
                                }
                                var consolidatdReservedUntil = Math.max(dbReservedUntil, parseInt(listing.reservedUntil));
                                var reservedUntilDateTime = new Date(consolidatdReservedUntil * 1000).toISOString();
                                returnObj = __assign(__assign({}, returnObj), { reservedUntilDateTime: reservedUntilDateTime, consolidatdReservedUntil: consolidatdReservedUntil });
                                return returnObj;
                            })];
                    case 2:
                        err_5 = _a.sent();
                        throw Error("getVaultNFTForMarketplace Error: ".concat(err_5));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        // This is used to return a Vault NFT which is owned by a Wallet
        this.getVaultNFTsForExplore = function (contractAddress, tokens) { return __awaiter(_this, void 0, void 0, function () {
            var tokensPromiseArray, tokensInVault_1, response, err_6;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        tokensPromiseArray = tokens.map(function (token) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                return [2 /*return*/, this.vaultSdk.tokens(contractAddress, token.tokenId)];
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(tokensPromiseArray)];
                    case 1:
                        tokensInVault_1 = _a.sent();
                        response = tokens.map(function (token, index) {
                            return __assign(__assign({}, token), { tokens: tokensInVault_1[index], isVault: true, isERC721: false, isERC1155: false });
                        });
                        return [2 /*return*/, response];
                    case 2:
                        err_6 = _a.sent();
                        throw Error("getVaultNFTForEXPLORE Error: ".concat(err_6));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getNFTCollection = function (nftContractAddress, filters, fanfireFilters) { return __awaiter(_this, void 0, void 0, function () {
            var nfts, tokens, err_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 9, , 10]);
                        return [4 /*yield*/, this.fanFireApi.getNftCollection([nftContractAddress], this.chain, filters, fanfireFilters)];
                    case 1:
                        nfts = _a.sent();
                        if (nfts.length === 0)
                            return [2 /*return*/, []];
                        tokens = nfts;
                        if (!(nftContractAddress.toLowerCase() ===
                            "0x59974e728098f39ccdac62dbb17978612450d8e2".toLowerCase() ||
                            nftContractAddress.toLowerCase() ===
                                "0xc053eb2c0d59cdb2b8686d414c636b72fefc40a3".toLowerCase() ||
                            nftContractAddress.toLowerCase() ===
                                "0xd7cb3d1d12c5d54041d68ab057d5356e080ff428".toLowerCase() ||
                            nftContractAddress.toLowerCase() ===
                                "0x01c2be5bcfb42433732a6bbd4f12e26093cece43".toLowerCase())) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getVaultNFTsForExplore(nftContractAddress.toLowerCase(), tokens)];
                    case 2: 
                    // get the details for this vault NFT
                    return [2 /*return*/, _a.sent()];
                    case 3:
                        if (!tokens[0].isERC721) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.getERC721NFTsForExplore(tokens)];
                    case 4: 
                    // get the details for this ERC721 NFT
                    return [2 /*return*/, _a.sent()];
                    case 5:
                        if (!tokens[0].isERC1155) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.getERC1155ForExplore(nftContractAddress, tokens)];
                    case 6: 
                    // get the details for this ERC1155 NFT
                    return [2 /*return*/, _a.sent()];
                    case 7: throw Error("Contract Address ".concat(nftContractAddress, " is not supported"));
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        err_7 = _a.sent();
                        throw Error("An error occurred while getting NFT Collection: ".concat(err_7));
                    case 10: return [2 /*return*/];
                }
            });
        }); };
        this.getBulkVaultTokenDetails = function (tokens) { return __awaiter(_this, void 0, void 0, function () {
            var promArr1, promArr2, res, _a, _b, _c, details, dbDetails_1, err_8;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 4, , 5]);
                        promArr1 = tokens.map(function (token) {
                            return _this.fanFireApi.moralisNftDetails(token.nftAddress, token.tokenId, _this.chain);
                        });
                        promArr2 = tokens.map(function (token) {
                            return _this.fanFireApi.getDBNFT(token.nftAddress, token.tokenId, _this.chain);
                        });
                        _b = (_a = Promise).all;
                        return [4 /*yield*/, Promise.all(promArr1)];
                    case 1:
                        _c = [
                            _d.sent()
                        ];
                        return [4 /*yield*/, Promise.all(promArr2)];
                    case 2: return [4 /*yield*/, _b.apply(_a, [_c.concat([
                                _d.sent()
                            ])])];
                    case 3:
                        res = _d.sent();
                        details = res[0];
                        dbDetails_1 = res[1];
                        return [2 /*return*/, details.map(function (entry, index) {
                                return __assign(__assign({}, entry.value), dbDetails_1[index].value);
                            })];
                    case 4:
                        err_8 = _d.sent();
                        throw Error("getNFTDetails Error: ".concat(err_8));
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.vaultSdk = new vault_1.Vault(api, chain);
        this.fanFireApi = api;
        this.chain = chain;
    }
    return NFT;
}());
exports.NFT = NFT;
