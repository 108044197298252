// Libraries
import { useContext } from "react";

// Packages
import { FanfireSDK } from "fanfire-sdk";

// Models
import {
  ContractListingIM,
  MarketplaceListing,
} from "fanfire-sdk/dist/models/models/marketplace";

// Context
import SDKContext from "../../context/sdk-context";

/**
 * Returns the number of listings available to buy.
 *
 * @param marketplaceListing
 */
export default function getForSale1155Length(
  marketplaceListing: MarketplaceListing
) {
  const sdkService: FanfireSDK = useContext(SDKContext).ffsdk;

  if (marketplaceListing.listings) {
    const wallet = sdkService.wallet.walletAddress;
    const listingsAvail = marketplaceListing.listings.filter(function (
      el: ContractListingIM
    ) {
      if (el.sellerAddress && wallet) {
        return (
          el.sellerAddress.toLowerCase() !== wallet?.toLowerCase() && el.price
        );
      } else {
        return false;
      }
    });
    if (listingsAvail.length) {
      return listingsAvail
        .map((item: any) => item.amount ?? 0)
        .reduce((prev: any, next: any) => Number(prev) + Number(next));
    } else {
      console.log("there is no listings available to deduce price");
      return 0;
    }
  } else {
    console.log("there is no listings to deduce price");
    return 0;
  }
}
