"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wallet = void 0;
var ethers_1 = require("ethers");
var nft_1 = require("../NFTs/nft");
var vault_1 = require("../Vault/vault");
var Wallet = /** @class */ (function () {
    function Wallet(api, chain) {
        var _this = this;
        // Method that is called when web3 is enabled in the FFSDK
        this.setWeb3 = function (web3Provider, web3Service) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.web3 = web3Provider;
                        this.web3Service = web3Service;
                        _a = this;
                        return [4 /*yield*/, this.getWalletAddress()];
                    case 1:
                        _a.walletAddress = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        // Method that is called when web2 is enabled in the FFSDK
        this.setWeb2 = function (firebaseAuth) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.firebaseAuth = firebaseAuth;
                        // console.log('GETTING WALLET ADDRESS');
                        _a = this;
                        return [4 /*yield*/, this.getWalletAddress()];
                    case 1:
                        // console.log('GETTING WALLET ADDRESS');
                        _a.walletAddress = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.getAllWalletNFTs = function (walletAddress, lowerIndex, higherIndex) { return __awaiter(_this, void 0, void 0, function () {
            var nfts, nftsArr_1, uniqueNftAdresses, detailsPromise, listedNFTs, responseObj, res, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.fanFireApi.getAllWalletNFTs(walletAddress, this.chain)];
                    case 1:
                        nfts = _a.sent();
                        if (!nfts.isSuccess || !Array.isArray(nfts.value))
                            throw Error("Wallet NFT retrieval Error: ".concat(nfts.errorMessage));
                        nftsArr_1 = nfts.value.slice(lowerIndex, higherIndex);
                        uniqueNftAdresses = __spreadArray([], __read(new Set(nfts.value.map(function (nft) { return nft.contractAddress; }))), false);
                        detailsPromise = uniqueNftAdresses.map(function (nftAddress) { return __awaiter(_this, void 0, void 0, function () {
                            var thisAddressesNFTs;
                            return __generator(this, function (_a) {
                                thisAddressesNFTs = nftsArr_1.filter(function (nft) {
                                    return nft.contractAddress.toLowerCase() === nftAddress.toLowerCase();
                                });
                                // check if this is a vault NFT address
                                if (thisAddressesNFTs.length === 0) {
                                    return [2 /*return*/];
                                }
                                if (nftAddress.toLowerCase() === '0x59974e728098f39ccdac62dbb17978612450d8e2'.toLowerCase() ||
                                    nftAddress.toLowerCase() === '0xc053eb2c0d59cdb2b8686d414c636b72fefc40a3'.toLowerCase() ||
                                    nftAddress.toLowerCase() === '0xd7cb3d1d12c5d54041d68ab057d5356e080ff428'.toLowerCase() ||
                                    nftAddress.toLowerCase() === '0x01c2be5bcfb42433732a6bbd4f12e26093cece43'.toLowerCase()) {
                                    // get the details for this vault NFT
                                    return [2 /*return*/, this.nftSdk.getVaultNFTsForExplore(nftAddress.toLowerCase(), thisAddressesNFTs)];
                                }
                                if (thisAddressesNFTs[0].isERC721) {
                                    // get the details for this ERC721 NFT
                                    return [2 /*return*/, this.nftSdk.getERC721NFTsForExplore(thisAddressesNFTs)];
                                }
                                else if (thisAddressesNFTs[0].isERC1155) {
                                    // get the details for this ERC1155 NFT
                                    return [2 /*return*/, this.nftSdk.getERC1155ForExplore(nftAddress, thisAddressesNFTs)];
                                }
                                else {
                                    console.log('Unsupported NFT found in wallet');
                                    return [2 /*return*/];
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(detailsPromise)];
                    case 2:
                        listedNFTs = _a.sent();
                        responseObj = [].concat.apply([], listedNFTs);
                        res = { tokens: responseObj, totalLength: nfts.value.length };
                        return [2 /*return*/, res];
                    case 3:
                        err_1 = _a.sent();
                        throw Error("getAllWalletNFTs Error: ".concat(err_1));
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.getWalletNFT = function (walletAddress, nftAddress, tokenId) { return __awaiter(_this, void 0, void 0, function () {
            var nfts, nft, promArr2, prom_1, merged, err_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.fanFireApi.getAllWalletNFTs(walletAddress, this.chain)];
                    case 1:
                        nfts = _a.sent();
                        if (!nfts.isSuccess || !Array.isArray(nfts.value))
                            throw Error("Wallet NFTs retrieval error: ".concat(nfts.errorMessage));
                        nft = nfts.value.filter(function (entry) {
                            if (entry.token_address.toLowerCase() === nftAddress.toLowerCase() && entry.token_id === tokenId) {
                                return entry;
                            }
                            else {
                                return;
                            }
                        });
                        promArr2 = nft.map(function (entry) {
                            var _a, _b, _c;
                            if (((_a = entry.value) === null || _a === void 0 ? void 0 : _a.token_address) && entry.value.token_id) {
                                return _this.fanFireApi.getDBNFT((_b = entry.value) === null || _b === void 0 ? void 0 : _b.token_address, (_c = entry.value) === null || _c === void 0 ? void 0 : _c.token_id, _this.chain);
                            }
                            else {
                                return;
                            }
                        });
                        return [4 /*yield*/, Promise.all(promArr2)];
                    case 2:
                        prom_1 = _a.sent();
                        merged = nft.map(function (entry, index) {
                            var _a;
                            return __assign(__assign({}, entry), (_a = prom_1[index]) === null || _a === void 0 ? void 0 : _a.value);
                        });
                        return [2 /*return*/, merged];
                    case 3:
                        err_2 = _a.sent();
                        console.error(err_2);
                        throw Error("getWalletNFT Error: ".concat(err_2));
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.getWalletAddress = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        if (!this.web3) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.web3.getAddress()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        if (!this.firebaseAuth) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.fanFireApi.getUserWallets()];
                    case 3:
                        res = _a.sent();
                        // NB Returns the first wallet address in wallet array
                        if (res.isSuccess && Array.isArray(res.value))
                            return [2 /*return*/, res.value[0].address];
                        throw Error('No wallets found for this user');
                    case 4: throw Error('Authentication is required for this method  Call enableWeb2 of enableWeb3.');
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_3 = _a.sent();
                        throw Error("An Error occurred while retrieving the wallet address: ".concat(err_3));
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        this.getWalletERC20Balance = function (walletAddress, erc20Address) { return __awaiter(_this, void 0, void 0, function () {
            var service, res, res, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        service = this.web3Service ? this.web3Service : this.fanFireApi;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error('Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize');
                        }
                        if (!this.web3Service) return [3 /*break*/, 2];
                        return [4 /*yield*/, service.callAuthedContract(erc20Address, this.chain, 'balanceOf', {
                                accountAddress: walletAddress,
                            })];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value.toString()];
                    case 2: return [4 /*yield*/, service.callAuthedContract(erc20Address, this.chain, 'balanceOf', {
                            accountAddress: walletAddress,
                        })];
                    case 3:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, ethers_1.ethers.BigNumber.from(res.value.hex).toString()];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_4 = _a.sent();
                        throw Error("getWalletERC20Balance Error: ".concat(err_4));
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        this.getWalletNativeBalance = function () { return __awaiter(_this, void 0, void 0, function () {
            var service, res, err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        service = this.web3Service ? this.web3Service : this.fanFireApi;
                        if (!this.web3Service && !this.firebaseAuth) {
                            throw Error('Firebase OR Web3 authentication is required to authorize this request. Call enableWeb2 or enableWeb3 to initialize');
                        }
                        return [4 /*yield*/, service.getWalletNativeBalance(this.chain)];
                    case 1:
                        res = _a.sent();
                        if (!res.isSuccess)
                            throw Error(res.errorMessage);
                        return [2 /*return*/, res.value];
                    case 2:
                        err_5 = _a.sent();
                        throw Error("getWalletNativeBalance Error: ".concat(err_5));
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.nftSdk = new nft_1.NFT(api, chain);
        this.vaultSdk = new vault_1.Vault(api, chain);
        this.fanFireApi = api;
        this.chain = chain;
    }
    return Wallet;
}());
exports.Wallet = Wallet;
