import React, { useState } from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import InputTextField from "../inputs/InputTextField";
import CheckboxRequestInput from "../inputs/CheckboxRequestInput";
import Text from "../text/Text";
import { useSnackBar } from "../../context/snackAlert-context";
import BasicLoader from "../loaders/BasicLoader";
import { FanfireSDK } from "fanfire-sdk";

//MUI
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DialogActions from "@mui/material/DialogActions";

const styles = {
  dialogContainer: {
    borderRadius: "16px",
    width: "100%",
  },
  accordion: {
    borderRadius: "8px",
  },
};

interface Props {
  formData: any;
  name: string;
  email: string;
  contact: string;
  otherData: any;
  parentModal: any;
  sdkService: FanfireSDK;
  appConfig: any;
}

const PrintConfirmation = ({
  formData,
  email,
  name,
  contact,
  otherData,
  parentModal,
  sdkService,
  appConfig,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showSnackBar } = useSnackBar();

  // const context = useContext(SDKContext)
  // const ffsdk = context.ffsdk

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const requestSubmit = async () => {
    setLoading(true);
    try {
      console.log("Submitting print request");
      const data = JSON.stringify({ ...formData, otherData });
      console.log("Requested prints and data: ", data);
      const print = await sdkService.marketplace.requestPrint(
        name,
        contact,
        email,
        0,
        "",
        data
      );
      showSnackBar(
        "Success! One of our representatives will contact you soon! ",
        "success"
      );
      setLoading(false);
      setOpen(false);
      parentModal();
    } catch (error) {
      showSnackBar("Request has failed. Please try again.", "error", error);
      console.error(error);
      setLoading(false);
      setOpen(false);
      parentModal();
    }
  };

  return (
    <div>
      {name && email && contact ? (
        <PrimaryButton onClick={() => handleClickOpen()} type="submit">
          <Text
            variantText="body1"
            style={{ color: "black", fontWeight: "bold" }}
          >
            Submit
          </Text>
        </PrimaryButton>
      ) : (
        <PrimaryButton disabled>
          <Text
            variantText="body1"
            style={{
              fontWeight: "bold",
              ...appConfig.general.styleOverrides.buttonPrimaryTextColor,
            }}
          >
            Please fill in required fields
          </Text>
        </PrimaryButton>
      )}

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: styles.dialogContainer,
        }}
      >
        <DialogTitle component="div">
          {
            <Text variantText="h1" style={{ textAlign: "center" }}>
              Confirm Request
            </Text>
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            component="div"
          >
            <Text variantText="h3">Name:</Text>
            <Text variantText="body1">{name}</Text>
            <Text variantText="h3">Email:</Text>
            <Text variantText="body1">{email}</Text>
            <Text variantText="h3">Contact:</Text>
            <Text variantText="body1">{contact}</Text>
            {otherData.other && (
              <>
                <Text variantText="h3">Other Details:</Text>
                <Text variantText="body1">{otherData.other}</Text>
              </>
            )}
            {otherData.highResImage && (
              <>
                <Text variantText="h3">High Resolution Image:</Text>
                <Text variantText="body1">True</Text>
              </>
            )}
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={6}>
                <Text variantText="h6" style={{ textAlign: "center" }}>
                  Canvas
                </Text>
                {formData.map(
                  (element: any) =>
                    element.type === "canvas" && (
                      <>
                        <Text variantText="body1">
                          {element.item} x {element.qty}
                        </Text>
                        <Divider style={{ backgroundColor: "white" }}></Divider>
                      </>
                    )
                )}
              </Grid>
              <Grid item xs={6}>
                <Text variantText="h6" style={{ textAlign: "center" }}>
                  Photo
                </Text>
                {formData.map(
                  (element: any) =>
                    element.type === "photo" && (
                      <>
                        <Text variantText="body1">
                          {element.item} x {element.qty}
                        </Text>
                        <Divider style={{ backgroundColor: "white" }}></Divider>
                      </>
                    )
                )}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <Box m={2}>
          <DialogActions style={{ textAlign: "center" }}>
            {!loading ? (
              <>
                <PrimaryButton onClick={() => requestSubmit()}>
                  <Text
                    variantText="body1"
                    style={{
                      fontWeight: "bold",
                      ...appConfig.general.styleOverrides
                        .buttonPrimaryTextColor,
                    }}
                  >
                    Confirm
                  </Text>
                </PrimaryButton>
                <SecondaryButton onClick={() => handleClose()}>
                  <Text variantText="body1">Cancel</Text>
                </SecondaryButton>
              </>
            ) : (
              <BasicLoader text="Sending Communications" />
            )}
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

interface RequestAPrintProps {
  nft: any;
  sdkService: FanfireSDK;
  appConfig: any;
}

export default function RequestAPrint({
  nft,
  sdkService,
  appConfig,
}: RequestAPrintProps) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<any[]>([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [checked, setChecked] = useState(false);
  const [other, setOther] = useState<string>("");
  const [otherData, setOtherData] = useState({
    other: "",
    highResImage: false,
    nft_address: nft.token_address,
    nft_token_id: nft.token_id,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData([]);
    setName("");
    setEmail("");
    setContact("");
  };

  const choicesCanvas = ["A3", "A2", "A1", "A0"];
  const choicesPhotoPrint = ["A3", "A2", "A1", "A0"];

  const handleFieldChange = (data: any) => {
    let df = formData;
    let itemIndex = df.findIndex(
      (e) => e.item === data.item && e.type === data.type
    ); // Check if item is already in form data
    if (!data.remove) {
      // if item is unchecked or made emty it should be removed from form data
      if (itemIndex !== -1) {
        // In form data - updating with new value
        console.log("Updating user Selection");
        df[itemIndex] = data;
      } else {
        // not in form data - inserting value
        console.log("Adding user Selection");
        df = [...df, data];
      }
    } else {
      // if item is unchecked or made emty it should be removed from form data
      console.log("Removing Item");
      df.splice(itemIndex, 1); // removing item from form data
    }
    setFormData(df);
  };

  const handleHighResCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChecked(event.target.checked);
    setOtherData({ ...otherData, highResImage: event.target.checked });
  };

  const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOther(event.target.value);
    setOtherData({ ...otherData, other: event.target.value });
  };

  return (
    <>
      <SecondaryButton onClick={handleClickOpen}>
        <Text variantText="body1">Request A Print</Text>
      </SecondaryButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: styles.dialogContainer,
        }}
        scroll="body"
      >
        <DialogTitle>
          <Avatar sx={{ width: 64, height: 64, background: "#000000" }}>
            <LocalPrintshopOutlinedIcon sx={{ fontSize: 50, color: "white" }} />
          </Avatar>
        </DialogTitle>
        <DialogTitle style={{ textTransform: "uppercase" }}>
          Request a Print
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <Text variantText="body1">
              Fill in the below form to request a quote:
            </Text>
          </DialogContentText>
          <Box pt={3} pb={3}>
            <FormControl fullWidth>
              <Box pt={3} pb={1}>
                <Accordion style={styles.accordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Text variantText="body1">
                      Canvas (Framed in black with white backing)
                    </Text>
                  </AccordionSummary>
                  <AccordionDetails>
                    {choicesCanvas.map((item, index) => (
                      <CheckboxRequestInput
                        key={index}
                        label={item}
                        typeRequest="canvas"
                        onChange={handleFieldChange}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box pt={3} pb={1}>
                <Accordion style={styles.accordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Text variantText="body1">
                      Photo Print (Framed in black with white backing)
                    </Text>
                  </AccordionSummary>
                  <AccordionDetails>
                    {choicesPhotoPrint.map((item, index) => (
                      <CheckboxRequestInput
                        key={index}
                        label={item}
                        typeRequest="photo"
                        onChange={handleFieldChange}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box pt={3} pb={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleHighResCheckChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    <Text variantText="body1">
                      Request a High Resolution Image
                    </Text>
                  }
                />
              </Box>
              <Box pt={1} pb={1}>
                <DialogContentText component="div">
                  <Text variantText="body1">Other Specifications:</Text>
                </DialogContentText>
              </Box>
              <InputTextField onChange={(e: any) => handleOtherChange(e)} />
              <Box pt={1} pb={1}>
                <DialogContentText component="div">
                  <Text variantText="body1"> Contact Details:</Text>
                </DialogContentText>
              </Box>
              <InputTextField
                label="Name*"
                onChange={(e: any) => setName(e.target.value)}
              />
              <InputTextField
                label="Email*"
                onChange={(e: any) => setEmail(e.target.value)}
              />
              <InputTextField
                label="Contact Number*"
                onChange={(e: any) => setContact(e.target.value)}
              />

              <PrintConfirmation
                appConfig={appConfig}
                formData={formData}
                name={name}
                email={email}
                contact={contact}
                otherData={otherData}
                parentModal={handleClose}
                sdkService={sdkService}
              />
              <SecondaryButton onClick={handleClose}>
                <Text variantText="body1">Cancel</Text>
              </SecondaryButton>
            </FormControl>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
