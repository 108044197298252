import React, { useState } from 'react';
import PropTypes from 'prop-types';

const styles = {
  showHide: {
    textDecoration: 'underline white',
    letterSpacing: '1px',
    cursor: 'pointer',
    color: 'grey'
  }
};

interface Props{
    children: any;
    size: any;
}

const ReadMore = ({ children, size }: Props) => {
    let text;
    if(typeof children === 'string' || children instanceof String || Array.isArray(children)){
        text = children ?? '';
    }else{
        text = children.props.children ?? '';
    }
    let split = 200;
    if(typeof size === 'number'){
        split = size
    }else if(size === 'small') {
        split = 150;
    } else if (size === 'large') {
        split = 250;
    }
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <span>
        {isReadMore ? text.slice(0, split) : text}
        {text.length > split ?
            <>
                {isReadMore ? '...' : ''}
                <span onClick={toggleReadMore} style={styles.showHide} className="read-or-hide">
                    {isReadMore ? <span style={{display: 'inline'}}>read more</span> : <span>...show less</span>}
                </span>
            </>
        :
            <></>
        }
        </span>
    );
};

ReadMore.propTypes = {
  children: PropTypes.node,
  size: PropTypes.any
};

export default ReadMore;
