import {
  configureStore,
  combineReducers,
  Store,
  AnyAction,
} from "@reduxjs/toolkit";
import exchangeReducer from "./exchangeSlice/exchangeSlice";
import userReducer from "./userSlices/userSlice";

const combinedReducer = combineReducers({
  user: userReducer,
  exchange: exchangeReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "user/resetUser") {
    // Reset the Redux store
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

const store: Store = configureStore({
  devTools: process.env.REACT_APP_FANFIRE_DEPLOYMENT_ENV !== "production", // remove Redux devTools from production
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
