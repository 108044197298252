import React, { createContext, useContext } from "react";
import SnackBarAlert from "../components/alerts/SnackBarAlert";

const styles = {
  paper: {
    padding: "20px",
    borderRadius: "8px",
    marginTop: "5px",
    maxWidth: "40vw",
  },
  accordion: {
    borderRadius: "8px",
    paddingLeft: "10px",
    paddingRight: "10px",
    maxWidth: "40vw",
  },
};

type SnackBarContextActions = {
  showSnackBar: (
    text: string,
    typeColor: any,
    error?: any,
    modalType?: boolean
  ) => void;
};

const SnackBarContext = createContext({} as SnackBarContextActions);

interface SnackBarContextProviderProps {
  children: React.ReactNode;
}

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const [typeColor, setTypeColor] = React.useState<any>("info");
  const [modalType, setModalType] = React.useState<any>(false);

  const showSnackBar = (
    text: string,
    color: any,
    error?: any,
    modalType?: boolean
  ) => {
    setMessage(text);
    setTypeColor(color);
    setOpen(true);
    setError(String(error));
    setModalType(modalType ? modalType : false);
  };

  const handleClose = () => {
    setOpen(false);
    setTypeColor("info");
  };

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      <SnackBarAlert
        open={open}
        typeColor={typeColor}
        handleClose={handleClose}
        message={message}
        modalType={modalType}
      />
      {children}
    </SnackBarContext.Provider>
  );
};

const useSnackBar = (): SnackBarContextActions => {
  const context = useContext(SnackBarContext);

  if (!context) {
    throw new Error("useSnackBar must be used within an SnackBarProvider");
  }

  return context;
};

export { SnackBarProvider, useSnackBar };
