import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { NetworkStatusEnums } from "../../helpers/enums";
import { useSelector, RootStateOrAny } from "react-redux";
import { FanfireSDK } from "fanfire-sdk";

// Comps
import Text from "../../components/text/Text";
import LogIn from "../../components/auth/LogIn";
import SignUp from "../../components/auth/SignUp";
import PrimaryButton from "../../components/buttons/PrimaryButton";

// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AuthModal from "../../components/auth/AuthModal";

const styles = {
  attandanceImage: {
    filter: "drop-shadow(0px 1.27599px 15.6522px #000000)",
    width: "auto",
    height: "300px",
  },
  background: {
    // backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.39539565826330536) 80%, rgba(0,0,0,1) 90%), url(${background})`,
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    filter: "blur(4px)",
    position: "absolute" as "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    height: "100vh",
  },
  dropShadow: {
    filter: "drop-shadow(0px 1.27599px 15.6522px #000000)",
  },
};

interface Props {
  authLoading: boolean;
  sdkService: FanfireSDK;
  appConfig: any;
  sliceStore: any;
}

export default function AttendanceTokens({
  authLoading,
  sdkService,
  appConfig,
  sliceStore,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [load, setLoad] = useState<boolean>(true);
  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);
  const attendanceData = {
    chain: searchParams.get("chain") ?? "",
    nftAddress: searchParams.get("nftAddress") ?? "",
  };

  const airdrop = async () => {
    const wallet = sdkService.wallet.walletAddress;
    console.log("airdrop token to: " + wallet);
    console.log("airdrop chain: " + attendanceData.chain);
    console.log("airdrop nftAddress: " + attendanceData.nftAddress);
    if (attendanceData.nftAddress !== "") {
      try {
        console.log("air dropping...");
        const airdrop = await sdkService.marketplace.airdropNFT(
          wallet ?? "",
          attendanceData.nftAddress
        );
        console.log(airdrop);
        setLoad(false);
      } catch (err) {
        setLoad(false);
        console.error(err);
      }
    } else {
      setLoad(false);
      console.log("nft address is not included");
    }
  };

  useEffect(() => {
    if (isAuthenticated && !authLoading) {
      setLoad(true);
      console.log("User is already Authenticated!");
      console.log("Dropping a attendance token");
      airdrop();
    }
  }, [authLoading]);

  return (
    <div style={{ position: "relative" }}>
      <div style={styles.background}></div>
      <div style={{ zIndex: "-1" }}>
        <Grid
          container
          paddingTop={15}
          pb={10}
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={6}>
            <Box pb={3}>
              <Text
                variantText="h1"
                style={{ ...styles.dropShadow, textAlign: "center" }}
              >
                {appConfig.attendanceTokens.heading}
              </Text>
            </Box>
            <Box
              pb={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text
                variantText="body1"
                style={{
                  ...styles.dropShadow,
                  textAlign: "center",
                  marginRight: "5px",
                }}
              >
                by
              </Text>
              {appConfig.attendanceTokens.byImage ? (
                <img
                  style={styles.attandanceImage}
                  src={appConfig.attendanceTokens.byImage}
                  alt="Attandnace Image"
                />
              ) : (
                <Text
                  variantText="h3"
                  style={{ ...styles.dropShadow, textAlign: "center" }}
                >
                  {appConfig.attendanceTokens.byText}
                </Text>
              )}
            </Box>
            <Box pb={3}>
              <Text
                variantText="h3"
                style={{ ...styles.dropShadow, textAlign: "center" }}
              >
                {appConfig.attendanceTokens.sub_heading}
              </Text>
            </Box>
            {!authLoading ? (
              isAuthenticated ? (
                !load ? (
                  <Box pb={1}>
                    <Text
                      variantText="h6"
                      style={{
                        ...styles.dropShadow,
                        textTransform: "none",
                        textAlign: "center",
                      }}
                    >
                      Congratulations!
                    </Text>
                    <Text
                      variantText="body1"
                      style={{ ...styles.dropShadow, textAlign: "center" }}
                    >
                      You will recieve your token within the next 24 hours!
                    </Text>
                    <Box pt={2}>
                      <PrimaryButton
                        onClick={() => window.location.replace("/")}
                      >
                        <Text
                          variantText="h6"
                          style={{
                            textTransform: "none",
                            ...appConfig.general.styleOverrides
                              .buttonPrimaryTextColor,
                          }}
                        >
                          Continue To Site
                        </Text>
                      </PrimaryButton>
                    </Box>
                  </Box>
                ) : (
                  <>
                    {/* <Loader text=""/> */}
                    <Text
                      variantText="body1"
                      style={{ ...styles.dropShadow, textAlign: "center" }}
                    >
                      Getting Your Attendance NFT...
                    </Text>
                  </>
                )
              ) : (
                <>
                  <Box pb={1}>
                    <Text
                      variantText="body1"
                      style={{ ...styles.dropShadow, textAlign: "center" }}
                    >
                      Log In or Sign up to recieve your very own attendance
                      token!
                    </Text>
                  </Box>
                  <Box justifyContent="center" display="flex">
                    <AuthModal
                      type="login"
                      attendance={true}
                      attendanceData={attendanceData}
                      setLoading={setLoad}
                      sdkService={sdkService}
                      sliceStore={sliceStore}
                      appConfig={appConfig}
                    />
                    <AuthModal
                      type="signup"
                      attendance={true}
                      attendanceData={attendanceData}
                      setLoading={setLoad}
                      sdkService={sdkService}
                      sliceStore={sliceStore}
                      appConfig={appConfig}
                    />
                  </Box>
                </>
              )
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
