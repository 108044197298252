import { createContext } from 'react';
import { FanfireSDK } from 'fanfire-sdk';

const ffsdk : FanfireSDK= new FanfireSDK(
    process.env.REACT_APP_FANFIRE_API_BASE_URL ?? 'https://europe-west3-fanfire-01.cloudfunctions.net/api',
    process.env.REACT_APP_FANFIRE_CHAIN ?? 'mumbai'
);

const SDKContext: React.Context<{
    ffsdk: FanfireSDK;
}> = createContext({ ffsdk });

export default SDKContext;
