import { FanfireSDK } from "fanfire-sdk";
import { useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "../../context/snackAlert-context";
import { NetworkStatusEnums } from "../../helpers/enums";

interface Props {
  nftAddress: string;
  tokenId: string;
  userAddress: string;
  sdkService: FanfireSDK;
  likedAmount: number;
  setLikedAmount: (amount: number) => void;
  setLiked: (liked: boolean) => void;
}

/**
 * This hook is used handle a liking of an item along with its state
 *
 * @returns { unListLoading, unListText, error, sendUnlist } Returns whether the like is loading, whether there is an error and the function to run the unlist sdk method
 */
const useLikeNFT = () => {
  const [likeLoading, setLikeLoading] = useState<boolean>(false);
  const [likeError, setLikeError] = useState<boolean>(false);
  const { showSnackBar } = useSnackBar();
  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);

  /**
   * This function is used handle a liking of an item
   *
   * @param {string} nftAddress nft address of the token
   * @param {string} tokenId token id of the token
   * @param {string} userAddress the user liking the item
   * @param {number} likedAmount the liked amount
   * @param {function} setLikedAmount set the liked amount
   */
  const sendLikeNFT = async ({
    nftAddress,
    tokenId,
    userAddress,
    sdkService,
    likedAmount,
    setLikedAmount,
    setLiked,
  }: Props) => {
    if (isAuthenticated) {
      console.log("Liking NFT", {
        nftAddress: nftAddress,
        tokenId: tokenId,
        userAddress: userAddress,
        sdkService: sdkService,
      });
      setLikedAmount(likedAmount + 1);
      setLiked(true);
      setLikeLoading(true);

      try {
        await sdkService.marketplace.likeNFT(userAddress, nftAddress, tokenId);
      } catch (error) {
        setLiked(false);
        setLikedAmount(likedAmount);
        console.error(error);
        setLikeError(true);
        showSnackBar(
          "An error has occurred liking this item. Please try again.",
          "error",
          error
        );
      }
      setLikeLoading(false);
    } else {
      showSnackBar("You need to be logged in to like a NFT!", "warning");
    }
  };

  return { likeLoading, likeError, sendLikeNFT };
};

export default useLikeNFT;
