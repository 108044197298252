/* 
=================================================================================================================
=================================================================================================================
=== APP CONFIG ==================================================================================================
=================================================================================================================
=================================================================================================================

Please populate the below to make changes to the app.
Please do not remove any fields. 
*/

const appConfig = {
  /* 
  =================================================================================================================
  === GENERAL CONFIGS =============================================================================================
  =================================================================================================================
  */
  general: {
    app: 'Vaulted',
    appCategory: 'wine',
    sdkService: 'fanFireSDK', // fanFireSDK | demo
    logo: require('./assets/logo.png'), // Logo on the Nav bar
    contactDetails: {
      telephone: '+27 21 833 9652',
      email: 'info@vaulted.wine',
      email_subject: 'Vaulted Inquiry',
    },
    socialMedia: {
      // add more possibilities for users
      instagram: 'https://www.instagram.com/fanfire.ai/',
      twitter: 'https://twitter.com/FanfireWeb3',
      facebook: 'https://facebook.com/vaulted.wine',
      youtube: 'https://youtu.be/JoLrFpG25xA?t=626',
      custom: [
        // TODO: support SVG
        {
          //Fanfire
          logo: require('./assets/FanFire_Logo_black.png'), //change to either black or white
          link: 'https://linktr.ee/fanfire',
        },
        // Add more objects here to add more options
      ],
    },

    // some general throughout configs
    disablePurchasing: false, // Disbles purchasing / listing / unlisting
    openseaLogo: require('./assets/OpenSeaBlue.png'), //Logo to use for Opensea (does not support svg yet)
    metaMaskLogo: require('./assets/MetaMask-Fox.png'), //Logo to use for Metamask
    listViewsErrorImage: require('./assets/placeholderImage.png'), // Image used on error pages when trying to list NFTs
    nftImageNotFoundImage: require('./assets/placeholderImage.png'), // Image to use on card item if image of NFT can not be found
    cardItemViewText: 'View Wine', // Text displayed on card item to route into detail view
    serviceFee: 2.5, //Amount for the service
    creatorFee: 10, //Amount the creator gets
    creatorFeeText: 'Winemaker Royalty', //text displayed on list NFT for creator cut

    site_noun: 'wine',
    site_noun_plural: 'wines',

    rightsText: '', //Text used for rights text on the checkout modal when buying an item
    rightsAgreeButtonText: 'I understand the Terms and Conditions for purchasing this wine.',

    enableQrRedemption: true,
    qrCodeImage: require('./assets/logo.png'),
    redemptionLocations: [
      {
        name: 'Lourensford Wine Estate',
        mumbai: '0x2886f0a2e82446aa2481147fcc357c09ad7fa701',
        polygon: '0x654279a18326bc6cd866c669dbf42d82919afc0b',
      },
    ],

    // FILTERS
    // only works for numbers now
    default_FF_sort: {
      ff_meta_field: 'Score', // field in FF metadata in DB
      sortBy: 'desc' //asc
    },
    // 
    default_FF_filter: true,
    avail_filters: [
      {
        name: 'Name',
        fe_name: 'Wine Name',
        type: 'free-text',
      },
      {
        name: 'Growth',
        type: 'select',
        fe_name: 'Category',
        ff_search: true,
        values: ['Distinguished Growth', 'Vaulted Cru', 'Mixed Cellar Lots'],
        default: 'Distinguished Growth'
      },
      {
        name: 'Estate',
        type: 'select',
        values: [
          'Allesverloren',
          'Beaucastel',
          'Boplaas',
          'Boschendal',
          'Bouchard',
          'Cathedral Cellar',
          'Cavendish',
          'Clos des Paulilles',
          'De Bortoli',
          'Doolhof',
          'Groot Constantia',
          'JC Le Roux',
          'KWV',
          'Lourensford',
          'Lucy\’s Run',
          'Meerlust',
          'Middelvlei',
          'Mirabelle de Lorraine',
          'Monis',
          'Mont Blois',
          'Morgenhof',
          'Muratie',
          'Overgaauw',
          'Penfolds',
          'Plaisir de Merle',
          'Rustenberg',
          'Simonsig',
          'Taaibosch',
          'Tesselaarsdal',
          'Three Bridges',
          'Veuve Clicquot',
          'Vriesenhof',
          'Warwick',
          'Waterford',
          'Wyndham',
          'Zonnebloem',
        ],
      },
      {
        name: 'Vintage',
        type: 'select',
        values: Array(2022 - 1948 + 1).fill(1).map((_, idx) => 1948 + idx).map(String),
      },
    ],

    //Some General style overides in the app
    styleOverrides: {
      buttonPrimaryTextColor: {
        // color: 'black'
      },
    },

    redeemText: 'Collect Wine from the Vault'
  },
  /* 
  =================================================================================================================
  === LADNING PAGE ================================================================================================
  =================================================================================================================
  */
  landingPage: {
    // Header on the Landing Page
    header: '',

    // Description on the Landing Page
    description:
      'The beautiful Lourensford Estate covers 4 000 hectares on the slopes of the Helderberg Mountains. In the midst of these vineyards, orchards, forest and fynbos lies the Wine Vault which holds a curated collection of some of South Africa\'s finest wines. Here at Vaulted you can collect and trade these wines online, while each case rests securely in the Lourensford Vault until the day it is ready to be enjoyed.',

    // This is the main image displayed on the Landing page
    landingImagePath: undefined, // either landingImagePath or landingVideoPath

    landingVideoPath: require('./assets/vaulted_video.mp4'), // either landingImagePath or landingVideoPath

    // Landing Page background Image: undefined or image -> require('./assets/<image-path.extension>')
    landingBackroundPath: require('./assets/vaulted_landing.jpg'),

    // either byImage or byText - Specifies the CLient name through a logo image
    byImage: require('./assets/Untitled.png'),

    // either byImage or byText - Specifies the CLient name through text
    byText: '',

    // text before image on left
    byPreText: '',

    // Text that appears above the landing image
    // Keep as empty string to exlude
    textAboveImage: '',

    // Link Button that appears below the landing image
    linkBelowImage: {
      include: true, // Boolean to indeicate whether to include or not
      link: 'https://collin086.wixsite.com/vaultedwine', // Where to link to
      text: 'More about Vaulted', // Text for the link
      includeIcon: false,
    },

    // Primary Button on Landing Page:
    primary: {
      include: true, // Whether or not to include the button on the landing Page
      text: 'Browse Vault', // Text defined within the button
      routeToPage: 'for-sale', // Where the button will route to (this must be one of the routeable pages)
    },

    // Secondary Button on Landing Page:
    secondary: {
      include: false, // Whether or not to include the button on the landing Page
      text: 'For Sale', // Text defined within the button
      routeToPage: 'for-sale', // Where the button will route to (this must be one of the routeable pages)
    },

    // Text style for landing page
    styleOverrides: {
      text: {
        color: 'white',
      },
    },
  },
  /* 
  =================================================================================================================
  === BROWSE COLLECTION PAGE ======================================================================================
  =================================================================================================================
  */
  browseCollectionPage: {
    // Whether to include this page in the App
    include: false,

    // Header on the explore page
    header: 'Explore',

    // undefined or image require('./assets/<image-path.extension>')
    headerImage: require('./assets/header_image.jpg'),
  },
  /* 
  =================================================================================================================
  === NAV ITEMS ===================================================================================================
  =================================================================================================================
  */
  navItems: {
    profileNavbarText: "My Wine",
    forSaleNavbarText: "Browse Vault",
    backButtonNavIconColor: 'black',
    backButtonNavIconPaddingColor: 'white',
    navActiveColor: 'white',
    iconNavBarColor: 'white',
    // mobile drawer color of text nav items
    mobileNavItems: {
      styleOverride: {
        text: {
          color: 'black',
        },
        drawerColor: {
          // Color of the drawer that opens up to nav on the mobile view
          background: '#fff6e8',
        },
      },
    },
  },
  /* 
  =================================================================================================================
  === PROFILE PAGE ================================================================================================
  =================================================================================================================
  */
  profilePage: {
    myItemsText: "My Wines", //new
    // Color of navbar line on active tab (Transactions, my items / liked items)
    navActiveColor: 'black',
    // Text that displays on the toolbar for redeeming your items
    navRedeemText: 'Claim',
    // Text for the NavItem which displays the users liked NFT's
    navLikedText: 'Liked',
    loggedOutText: 'Please log in to view your wines',
    dashboard: {
      styleOverride: {
        text: {
          color: 'white',
        },
      },
    },
  },
  /* 
  =================================================================================================================
  === NFT DETAIL PAGE =============================================================================================
  =================================================================================================================
  */
  nftDetail: {

    lessImageMobileDetailView: true,

    // This option adds a custom description to every NFT detail page - Leave as '' to remove
    generalDescription: 'Build a wine collection with Vaulted.',
    // The text which appears on the button to purchase a listed NFT
    checkoutButtonText: 'Buy',
    // Custom accordian to add to every Detail Page
    customAccordian: {
      include: true, // Whether or not to include this accordian in detail view
      header: 'Buying wines on Vaulted', // Header text of the Custom accordian
      items: [
        'After purchase, we store your wine in perfect cellar conditions at a secure facility on Lourensford Wine Estate', // item shown in accoridan dropdown sperated by a divider
        'You will receive a digital certificate (NFT) as proof of ownership and digital representation of your wines.', // item shown in accoridan dropdown sperated by a divider
        'Ownership certificates may be traded freely on the Vaulted service or third-party NFT marketplaces. The original wine producer receives a royalty on all future trades of the wine.',
        'The ownership certificate may be exchanged for the wine at any time, either directly at Lourensford Wine Estate, or by being shipped to the address of your choice (shipping charges apply).',
      ],
      link: {
        include: true,
        linkTo: '/FAQ',
        text: 'View More'
      }
    },
  },
  /* 
  =================================================================================================================
  === FOR SALE PAGE ===============================================================================================
  =================================================================================================================
  */
  forSalePage: {
    // Whether to include this page in the App
    include: true,

    // Header on the for sale page
    header: 'Browse Vault',

    // undefined or image require('./assets/<image-path.extension>')
    headerImage: require('./assets/digitalwines.png'),
  },
  /* 
  =================================================================================================================
  === NFT VAULT TABLE LIST PAGE ===================================================================================
  =================================================================================================================
  */
  nftVaultTable: {
    // Table to display all vault tokens,
    table: {
      /*
      Table is already populated with: Details, Name, Image, Description and a link to Opensea.
      The below options will have to exist within the attributes (properties) of the token metadata. 
      All options here will be displayed as a column within the table.
      */
      attributesFromMeta: ['Vintage', 'Seal Codes', 'Volume (ml)'],
    },
  },
  /* 
  =================================================================================================================
  === FOOTERS =====================================================================================================
  =================================================================================================================
  */
  footers: {
    // Text on mobile permanent footer,
    mobileText: 'Vaulted',

    stayInformedText: 'Sign up for Fanfire newsletters and receive information on new wines and other promotions.',

    footerTextColor: {
      styleOverrides: {
        text: {
          color: 'white',
        },
        socialMediaFooterTextColor: {
          text: {
            // color: 'black'
          },
        },
      },
    },
  },
  /* 
  =================================================================================================================
  === ATTENDANCE TOKENS PAGE ======================================================================================
  =================================================================================================================
  */
  attendanceTokens: {
    // either byImage or byText - Specifies the CLient name through text
    byImage: undefined,

    // either byImage or byText - Specifies the CLient name through text
    byText: 'Placeholder Client Name',

    // Background Image of attandance token page
    background: undefined,

    // Heading of the Attendance Token page
    heading: 'Attendance Token Heading',

    // Sub-heading of the Attendance Token page
    sub_heading: 'Attendance Token sub heading',
  },
};
export default appConfig;

/* 
=================================================================================================================
=== FAQ CONFIG ==================================================================================================
=================================================================================================================

Enter All FAQ options in here. This will populate the FAQ Page.
*/
export const faqs = [
  {
    heading: "General",
    questions: [
      {
        question: 'Terms and Conditions',
        answer: `
# Vaulted Wine

Buyer Terms and Conditions

Last updated: 03 October 2022

Please read these terms and conditions carefully before using Vaulted's Service.

# Conditions of Purchase

  1. To use Vaulted Wine and purchase from it, you must be of legal drinking age.
  2. Due to the various ages of bottles, corks and their seals, condition of the wine and or spirit is entirely and solely at the buyer's discretion.
  3. No claim or claims can be made against Vaulted in the event of undrinkable or unideal wine and or spirits.
  4. No wine and or spirit is considered new, however, all items are expected to be in a generally good conditions – if not, this will be made clear in the item description.
  5. The images, illustrations, and content provided may not be of the actual item you will receive and are provided solely for display purposes.
  6. All information provided for the wines and/or spirits, including tasting notes, vintage, and age, is correct to the best of our knowledge, but we cannot guarantee its accuracy.
  7. If any mistakes, error, discrepancy, or issue are identify you should notify Vaulted immediately.
  8. With purchasing a wine and or spirit you confirm that you have checked the item and are satisfied with the listing and your purchase.
  9. If for any reason some wine and or spirits are no longer available after payment has been made, users will be refunded.

# Sales Fees & Return Policy

  1. All items are listed in US Dollar with a South African rands equivalent amount at the prevalent conversion rate.
  2. As a buyer, you pay the list/purchase price of the wine and or spirit. In addition to the purchase price, the buyer must also provide a 3-year storage deposit (R150 per annum per case, i.e., R450 per case). This deposit is refunded pro rata if the wine and or spirit is resold, or redeemed.
  3. All wines and or spirits that are for sale on Vaulted's platform ([vaulted.fanfire.ai/for-sale](http://vaulted.fanfire.ai/for-sale)) will have a 10-minute buy-window in which you can make your purchase.
  4. A 2.5% Redemption fee covers the protocol costs of effacing the digital ownership certificate (or NFT) and exchanging it for the physical item.
  5. Further Vault and seller fees may apply.
  6. All goods must be inspected when picked up or delivered. Please contact Vaulted within 2 days of collection or delivery if your order was incorrectly fulfilled or damaged. You will not be eligible for a refund after that.
  7. Vaulted will make every effort to ensure that orders are correctly filled. If you do not receive exactly what you ordered upon redemption or delivery, we will gladly correct the problem or provide a full refund at no additional cost.

# Ownership and Insurance

  1. Any wines and or spirits purchased will result in the ownership certificate, or NFT, of such wine and or spirit being assigned to your cryptographic (custodial) Wallet via a blockchain transaction.
  2. You enjoy full ownership over your purchased wine and or spirit in your Vaulted Wallet.
  3. Your wine and or spirits are safely stored in a secure Lourensford Wine Estate cellar which maintains ideal conditions.
  4. While in the Vault, your wine and or spirit is insured up to a maximum of R400.00 per Standard 750ml bottle. The cost of which shall be prorated for bottles of various sizes.

# Storage and Shipping

  1. No unauthorised person will be allowed to enter the Vault.
  2. Your wine and or spirit is securely stored in ideal cellar conditions (temperature and security) for long-term storage of fine wines.
  3. You hold the right to redeem any of the wines and or spirits in your Wallet at the Vault during operating hours with prior notice.
  4. You can choose to have your item shipped to you at an additional cost.
  5. Storage of wines and spirits include the cataloguing and labelling of cases with unique identifying tags (UIT) and QR-codes.

# Limitation of Liability

  1. To the maximum extent permitted by applicable law, in no event shall Vaulted, on its own behalf and on behalf of its affiliates, and its and their respective licensors and service providers, be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, loss of privacy arising out of or in any way related to the use of or inability to use the Service, loss or damage of any item, or otherwise in connection with any provision of this Terms), even if the buyer has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
  2. Vaulted Wine, on its own behalf and on behalf of its affiliates, and its and their respective licensors and service providers, cannot be held responsible for refunds or replacements of wines deemed spoilt, over-mature, cork-tainted, or faulty in any way.
  3. Without limitation to the foregoing, Vaulted provides no warranty or undertaking, and makes no representation of any kind that the service will meet the buyers requirements, achieve any intended results, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
  4. Although maintaining cellar conditions, the Vault is neither guaranteed or represented by Vaulted to be suitable for storing wine and/or spirits. By agreeing to these terms, the buyer confirms satisfied with the condition of the Vault.
  5. The buyer forfeits all wine and/or spirits at the Vault to Vaulted and waives any claims of any kind against Vaulted if Vaulted cannot contact the buyer in any given year, despite reasonable attempts to make contact, or if the buyer has not paid the storage fee(s) - if any - due for such year.
  6. Once wine and or spirit leaves the Vault, the liability falls on the customer.

# Changes to Terms and Conditions

  1. Vaulted reserve the right, at sole discretion, to modify or replace these Terms at any time. If a revision is material Vaulted will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Vaulted's sole discretion.
  2. By continuing to access or use Vaulted's Service after those revisions become effective, the Buyer agree to be bound by the revised terms.

# Contact

If you have any questions about these Terms and Conditions, You can contact us:

By email: [collin@vaulted.wine](mailto:collin@vaulted.wine)

By telephone: +27 21 833 9652
        `
      }
    ]
  },
  {
    heading: 'How to',
    questions: [
      {
        question: 'How to Sign Up',
        answer: `https://youtube.com/embed/27B3QS2qWfQ`,
        type: 'video'
      },
      {
        question: 'Navigating the platform',
        answer: `https://youtube.com/embed/vkFWkokBPuM`,
        type: 'video'
      },
      {
        question: 'How to purchase wine',
        answer: `https://youtube.com/embed/iTHO1AOXwhk`,
        type: 'video'
      },
      {
        question: 'View, sell and redeem wine',
        answer: `https://youtube.com/embed/-xYpnAYjT-c`,
        type: 'video'
      },
    ]
  },
  {
    heading: 'The Service',
    questions: [
      {
        question: 'Can I consign my wine?',
        answer: `Yes. Individuals and estates may consign cases of wine to the wine vault. Optionally, individual bottles (e.g., historical vintages or magnums) may also be accommodated. The vault has full autonomy over available capacity, and how to manage the variety of consignments added to the vault. Upon consignment, each case is uniquely labelled, and an NFT is minted that represents that specific case.`,
      },
      {
        question: 'Where is my wine stored?',
        answer: `Your wine is safely stored in a secure Lourensford Wine Estate vault which maintains ideal cellar conditions (temperature, humidity, and security) for long-term storage of fine wines.`,
      },
      {
        question: 'Can I redeem my wine?',
        answer: `Yes. Just visit the vault at Lourensford Wine Estate with your NFT in your wallet or contact us for delivery. A buyer can redeem their wines in person or by ordering shipping (additional shipping fees will then apply). A 2.5% redemption fee covers the protocol costs of destroying the NFT and exchanging it for the physical item.`,
      },
      {
        question: 'What are the fees to consign my wine?',
        answer: `Firstly, the consigning estate sets the list price of the NFT. This allows the cellar to charge retail or premium prices for their wines – particularly for cases of rarer or sought-after vintages. Additionally, 1% of all future royalties (10% charged on resales) will flow to the original consigning cellar.
        The vault provides 6 months storage after initial consignment, free of charge. `,
      },
      {
        question: 'What fees do I pay as a consignee of wines?',
        answer: `As a consignee of wine you will receive 88.5% of the list price of your wine. The remaining amount is split up to accommodate for vault storage fees, protocol fees on the blockchain, and Fanfire's fee. The consigning estate can, however, set the NFT list price, which allows the cellar to charge premium prices for their wines.`,
      },
      {
        question: 'What fees do I pay if I buy wine?',
        answer: `As a buyer, you pay the list/purchase price of the NFT. In addition to the purchase price, the buyer must also provide a 3-year storage deposit (R150 per annum per case, i.e., R450 per case). This deposit is refunded pro rata if the NFT is resold, or the wine redeemed.`,
      },
      {
        question: 'Where are the fees going?',
        answer: `## On the initial sale of a wine NFT, the price breakdown is as follows:
1. The consigning estate receives 88.5% of the list price.
2. The vault receives 1% of the list price.
3. Fanfire receives an 8% commission.
4. The NFT trading platform (e.g., Fanfire or OpenSea) charges a standard 2.5% protocol fee to cover blockchain gas fees and other transaction costs.


## When a wine NFT is resold, a 10% resale royalty is charged, which is standard for NFT resales. The royalty is distributed as follows:

1. 1% to the original consigning cellar.
2. 1% to the vault.
3. 8% to Fanfire as sales commission.

A buyer can redeem their wines in person or by ordering shipping (additional shipping fees will then apply). A 2.5% redemption fee covers the protocol costs of destroying the NFT and exchanging it for the physical item.`,
      },
    ],
  },
  {
    heading: 'About Vaulted and Fanfire',
    questions: [
      {
        question: 'What is Vaulted?',
        answer:
          'Vaulted is a product of Fanfire.ai, an Enterprise Web3 Solutions firm focusing on providing tailored blockchain services. Vaulted allows its clients to build up wine collections while wines are safely stored in a secure and optimal wine vault.',
      },
      {
        question: 'What does Fanfire do?',
        answer:
          'Fanfire provides solutions for artists, athletes and businesses who recognise the opportunities presented by the third-generation web (“Web3”), such as cryptographic tokens, web wallets, and loyalty economies. The team has worked with a diverse range of partners such as the Cell C Sharks rugby franchise and Care for Wild Rhino Sanctuary to create new Web3 engagement strategies, and is also involved in digitizing the ownership and trade of collectible wines.',
      },
      {
        question: 'Who is Fanfire?',
        answer: `Fanfire is part of the Alphawave Group, a leading technology investment group consisting of businesses with products and services that are hard to replicate. The group is headquartered in Stellenbosch and applies South Africa's signature high-innovation-low-cost creativity to concepts with world-wide application. The company is led by the former Research Chair in IoT at Stellenbosch University (who co-founded Custos, an InfoSec blockchain company, in 2013). It is chaired by an industry veteran who has led two JSE-listed companies, and has decades of experience in technology, entertainment and consumer businesses, including one of the largest alcoholic beverage firms.`,
      },
      {
        question: 'Why are NFTs used?',
        answer: `A non-fungible token or NFT is merely an ownership certificate. In much the same way that a title deed represents the ownership of a house, an NFT is a cryptographically-secured digital certificate that proves ownership of something. 
It can represent ownership of anything; such as commercial rights (of a movie or artwork), it could represent ownership of a certain privilege (such as VIP access to a sport stadium or event), or even represent ownership of a physical collectable (such as a wine vintage)  the possibilities are endless. NFTs are validated and secured on blockchains.`,
      },
      {
        question: 'What is a blockchain?',
        answer: `In short, a blockchain is a method of recording information such that it is nearly impossible to alter, hack or cheat the system. 
        A blockchain is underpinned by a decentralised (meaning not one institution has custody over it) digital ledger that tracks transactions using secure and trustless protocols. The most famous blockchains include the Bitcoin- and Ethereum-blockchains. 
        Blockchain and cryptocurrencies are not the same. Bitcoin and USDC are two examples of a cryptocurrency, like rands or dollars, that run on a blockchain. These cryptocurrencies are merely a few lines of code (known colloquially as tokens) in a smart contract that gets executed.`,
      },
      {
        question: 'What is USDC?',
        answer:
          'USD Coin or USDC is a cryptocurrency (like Bitcoin of Ethereum) which is pegged to the United States dollar, making it extremely stable. USDC was launched in 2018 by a consortium of companies called Centre, and is is fully collateralised while being US dollar-backed.',
      },
      {
        question: `What is a 'Web 3 wallet'?`,
        answer:
          'A Web3 wallet is essentially a digital wallet which contains all your digital assets, such as NFTs and other coins (or cryptocurrency).',
      },
      {
        question: 'How can I sell my NFT?',
        answer: `On the Fanfire platform you will be able to 'list' your NFTs for sale by clicking the 'List Item' button on your NFT profile page.`,
      },
      {
        question: 'How do I redeem my funds?',
        answer:
          'Fanfire uses VALR.com, a trusted digital asset trading platform, for users to convert their cryptocurrency into rands. VALR enables the seamless buying, selling, storing and transfer of any cryptocurrency safely and securely in ZAR.',
      },
      {
        question: 'Why does my transaction take so long?',
        answer: `Fanfire's platform is extremely secure and depends on a number of protocols to facilitate a transaction. Some transactions might take longer than others to complete as the blockchain needs to be updated.`,
      },
    ],
  },
];
