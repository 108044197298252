import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Link as RouterLink } from "react-router-dom";
import CardItem from "../cardItem/CardItem";
import CardSkeleton from "../loaders/CardSkeleton";
import Text from "../text/Text";
import axios from "axios";
import { useLocation } from "react-router-dom";
import SecondaryButton from "../buttons/SecondaryButton";
import PrimaryButton from "../buttons/PrimaryButton";
import useFetchExchange from "../../hooks/useFetchExchange";
import { FanfireSDK } from "fanfire-sdk";

interface MapNFTsProps {
  nftItemsIn: any;
  loading: boolean;
  appConfig: any;
  sdkService: FanfireSDK;
  // setPage?: any;
  // page?: number;
  // amountItems?: number;
}

export default function MapCardItems({
  nftItemsIn,
  loading,
  sdkService,
  appConfig,
}: MapNFTsProps) {
  const [currency, setCurrency] = useState<number>(0);
  const [nftItems, setNFts] = useState<any>([]);
  // const [currencyLoading, setCurrencyLoading] = useState<boolean>(true);

  const location = useLocation();

  useEffect(() => {
    setNFts(nftItemsIn.filter((item: any) => item !== undefined));
  }, [nftItemsIn]);

  const { currencyError, currencyLoading, currencyPrice } = useFetchExchange({
    coin: "usd-coin",
    currency: "zar",
  });

  // const getCoinVsCurrency = async (coin: string, currency: string) => {
  //   // https://www.coingecko.com/en/api/documentation
  //   try {
  //     const resp = await axios.get(
  //       `${process.env.REACT_APP_FANFIRE_API_BASE_URL}/forex/exchange/usd-coin/zar`
  //     );

  //     const zar = resp.data["usd-coin"]["zar"];
  //     setCurrencyLoading(false);
  //     return zar;
  //   } catch (error) {
  //     console.log("error");
  //     return "error";
  //   }
  // };

  // useEffect(() => {
  //   let mounted = true;
  //   const prep = async () => {
  //     setCurrencyLoading(true);
  //     const currency = await getCoinVsCurrency("usd-coin", "zar");
  //     if (mounted && currency !== "error") {
  //       setCurrency(currency);
  //     }
  //   };
  //   prep();
  //   return () => {
  //     mounted = false;
  //   };
  // }, []);

  return (
    <Grid
      container
      padding={5}
      pt={10}
      px={"10%"}
      spacing={5}
      alignItems="center"
      justifyContent="center"
      direction="row"
    >
      {!loading ? (
        nftItems.length !== 0 ? (
          <>
            {nftItems.map(
              (
                nftElement: any,
                i: number // update any
              ) => (
                <Grid
                  item
                  key={nftElement._id}
                  container
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={3}
                  style={{ display: "grid" }}
                  pb={5}
                >
                  <RouterLink
                    key={i}
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: `/${nftElement.contractAddress}/${nftElement.tokenId}`,
                    }}
                    state={
                      !location.pathname.includes("/profile")
                        ? {
                            ref: nftElement.tokenId,
                            address: nftElement.contractAddress,
                            nft: nftElement,
                          }
                        : {}
                    }
                  >
                    <CardItem
                      keyRef={nftElement._id}
                      nft={nftElement}
                      currency={!currencyLoading ? currencyPrice : 0}
                      appConfig={appConfig}
                      sdkService={sdkService}
                    />
                  </RouterLink>
                </Grid>
              )
            )}
          </>
        ) : (
          !location.pathname.includes("/for-sale") && (
            <Grid item xs={6}>
              {/* <Text variantText="h3" style={{ textAlign: "center" }}>
                  No items to display
                </Text> */}
              <Text
                variantText="h6"
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                }}
              >
                You don't own any items.
              </Text>
              <RouterLink style={{ textDecoration: "none" }} to="/for-sale">
                <PrimaryButton>
                  <Text
                    variantText="body1"
                    style={{
                      fontWeight: "bold",
                      ...appConfig.general.styleOverrides
                        .buttonPrimaryTextColor,
                    }}
                  >
                    Explore{" "}
                    {appConfig.general.site_noun_plural
                      ? appConfig.general.site_noun_plural
                      : "Items"}
                  </Text>
                </PrimaryButton>
              </RouterLink>
            </Grid>
          )
        )
      ) : (
        [1, 2, 3, 4].map((e, i) => (
          <Grid
            item
            key={`${i}-grid-item`}
            container
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            style={{ display: "grid" }}
            pb={5}
          >
            <CardSkeleton keyRef={i} />
          </Grid>
        ))
      )}
    </Grid>
  );
}
