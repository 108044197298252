import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import Text from "../text/Text";
import { Variant } from "@mui/material/styles/createTypography";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { getEllipsisTxt } from "../../helpers/formatters";
import { FanfireSDK } from "fanfire-sdk";

interface Props {
  sdkService: FanfireSDK;
  variant?: Variant;
  textColor?: string;
}

export default function CopyWalletAddress({
  variant,
  sdkService,
  textColor,
}: Props) {
  const [copyText, setCopyText] = useState<string>("Copy");
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getwallet = async () => {
      const userWallet = sdkService.wallet.walletAddress;
      setWalletAddress(userWallet ?? "");
      setLoading(false);
    };
    setLoading(true);
    getwallet();
  }, []);

  const copyWallet = async () => {
    navigator.clipboard.writeText(walletAddress);
    setCopyText("Copied!");
    await new Promise((r) => setTimeout(r, 2000));
    setCopyText("Copy");
  };
  return (
    <Box>
      <Tooltip
        placement="top"
        enterTouchDelay={0}
        title={copyText}
        disableFocusListener
        arrow
      >
        <div style={{ cursor: "pointer" }}>
          <Text
            variantText={variant ?? "body1"}
            onClick={() => copyWallet()}
            style={{ textAlign: "center", color: textColor }}
          >
            {loading ? (
              <Skeleton variant="text" />
            ) : (
              <>
                {getEllipsisTxt(walletAddress)}
                <ContentCopyRoundedIcon fontSize="inherit" />
              </>
            )}
          </Text>
        </div>
      </Tooltip>
    </Box>
  );
}
