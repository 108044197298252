import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import React from "react";
import Text from "../text/Text";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useIsMobile from "../../hooks/useIsMobile";

interface Props {
  nftDetail: any;
}

export default function MoreDetailsAccordion({ nftDetail }: Props) {
  const { mobileView } = useIsMobile();
  return (
    <Box pt={1} pb={1}>
      <Accordion
        className="primary"
        defaultExpanded={mobileView ? true : false}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Text
            variantText="body1"
            style={{
              textTransform: "none",
              fontWeight: "bolder",
            }}
          >
            More details
          </Text>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justifyContent="center" spacing={1}>
            {nftDetail.metadata && nftDetail.metadata.attributes ? (
              nftDetail.metadata.attributes.map((e: any, i: any) => (
                <Grid key={`${i}-gird-${e.trait_type}`} item>
                  <Box sx={{ border: "solid 1px white" }} p={1}>
                    <Text variantText="h6" style={{ textAlign: "center" }}>
                      {e.trait_type}
                    </Text>
                    <Text variantText="body1" style={{ textAlign: "center" }}>
                      {e.display_type && e.display_type === "date" ? (
                        <>
                          {new Date(e.value * 1000).toLocaleString("en-GB", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                            hour: "numeric",
                            minute: "2-digit",
                          })}
                        </>
                      ) : (
                        <>{e.value}</>
                      )}
                    </Text>
                  </Box>
                </Grid>
              ))
            ) : (
              <Text variantText="h6" style={{ textAlign: "center" }}>
                No Attributes
              </Text>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
