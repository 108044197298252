import React, { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Text from "../text/Text";

interface Props {
  label: any;
  onChange?: any;
  typeRequest: string;
  qtyLimit?: number;
}

export default function CheckboxRequestInput({
  typeRequest,
  label,
  onChange,
  qtyLimit,
}: Props) {
  const [checked, setChecked] = useState(false);
  const [qty, setQty] = useState(1);

  let labelName = "";
  if (typeof label === "string") {
    labelName = label;
  } else {
    if (label.metadata) {
      labelName = label.metadata.name;
    } else {
      labelName = label.name;
    }
  }

  useEffect(() => {
    if (qtyLimit) {
      setQty(qtyLimit);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (!checked) {
      onChange({
        qty: qty,
        item: label,
        type: typeRequest,
        remove: false,
      });
    } else {
      onChange({
        qty: qty,
        item: label,
        type: typeRequest,
        remove: true,
      });
      setQty(1);
    }
  };

  const handleAdd = () => {
    let newQty: number = qty;
    if (Number(qtyLimit)) {
      if (qty === Number(qtyLimit)) {
        console.log("User hit the quantity Limit");
      } else {
        newQty = newQty + 1;
        setQty(newQty);
      }
    } else {
      newQty = newQty + 1;
      setQty(newQty);
    }

    onChange({
      qty: newQty,
      item: label,
      type: typeRequest,
      remove: false,
    });
  };

  const handleRemove = () => {
    const newQty: number = qty - 1;
    if (newQty === 0) {
      setChecked(!checked);
      onChange({
        qty: newQty,
        item: label,
        type: typeRequest,
        remove: true,
      });
      setQty(1);
    } else {
      setQty(newQty);
      onChange({
        qty: newQty,
        item: label,
        type: typeRequest,
        remove: false,
      });
    }
  };

  return (
    <FormGroup key={`${labelName}-form-group`}>
      <Grid container p={1} alignItems="center">
        <Grid item xs={12} sm={6} md={8}>
          <FormControlLabel
            key={`${labelName}-form-label`}
            control={
              <Checkbox
                key={`${labelName}-checkbox`}
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                // style ={{
                //   color: "white",
                // }}
              />
            }
            sx={{ float: "left" }}
            label={
              <Text variantText="h6" style={{ textAlign: "left" }}>
                {labelName}
              </Text>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {checked ? (
            <ButtonGroup
              key={`${labelName}-button-group`}
              size="small"
              component="div"
            >
              <Button
                onClick={() => handleRemove()}
                style={{ border: "none" }}
                component="div"
              >
                <Text variantText="h6">-</Text>
              </Button>
              <Button style={{ border: "none" }} component="div">
                {qtyLimit ? (
                  <Text variantText="h6">
                    {qty}
                    {`/${qtyLimit}`}
                  </Text>
                ) : (
                  <Text variantText="h6">{qty}</Text>
                )}
              </Button>
              <Button
                onClick={() => handleAdd()}
                style={{ border: "none" }}
                component="div"
              >
                <Text variantText="h6">+</Text>
              </Button>
            </ButtonGroup>
          ) : (
            <></>
          )}
        </Grid>
        {qtyLimit && (
          <Grid item xs={12} sm={8} md={6}>
            <Text
              variantText="body1"
              style={{ textAlign: "left", float: "left" }}
            >
              Quantity Available: {qtyLimit}
            </Text>
          </Grid>
        )}
      </Grid>
    </FormGroup>
  );
}
