import React from 'react'
import Button from '@mui/material/Button'

interface Props {
  onClick?: any;
  disabled?: boolean;
  children?: React.ReactNode;
}

export default function SecondaryButton({ children, onClick, disabled=false }: Props) {
  return (
    <Button 
      className='secondary'
      fullWidth 
      onClick={onClick}
      disabled={disabled} 
      variant='text'
    >
      {children}
    </Button>
  )
}
