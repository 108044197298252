import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import Loader from "../loaders/BasicLoader";
import { useSnackBar } from "../../context/snackAlert-context";
import axios from "axios";

// MUI
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Text from "../text/Text";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
// import TertiaryButton from "../buttons/TertiaryButton";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { currencyFormat } from "../../helpers/formatters";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useSelector, RootStateOrAny } from "react-redux";
import { FanfireSDK } from "fanfire-sdk";
import useUnlist from "../../hooks/sdkMethods/useUnlist";

const styles = {
  dialogContainer: {
    borderRadius: "16px",
    width: "100%",
  },
};

interface Props {
  nft: any;
  address: any;
  setListed?: any;
  setNFT?: any;
  sdkService: FanfireSDK;
  appConfig: any;
  relist?: boolean;
}

export default function ListNFT({
  nft,
  address,
  setListed,
  setNFT,
  sdkService,
  appConfig,
  relist = false,
}: any) {
  const [open, setOpen] = useState(false);
  const [listCoinPrice, setListCoinPrice] = useState<number>(0);
  const [listMinZarPrice, setListMinZarPrice] = useState<number>(0);
  const [listAmount, setListAmount] = useState<number>(1);
  const [listAmountError, setListAmountError] = useState<boolean>(false);
  const [listPriceZarError, setListPriceZarError] = useState<boolean>(false);
  const [listPriceCoinError, setListPriceCoinError] = useState<boolean>(false);
  const [listPriceZarErrorText, setListPriceZarErrorText] =
    useState<string>("");
  const [listPriceCoinErrorText, setListPriceCoinErrorText] =
    useState<string>("");
  const [listAmountErrorText, setListAmountErrorText] = useState<string>("");
  const [listZarPrice, setListZarPrice] = useState<number>(0);
  const [currencyLoading, setCurrencyLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [listLoadingText, setListLoadingText] = useState("false");
  const [currency, setCurrency] = useState<number>(0);
  const [royalty, setRoyalty] = useState({ coin: 0, zar: 0 });
  const { showSnackBar } = useSnackBar();

  const isWeb2 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb2);
  const isWeb3 = useSelector((state: RootStateOrAny) => state.user.auth.isWeb3);

  const { unListLoading, unListText, unlistError, sendUnlist } = useUnlist();

  const navigate = useNavigate();
  useEffect(() => {
    const prep = async () => {
      const currency = await getCoinVsCurrency("usd-coin", "zar");
      if (currency !== "error") {
        setCurrency(currency);
      }
      const zar = Number(currency !== "error" ? currency.toFixed(2) : 0);
      setListZarPrice(zar);
      setListMinZarPrice(zar);
      setListCoinPrice(1);
    };
    setCurrencyLoading(true);
    prep();
  }, []);

  // useEffect(() => {
  //   if (open) {
  //     console.log("AWEJKHABWDMNABWJDHB");
  //     console.log(nft);
  //   }
  // }, [open]);

  useEffect(() => {
    // LP - Listing Price
    // SFP - Service Fee Percentage
    // CFP - Creator Fee Percentage

    // you will get = LP - (SFP*LP) - (CFP*LP)
    const royaltyCoin =
      listCoinPrice -
      (appConfig.general.serviceFee / 100) * listCoinPrice -
      (appConfig.general.creatorFee / 100) * listCoinPrice;
    setRoyalty({ coin: royaltyCoin, zar: royaltyCoin * currency });
  }, [listZarPrice, listCoinPrice]);

  const handleList = async () => {
    try {
      setListLoading(true);
      if (relist) {
        setListLoadingText("Unlisting listed Item...");
        await sendUnlist({
          nftAddress: nft.nftAddress
            ? nft.nftAddress
            : nft.contractAddress
            ? nft.contractAddress
            : undefined,
          tokenId: nft.tokenId,
          sellerAddress: nft.sellerAddress,
          sdkService: sdkService,
          appConfig: appConfig,
          amount: 1,
          redirect: false,
        });
      }
      if (unlistError) throw new Error("Error unlisting item");

      console.log(nft);

      console.log("Listing item");
      setListLoadingText("Listing Item...");
      const paymentToken =
        process.env.REACT_APP_ERC20_PAYMENT_ADDRESS ??
        "0x1e65Cd156fe76d68B15C5f2Fa8B42C32Af5af048";
      const wallet = sdkService.wallet.walletAddress;
      const list = await sdkService.marketplace.listNFT(
        nft.nftAddress
          ? nft.nftAddress
          : nft.contractAddress
          ? nft.contractAddress
          : undefined,
        nft.tokenId,
        listCoinPrice,
        paymentToken,
        listAmount,
        wallet
      );
      console.log(list);
      setOpen(false);
      setListLoading(false);
      showSnackBar(
        `Listing Item was a Success. You will be able to see your listing on the ${
          appConfig.navItems.forSaleNavbarText
            ? appConfig.navItems.forSaleNavbarText
            : "For Sale"
        }`,
        "success"
      );
      if (setListed) {
        setListed(true);
      }
      if (setNFT) {
        setNFT({
          ...nft,
          price: Number(listCoinPrice),
          priceZar: Number(listZarPrice),
        });
      }
      if (isWeb2) {
        navigate("/profile/#transactionStarted", { replace: true });
      } else if (isWeb3) {
        navigate("/for-sale", { replace: true });
      }
    } catch (error) {
      console.error(error);
      setListLoadingText("Listing Item Failed!");
      showSnackBar("Listing Item Failed. Please try again.", "error", error);
      setListLoading(false);
      setOpen(false);
    }
  };

  const getCoinVsCurrency = async (coin: string, currency: string) => {
    // https://www.coingecko.com/en/api/documentation
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_FANFIRE_API_BASE_URL}/forex/exchange/usd-coin/zar`
      );

      const zar = resp.data["usd-coin"]["zar"];
      setCurrencyLoading(false);
      return zar;
    } catch (error) {
      console.error(error);
      return "error";
    }

    // const currency = await getCoinVsCurrency('usd-coin', 'zar')
  };

  const handleListCoinPriceChange = async (e: any) => {
    setListCoinPrice(e);
    const zar = e * currency;
    setListZarPrice(Number(zar.toFixed(2)));
    if (e < 1) {
      setListPriceCoinError(true);
      setListPriceCoinErrorText(`Minimum USDC list price is : 1`);
    } else if (e > Number((10000000 / currency).toFixed(2))) {
      setListPriceCoinError(true);
      setListPriceCoinErrorText(
        `Maximum USDC list price is : ${(10000000 / currency).toFixed(2)}`
      );
    } else {
      setListPriceCoinError(false);
      setListPriceZarError(false);
    }
  };

  const handleListZarPriceChange = async (e: any) => {
    setListZarPrice(e);
    const coin = e / currency;
    setListCoinPrice(Number(coin.toFixed(2)));
    if (e < listMinZarPrice) {
      setListPriceZarError(true);
      setListPriceZarErrorText(`Minumum ZAR list price is : R ${currency}`);
    } else if (e > 10000000) {
      setListPriceZarError(true);
      setListPriceZarErrorText("Maximum ZAR list price is : R 10 000 000");
    } else {
      setListPriceZarError(false);
      setListPriceCoinError(false);
    }
  };

  const handleListAmountChange = async (e: any) => {
    setListAmount(Math.trunc(e));
    if (e < 1) {
      setListAmountError(true);
      setListAmountErrorText(
        `Minimum quantity of ${
          appConfig.general.site_noun_plural
            ? appConfig.general.site_noun_plural
            : "items"
        } to list is 1.`
      );
    } else if (
      e >
      Number(
        !relist
          ? Number(Number(nft.ownedAmount ?? 0) - Number(nft.amount ?? 0))
          : Number(nft.ownedAmount ?? 0)
      )
    ) {
      setListAmountError(true);
      setListAmountErrorText(
        `Maximum quantity of ${
          appConfig.general.site_noun_plural
            ? appConfig.general.site_noun_plural
            : "items"
        } available to list is ${
          !relist
            ? Number(Number(nft.ownedAmount ?? 0) - Number(nft.amount ?? 0))
            : Number(nft.ownedAmount ?? 0)
        }.`
      );
    } else {
      setListAmountError(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box p={1}>
        <PrimaryButton
          disabled={listZarPrice === 0 ? true : false}
          onClick={handleClickOpen}
          // styleOverride={{ border: '1px solid #5A5A5A', width: '87px', height: '39px', textTransform: 'capitalize' }}
        >
          <Text variantText="body1">{relist ? `Edit` : `Sell`}</Text>
        </PrimaryButton>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: styles.dialogContainer,
        }}
        scroll="body"
      >
        <DialogTitle>
          <Avatar sx={{ width: 64, height: 64, background: "#000000" }}>
            <PlaylistAddOutlinedIcon sx={{ fontSize: 50, color: "white" }} />
          </Avatar>
        </DialogTitle>
        <DialogTitle id="alert-dialog-title">
          <Text variantText="h1">List NFT</Text>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Text variantText="body1">
              List your{" "}
              {appConfig.general.site_noun
                ? appConfig.general.site_noun
                : "item"}{" "}
              to the marketplace for sale
            </Text>
          </DialogContentText>
          <Box pt={3}>
            <TextField
              id="outlined-basic"
              label="Price (ZAR)"
              variant="outlined"
              size="small"
              value={listZarPrice}
              type="number"
              fullWidth
              InputProps={{ inputProps: { min: 1, max: 10000000 } }}
              InputLabelProps={{ style: { fontFamily: "Raleway" } }}
              error={listPriceZarError}
              helperText={listPriceZarError && listPriceZarErrorText}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "black",
                  },
                  "&:hover fieldset": {
                    borderColor: "black",
                  },
                  fontFamily: "raleway",
                },
              }}
              //defaultValue={minPrice}
              onChange={(e: any) => handleListZarPriceChange(e.target.value)}
            />
          </Box>
          <Box pt={3} pb={3}>
            <TextField
              id="outlined-basic"
              label="Price (USDC)"
              variant="outlined"
              size="small"
              value={listCoinPrice}
              type="number"
              fullWidth
              InputProps={{ inputProps: { min: 1 } }}
              InputLabelProps={{ style: { fontFamily: "Raleway" } }}
              error={listPriceCoinError}
              helperText={listPriceCoinError && listPriceCoinErrorText}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "black",
                  },
                  "&:hover fieldset": {
                    borderColor: "black",
                  },
                  fontFamily: "raleway",
                },
              }}
              onChange={(e: any) => handleListCoinPriceChange(e.target.value)}
            />
          </Box>
          {nft.isERC1155 && (
            <Box pb={3}>
              <TextField
                id="outlined-basic"
                label="Quantity"
                variant="outlined"
                size="small"
                value={listAmount}
                type="number"
                fullWidth
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: !relist
                      ? Number(
                          Number(nft.ownedAmount ?? 0) - Number(nft.amount ?? 0)
                        )
                      : Number(nft.ownedAmount ?? 0),
                  },
                }}
                InputLabelProps={{ style: { fontFamily: "Raleway" } }}
                error={listAmountError}
                helperText={
                  listAmountError
                    ? listAmountErrorText
                    : `The quantity of ${
                        appConfig.general.site_noun_plural
                          ? appConfig.general.site_noun_plural
                          : "items"
                      } you want to list. Available: ${
                        !relist
                          ? Number(
                              Number(nft.ownedAmount ?? 0) -
                                Number(nft.amount ?? 0)
                            )
                          : Number(nft.ownedAmount ?? 0)
                      } units `
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black",
                    },
                    "&:hover fieldset": {
                      borderColor: "black",
                    },
                    fontFamily: "raleway",
                  },
                }}
                onChange={(e: any) => handleListAmountChange(e.target.value)}
              />
            </Box>
          )}
          <DialogContentText id="alert-dialog-description">
            <Text variantText="h6" style={{ textAlign: "right" }}>
              You will get:
            </Text>
            <Text variantText="body1" style={{ textAlign: "right" }}>
              {!nft.isERC1155
                ? !currencyLoading && currencyFormat(royalty?.zar, "ZAR")
                : !currencyLoading &&
                  currencyFormat(royalty?.zar * listAmount, "ZAR")}
            </Text>
            <Text variantText="body2" style={{ textAlign: "right" }}>
              {!nft.isERC1155
                ? !currencyLoading &&
                  currencyFormat(royalty?.coin, isWeb3 ? "USDC" : "$")
                : !currencyLoading &&
                  currencyFormat(
                    royalty?.coin * listAmount,
                    isWeb3 ? "USDC" : "$"
                  )}
              {isWeb2 && " (USD)"}
            </Text>
          </DialogContentText>
          <DialogContentText
            pt={3}
            id="alert-dialog-description"
            component="div"
          >
            <Divider></Divider>
            <Text variantText="h6">
              Fees:
              <Tooltip
                enterTouchDelay={0}
                title={
                  <Text variantText="body1">
                    Listing is free. Once sold, the following fees will be
                    deducted.{" "}
                    <a href="/faq" target="_blank">
                      Learn more
                    </a>
                  </Text>
                }
                placement="top"
              >
                <InfoOutlinedIcon fontSize="small" style={{ float: "right" }} />
              </Tooltip>
            </Text>
            <Text variantText="body1">
              Service Fee:
              <Text variantText="body1" style={{ float: "right" }}>
                {appConfig.general.serviceFee}%
              </Text>
            </Text>
            <Text variantText="body1">
              {appConfig.general.creatorFeeText
                ? appConfig.general.creatorFeeText
                : "Creator Fee"}
              :
              <Text variantText="body1" style={{ float: "right" }}>
                {appConfig.general.creatorFee}%
              </Text>
            </Text>
          </DialogContentText>
        </DialogContent>
        {!listLoading ? (
          <Box m={2}>
            <DialogActions>
              <SecondaryButton onClick={() => handleClose()}>
                <Text variantText="body1">Cancel</Text>
              </SecondaryButton>
              {!listPriceZarError && !listPriceCoinError && !listAmountError && (
                <PrimaryButton onClick={() => handleList()}>
                  <Text
                    variantText="body1"
                    style={{
                      fontWeight: "bold",
                      ...appConfig.general.styleOverrides
                        .buttonPrimaryTextColor,
                    }}
                  >
                    {relist ? `Edit` : `Sell`}
                  </Text>
                </PrimaryButton>
              )}
            </DialogActions>
          </Box>
        ) : (
          <>
            <Loader size={25} text={listLoadingText} />
            <Text
              variantText="body1"
              style={{ textAlign: "center", textTransform: "none" }}
            >
              This may take a few minutes.
            </Text>
          </>
        )}
      </Dialog>
    </div>
  );
}
