import React from 'react'
import { useNavigate } from "react-router-dom";

// MUI
import Grid from '@mui/material/Grid'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

//Components
import Text from '../text/Text';

const styles = {
    nftImage: {
        height: '100%',
        width: '100%',
        filter: 'drop-shadow(0px 1.27599px 15.6522px #000000)',
        borderRadius: '10px',
        objectFit: 'contain' as 'contain',
        display: 'block'
    },
    grid: {
        textAlign: 'left' as 'left', 
        position: 'relative' as 'relative',
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-around',
    },
    imageContainer:{
        position: 'relative' as 'relative',
    },
    overlayBackButton: {
        position: 'absolute' as 'absolute',
        top: -50,
        color: 'white',
    }
  };


interface Props{
    mobileView: boolean;
    appConfig: any;
}

export default function NFTDetailError({ mobileView, appConfig }: Props) {
    const navigate = useNavigate();

    return (
        <Grid container pt={25} pb={10} spacing={5} justifyContent="center" alignItems='center'>
            <Grid item xs={10} sm={10} md={8} lg={3} style={styles.grid} >
            <div style={styles.overlayBackButton}>
                <Avatar sx={{ width: 40, height: 40, background: `${appConfig.navItems.backButtonNavIconPaddingColor ? appConfig.navItems.backButtonNavIconPaddingColor : 'rgba(255, 255, 255, 0.1)'}`, top: '-5vh' }}>
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBackIosNewRoundedIcon sx={{ fontSize: 25, color: `${appConfig.navItems.backButtonNavIconColor ? appConfig.navItems.backButtonNavIconColor : 'white'}` }} />
                    </IconButton>
                </Avatar>
            </div>
            <Text variantText='h1' style={{textAlign: 'center'}}><ErrorOutlineIcon sx={{ fontSize: 40, color: 'red' }}/></Text>
            <Text variantText='h1' style={{textAlign: 'center'}}>{`An error occured retrieving this ${appConfig.general.site_noun ? appConfig.general.site_noun : 'item'}`}</Text>
            </Grid>
            <Grid item xs={10} sm={10} md={8} lg={6} style={styles.imageContainer}>
                <img style={styles.nftImage} src={appConfig.general.listViewsErrorImage} alt="NFT" />
            </Grid>
        </Grid>
    )
}