// Libraries
import React, { useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { ethers } from "ethers";
import axios from "axios";
import { useSelector, RootStateOrAny } from "react-redux";

// Packages
import { FanfireSDK } from "fanfire-sdk";

// Hooks, Context & Helpers
import { NetworkStatusEnums } from "../../helpers/enums";
import { getLowest1155Price } from "../../helpers/functions";
import useFetchExchange from "../../hooks/useFetchExchange";
import appConfigContext from "../../context/appConfig-context";
import SDKContext from "../../context/sdk-context";
import useIsMobile from "../../hooks/useIsMobile";

// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip/Chip";

//components
import NFTDetailError from "../../components/errorComponents/NFTDetailError";
import NFTDetailSkeleton from "../../components/loaders/NFTDetailSkeleton";
import ChipItem from "../../components/chip/ChipItem";
import NFTDetailBottomBar from "../../components/detail/NFTDetailBottomBar";
import ScrollToTop from "../../components/helpers/ScrollToTop";
import OpenSeaLinks from "../../components/detail/OpenSeaLinks";
import NFT1155Info from "../../components/detail/NFT1155Info";
import BasicDetails from "../../components/detail/BasicDetails";
import DetailActions from "../../components/detail/DetailActions";
import BackButton from "../../components/backButton/BackButton";
import Favorite from "../../components/favourite/Favorite";
import MediaShowcase from "./components/MediaShowcase";

//Types
import { NftVM } from "fanfire-sdk/dist/models/models/nft";

const styles = {
  grid: {
    textAlign: "left" as "left",
    position: "relative" as "relative",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-around",
  },
  imageContainer: {
    position: "relative" as "relative",
  },
};

interface Props {
  mobileView: boolean;
  authLoading: boolean;
  sdkService: FanfireSDK;
  appConfig: any;
  sliceStore: any;
}

export default function NFTDetail({ authLoading, sliceStore }: Props) {
  const [nftDetail, setNFTDetail] = useState<any>({});
  const [nftDetailListing, setNFTDetailListing] = useState<any>({});
  const [listed, setListed] = useState(false);
  const [isReserved, setIsReserved] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingFastProps, setLoadingFastProps] = useState<boolean>(true);
  const [isMarketPlaceNFT, setIsMarketPlaceNFT] = useState<boolean>(true);
  const [nftError, setNFTError] = useState<boolean>(false);
  const { address, id }: any = useParams();

  const { mobileView } = useIsMobile();
  const appConfig: any = useContext(appConfigContext).appConfig;
  const sdkService: FanfireSDK = useContext(SDKContext).ffsdk;

  const location = useLocation();
  const isAuthenticated =
    NetworkStatusEnums.SUCCESS ===
    useSelector((state: RootStateOrAny) => state.user.status);

  const { currencyPrice, getApiExchangeData } = useFetchExchange({
    coin: "usd-coin",
    currency: "zar",
    lazy: true,
  });

  /*
  ------------------------------------------------------------------------------------------------------
   * Get NFT detail, this section gets the basics of a listing to display and make load time quicker
  ------------------------------------------------------------------------------------------------------
   */
  useEffect(() => {
    const prep = async () => {
      await getNFT();
    };
    setLoading(true);
    if (!authLoading) {
      console.log("Getting NFT Detail");
      prep();
    }
  }, [authLoading]);

  const getNFT = async () => {
    //If the NFT data is within location use that to populate state
    //the details not included will be added later
    const loc: any = location.state;
    const nftProp: any = loc && loc.nft ? loc.nft : null;
    if (nftProp) {
      console.log("nft populated through state", nftProp);
      const isListed =
        nftProp.listings && checkListingPriceExist(nftProp.listings);
      if (nftProp.listings && isListed) {
        //Because some of the data that exists within the listings call come through with the state from sale page
        //we add that data to the listing state because it is used to show the price etc.
        await createNFTListingDetail(nftProp);
      }

      if (isListed) {
        setListed(true);
      } else if (nftProp.price) {
        setListed(true);
      } else {
        setListed(false);
      }
      setNFTDetail(nftProp);
      createNFTDetail(nftProp, isListed);
      setLoading(false);
    } else {
      setLoading(true);
    }

    //Get nft details from sdk, if state was used this is called just to refresh all the info as well
    let nfts: any;
    let nft: NftVM | undefined;
    let isListed: boolean = false;
    try {
      nfts = await sdkService.marketplace.getNftDetail(
        ethers.utils.getAddress(address),
        id,
        isAuthenticated ? sdkService.wallet.walletAddress : undefined
      );
      nft = nfts[0]; // sends through array of tokens, but with detail there is always just one entry as we are calling detail for one item
      if (nftProp) {
        nft = { ...nftProp, ...nft };
      }
      isListed = await sdkService.marketplace.isListed(address, id, "");
      setListed(isListed);
    } catch (error) {
      nft = undefined;
      console.log("There was an error fetching the NFT:");
      console.error(error);
    }

    console.log("NFT Details: ", nft);
    if (nft === undefined) {
      setNFTError(true);
    } else if (nft.metadata) {
      await createNFTDetail(nft, isListed);
    } else if (nft.tokenUri && nft.tokenUri.startsWith("https://")) {
      try {
        console.log("There is no metadata getting from it tokenURI...");
        const res = await axios.get(nft.tokenUri);
        await createNFTDetail(nft, isListed, res);
      } catch (error) {
        console.log(
          "There was an error fetching the NFT metadata from token URI"
        );
        console.error(error);
      }
    }
    setLoading(false);
  };

  const getTokenPrice = (nft: any) => {
    if (nft.listings) {
      return getLowest1155Price(nft);
    } else if (nft.isERC721) {
      return Number(
        ethers.utils.formatUnits(
          nft.price,
          Number(process.env.REACT_APP_FANFIRE_COIN_DECIMALS ?? 18)
        )
      );
    } else {
      return 0;
    }
  };

  const createNFTDetail = async (nft: any, listed: boolean, res?: any) => {
    let nftAddressesENV =
      process.env.REACT_APP_FANFIRE_NFT_CONTRACT_ADDRESSES?.split(",") ?? [];
    nftAddressesENV = nftAddressesENV.map(function (x) {
      return x.toLowerCase();
    });
    const isMarketPlaceNFTNFT = nftAddressesENV.includes(
      nft.contractAddress.toLowerCase()
    );

    const metadata = res === undefined ? nft.metadata : res.data;

    if (
      nft.consolidatdReservedUntil &&
      nft.consolidatdReservedUntil !== 0 &&
      Date.now() / 1000 <= nft.consolidatdReservedUntil
    ) {
      setIsReserved(true);
    }

    setIsMarketPlaceNFT(isMarketPlaceNFTNFT);
    setNFTDetail({
      ...nftDetail, //merge with anything in state, as it could have already been set with props
      ...nft,
      metadata: metadata,
    });
  };

  /*
  ------------------------------------------------------------------------------------------------------
  ------------------------------------------------------------------------------------------------------
  */

  /*
  ------------------------------------------------------------------------------------------------------
   * Get NFT detail Listing, this section gets the details of a listing to display which includes price
   * and amount Owned etc
  ------------------------------------------------------------------------------------------------------
   */

  useEffect(() => {
    const prep = async () => {
      await getNFTListing();
    };
    if (!authLoading && isAuthenticated) {
      console.log("Getting NFT Detail listing info");
      prep();
    }
  }, [authLoading, isAuthenticated]);

  // const objectsEqual: any = (o1: any, o2: any) =>
  //   typeof o1 === "object" && Object.keys(o1).length > 0
  //     ? Object.keys(o1).length === Object.keys(o2).length &&
  //       Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
  //     : o1 === o2;

  // const arraysEqual: any = (a1: any, a2: any) =>
  //   a1.length === a2.length &&
  //   a1.every((o: any, idx: any) => objectsEqual(o, a2[idx]));

  const getNFTListing = async () => {
    setLoadingFastProps(true);
    const nftListing = await sdkService.marketplace.getNftListing(
      address,
      id,
      sdkService.wallet.walletAddress
    );
    console.log("NFTListing", nftListing);
    createNFTListingDetail(nftListing);
    setLoadingFastProps(false);
  };

  const checkListingPriceExist = (listings: any) => {
    return (
      listings.filter((e: any) => {
        return e.price;
      }).length > 0
    );
  };

  const createNFTListingDetail = async (nft: any) => {
    const priceCoin = getTokenPrice(nft);
    const priceCurrency = await getApiExchangeData();
    const priceZar = priceCurrency * priceCoin;

    const nftListing = {
      listings: nft.listings,
      nftAddress: nft.nftAddress,
      tokenId: nft.tokenId,
      price: priceCoin,
      ownedAmount: nft.ownedAmount ? nft.ownedAmount : 0,
      priceZar: priceZar,
    };

    setNFTDetailListing(nftListing);
  };

  return (
    <>
      <ScrollToTop />
      {!authLoading && !loading ? (
        nftError ? (
          <NFTDetailError mobileView={mobileView} appConfig={appConfig} />
        ) : (
          <>
            {!loading &&
              !authLoading &&
              !appConfig.general.disablePurchasing &&
              mobileView && (
                <NFTDetailBottomBar
                  userWallet={sdkService.wallet.walletAddress ?? ""}
                  nft={nftDetail}
                  nftDetailListing={nftDetailListing}
                  loading={loading}
                  reserved={isReserved}
                  listed={listed}
                  isInCurrentMarketplace={isMarketPlaceNFT}
                  sdkService={sdkService}
                  sliceStore={sliceStore}
                  appConfig={appConfig}
                  loadingFastProps={loadingFastProps}
                />
              )}
            <Grid
              container
              pt={!mobileView ? 25 : 5}
              pb={!mobileView ? 10 : 2}
              spacing={5}
              px={{ sm: 0, md: 15, lg: 20 }}
              justifyContent="center"
            >
              <Grid
                container
                item
                xs={10}
                sm={10}
                md={6}
                lg={6}
                justifyContent="center"
                order={{ xs: 1, sm: 1, md: 1, lg: 2 }}
                style={styles.imageContainer}
              >
                {/* --- Displays the image or video in mobile view or when lessAppImage in the appConfig --- */}
                <MediaShowcase nftDetail={nftDetail} />
              </Grid>
              <Grid
                item
                xs={10}
                sm={10}
                md={6}
                lg={6}
                style={styles.grid}
                order={{ xs: 2, sm: 2, md: 2, lg: 1 }}
              >
                {!mobileView && <BackButton appConfig={appConfig} />}
                {!mobileView && (
                  <Box display="flex" justifyContent="space-between">
                    {!loadingFastProps ? (
                      <ChipItem
                        userWallet={sdkService.wallet.walletAddress ?? ""}
                        nft={nftDetail}
                        listed={listed}
                      />
                    ) : (
                      <Chip className="primary" label={"..."} />
                    )}
                    <Favorite sdkService={sdkService} nft={nftDetail} />
                  </Box>
                )}
                <BasicDetails
                  appConfig={appConfig}
                  nftDetail={nftDetail}
                  nftDetailListing={nftDetailListing}
                  sdkService={sdkService}
                  sliceStore={sliceStore}
                  isReserved={isReserved}
                  isMarketPlaceNFT={isMarketPlaceNFT}
                  loadingFastProps={loadingFastProps}
                  listed={listed}
                  setListed={setListed}
                  setNFTDetail={setNFTDetail}
                  currency={currencyPrice}
                />
                <DetailActions
                  nftDetail={nftDetail}
                  appConfig={appConfig}
                  sdkService={sdkService}
                  isMarketPlaceNFT={isMarketPlaceNFT}
                />
              </Grid>
              <Grid item xs={12} order={{ xs: 3, sm: 3, md: 3, lg: 3 }}>
                <NFT1155Info
                  appConfig={appConfig}
                  nftDetail={nftDetail}
                  nftDetailListing={nftDetailListing}
                  sdkService={sdkService}
                  sliceStore={sliceStore}
                  loadingFastProps={loadingFastProps}
                />
              </Grid>
            </Grid>
            <OpenSeaLinks nftDetail={nftDetail} appConfig={appConfig} />
          </>
        )
      ) : (
        <NFTDetailSkeleton mobileView={mobileView} appConfig={appConfig} />
      )}
    </>
  );
}
