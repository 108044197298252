import React from 'react'
import Button from '@mui/material/Button'

interface Props {
  styleOverride?: object;
  onClick?: any;
  children?: React.ReactNode;
}
  
export default function TertiaryButton({ styleOverride, onClick, children }: Props) {
  return (
    <Button 
      className='tertiary'
      onClick={onClick} 
      style={{...styleOverride}}
    >
      {children}
    </Button>
  )
}
