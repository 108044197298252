import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";

//MUI
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";

//Components
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import Text from "../text/Text";

interface Props {
  onClose?: ({}: boolean) => void;
  appConfig: any;
}

const { ethereum } = window;
let provider: any;
if (ethereum) {
  provider = new ethers.providers.Web3Provider(ethereum);
}

/*
=================================================================
Component used to show ta popup to let the user manually exist metamask.
We cannot log the user out programmatically
Mostly a display component with no real app functionality
=================================================================
*/

export default function MetaMaskLogout({ onClose, appConfig }: Props) {
  const [open, setOpen] = useState(true);
  const [connected, setConnected] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setConnected(false);
    if (onClose) onClose(false);
  };

  const handleConfirm = async () => {
    // Check whether there is still a connection to metamask
    // if still connected a prompt is shown
    console.log("User Confirmation - Reloading page");
    const accounts = await provider.listAccounts();
    if (accounts.length > 0) {
      setConnected(true);
      return false;
    }
    setConnected(false);
    navigate("/", { replace: true });
    window.location.reload();
  };

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose()} scroll="body">
        <DialogTitle>
          <img
            src={appConfig.general.metaMaskLogo}
            height={100}
            width={120}
            alt="FF"
          />
        </DialogTitle>
        <DialogTitle style={{ textTransform: "uppercase" }}>
          MetaMask
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingBottom: "20%" }}>
            <Text variantText="body1">
              Unfortunately, We are unable to log you out ourselves.
            </Text>
            <Text variantText="body1">
              Please Disconnect from MetaMask via your browser Extension.
            </Text>
            {connected && (
              <Box pt={5}>
                <Text variantText="h6">
                  There is still a connected MetaMask Account.
                </Text>
                <Text variantText="body1">
                  Disconnect via the Extension and then press Confirm!
                </Text>
              </Box>
            )}
          </DialogContentText>
          <PrimaryButton onClick={() => handleConfirm()}>
            <Text
              variantText="body1"
              style={{
                fontWeight: "bold",
                ...appConfig.general.styleOverrides.buttonPrimaryTextColor,
              }}
            >
              Confirm
            </Text>
          </PrimaryButton>
          <SecondaryButton>
            <Text variantText="body1" onClick={() => handleClose()}>
              Cancel
            </Text>
          </SecondaryButton>
        </DialogContent>
      </Dialog>
    </div>
  );
}
