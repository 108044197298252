import React from 'react'

//Components
import Text from '../text/Text';

//MUI
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Grow from '@mui/material/Grow';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const styles = {
  card:{
    borderRadius: '12px',
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
    overflow: 'visible',
    position: 'relative' as 'relative'
  },
  image:{
    borderRadius: '9.2px',
    filter: 'drop-shadow(0px 1.95652px 24px #000000)',
    objectFit: 'cover' as 'cover',
    width: '100%'
  },
  chip: {
    float: "left" as 'left',
  },
  viewArtButton: {
    marginLeft: 'auto',
  },
  overlay: {
     position: 'absolute' as 'absolute',
     top: '20%',
     left: '10px',
     color: 'white',
  }
}

interface Props{
  appConfig: any;
}

export default function MapNFTsError({ appConfig }: Props) {
  return (
    <Grid item xs={10}>
      <Box>
        <Card style={styles.card}
        sx={{
          ':hover': {
            transform: "scale3d(1.05, 1.05, 1)",
          },
          ':hover > img.cardImage': {
            transform: "scale3d(1.05, 1.05, 1)",
          },
          
        }}
        >
          <CardContent>
            <Text variantText='h1' style={{textAlign: 'center'}}><ErrorOutlineIcon sx={{ fontSize: 40, color: 'red' }}/></Text>
            <Text variantText='h6' style={{textAlign: 'center' , textTransform: 'none'}}>{`Error retrieving ${appConfig.general.site_noun_plural ? appConfig.general.site_noun_plural : 'items'}`}</Text>
          </CardContent>
          <Grow in={true}
            style={{ transformOrigin: '0 0 0 0' }}
            {...(true ? { timeout: 3000 } : {})}>
              <CardMedia
                className='cardImage'
                style={styles.image}
                component="img"
                height="230px"
                // width="100%"
                image={appConfig.general.listViewsErrorImage}
                alt="CardItem"
              />
          </Grow>
        </Card>
      </Box>
    </Grid>
  );
}