// Libraries
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

// MUI
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Stack, StepIconProps, styled, useTheme } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Check } from "@mui/icons-material";
import Button from "@mui/material/Button";

// Components
import Text from "../text/Text";

// Helpers & Functions
import { currencyFormat } from "../../helpers/formatters";
import { formatIntentText } from "./functions/txFunctions";

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "darkorange",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "green",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

const steps = ["Processing Payment", "Transferring", "Wrapping Up"];

interface tx {
  id: string;
  createdAt: string;
  type: "buy" | "list" | "unlist" | "transfer" | "gift";
  priceCoin: number;
  priceCurrency: number;
  status: "pending" | "failed" | "complete" | "sent";
  heading: string;
  subHeading: string;
  name: string;
  step: "payment" | "transfer" | "complete";
  nftAddress: string;
  tokenId: string;
}

interface Props {
  purchase: tx;
  keyRef: number;
  appConfig: any;
}

export default function FiatBuyProgressBasic({
  purchase,
  keyRef,
  appConfig,
}: Props) {
  const [activeStep, setActiveStep] = useState<number>(0);
  const theme = useTheme();

  const styles = {
    span: {
      color: "#8247E5",
    },
    accordion: {
      borderRadius: "8px",
    },
    image: {
      borderRadius: "9.2px",
      objectFit: "contain" as "contain",
      marginRight: "10px",
      height: "50px",
    },
    cardStatus: {
      borderRight: `8px solid ${
        purchase.status === "pending" || purchase.status === "sent"
          ? "darkorange"
          : purchase.status === "complete"
          ? "green"
          : "red"
      }`,
      borderTopRightRadius: "3px",
      borderTopLeftRadius: "3px",
      width: "100%",
    },
    cardStatus1: {
      borderRight: `8px solid`,
      borderColor: `${
        purchase.status === "pending" || purchase.status === "sent"
          ? "darkorange"
          : purchase.status === "complete"
          ? "green"
          : "red"
      }`,
      borderBottomRightRadius: "3px",
      borderTopLeftRadius: "3px",
      width: "100%",
    },
  };

  useEffect(() => {
    // console.log(purchase);
    if (purchase.step === "payment") {
      setActiveStep(0);
    }
    if (purchase.step === "transfer") {
      setActiveStep(1);
    }
    if (purchase.step === "complete") {
      setActiveStep(2);
    }
    if (purchase.status === "complete") {
      setActiveStep(3);
    }
  }, [purchase]);

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  return (
    <div key={`${keyRef}-${purchase.id}-div-outer`}>
      <Box m={1} key={`box-${keyRef}`}>
        <Accordion className="primary" key={`accordion-${keyRef}`}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={styles.cardStatus}
          >
            <Stack sx={{ width: "100%" }}>
              <Box
                display="inline-flex"
                sx={{ justifyContent: "space-between" }}
              >
                <Text
                  variantText="body1"
                  style={{ textAlign: "left", textTransform: "capitalize" }}
                >
                  {new Date(purchase.createdAt).toLocaleString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                  })}
                  : <strong>{purchase.status}</strong>
                </Text>
                <Text
                  variantText="body1"
                  style={{ textAlign: "right", textTransform: "capitalize" }}
                >
                  {formatIntentText(purchase.type)}
                </Text>
              </Box>
              <Grid
                container
                pt={1}
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid item xs={8} sm={4} order={{ xs: 1 }}>
                  <Box display="flex">
                    {/* <img
                      style={styles.image}
                      src={
                        "https://media.istockphoto.com/id/515707206/photo/glass-of-red-wine-on-white.jpg?s=612x612&w=0&k=20&c=egaR8Rt_4izII-4dcj4zkxYP4Smx1cWpL4Q2COv4-jo="
                      }
                      alt="image"
                    /> */}
                    <Stack>
                      <Text
                        variantText="h6"
                        style={{
                          textAlign: "left",
                          maxWidth: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "capitalize",
                        }}
                      >
                        {formatIntentText(purchase.heading)}
                      </Text>
                      <Text variantText="body1" style={{ textAlign: "left" }}>
                        {purchase.subHeading}
                      </Text>
                    </Stack>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{ sm: { pt: 2 }, md: { pt: 0 } }}
                  order={{ xs: 3, sm: 2 }}
                >
                  <Text
                    variantText="body1"
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    {purchase.name}
                  </Text>
                </Grid>
                <Grid
                  item
                  xs={4}
                  justifyContent="right"
                  order={{ xs: 2, sm: 3 }}
                >
                  <Text variantText="body1" style={{ textAlign: "right" }}>
                    {purchase.priceCurrency
                      ? currencyFormat(purchase.priceCurrency, "ZAR")
                      : ""}
                  </Text>
                  <Text variantText="body1" style={{ textAlign: "right" }}>
                    {purchase.priceCoin
                      ? currencyFormat(purchase.priceCoin, "USD")
                      : ""}
                  </Text>
                </Grid>
              </Grid>
            </Stack>
          </AccordionSummary>

          <AccordionDetails style={styles.cardStatus1}>
            <Box sx={{ width: "100%" }}>
              {purchase.type === "buy" && (
                <>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon}>
                          <Text
                            variantText="body1"
                            style={{ textAlign: "center" }}
                          >
                            {label}
                          </Text>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </>
              )}
              <Box textAlign="center" pt={4}>
                <Button
                  className="primary"
                  variant="contained"
                  sx={{
                    paddingX: "1.5rem",
                    color: theme.palette.primary.contrastText,
                  }}
                  component={RouterLink}
                  to={{
                    pathname: `/${purchase.nftAddress}/${purchase.tokenId}`,
                  }}
                  state={{
                    ref: purchase.tokenId,
                    address: purchase.nftAddress,
                  }}
                >
                  <Text
                    variantText="h6"
                    style={{ textAlign: "center", textTransform: "capitalize" }}
                  >
                    View{" "}
                    {appConfig.general.site_noun
                      ? appConfig.general.site_noun
                      : "item"}
                  </Text>
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
}
